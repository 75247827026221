
import React, { useEffect } from "react";
import { AdSlot, DFPManager, DFPSlotsProvider } from "react-dfp";
import { adsense, GetTypeDevice } from "helpers/functions";

const Advertisements = ({
  type,
  sizes,
  slotId,
  adUnit,
  style,
  setIsEmpty = () => {},
  targetingArguments = {},
}) => {
  const NETWORK_ID = process.env.REACT_APP_ADS_NETWORK_ID;
  const currentType = GetTypeDevice();

  useEffect(() => {
    if (adsense() && type === currentType) {
      if (Object.entries(targetingArguments).length > 0) {
        DFPManager.setTargetingArguments(targetingArguments);
      }
      //DFPManager.configureSingleRequest(true);
      //DFPManager.configureLazyLoad(true);
      //DFPManager.configureLimitedAds(true);
      DFPManager.setCollapseEmptyDivs(true);
      DFPManager.getGoogletag().then((googletag) => {
        googletag.cmd.push(() => {
          googletag.pubads().refresh();
        });
      });

      DFPManager.load();
    }

  }, []);

  useEffect(() => {
    if (adsense() && type === currentType) {
      if (Object.entries(targetingArguments).length > 0) {
        DFPManager.setTargetingArguments(targetingArguments);
        DFPManager.reload(slotId);
      }
    }

  }, [targetingArguments]);

  return (
    <>
      {adsense() && type === currentType && (
        <DFPSlotsProvider dfpNetworkId={NETWORK_ID}>
          <div>
            <AdSlot
              dfpNetworkId={NETWORK_ID}
              sizes={[sizes]}
              slotId={slotId}
              adUnit={adUnit}
              onSlotRender={(ed) => {
                setIsEmpty(ed.event.isEmpty);
              }}
            />
          </div>
        </DFPSlotsProvider>
      )}
    </>
  );
};

export default Advertisements;
