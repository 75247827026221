export const initialState = {
    loading:false,
    initLoading:false,
    product:{},
    favorite:false,
    quantity:0,
    action:"",
    baseImg:"",
    specs:[],
    pictures:[],
    relateds:[],
    locations:[],
    distribution:{},
    distributionDetail:{},
    open:"none",
    openFull: "none",
    openContainer:"none",
    videoSrc:""
  }