import React, { useContext, useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { getData, postData } from "helpers/service";
import { findElement } from "helpers/functions";
import Loading from "components/share/loading";
import GoogleMaps from "components/share/maps";
import { useNavigate } from "react-router-dom";
import ListAddress from "./ListAddress";
import AccountContext from "context/Account";
import { updateStateReducer } from "store/common/commonReducer";
import { initialState } from "store/checkout/shippingRate/state";
import {
  LOADING,
  STORES,
  CONTACTS,
  ADDRESSES,
  LOCATION_ID,
  PICKUP_ID,
  ADDRESS_ID,
  SHIPPING_TYPE,
  SHIPPING_RATES,
  STORE_MARKER,
} from "store/checkout/shippingRate/actionTypes";

export default function SelectShippingRate({
  isOpen,
  onSetClose,
  onSetShippingRate,
  type,
}) {
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const DEFAULT_SHIPPING_TYPE = account?.default_shipping_type;

  const [
    {
      loading,
      stores,
      contacts,
      addresses,
      locationId,
      pickupId,
      addressId,
      shippingType,
      storeMarker,
      shippingRates,
    },
    dispatch,
  ] = useReducer(updateStateReducer, initialState);

  const setShippingRate = () => {
    let data = {
      shipping_type: shippingType,
      addressid: addressId,
      pickupid: pickupId,
      locationid: locationId,
    };

    dispatch({ type: LOADING, payload: true });
    postData(`checkout/shipping-rate`, data, {}, (res, err) => {
      if (!err) {
        onSetShippingRate(res.data);
      }
      dispatch({ type: LOADING, payload: false });
    });
  };

  const goToContactsPage = () => {
    onSetClose("none", "webModal-container");
    navigate("/account/contacts", { replace: true });
  };

  const goToAddressPage = () => {
    onSetClose("none", "webModal-container");
    navigate("/account/addresses", { replace: true });
  };

  const handleSetAddressId = (value) => {
    dispatch({ type: ADDRESS_ID, payload: value });
   }

  useEffect(() => {
    if (type !== null) dispatch({type:SHIPPING_TYPE, payload:type});
  }, [type]);

  useEffect(() => {
    const currentStore = stores.find((item) => item.locationid === locationId);
    dispatch({ type: STORE_MARKER, payload: currentStore });
  }, [locationId]);

  useEffect(() => {
    if (isOpen === "shipping-rate") {
      dispatch({ type: LOADING, payload: true });
      dispatch({ type: SHIPPING_TYPE, payload: DEFAULT_SHIPPING_TYPE });
      getData(`checkout/shipping-rate`, null, (res, err) => {
        if (!err) {
          dispatch({ type: STORES, payload: res.stores });
          dispatch({ type: CONTACTS, payload: res.contacts });
          dispatch({ type: ADDRESSES, payload: res.addresses });
          dispatch({ type: SHIPPING_RATES, payload: res.shippingrates });
          dispatch({ type: LOCATION_ID, payload: findElement(res.stores || [], "by_default", 1)?.locationid });
          dispatch({ type: PICKUP_ID, payload: findElement(res.contacts || [], "by_default", 1)?.pickupid });
          dispatch({ type: ADDRESS_ID, payload: findElement(res.addresses || [], "by_default", 1)?.addressid });
        }
        dispatch({ type: LOADING, payload: false });
      });
    }

  }, [isOpen]);

  const ListContact = () =>
    contacts?.map((item, idx) => (
      <li key={idx}>
        <label>
          <input
            onChange={() => dispatch({type:PICKUP_ID , payload:item.pickupid})}
            className="uk-radio"
            type="radio"
            name="radio-contact"
            defaultChecked={
              item.pickupid === pickupId || item.by_default === 1
            }
          />{" "}
          {`${item.first_name} ${item.last_name}`}
        </label>
      </li>
    ));

  const ListStore = () =>
    stores?.length > 0 &&
    stores?.map((item, idx) => (
      <li key={idx}>
        <label>
          <input
            onChange={() => dispatch({type:LOCATION_ID, payload:item.locationid})}
            className="uk-radio"
            type="radio"
            name="radio-pickup"
            defaultChecked={item.locationid === locationId}
          />
          <span>{item.name}</span>
        </label>
      </li>
    ));

  if (isOpen !== "shipping-rate") return null;

  return ReactDOM.createPortal(
    loading ? (
      <Loading />
    ) : (
      <div id="modal-shipping-rate" data-test="modal-shipping-rate" className="uk-modal-dialog">
        <button
          onClick={() => onSetClose("none", "webModal-container")}
          className="uk-modal-close-default"
          type="button"
          data-uk-close
        ></button>
        <div className="uk-modal-body">
          {/* Tabs */}
            <ul uk-tab="connect: #pickup-delivery-modal;">
              
              {shippingRates.filter((item)=>(item.active === 1)).map((item) => (
                <li key={item.shipping_type}
                    className={item.shipping_type === shippingType && 'uk-active'}>
                  <a href="#0"
                     onClick={() => dispatch({ type: SHIPPING_TYPE, payload: item.shipping_type })}>
                  {item.name}
                </a>
              </li>
              ))}

          </ul>

          <ul id="pickup-delivery-modal" className="uk-switcher">
            {/* Tab pickup */}
            <li>
                <div
                  className="uk-child-width-1-2@m uk-grid uk-flex"
                  uk-grid="true"
                >
                  <div>
                    <div
                      className="uk-position-relative"
                      uk-height-viewport="offset-bottom: 70"
                    >
                      <div id="gmapModal" className="gmap">
                        <GoogleMaps stores={[storeMarker]} />
                      </div>
                    </div>
                    <div className="gi_map_title">{storeMarker?.address}</div>
                    <div className="uk-visible@m gi_pickup_txt">
                      <hr className="uk-margin-medium-top uk-margin-medium-bottom gi_remove_hr" />
                      <p className="uk-h5">Quién recoge</p>
                      <ul className="uk-list">
                        <ListContact />
                      </ul>

                      <p>
                        <button
                          onClick={goToContactsPage}
                          className="uk-button uk-button-primary uk-button-small"
                        >
                          <span>Agregar un Contacto</span>
                          <i className="fas fa-user-plus"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                  <div className="uk-flex-first@m">
                    <p className="uk-h5">Localidad</p>
                      <ul id="stores-list-pickup" data-test="stores-list-pickup"
                        className="uk-list">
                      <ListStore />
                    </ul>
                  </div>
                </div>
            </li>
            {/* Tab Delivery */}
            <li>
              <div className="uk-grid">
                <div className="uk-width-1-1 uk-width-3-5@m gi_delivery_txt">
                  <p className="gi_explain2">
                    Entrega en el horario seleccionado de tu preferencia.
                  </p>
                  <p className="uk-h5">Direcciones</p>
                  <ul className="uk-list" data-test="address-list-delivery">
                    <ListAddress
                      addresses={addresses}
                      addressId={addressId}
                      setAddressId={handleSetAddressId}
                      shippingType="D"
                    />
                  </ul>
                  <p>
                    <button
                      onClick={goToAddressPage}
                      className="uk-button uk-button-primary gi_round_grey_button"
                    >
                      <i className="fas fa-map-marked-alt"></i> Agregar una
                      Dirección
                    </button>
                  </p>
                </div>
                <div className="uk-width-1-1 uk-width-2-5@m gi_delivery_ilust">
                  <img
                    src="/assets/global/images/img_delivery.jpg"
                    alt="Delivery"
                  />
                </div>
              </div>
              </li>       
            {/* Tab Delivery Express */}
              <li>
              <div className="uk-grid">
                <div className="uk-width-1-1 uk-width-3-5@m gi_delivery_txt">
                  <p className="gi_explain2">
                    <span>
                      Máximo 20 artículos. ¡Entrega en 45 minutos o menos!
                    </span>
                  </p>
                  <p className="uk-h5">Direcciones</p>
                  <ul id="address-list-DE" className="uk-list">
                    <ListAddress
                      addresses={addresses}
                      addressId={addressId}
                      setAddressId={handleSetAddressId}
                      shippingType="DE"
                    />
                  </ul>
                  <p>
                    <button
                      onClick={goToAddressPage}
                      className="uk-button uk-button-primary gi_round_grey_button"
                    >
                      <i className="fas fa-map-marked-alt"></i> Agregar una
                      Dirección
                    </button>
                  </p>
                </div>
                <div className="uk-width-1-1 uk-width-2-5@m gi_delivery_ilust2">
                  <img
                    src="/assets/global/images/img_deliveryexpress.jpg"
                    alt="Delivery Express"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="uk-modal-footer uk-text-center">
          <div
            className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center uk-grid"
            uk-grid="true"
          >
            <div className="uk-width-1-1 uk-width-expand@m uk-text-left@m">
              <p className="uk-text-small uk-text-bold">
                Cambiar la ubicación puede alterar la disponibilidad de tu
                pedido.
              </p>
            </div>
            <div className="uk-width-1-1 uk-width-auto@m">
              <button
                disabled={loading}
                onClick={() => onSetClose("none", "webModal-container")}
                className="uk-button uk-modal-close uk-button-custom"
                type="button"
              >
                <span>Cancelar</span>
                <i className="fas fa-times"></i>
              </button>
                <button
                  id="button-change"
                  data-test="button-change"
                  data-shipping={JSON.stringify({
                    shipping_type: shippingType,
                    addressid: addressId,
                    pickupid: pickupId,
                    locationid: locationId
                  })}
                disabled={loading}
                onClick={setShippingRate}
                className="uk-button uk-button-default uk-button-custom"
                type="button"
              >
                <span>Cambiar</span>
                <i className="fas fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
    document.getElementById("webModal-container")
  );
}
