export const OPEN = 'open'
export const ORDER = 'order'
export const BALANCE = 'balance'
export const PICKUP_ID = 'pickupId'
export const LOCATION_SLOT_ID = 'locationslotId'
export const LOCATION_SLOT = 'locationslot'
export const CURRENT_BALANCE = 'currentBalance'
export const CURRENT_TOTAL = 'currentTotal'
export const BASE_IMG = 'baseImg'
export const ITEMS = 'items'
export const LOADING = 'loading'
export const CURRENT_ITEM = 'currentItem'
export const CURRENT_IDX = 'currentIdx'
export const TMP_KEY = 'tmpkey'