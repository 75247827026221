/* eslint-disable */
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import LoginModal from "components/account/auth/login-modal";

const Footer = () => {
  let location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <div>
      <div className="uk-padding-small uk-padding-remove-left uk-padding-remove-right uk-background-tertiary uk-light">
        <div className="uk-container uk-container-expand">
          <div className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center">
            <div className="uk-width-1-1 uk-width-auto@m uk-text-left@m">
              <a
                href="#0"
                className="uk-button uk-button-default uk-button-small"
                hidden
              >
                <i className="fas fa-bell"></i> Suscríbete a nuestras
                notificaciones
              </a>
            </div>
            <div className="uk-width-1-1 uk-width-expand@m uk-text-right@m btns-download">
              <p className="uk-h5 uk-text-uppercase uk-width-expand@m">
                Descarga nuestra app móvil
              </p>
              <div className="uk-width-1-1 uk-width-auto@m">
                <a
                  className="btn-appstore"
                  href="https://apps.apple.com/us/app/sirena-do/id1493054629?l=es&ls=1"
                >
                  <img alt="" src="/assets/global/badge-appstore.svg" />
                </a>
                <p className="hidden-desktop googleplay uk-text-small">
                  * También disponible en{" "}
                  <a href="https://play.google.com/store/apps/details?id=com.gruporamos.shoppingcart">
                    {" "}
                    Google Play
                  </a>
                  .
                </p>
                <a
                  className="btn-playstore"
                  href="https://play.google.com/store/apps/details?id=com.gruporamos.shoppingcart"
                >
                  <img
                    alt="googleplay"
                    src="/assets/global/badge-googleplay.svg"
                  />
                </a>
                <p className="hidden-desktop appstore uk-text-small">
                  * También disponible en
                  <a href="https://apps.apple.com/us/app/sirena-do/id1493054629?l=es&ls=1">
                    App Store
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="main-footer" className="uk-background-secondary uk-light">
        <div className="uk-container uk-container-expand">
          <div
            className="uk-grid uk-child-width-1-2@s uk-child-width-1-4@m"
            uk-grid="true"
          >
            <div>
              <div className="subnav-footer subnav-footer-1">
                <p className="uk-h5">
                  <a href="#" uk-toggle="target: .subnav-footer-1; cls: show">
                    Información
                  </a>
                </p>
                <ul className="uk-nav">
                  <li>
                    <Link to="/club-siremas">Club Siremás</Link>
                  </li>

                  <li>
                    <Link to="/site/page/sobre-nosotros">Sobre Nosotros</Link>
                  </li>
                  <li>
                    <Link to="/site/page/ventajas">Ventajas</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.gruporamos.com"
                      className="link-gruporamos"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: "17%" }}
                        src="/assets/logo/logo_gr_footer.svg"
                        alt="Grupo Ramos"
                      />
                      <span>Sobre Grupo Ramos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="subnav-footer subnav-footer-2">
                <p className="uk-h5">
                  <a href="#" uk-toggle="target: .subnav-footer-2; cls: show">
                    Servicio al cliente
                  </a>
                </p>
                <ul className="uk-nav">
                  <li>
                    <Link to="/site/page/como-ordenar">Cómo Ordenar</Link>
                  </li>
                  <li>
                    <Link to="/site/page/preguntas-frecuentes">
                      Preguntas Frecuentes
                    </Link>
                  </li>
                  <li>
                    <Link to="/site/page/zona-cobertura">
                      Zonas de Cobertura
                    </Link>
                  </li>
                  <li>
                    <Link to="/site/page/cambios-y-devoluciones">
                      Cambios y devoluciones
                    </Link>
                  </li>
                  <li className="uk-nav-divider"></li>
                  <li>
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=18293224444&text=Deseo%20una%20ayuda%20con"
                    >
                      <i className="fab fa-whatsapp"></i>
                      <strong>(829) 322-4444</strong>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:servicioalcliente@sirena.do">
                      <i className="fas fa-envelope"></i>{" "}
                      servicioalcliente@sirena.do
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="subnav-footer subnav-footer-3">
                <p className="uk-h5">
                  <a href="#" uk-toggle="target: .subnav-footer-3; cls: show">
                    Encuéntranos
                  </a>
                </p>
                <ul className="uk-nav">
                  <li>
                    <Link to="/stores">Nuestras tiendas</Link>
                  </li>
                  <li className="uk-nav-divider"></li>
                  <li>
                    En las Redes
                    <ul className="nav-social">
                      <li>
                        <a
                          href="https://www.facebook.com/sirena.do"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span uk-icon="facebook"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/sirena"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span uk-icon="instagram"></span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/user/lasirenard"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span uk-icon="youtube"></span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="subnav-footer subnav-footer-4">
                <p className="uk-h5">
                  <a href="#" uk-toggle="target: .subnav-footer-4; cls: show">
                    Mi Cuenta
                  </a>
                </p>
                <ul className="uk-nav">
                  <li>
                    <Link to="/account">Ingresar</Link>
                  </li>
                  <li>
                    <Link to="/cart">Ver Carrito</Link>
                  </li>
                  <li>
                    <Link to="/account/favorites">Mis Favoritos</Link>
                  </li>
                  <li>
                    <Link to="/account/mylists">Mis Listas</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-container uk-container-expand uk-margin-medium-top">
          <hr className="uk-margin-medium-bottom" />
          <div className="uk-flex uk-flex-wrap uk-child-width-1-1 uk-child-width-1-2@m uk-text-center">
            <div className="uk-text-right@m uk-flex-last@m">
              <Link
                to="/site/page/terminos-y-condiciones"
                className="uk-button uk-button-text"
              >
                Términos y Condiciones
              </Link>
            </div>
            <div className="uk-text-left@m">
              v{process.env.REACT_APP_VERSION} © {new Date().getFullYear()}{" "}
              Grupo Ramos. Todos los derechos reservados.
            </div>
          </div>
        </div>
      </footer>
      <LoginModal />
    </div>
  );
};
export default Footer;
