import React from "react";
import ContentLoader from "react-content-loader"

export default function LoadingHome(props) {


    return <div className="uk-width-1-1" style={{height: '800px'}}>
        <div className="uk-position-top uk-text-center">
                <ContentLoader viewBox="0 0 500 400"
                               height={800} width={1000}
                               backgroundColor="#f3f3f3"
                               foregroundColor="#ffe64c"
                               {...props}>

                    <rect x="18" y="5" rx="0" ry="0" width="465" height="155" />
                    <rect x="18" y="170" rx="0" ry="0" width="141" height="38" />
                    <rect x="182" y="170" rx="0" ry="0" width="141" height="38" />
                    <rect x="343" y="170" rx="0" ry="0" width="141" height="38" />
                    <rect x="18" y="220" rx="0" ry="0" width="141" height="38" />
                    <rect x="182" y="220" rx="0" ry="0" width="141" height="38" />
                    <rect x="343" y="220" rx="0" ry="0" width="141" height="38" />



                    <circle cx="56" cy="320" r="38" />
                    <rect x="18" y="360" rx="5" ry="5" width="74" height="10" />
                    <circle cx="154" cy="320" r="38" />
                    <rect x="116" y="360" rx="5" ry="5" width="74" height="10" />
                    <circle cx="251" cy="320" r="38" />
                    <rect x="213" y="360" rx="5" ry="5" width="74" height="10" />
                    <circle cx="347" cy="320" r="38" />
                    <rect x="309" y="360" rx="5" ry="5" width="74" height="10" />
                    <circle cx="446" cy="320" r="38" />
                    <rect x="408" y="360" rx="5" ry="5" width="74" height="10" />

                </ContentLoader>

        </div>
    </div>

}
