import React, { useContext } from "react";
import {Link } from "react-router-dom"
import AccountContext from "context/Account";

export default function Connected()
{

    const {onLogOut, account} = useContext(AccountContext);

    return <div className='box-user box-user-menu toggle-login'>
        <div className='uk-flex uk-grid-small'>
            <div className='uk-width-auto'>
                <i className='fas fa-user-circle fa-3x'></i>
            </div>
            <div className='uk-width-expand'>
                <p className='uk-text-large uk-margin-remove-bottom'>Hola, {account?.first_name}</p>
                <a href="#0" onClick={onLogOut}   className='uk-text-small uk-link-text' ><i className='fas fa-sign-out-alt'></i> Salir</a>
            </div>
        </div>
        <div className='uk-navbar-dropdown-grid uk-child-width-1-2' uk-grid="true">
            <div>
                <ul className='uk-nav uk-navbar-dropdown-nav'>
                    <li><Link to='/account'><i className='fas fa-info-circle'></i> Mi Cuenta</Link></li>
                    <li><Link to='/account/order-history'><i className='fas fa-receipt'></i> Mis Órdenes</Link></li>
                    <li><Link to='/account/shopping-lists'><i className='far fa-file-alt'></i> Mis Listas</Link></li>
                    <li><Link to='/account/favorites'><i className='fas fa-heart'></i> Mis Favoritos</Link></li>
                </ul>
            </div>
            <div>
                <ul className='uk-nav uk-navbar-dropdown-nav'>
                    <li><Link to='/account/addresses'><i className='fas fa-map-marker-alt'></i> Mis Direcciones</Link></li>
                    <li><Link to='/account/contacts'><i className='fas fa-users'></i> Mis Contactos</Link></li>
                    <li><Link to='/account/creditcards'><i className='far fa-credit-card'></i> Mis Tarjetas</Link></li>
                    <li><Link to='/account/password'><i className='fas fa-key'></i> Cambiar contraseña</Link></li>
                </ul>
            </div>
        </div>
    </div>
}
