export const LOADING = 'loading';
export const SEARCH_VALUE = 'searchValue';
export const PRODUCTS = 'products';
export const CATEGORIES = 'categories';
export const PAGES = 'pages';
export const TOTAL = 'total';
export const SHOW = 'show';
export const TIMEOUT_ID = 'timeoutId';
export const BASE_IMG = 'baseImg';
export const HISTORY_VALUE = 'historyValue';
export const PREDICTIONS = 'predictions';