import React from "react";
import { Link } from "react-router-dom";
import {ProfileMenu} from "models/options"

export default function Profilebar() {

    let currentUrl = window.location.pathname;

    return <ul className='uk-accordion'>
        <li>
            <div className='uk-accordion-title uk-h5 uk-visible@m'>Mi cuenta</div>
            <div className='uk-accordion-content'>
                <ul className='uk-list'>
                    {ProfileMenu.map((o, idx) => (
                            <li key={idx} className={`${currentUrl === o.url ? "uk-active" : ""}`}>
                                <Link to={o.url}><i className={o.icon}></i>{o.name}</Link>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </li>
    </ul>
}
