
import React, { useContext } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Outlet } from "react-router-dom";
import Header from "components/share/header";
import Footer from "components/share/footer";
import Profilebar from "components/account/profile/profilebar";
import AuthAnonymous from "components/account/auth/anonymous";
import AccountContext from "context/Account";

UIkit.use(Icons);

export default function AccountLayout() {
  const { isLogin } = useContext(AccountContext);

  const ConectedAccount = () => (
    <div>
      {/* <div
        id="modal-filters"
        className="uk-hidden@m"
        uk-offcanvas="overlay: true;"
      >
        <div className="uk-offcanvas-bar uk-flex uk-flex-column sidebar">
          <div className="uk-flex uk-flex-middle">
            <a href="#" className="uk-offcanvas-close" uk-close="true">Link</a>
            <p className="uk-h5 uk-text-uppercase">Mi cuenta</p>
          </div>
          <hr />
          <Profilebar />
        </div>
      </div> */}
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-container-expand">
          <div className="uk-grid uk-grid-medium" uk-grid="true">
            <div className="uk-width-1-1 uk-width-1-4@m uk-width-1-5@l uk-visible@m sidebar">
              <Profilebar />
            </div>
            <div className="uk-width-1-1 uk-width-3-4@m uk-width-4-5@l">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  return (
    <>
      <Header />
      <main role="main" id="container">
        {isLogin ? <ConectedAccount /> : <AuthAnonymous source={"full"} />}
      </main>
      <Footer />
    </>
  );
}
