
import React, { useEffect } from "react";

export const NotificationContent = ({
  data,
  setNotificationProps,
  handleCloseNotification,
  nextStep,
  currentStep,
  totalSteps,
}) => {

  const { title, message, notificationId } = data;

  const notificationProps = {
    currentId: notificationId,
    currentStep: currentStep,
    totalSteps: totalSteps,
    nextStep: nextStep,
  };

  useEffect(() => {
    setNotificationProps(notificationProps);

  }, [currentStep]);

  const handleNextStep = ( currentStep, totalSteps ) => {
    if (currentStep === totalSteps) return handleCloseNotification();
    nextStep();
  };

  return (
    <div className="gi_anun_portada">
      <div className="uk-width-1-1">
        <p className="gi-title1">{title}</p>
      </div>

      {/* Init */}
      <div
        className="gi_anun_portada_txt"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {/* End */}

      <div className="gi_mid-align">
        <p className="uk-text-center">
          <button
            className="uk-button uk-button-primary gi_round_button"
            onClick={() => handleNextStep(currentStep, totalSteps)}>
           De acuerdo
          </button>
        </p>
      </div>
    </div>
  );
};
