/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link } from "react-router-dom";

export default function ProductCategoryChildren({ categories }) {
  return (
    categories?.length > 0 && (
      <div className="subcat-nav uk-margin-medium-top">
        <div uk-slider="sets: true; finite: true">
          <div className="uk-position-relative">
            <div className="uk-slider-container">
              <ul className="uk-slider-items">
                {categories.map((c) => (
                  <li key={c.id}>
                    <Link
                      to={`/products/category/${c.friendlyurl}`}
                      state={{
                        metaTitle: c.meta_title,
                        metaKeywords: c.meta_keywords,
                        metaDescription: c.meta_description,
                      }}
                    >
                      {c?.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <a
              className="uk-position-center-left-out"
              href="#"
              uk-slider-item="previous"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <a
              className="uk-position-center-right-out"
              href="#"
              uk-slider-item="next"
            >
              <i className="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    )
  );
}
