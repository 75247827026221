import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const xSwal = withReactContent(Swal);

const TITLE = "Sirena.do";
const OKBUTTON = "Entiendo";
const iconHtml = "success";

const confirm = (
  customMessage,
  icon = iconHtml,
  customTitle = TITLE,
  customOkbutton = OKBUTTON
) => {
  return xSwal.fire({
    title:
      '<img src="/logo.svg?v=20221013" style="width: 80px;" alt="Sirena"/>',
    html: customMessage,
    confirmButtonText: customOkbutton,
    icon: icon,
    footer: "",
  });
};

const toast = (
  customMessage,
  customTitle = "",
  icon = iconHtml,
  customOkbutton = OKBUTTON
) => {
  const Toast = Swal.mixin({
    showConfirmButton: false,
    toast: true,
    position: "top-end",
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  return Toast.fire(customTitle, customMessage, "success");
};

export default { confirm, toast };
