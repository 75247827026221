
import { SetSession } from "helpers/functions";
import React from "react";
import ReactDOM from "react-dom";

const AdsDeliveryExpress = ({ isOpen, onSetClose, setShippingType }) => {
  const handleNoShowAgain = (e) => {
    SetSession("AdsDeliveryExpress", "false");
    onSetClose("none", "webModal-container");
    e.preventDefault();
  };

  const handleSelect = (type) => {
    setShippingType(type);
  };

  if (isOpen !== "ads-delivery-express") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical gi_modal_aviso">
      <button id='btn-close'className="uk-modal-close-default" type="button" data-uk-close />
      <div className="uk-modal-header"></div>
      <div className="uk-modal-body">
        <div className="uk-grid gi_anun_portada">
          <div className="uk-width-1-1">
            <p className="gi-title1">
              ¿Te gustaría recibir tu pedido en 45 minutos o menos?
            </p>
          </div>
          <div className="uk-width-1-1 uk-width-2-5@s gi_anun_portada_ilust">
            <img
              src="/assets/global/images/img_anunciodeliveryexpress.jpg"
              alt="Anuncio de delivery express"
            />
          </div>
          <div className="uk-width-1-1 uk-width-3-5@s gi_anun_portada_txt">
            <div className="gi_mid-align">
              <h3>
                Selecciona <span>Delivery Express</span>
              </h3>
              <h4>para pedidos de hasta 20 artículos</h4>
              <p>
                <button
                  className="uk-button uk-button-primary gi_round_button"
                  onClick={() => handleSelect("DE")}
                >
                  Seleccionar
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center gi_modal_footer">
        <div
          className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center uk-grid"
          data-uk-grid
        >
          <div className="uk-width-1-1 uk-width-expand@m uk-text-right@m">
            <a
              className="gi_ulink"
              href="#0"
              onClick={(e) => handleNoShowAgain(e)}
            >
              No mostrar más este aviso
            </a>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
};

export default AdsDeliveryExpress;
