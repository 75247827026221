import { useCallback, useState } from "react";
import { CheckoutContext } from ".";

const initialValuePointsApplied = {
  supermarket: 0,
  homeDelivery: 0,
  homeDeliveryStore: 0,
};

export const CheckoutProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pointsApplied, setPointsApplied] = useState(initialValuePointsApplied);
  const [addresses, setAddresses] = useState([]);
  const [baseImg, setBaseImg] = useState("");
  const [locationslotId, setLocationslotId] = useState(null);
  const [locationslot, setLocationslot] = useState(null);

  const handleSetPointsApplied = useCallback(({ points, type }) => {
    setPointsApplied((prevState) => ({
      ...prevState,
      [type]: parseInt(points),
    }));
  }, []);

  const handleUnSetPointsApplied = useCallback((type) => {
    setPointsApplied((prevState) => ({
      ...prevState,
      [type]: 0,
    }));
  }, []);

  const values = Object.values(pointsApplied);
  const totalSirepointsApplied = values.reduce((prev, current) => {
    return parseInt(prev) + parseInt(current);
  }, 0)

  return (
    <CheckoutContext.Provider
      value={{
        handleSetPointsApplied,
        handleUnSetPointsApplied,
        pointsApplied,
        totalSirepointsApplied,
        addresses,
        setAddresses,
        baseImg,
        setBaseImg,
        locationslot,
        setLocationslot,
        locationslotId,
        setLocationslotId,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
