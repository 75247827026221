import { monthWithNameOptions, daysOptions } from "models/options";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function RegisterAccountStep2(props) {
  const { gender, handleDataChange } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [year, setYear] = useState([]);

  useEffect(() => {
    const x = [];
    const z = new Date().getFullYear() - 18;
    for (let i = z; i > z - 100; i--) {
      x.push(i);
    }
    setYear(x);
  }, []);

  const onSubmit = (data) => {
    handleDataChange(data);

    props.nextStep();
  };

  const ToPreviousStep = () => {
    props.previousStep();
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <progress
          className="uk-progress uk-position-top"
          value="50"
          max="100"
        ></progress>
        <p className="uk-text-right uk-text-small uk-margin-remove-top">
          Paso 2 de 4
        </p>
        <div className="title-border">
          <p className="uk-h4">Datos personales:</p>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="first_name">
            Nombre(s) <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <input
              id="first_name"
              name="first_name"
              maxLength={128}
              className="uk-input"
              type="text"
              placeholder=""
              {...register("first_name", {
                required: "Nombre(s) son requeridos",
              })}
            />
            <p className="error">
              {errors?.first_name && errors?.first_name.message}
            </p>
          </div>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="last_name">
            Apellido(s) <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <input
              name="last_name"
              maxLength={128}
              className="uk-input"
              id="last_name"
              type="text"
              placeholder=""
              {...register("last_name", {
                required: "Apellido(s) son requeridos",
              })}
            />
            <p className="error">
              {errors?.last_name && errors?.last_name.message}
            </p>
          </div>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="gender_id">
            Genero
          </label>
          <div className="uk-form-controls">
            <select
              className="uk-select"
              name="gender_id"
              {...register("gender_id", { required: "genero es requerido" })}
            >
              <option value=""></option>
              {gender.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <p className="error">
              {errors?.gender_id && errors.gender_id.message}
            </p>
          </div>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label">
            Fecha de nacimiento <span className="required">*</span>
          </label>
          <div className="uk-form-controls uk-grid-small uk-child-width-1-3 uk-grid">
            <label>
              <select
                id="dob_day"
                name="dob_day"
                className="uk-select"
                {...register("dob_day", {
                  required: "Dia es requerido",
                  validate: (value) => value !== "00" || "Dia es requerido.",
                })}
              >
                {daysOptions.map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </label>

            <label>
              <select
                name="dob_month"
                className="uk-select"
                {...register("dob_month", {
                  required: "Mes es requerido",
                  validate: (value) => value !== "00" || "Mes es requerido.",
                })}
              >
                {monthWithNameOptions.map(({value, label}) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              <select
                name="dob_year"
                className="uk-select"
                {...register("dob_year", {
                  required: "Año es requerido",
                  validate: (value) => value !== "0000" || "Año es requerido.",
                })}
              >
                <option value="0000">Año</option>
                {year.map((y, idx) => (
                  <option key={idx} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </label>
            <p className="error">{errors.dob_day && errors.dob_day.message}</p>
            <p className="error">
              {errors.dob_month && errors.dob_month.message}
            </p>
            <p className="error">
              {errors.dob_year && errors.dob_year.message}
            </p>
          </div>
        </div>
        <hr />
        <div className="uk-margin uk-text-right">
          <button
            type="button"
            className="uk-button  uk-button-custom"
            onClick={ToPreviousStep}
          >
            Anterior
          </button>
          <button
            id="btn-next-step-2"
            type="button"
            className="uk-button uk-button-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
}
