import React, {useState} from "react";
import ReactDOM from "react-dom";
import {postData} from "helpers/service";
import {Encode} from "helpers/functions";

export default function AddressDelete({ isOpen, item, setClose}) {

    const [loading, setLoading] = useState(false);

    const onDeleteClick = () => {
        setLoading(true)
        postData(`account/address/${Encode(item.id)}/remove`,
            {"key": Encode(item.id),},
            {},
            (res, err) => {
                if (!err) {
                    setClose(true, res.message);
                }
                setLoading(false);
            });
    }

    if (isOpen !== 'delete') return null;
    return ReactDOM.createPortal(
        <div className='uk-modal-dialog uk-margin-auto-vertical'>
            <button className='uk-modal-close-default' type='button' uk-close="true"></button>

            <div className='uk-modal-body uk-text-center'>
                <p className='uk-text-secondary'><i className='far fa-question-circle fa-3x'></i></p>
                <p className='uk-h4'>Borrar Dirección</p>
                <p>¿Estas seguro que deseas borrar la dirección <strong>{item.tag}</strong>?</p>

            </div>

            <div className='uk-modal-footer uk-text-center'>
                <button onClick={setClose} disabled={loading} className="uk-button uk-modal-close uk-button-custom"><span>Cancelar</span> <i className='fas fa-times'></i>
                </button>
                <button onClick={onDeleteClick} disabled={loading} className="uk-button uk-modal-close uk-button-default uk-button-custom" type='button'>
                    <span>Borrar</span> <i
                    className='fas fa-check'></i></button>
            </div>
        </div>,
        document.getElementById('webModal'))

}
