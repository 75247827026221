
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import AccountContext from "context/Account";
import UIkit from "uikit";
import { CheckDocument11, MoneyFormat, publishEvent, unsubscribeEvent, addIdToItem  } from "helpers/functions";
import alert from "helpers/alerts";

/*MODALS*/
import LoadFavorite from "./load-favorite";
import LoadXlsx from "./load-xlsx";

export default function DigitalPointCart(props) {

  const [modalOpen, setModalOpen] = useState("none");
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  
  const { getCash, setCash } = useContext(AccountContext);

  const SIRENA_CASH_ID = process.env.REACT_APP_SIRENA_CASH_ID;

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    let data = getCash();

    setItems(data["details"] || []);
    setTotalAmount(data["total"] || 0);

    return () => {
      unsubscribeEvent('add-to-cart', null, 'form-blue-box');
      unsubscribeEvent('remove-cart', null, 'form-blue-box');
    }

  }, []);

  useEffect(() => {
    let data = getCash();

    let rows = data["details"]?.filter((x) => x.error === 1);

    if (rows.length > 0) {
      alert.confirm(
        "Los beneficiarios que se muestran con un mensaje en rojo (<i class='fa-solid fa-circle-exclamation uk-text-danger'></i>) requieren atención. Favor revisar el mensaje para que se puedan cargar al pedido.",
        "error"
      );
      return;
    }
  }, [items]);

  const ToNextStep = () => {
    let Order = {
      details: items,
      total: totalAmount,
    };
    setCash(Order);

    props.nextStep();
  };

  //*********** CRM **************//
  //********? Add to cart *******//
  const handleAddToCart = (data, element) => {

    let lineItem = {
      _id: SIRENA_CASH_ID,
      price: parseFloat(data.amount),
      quantity: 1,
      currency:'DOP'
    }
    publishEvent('add-to-cart', lineItem, element);
  }

  //********? Remove cart *******//
  const handleRemoveCart = (removedItem) => {
  
    let lineItem = {
      _id: SIRENA_CASH_ID,
      price: parseFloat(removedItem[0]?.amount),
      quantity:1
    }
    publishEvent('remove-cart', lineItem, 'form-blue-box')
  }
  
  //********** Fin CRM ************//

  const onAddItem = (data) => {
    if (!CheckDocument11(data["document"])) {
      alert.confirm("El número de cedula es incorrecto", "error");
      return;
    }

    if (parseInt(data["amount"]) > 99000) {
      alert.confirm(
        "El límite de compra en una misma transacción por beneficiario es de RD$99,000",
        "error"
      );
      return;
    }

    
    const newData = addIdToItem(data);

    handleAddToCart(newData, 'form-blue-box');
    newData["error"] = 0;
    newData["errorMsj"] = "";

    const CartItems = [...items, newData];

    setItems(CartItems);

    const total = CartItems.reduce(function (tot, arr) {
      return tot + parseFloat(arr.amount);
    }, 0);

    setTotalAmount(total);

    reset({
      document: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      amount: "",
      message: "",
      add_fav: false,
      error: 0,
      errorMsj: "",
    });

    let Order = {
      details: CartItems,
      total: total,
    };
    setCash(Order);
  };

  const RemoveItem = (id) => {
    const cartItems = [...items];
    
    let removedItem = cartItems.filter(item => item.customId === id);
    handleRemoveCart(removedItem);
    
    const newItems = cartItems.filter(item => item.customId !== id);
    const total = newItems.reduce(function (tot, arr) {
      return tot + parseFloat(arr.amount);
    }, 0);

    setTotalAmount(total);
    setItems(newItems);

    let Order = {
      details: newItems,
      total: total,
    };
    setCash(Order);

  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const handleClose = (accion = "cancel", modal = "webModal") => {
    if (accion === "load") {
      let data = getCash();

      setItems(data["details"] || []);
      setTotalAmount(data["total"] || 0);
    }

    UIkit.modal(`#${modal}`).hide();
    setModalOpen("none");
  };

  return (
    <div className="uk-section">
      <div className="uk-container uk-flex uk-flex-center">
        <div className="uk-width-1-1 uk-width-4-5@m">
          <h2 className="uk-h2 uk-text-center">Sirena Cash</h2>

          <div id="form-blue-box" className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small uk-light">
            <div className="title-border">
              <p className="uk-h4">Puntos a comprar:</p>
            </div>

            <div className="uk-margin uk-text-right">
              <button
                id="btn-load-from-excel"
                onClick={() => setModalOpen("xlsx")}
                className="uk-button uk-button-default uk-button-custom"
                uk-toggle="target: #webModal"
              >
                Cargar desde Excel
              </button>
              <button
                id="btn-load-from-favorite"
                onClick={() => setModalOpen("fav")}
                className="uk-button uk-button-default uk-button-custom"
                uk-toggle="target: #webModal-container"
              >
                Cargar desde Favoritos
              </button>
            </div>

            <div className="uk-margin uk-text-left">
              <strong>Datos del Beneficiario</strong>
            </div>

            <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="document">
                  Cédula <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <Controller
                    name="document"
                    control={control}
                    rules={{
                      required: "Cédula es requerida",
                      pattern: {
                        value: /^[0-9]{3}-[0-9]{7}-[0-9]{1}$/i,
                        message: "Cédula es requerida",
                      },
                    }}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="999-9999999-9"
                        className="uk-input"
                      />
                    )}
                  />
                  <p className="error">
                    {errors?.document && errors?.document.message}
                  </p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="first_name">
                  Nombre(s) <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    maxLength="128"
                    className="uk-input"
                    type="text"
                    name="first_name"
                    {...register("first_name", {
                      required: "Nombre(s) son requeridos",
                    })}
                  />
                  <p className="error">
                    {errors?.first_name && errors?.first_name?.message}
                  </p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="last_name">
                  Apellido(s) <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    maxLength="128"
                    className="uk-input"
                    type="text"
                    name="last_name"
                    {...register("last_name", {
                      required: "Apellido(s) son requeridos",
                    })}
                  />
                  <p className="error">
                    {errors?.last_name && errors?.last_name?.message}
                  </p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="phone">
                  Teléfono <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: "Teléfono es requerido",
                      pattern: {
                        value:
                          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i,
                        message: "Teléfono es requerido",
                      },
                    }}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="(999) 999-9999"
                        className="uk-input"
                      />
                    )}
                  />

                  <span className="error">
                    {errors?.phone && errors?.phone?.message}
                  </span>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="email">
                  Correo electrónico <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="email"
                    placeholder="ej. mi@correo.com"
                    maxLength={128}
                    {...register("email", {
                      required: "El correo electrónico es requerido",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message:
                          "La dirección de correo electrónico no es válida.",
                      },
                    })}
                  />
                  <span className="error">
                    {errors?.email && errors?.email?.message}
                  </span>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="amount">
                  Monto <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    name="amount"
                    {...register("amount", {
                      required: "Se Requiere un monto ",
                      min: { value: 100, message: "Monto minimo de venta" },
                    })}
                    className="uk-input"
                    id="amount"
                    min={100}
                    type="number"
                    placeholder="RD$ 0.00"
                  />
                  <span className="error">
                    {errors?.amount && errors?.amount?.message}
                  </span>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="message">
                  Mensaje
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type="text"
                    placeholder="Opcional"
                    maxLength={500}
                    name="message"
                    {...register("message")}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    name="add_fav"
                    {...register("add_fav")}
                  />{" "}
                  <span style={{ marginTop: "4px" }}>Agregar a Favoritos</span>
                </label>
              </div>
              <div className="uk-margin uk-text-right">
                <button
                  onClick={handleSubmit(onAddItem)}
                  className="uk-button uk-button-default uk-button-custom"
                >
                  Agregar al carrito
                </button>
              </div>
            </form>

            <table className="uk-table uk-table-divider uk-table-small">
              <thead>
                <tr>
                  <th className="uk-width-auto">Persona</th>
                  <th className="uk-width-auto">Contacto</th>
                  <th className="uk-width-auto uk-text-right">Monto</th>
                  <th className="uk-width-auto uk-text-right"></th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.document} <br />
                      {`${item.first_name} ${item.last_name}`}
                    </td>
                    <td>
                      {item.email} <br />
                      {item.phone}
                    </td>
                    <td className="uk-width-auto uk-text-right">
                      {MoneyFormat(item.amount)}
                    </td>
                    <td className="uk-width-auto uk-text-right">
                      {item.error === 0 ? (
                        <button
                          onClick={() => RemoveItem(item.customId)}
                          className="uk-button uk-modal-close uk-button-danger"
                          type="button"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      ) : (
                        <i
                          title={item.errorMsj}
                          className="fa-solid fa-circle-exclamation uk-text-danger"
                        ></i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="uk-width-auto uk-text-right">
                    RD$ {MoneyFormat(totalAmount)}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div className="uk-margin uk-text-right">
              <button
                id="btn-go-to-checkout"
                className="uk-button uk-button-default uk-button-custom"


                disabled={items.length === 0}
                onClick={ToNextStep}
              >
                Ir al Carrito
              </button>
            </div>
            <LoadXlsx isOpen={modalOpen === "xlsx"} setClose={handleClose} />
            <LoadFavorite isOpen={modalOpen === "fav"} setClose={handleClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
