/* eslint-disable */
import React, {useEffect, useState} from 'react'

import {Encode, isBlank,setTitle} from "helpers/functions";
import alert from 'helpers/alerts';
import {getData, postData} from "helpers/service";

import Loading from "components/share/loading/crud";

import AddressesList from "components/account/profile/addresses";
import AddressAdd from "components/account/profile/addresses/add";
import AddressEdit from "components/account/profile/addresses/edit";
import AddressDelete from "components/account/profile/addresses/delete";

import UIkit from "uikit";

export default function AddressesPage() {

    const [addressesItems, setAddressesItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState('none');
    const [current, setCurrent] = useState({});

    setTitle('Mis direcciones')

    useEffect(() => {
        loadData()
    },[]);

    const loadData = () =>
    {
        setLoading(true);
        setOpen('none');
        setAddressesItems([])
        getData('account/address', null,
            (res, err) => {
                if (!err) {
                    setAddressesItems(res.data);
                }
                setLoading(false);
            });
    }

    const onOpen = (action = 'add', data = []) => {
        setOpen(action)
        setCurrent(data);
    }

    const onSetByDefault = id => {
        setLoading(true);
        postData(`account/address/${Encode(id)}/default`,
            {"key": Encode(id)},
            {},
            (res, err) => {
                loadData()
        });
    }

    const onSetClose = (reload =false, message = '', data = null) => {
        UIkit.modal("#webModal").hide();

        setOpen('none')
        setCurrent(null);


        if (!isBlank(message)) alert.toast(message)

        if (reload) {
            loadData();
        }
    }


    return <div>
        <div className='title-border uk-flex uk-flex-between uk-flex-bottom toggle-address-add toggle-address-edit'>
            <h2 className='uk-h3'>Mis Direcciones</h2>
            <div className='uk-hidden@m'>
                <a href="#" className="uk-button uk-button-default uk-button-custom" uk-toggle='target: #modal-filters'>
                    <i className='fas fa-filter'></i>
                </a>
            </div>
        </div>
        <div className='uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters toggle-address-add toggle-address-edit'
             uk-grid="true">
            <div></div>
            <div>
                <button type="button"
                        onClick={() => onOpen()}
                        className="uk-button uk-button-default uk-button-custom"
                        uk-toggle='target: #webModal'>
                    <span>Agregar</span> <i className='fas fa-plus'></i>
                </button>
            </div>
        </div>
        <div className='uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid uk-grid-match uk-margin-small-top uk-margin-large-bottom'
             uk-grid="true">
            { loading? <Loading/> :
                <AddressesList addressesItems={addressesItems} onOpen={onOpen} setByDefault={onSetByDefault}/> }
        </div>

        <AddressAdd isOpen={open} setClose={onSetClose}/>
        <AddressEdit isOpen={open} setClose={onSetClose} item={current}/>
        <AddressDelete isOpen={open} setClose={onSetClose} item={current}/>


    </div>
}

