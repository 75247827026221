import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ProductItem from "components/product/item";
import Loading from "components/share/loading/list";

export default function ProductGrid({
  products,
  baseImg,
  loading,
  baseImgBanner,
  banners,
}) {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(products);
  }, [products]);

  const ProductList = itemList.map((item, index) => (
    <div id="grid-product" data-test="grid-product" key={index}>
      <ProductItem baseImg={baseImg} item={item} />
    </div>
  ));

  let xbanners = banners || [];

  return (
    <div
      className="uk-grid uk-grid-small uk-grid-match grid-products"
      uk-grid="true"
    >
      {loading && <Loading />}

      {!loading &&
        xbanners.map((b) => (
          <div key={b.id}>
            <div className="item-product-highlight">
              <Link to={b.link}>
                <img src={`${baseImgBanner}${b.img_banner}`} alt={b.name} />
              </Link>
            </div>
          </div>
        ))}

      {!loading && [ProductList]}
      
    </div>
  );
}
