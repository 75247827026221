import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";
import { setTitle } from "helpers/functions";
import PageNotFound from "components/share/error/404";

export default function PromotionPage() {
  let { friendlyurl } = useParams();

  const [loading, setLoading] = useState(true);
  const [baseImg, setBaseImg] = useState("");
  const [promotions, setPromotions] = useState("");

  setTitle("Promociones");

  useEffect(() => {
    getData(`setting/site/promotions`, null, (res, err) => {
      if (!err) {
        setPromotions(res.promotion);
        setBaseImg(res.base_img_banner);
      }
      setLoading(false);
    });
  }, [friendlyurl]);

  const Content = () => {
    return (
      <>
        {promotions?.length > 0 ? (
          <div className="uk-section uk-margin-medium-bottom">
            <div className="uk-container uk-container-expand">
              <div className="title-border uk-margin-medium-bottom">
                <h1 className="uk-h3">Promociones</h1>
              </div>

              <div
                className="uk-flex uk-flex-center uk-grid uk-grid-small uk-child-width-1-1 block-banners"
                uk-grid="true"
              >
                {promotions.map((item, idx) => (
                  <div key={idx}>
                    <a href={item.link}>
                      <img
                        src={`${baseImg}${item?.img_banner}`}
                        alt={item.name}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <PageNotFound />
        )}
      </>
    );
  };

  return loading ? <Loading /> : <Content />;
}
