/* eslint-disable react-hooks/exhaustive-deps */
import { CheckoutContext } from "context/Checkout";
import { useContext } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";

const SiremasPointsModal = ({
  isOpen,
  onSetClose,
  points = 0,
  orderAmount = 0,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { totalSirepointsApplied } = useContext(CheckoutContext);
  const onSubmit = (data) => {
    const { pointsToApply } = data;
    onSetClose(true, pointsToApply);
  };
  const MAX_ALLOWED = 20000;
  const pointsToShow = points - totalSirepointsApplied;

  if (isOpen !== "points-modal") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical gi_modal_aviso">
      <button
        className="uk-modal-close-default uk-icon uk-close"
        type="button"
        aria-label="Close"
        onClick={() => {
          reset();
          onSetClose(false);
        }}
      >
        <svg width="14" height="14" viewBox="0 0 14 14">
          <path
            fill="none"
            stroke="#000"
            strokeWidth="1.1"
            d="M1 1L13 13"
          ></path>
          <path
            fill="none"
            stroke="#000"
            strokeWidth="1.1"
            d="M13 1L1 13"
          ></path>
        </svg>
      </button>

      <div className="uk-modal-header"></div>
      <div
        className="uk-modal-body uk-overflow-auto"
        //data-uk-overflow-auto=""
        style={{ minHeight: 150, maxHeight: 771.8 }}
      >
        <div className="uk-grid">
          <div className="uk-width-1-1 gi_inputpuntos">
            <div>
              <p className="gi-title1">
                <span>{pointsToShow.toLocaleString()} puntos</span> Siremás
                disponibles
              </p>

              <p className="gi_input_aplicar_ptos_preg">
                ¿Cuántos puntos deseas aplicar a esta orden?
              </p>

              <form>
                <div className="gi_input_aplicar_ptos">
                  <input
                    id="cuantos_puntos"
                    name="cuantos_puntos"
                    type="number"
                    placeholder={`${pointsToShow.toLocaleString()}`}
                    {...register("pointsToApply", {
                      required: "Indica los puntos que deseas aplicar",
                      max:
                        orderAmount > pointsToShow && orderAmount < MAX_ALLOWED
                          ? {
                              value: pointsToShow,
                              message: `La cantidad de puntos indicados es mayor a los puntos disponibles, favor, indique una cantidad menor a ${pointsToShow.toLocaleString()}.`,
                            }
                          : orderAmount < pointsToShow &&
                            orderAmount < MAX_ALLOWED
                          ? {
                              value: orderAmount,
                              message: `La cantidad de puntos indicados es mayor al monto de la orden, indique una cantidad menor a ${orderAmount.toLocaleString()}.`,
                            }
                          : orderAmount > pointsToShow &&
                            orderAmount > MAX_ALLOWED &&
                            pointsToShow > MAX_ALLOWED
                          ? {
                              value: MAX_ALLOWED,
                              message: `La cantidad de puntos indicados es mayor al monto máximo permitido por transacción, favor, indique una cantidad menor a ${MAX_ALLOWED.toLocaleString()}`,
                            }
                          : null,

                      min: {
                        value: 100,
                        message:
                          "La cantidad de puntos indicados debe ser mayor o igual a 100 puntos, favor indique otra cantidad.",
                      },
                      pattern: {
                        value: /[0-9]+/,
                        message: "Solo se aceptan valores numéricos",
                      },
                    })}
                  />
                  <p className="error" style={{ fontSize: 16 }}>
                    {errors?.pointsToApply?.message}
                  </p>
                </div>

                <div className="uk-text-center">
                  <p>
                    <button
                      className="uk-button uk-button-primary gi_round_button gi_btn_aplicar_ptos"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      APLICAR
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="uk-modal-footer uk-text-center gi_modal_footer">
        <div
          className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center uk-grid uk-grid-stack"
          data-uk-grid=""
        >
          <div className="uk-width-1-1 uk-width-expand@m uk-text-right@m uk-first-column"></div>
        </div>
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
};

export default SiremasPointsModal;
