import React, { useContext } from "react";
import ReactDOM from "react-dom";
import AccountLogin from "./login";
import AccountLostPwd from "./lost-password";
import AccountContext from "context/Account";

export default function LoginModal() {
  const { isLogin } = useContext(AccountContext);

  if (isLogin) return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-body">
        <AccountLogin source="modal" /> 
        <AccountLostPwd />
      </div>
    </div>,
    document.getElementById("webLoginModal")
  );
}
