import React from "react";
import Loyalty from "./index";
import ReactDOM from "react-dom";

const LoyaltyModal= ({open, vdetails, onSetClose}) =>{  

  if (open!=="loyalty") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
        onClick={onSetClose}
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Movimiento de puntos
        </p>
      </div>
      <div className="uk-modal-body">
        <Loyalty vdetails={vdetails} />
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
}
export default LoyaltyModal;