import React from 'react'
import {useParams} from "react-router-dom";
import ProductDetail from "components/product/detail";
import {setTitle} from "helpers/functions";


export default function ProductPage() {

    let {friendlyurl} = useParams();

    setTitle('Detalle Producto')

    return <div className='uk-width-1-1'>
            <ProductDetail friendlyurl={friendlyurl} />
         </div>

}

