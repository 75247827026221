import React, { useState } from "react";
import ReactDOM from "react-dom";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";

export default function CreditCardDelete({ isOpen, item, setClose }) {

  const [loading, setLoading] = useState(false);

  const onDeleteClick = () => {
    setLoading(true);
    postData(
      `account/creditcard/${Encode(item.id)}/remove`,
      { key: Encode(item.id) },
      {},
      (res, err) => {
        if (!err) {
          setClose(true, res.message);
        }
        setLoading(false);
      }
    );
  };

  if (isOpen !== "delete") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-body uk-text-center">
        <p className="uk-text-secondary">
          <i className="far fa-question-circle fa-3x"></i>
        </p>
        <p className="uk-h4">Borrar tarjeta de crédito</p>
        <p>
          ¿Estas seguro que deseas borrar la tarjeta de crédito{" "}
          <strong>{item.cc_mask}</strong>?
        </p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          onClick={setClose}
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>{" "}
          <i disabled={loading} className="fas fa-times"></i>
        </button>
        <button
          onClick={onDeleteClick}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Borrar</span>{" "}
          <i disabled={loading} className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
