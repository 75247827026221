import React, { useEffect, useState } from "react";
import { SetSession, GetSession, GetStorage } from "helpers/functions";

import UIkit from "uikit";
import NotificationModal from "./NotificationModal";

export default function HomeNotification({ notifications }) {
  const [open, setOpen] = useState("none");

  let dismissedNotifications = GetStorage("dismissedNotifications") ?? [];

  useEffect(() => {
    if (Object.entries(notifications).length > 0) {
      let hasShowAgain = GetSession("hasShowAgain") ?? { notification: false };
      let showAgain = hasShowAgain["notification"];

      //Muestro modal si no hay notificaciones bloqueadas
      if (Object.entries(dismissedNotifications).length === 0 && !showAgain) {
        setOpen("notification");
        SetSession("hasShowAgain", { notification: true });
        return UIkit.modal(`#webModal`)?.show();
      }

      // si las notificaciones que llegan no estan en la lista de bloqueadas, las muestro.
      let notificationsCopy = [...notifications];
      const validNotifications = notificationsCopy.some(
        (item) => !dismissedNotifications.includes(item.notificationId)
      );
      if (validNotifications && !showAgain) {
        setOpen("notification");
        SetSession("hasShowAgain", { notification: true });
        return UIkit.modal(`#webModal`)?.show();
      }
    }


  }, [notifications]);

  const onSetClose = () => {
    UIkit.modal(`#webModal`)?.hide();
    setOpen("none");
  };

  return notifications?.length > 0 ? (
    <NotificationModal
      isOpen={open}
      onSetClose={onSetClose}
      dismissedNotifications={dismissedNotifications}
      items={notifications}
    />
  ) : (
    <></>
  );
}
