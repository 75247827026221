/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import Loading from "components/share/loading/table";
import { getData, postData } from "helpers/service";
import { useNavigate, useParams } from "react-router-dom";
import { DecimalFormat, Encode, setTitle } from "helpers/functions";
import CreditcardAdd from "components/account/profile/creditcards/add";
import UIkit from "uikit";
import { useForm } from "react-hook-form";
import LoadingModal from "components/share/loading/modal";
import PageNotFound from "components/share/error/404";
import Timeslot from "components/cart/checkout/timeslot";

export default function OrderPaymentPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  let { orderkey } = useParams();
  let navigate = useNavigate();

  const [locationslotId, setLocationslotId] = useState("");
  const [locationslot, setLocationslot] = useState("");
  const [open, setOpen] = useState("none");
  const [order, setOrder] = useState({});
  const [balance, setBalance] = useState({});
  const [currentBalance, setCurrentBalance] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [creditCard, setCreditCard] = useState([]);
  const [currentCreditCard, setCurrentCreditCard] = useState({});

  setTitle("Volver a Pagar");

  const onSetCreditCard = (reload = false, message = "", data = null) => {
    setOpen("none");
    if (data !== null) {
      getData(`account/creditcard`, {}, (res, err) => {
        if (!err) {
          setCreditCard(res.data);
        }
        setLoading(false);
      });
    }
    UIkit.modal(`#webModal`).hide();
  };
  const onSetTimeSlot = (xlocationslotId, xlocationslot) => {
    setOpen("none");
    UIkit.modal(`#webModal`)?.hide();

    setLocationslotId(xlocationslotId);
    setLocationslot(xlocationslot);
  };
  const onSetClose = () => {
    setOpen("none");
    UIkit.modal(`#webModal`)?.hide();
  };

  const onSetCurrentCC = (item) => {
    setCurrentCreditCard({
      ccId: item.id,
      cc_exp: item.cc_exp,
      cc_mask: item.cc_mask,
      cc_type: item.cc_type,
      confirmed: item.confirmed,
    });
  };

  useEffect(() => {
    getData(`account/order/${orderkey}/payment`, {}, (res, err) => {
      if (!err) {
        setCurrentCreditCard({
          ccId: res.payment.ccId,
          cc_exp: res.payment.cc_exp,
          cc_mask: res.payment.cc_mask,
          cc_type: res.payment.cc_type,
          confirmed: res.payment.confirmed,
        });
        setLocationslotId(res.order.locationslotid);
        setBalance(res.balance);
        setCurrentBalance(0);
        setCurrentTotal(res?.balance?.total_amount || 0);
        setCreditCard(res.creditcard);
        setOrder(res.order);
      }
      setLoading(false);
    });


  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    let submitOrder = {
      cc: {
        cvv: Encode(data["CVV"]),
        confirm: Encode(data["confirm_amount"]),
        id: currentCreditCard?.ccId,
      },
      locationslotid: Encode(locationslotId),
    };

    setOpen("loading");
    UIkit.modal(`#webModal`)?.show();

    postData(
      `account/order/${orderkey}/payment`,
      submitOrder,
      {},
      (res, err) => {
        if (!err) {
          setOpen("none");
          UIkit.modal(`#webModal`)?.hide();
          setLoading(false);
          navigate(`/order-confirmation/${Encode(orderkey)}`, {
            replace: true,
          });
        } else {
          setOpen("none");
          UIkit.modal(`#webModal`)?.hide();
          setLoading(false);
        }
      }
    );
  };

  const OrderDetails = () => (
    <div className="uk-grid uk-grid-large item-list-wrap" uk-grid="true">
      <div className="uk-width-1-1 uk-width-2-3@m">
        <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
          <div
            className="uk-flex uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom nav-filters"
            uk-grid="true"
          >
            <ul className="uk-list">
              {creditCard.map((item, idx) => (
                <li key={idx}>
                  <label
                    onClick={() => {
                      onSetCurrentCC(item);
                    }}
                  >
                    <div className="uk-flex uk-grid-small">
                      <input
                        defaultChecked={item.id === currentCreditCard.ccId}
                        className="uk-radio"
                        type="radio"
                        name="credit-card"
                      />
                      <div className="uk-width-auto">
                        <img
                          src={`/assets/creditcart/${item.cc_type}.jpg`}
                          alt={item.cc_type}
                          style={{ maxHeight: 35 }}
                        />
                      </div>
                      <div className="uk-width-expand">
                        <strong>{item.cc_mask}</strong>
                        <br />
                        <small>Vence: {item.cc_exp}</small>
                      </div>
                      {item.confirmed === 0 && (
                        <span className="uk-badge uk-badge-danger">
                          {" "}
                          Sin Confirmar
                        </span>
                      )}
                    </div>
                  </label>
                </li>
              ))}

              <li>
                <p>
                  <button
                    onClick={() => setOpen("CreditcardAdd")}
                    uk-toggle="target: #webModal"
                    className="uk-button uk-button-primary uk-button-small"
                  >
                    <span>Agregar Tarjeta</span>
                    <i className="far fa-credit-card"></i>
                  </button>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 uk-width-1-3@m">
        <div
          className="item-list-footer"
          uk-sticky="offset: 130; bottom: true; media: @m"
        >
          <div className="item-list-block">
            {currentCreditCard?.ccId !== null ? (
              <div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-auto">
                    <img
                      src={`/assets/creditcart/${currentCreditCard?.cc_type}.jpg`}
                      alt={currentCreditCard?.cc_type}
                      style={{ maxHeight: 35 }}
                    />
                  </div>
                  <div className="uk-width-expand">
                    <strong>{currentCreditCard?.cc_mask}</strong>
                    <br />
                    <small>Vence: {currentCreditCard?.cc_exp}</small>
                  </div>
                  <div className="uk-width-auto">
                    <input
                      type="password"
                      name="CVV"
                      autoComplete="cc-csc"
                      maxLength="4"
                      className="uk-input uk-form-width-small"
                      placeholder="CVV"
                      {...register("CVV", { required: "CVV es requerido" })}
                    />
                    <p className="uk-width-1-1 error">
                      {errors?.CVV && errors?.CVV.message}
                    </p>
                  </div>
                </div>
                {currentCreditCard?.confirmed === 0 ? (
                  <div className="uk-grid-small">
                    <div className="uk-width-1-1">
                      <br />
                      <p className="uk-h5">Confirmar tarjeta de crédito</p>
                    </div>
                    <div className="uk-width-1-1 uk-margin-remove">
                      <div className="uk-margin-small">
                        <label
                          className="uk-form-label"
                          htmlFor="confirm_amount"
                        >
                          Monto transacción <span className="required">*</span>
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input uk-text-right"
                            name="confirm_amount"
                            {...register("confirm_amount", {
                              required: "Monto transacción es requerido",
                            })}
                            type="text"
                            placeholder="0.00"
                          />
                        </div>
                        <p className="error">
                          {errors?.confirm_amount &&
                            errors?.confirm_amount.message}
                        </p>
                      </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin-remove">
                      Se realizó un cargo a su tarjeta de 1 a 20 pesos que será
                      su clave de validación. Consulte dicho monto en su
                      tarjeta. Este proceso solo es necesario al registrar por
                      primera vez la tarjeta y el monto será reversado.
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div>
                <p className="uk-text-bold">
                  No tienes una tarjeta de crédito o débito registrada.{" "}
                </p>
                <p>
                  <a
                    href="#"
                    onClick={(e) => {
                      setOpen("add");
                      e.preventDefault();
                    }}
                    className="uk-button uk-button-default uk-button-small"
                    uk-toggle="target: #webModal"
                  >
                    <span>Registrar</span>
                    <i className="far fa-credit-card"></i>
                  </a>
                </p>
              </div>
            )}
          </div>

          <hr className="uk-margin-medium-top" />

          <div
            className="item-list-footer"
            uk-sticky="offset: 130; bottom: true; media: @m"
          >
            <div className="item-list-total">
              <div className="title-border">
                <p className="uk-h4">Pedido {order.codigo}</p>
              </div>

              <div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase"></div>
                  <div className="uk-width-2-3 uk-text-right">
                    {order.order_date}
                  </div>
                </div>
              </div>

              <p>
                <strong>{order.shippingrate}</strong>
              </p>

              {order.shippingratetype === "P" && (
                <>
                {order.pickup_first_name !== null && (
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-user"></i>
                    <span>
                      <strong>
                        {order.pickup_first_name} {order.pickup_last_name}
                      </strong>
                      <br />
                      {order.pickup_documentType}: {order.pickup_document}
                    </span>
                  </p>
                )}
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-map-pin"></i>
                    <span>
                      <strong>{order.location}</strong>
                      <br /> {order.location_address}
                    </span>
                  </p>
                </>
              )}

              {order.shippingratetype === "D" && (
                <>
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-map-pin"></i>
                    <span>{`${order.shipping_address} ${order.shipping_address2} ${order.shipping_sector} ${order.shipping_state}`}</span>
                  </p>
                </>
              )}
              {order.req_timeslot === 1 ? (
                <div className="uk-text-small uk-alert uk-alert-warning">
                  <i className="fas fa-exclamation-triangle"></i>
                  <p>
                    Este horario ya no esta disponible para entrega, Seleccione
                    nuevamente.
                  </p>
                </div>
              ) : null}
              <p className="uk-flex uk-grid-small">
                <i className="far fa-calendar-alt"></i>
                <span>
                  <strong>{order.timeslot}</strong> (
                  <a
                    href="#"
                    onClick={(e) => {
                      setOpen("timeslot");
                      e.preventDefault();
                    }}
                    className="uk-button uk-button-link uk-padding-remove"
                    uk-toggle="target: #webModal"
                  >
                    {" "}
                    {"Cambiar"}
                  </a>
                  )
                  {locationslot !== "" && (
                    <>
                      <br />{" "}
                      <strong className="uk-text-danger">{locationslot}</strong>
                    </>
                  )}
                </span>
              </p>
            </div>

            <hr className="uk-margin-medium-top" />

            <div className="item-list-total">
              <div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase">Pagado:</div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(balance?.order_amount || 0)}
                  </div>
                </div>
                <div className="uk-flex uk-grid-small uk-text-bolder">
                  <div className="uk-width-1-3 uk-text-uppercase">Total:</div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(currentTotal)}
                  </div>
                </div>
                <div className="uk-flex uk-grid-small uk-text-bolder uk-text-secondary">
                  <div className="uk-width-1-3 uk-text-uppercase">
                    Por Pagar:
                  </div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(currentBalance)}
                  </div>
                </div>
              </div>

              <div
                className="uk-flex uk-flex-wrap uk-flex-center uk-grid-small item-list-btns"
                uk-grid="true"
              >
                <div>
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className="uk-button uk-button-primary"
                  >
                    <span>Proceder</span>
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (order.allow_payment === 0)
    // colocar 0
    return (
      <PageNotFound
        message={`El pedido No. : ${order.codigo} no permite pagos en este momento.`}
      />
    );
  return (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">VOLVER A PAGAR</h2>
        </div>
        {loading ? <Loading /> : <OrderDetails />}

        <CreditcardAdd
          isOpen={open === "CreditcardAdd" ? "add" : "none"}
          setClose={onSetCreditCard}
        />
        <LoadingModal
          isOpen={open}
          message="Estamos procesando su pago de su pedido, por favor espere..."
        />
        <Timeslot
          isOpen={open}
          onSetClose={onSetClose}
          onSetTimeSlot={onSetTimeSlot}
        />
      </div>
    </div>
  );
}
