import React, { useState } from "react";
import { adsense } from "helpers/functions";
import Advertisements from "components/share/adsense";
import { GetTypeDevice } from "helpers/functions";
import { BannerHomeModel, BannerHomeStandardModel } from "models/adsense";

export default function Banner({ baseImg, banners }) {
  const currentType = GetTypeDevice();

  const LocalBanner = (
    <section className="uk-section uk-padding-remove-vertical">
      <div className="uk-flex uk-flex-wrap block-banners">
        <div className="block-banners-main uk-width-1-1 uk-width-expand@m">
          <div
            className="uk-position-relative uk-slider uk-slider-container"
            uk-slider="animation: push; autoplay: false; autoplay-interval: 3500; pause-on-hover: true"
          >
            <ul className="uk-slider-items uk-child-width-1-1">
              {banners.map((item) => (
                <li key={item.id}>
                  <a href={item.link}>
                    <img
                      src={`${baseImg}${item?.img_banner}`}
                      alt={item.name}
                    />
                  </a>
                </li>
              ))}
            </ul>
            <ul className="uk-slider-nav uk-dotnav uk-position-bottom-right uk-position-small"></ul>
          </div>
        </div>
      </div>
    </section>
  );

  const GiroBannerItem = (row) => {
    const [IsEmpty, setIsEmpty] = useState(false);

    return (
      !IsEmpty && (
        <div className={row.item.className}>
          <Advertisements
            style={row.item.style}
            type={row.item.type}
            slotId={row.item.slotId}
            sizes={row.item.sizes}
            adUnit={row.item.adUnit}
            targetingArguments={{ Seccion: "home" }}
            setIsEmpty={setIsEmpty}
          />
        </div>
      )
    );
  };

  const AdManager = (
    <section className="uk-section uk-section-small">
      <div className="uk-container uk-container-expand">
        <div className="uk-child-width-1-1 uk-child-width-1-2@m uk-flex uk-flex-wrap block-banners">
          <div className="block-banners-main">
            <div
              className="uk-position-relative uk-slider uk-slider-container"
              uk-slider="animation: push; autoplay: true; autoplay-interval: 3500; pause-on-hover: true"
            >
              <div className="uk-slider-items uk-child-width-1-1 uk-transition">
                {BannerHomeModel.filter(
                  (item) => item.type === currentType
                ).map((item) => (
                  <GiroBannerItem key={item.slotId} item={item} />
                ))}
              </div>
              <ul
                className={`uk-slider-nav uk-dotnav uk-position-bottom-right uk-position-small`}
              ></ul>
            </div>
          </div>
          <div
            className={`block-banners-sub uk-child-width-1-1 uk-flex uk-flex-wrap banner_desktop`}
          >
            {
              <>
                {BannerHomeStandardModel.filter(
                  (item) => item.type === currentType
                ).map((item) => (
                  <Advertisements
                    key={item.slotId}
                    className={item.className}
                    type={item.type}
                    slotId={item.slotId}
                    sizes={item.sizes}
                    adUnit={item.adUnit}
                    targetingArguments={{ Seccion: "home" }}
                  />
                ))}
              </>
            }
          </div>
        </div>
      </div>
    </section>
  );

  return adsense() ? AdManager : LocalBanner;
}
