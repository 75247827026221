
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";

export default function CommentItem({
  isOpen,
  item,
  handleSetComment,
  onSetClose,
}) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({ comment: item?.comment });

    return () => {
      setLoading(false)
    }

  }, [isOpen]);

  const onSubmit = (data) => {
    setLoading(true);
    handleSetComment(data.comment, item);
  };


  if (isOpen !== "comment") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        onClick={() => onSetClose("webModal")}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">Comentario</p>
      </div>

      <div className="uk-modal-body">
        <textarea
          id="add-comment"
          name="comment"
          cols="30"
          rows="3"
          className="uk-textarea"
          placeholder="Agrega tu comentario aquí..."
          {...register("comment")}
              ></textarea>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          disabled={loading}
          onClick={() => handleSetComment("", item)}
          className="uk-button uk-modal-close uk-button-custom"
        >
          <span>Borrar</span> <i className="fas fa-times"></i>
        </button>
        <button
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
