/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import ProductItem from "components/product/item";

export default function ProductSponsored({
  OpenModal,
  banners,
  products,
  baseImgBanner,
  baseImg,
}) {
  if (banners?.length === 0 && products?.length === 0) return "";

  const SponsoredBanners = () => {
    if (banners?.length === 0) return "";

    return (
      <div
        className="slider-sponsored-banners uk-slider"
        uk-slider="sets: true"
      >
        <div className="uk-position-relative">
          <div className="uk-slider-container">
            <ul className="uk-slider-items uk-grid-match">
              {banners.map((b) => (
                <li key={b.id}>
                  {" "}
                  <Link to={b.link}>
                    <img src={`${baseImgBanner}${b.img_banner}`} alt={b.name} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <a
            className="uk-position-center-left-out slider-arrow"
            href="#"
            uk-slider-item="previous"
          >
            <i className="fas fa-chevron-left"></i>
          </a>
          <a
            className="uk-position-center-right-out slider-arrow"
            href="#"
            uk-slider-item="next"
          >
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    );
  };

  const SponsoredProducts = () => {
    if (products?.length === 0) return "";

    return (
      <div
        className="slider-sponsored-products uk-slider"
        uk-slider="sets: true"
      >
        <div className="uk-position-relative">
          <div className="uk-slider-container">
            <ul className="uk-slider-items uk-grid-match">
              {products.map((p) => (
                <li key={p.productid}>
                  <ProductItem
                    key={p.productid}
                    OpenModal={OpenModal}
                    baseImg={baseImg}
                    item={p}
                  />{" "}
                </li>
              ))}
            </ul>
          </div>
          <a
            className="uk-position-center-left-out slider-arrow"
            href="#"
            uk-slider-item="previous"
          >
            <i className="fas fa-chevron-left"></i>
          </a>
          <a
            className="uk-position-center-right-out slider-arrow"
            href="#"
            uk-slider-item="next"
          >
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="sponsored-products uk-background-primary uk-padding-small uk-border-rounded uk-margin-medium-bottom">
      <p className="uk-h5 uk-text-uppercase ">Productos patrocinados</p>
      <SponsoredBanners />
      <SponsoredProducts />
    </div>
  );
}
