import Moment from "moment";
import {v4 as uuid} from "uuid";
import { postData } from "./service";
const encodeData = false;
const ACCOUNT_KEY = "UserAccount";

export const store = process.env.REACT_APP_STORE;
export const debug = process.env.NODE_ENV !== "production" ? true : false;

export const GetTypeDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) && window.innerWidth < 960
    ? "mobile"
    : "desktop";
};

export const adsense = () => {
  return process.env.REACT_APP_ADSENSE !== "0";
};

export const Encode = (input, defaultx = "") => {
  if (!input) return defaultx;
  return btoa(input);
};

export const Decode = (input) => {
  if (!input) return null;
  return atob(input);
};

export const toArray = (input) => {
  if (!input) return null;
  try {
    return JSON.parse(input);
  } catch (e) {
     return [];
  }
};

export const toString = (input) => {
  if (!input) return null;
  return JSON.stringify(input);
};

export const ConsoleLog = (value) => {
  if (debug) console.log(value);
};

export const SetAccount = (data) => {
  SetStorage(ACCOUNT_KEY, data);
};

export const GetAccount = (key = "") => {
  let result = GetStorage(ACCOUNT_KEY);

  if (!result) return null;

  if (key === "") return result;

  return result[key];
};

export const SetStorage = (key, data) => {
  if (encodeData)
    window.localStorage.setItem(Encode(key), Encode(toString(data)));
  else window.localStorage.setItem(key, toString(data));
};

export const GetStorage = (key) => {
  if (encodeData) {
    return toArray(Decode(window.localStorage.getItem(Encode(key))));
  } else {
    return toArray(window.localStorage.getItem(key));
  }
};

export const SetValue = (key, data) => {
  SetStorage(key, { key: data });
};

export const GetValue = (key) => {
  let data = GetStorage(key);

  if (!data) return null;

  return data["key"];
};

export const ExistsStorage = (key) => {
  return GetStorage(key) ? true : false;
};

export const SetSession = (key, data) => {
  if (encodeData)
    window.sessionStorage.setItem(Encode(key), Encode(toString(data)));
  else window.sessionStorage.setItem(key, toString(data));
};

export const GetSession = (key) => {
  if (encodeData) {
    return toArray(Decode(window.sessionStorage.getItem(Encode(key))));
  } else {
    return toArray(window.sessionStorage.getItem(key));
  }
};

export const RemoveSession = (key) => {
  if (encodeData) {
    window.sessionStorage.removeItem(Encode(key));
  } else {
    window.sessionStorage.removeItem(key);
  }
};

export const ExistsSession = (key) => {
  return GetSession(key) ? true : false;
};

export const Islogin = () => {
  return ExistsStorage("login");
};

export const Logout = () => {
  let visitorId = GetValue("visitorId");
  window.localStorage.clear();
  SetValue("visitorId", visitorId);
};

export const DateFormat = (dt) => {
  if (Moment(dt).isValid()) return Moment(dt).format("DD-MMM-YYYY");

  return "-";
};

export const HumanizeDate = (dt) => {
  if (Moment(dt).isValid())
    return (
      Moment(dt).format("DD-MMM-YYYY") +
      " - " +
      Moment.duration(Moment(dt).diff(Moment())).humanize(true)
    );

  return "-";
};

export const MoneyFormat = (num) => {
  if (isNaN(parseFloat(num))) return "0.00";

  return "$" + DecimalFormat(num, 2);
};

export const DecimalFormat = (num, decimal = 2) => {
  if (isNaN(parseFloat(num))) return "0.00";

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(parseFloat(num));
};

export function DocumentIdentityFormat(DocumentNo) {
  if (!CheckDocument11(DocumentNo)) {
    return "";
  }

  let ldocument = DocumentNo?.toString();

  let c = ldocument.replace(/[^0-9]/g, "");

  let mun = c.substr(0, 3);
  let cedula = c.substr(3, 7);
  let verificador = c.substr(10, 1);

  return `${mun}-${cedula}-${verificador}`;
}

export function CheckDocument11(DocumentNo) {
  let ldocument = DocumentNo?.toString();

  if (isEmpty(ldocument) || isBlank(ldocument)) {
    return 0;
  }

    let c = ldocument.replace(/[^0-9]/g, "");

  if (c.length != 11) {
    return 0;
  }

  let cedula = c.substr(0, c.length - 1);
    let verificador = c.substr(c.length - 1, 1);
    let suma = 0;
    let cedulaValida = 0;
  if (c.length !== 11) {
    return false;
  }

  for (let i = 0; i < cedula.length; i++) {
    let mod = "";
    if (i % 2 == 0) {
      mod = 1;
    } else {
      mod = 2;
    }
    let res = cedula.substr(i, 1) * mod;
    if (res > 9) {
      res = res.toString();
      let uno = res.substr(0, 1);
      let dos = res.substr(1, 1);
      res = eval(uno) + eval(dos);
    }
    suma += eval(res);
  }

  let el_numero = (10 - (suma % 10)) % 10;

  if (el_numero.toString() === verificador && cedula.substr(0, 3) !== "000") {
    return 1;
  }

  return 0;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function isEmpty(str) {
  return !str || str.length === 0;
}

export function insertUrlParam(searchParams) {
  if (window.history.pushState) {
    let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams}`;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

export const IsValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function setTitle(title) {
  document.title = `${store} - ${title}`;
}


  export function unsubscribeEvent(eventName, callback, element = null) {
      if (!element ) {
        document.removeEventListener(eventName, callback);
      } else {
        document.getElementById(element)?.removeEventListener(eventName, callback)
    }
  }

export function publishEvent(eventName, data = null, element = null) {
  const event = new CustomEvent(eventName, {
    detail: data,
    cancelable: false,
    bubbles: true,
    composed: true
  })

  if (!element) {
    document.dispatchEvent(event);
  } else {
    const htmlElement = document.getElementById(element);
    htmlElement?.dispatchEvent(event);
  }

}


export function addIdToArray(array) {
  const newArray = array?.map(item => ({ customId: uuid(), ...item }))
  return newArray;
}

export function addIdToItem(item) {
  item.customId = uuid();
  return item;
}

export function checkMode (){

  const HTML = document.getElementsByTagName('html')[0];
  const Mode = HTML.classList.contains('uk-offcanvas-page') ? 'OffCanvasMode'
      : HTML.classList.contains('uk-modal-page') ? 'ModalMode' : 'Nothing';

  return ConsoleLog(Mode);
}

export function gtmPushEvent (name, event="error"){
 return  window?.dataLayer?.push({
    event,
    ecommerce:{
      error_type: "server",
      form_name: window.location.pathname,
      input_name: name,
      input_type: "string"
    }
  });
}

export const findElement = (array, column, value) => {
  return array.find((element) => {
    return element[column] === value;
  });
};

/**
*`addSearchToMedata` recolector de datos para metadata.
* 
* Se encarga de recolectar los valores recibidos en la barra
* de busqueda al igual que los productos visitados luego de la busqueda misma, para
* agregarlos al banco de datos y conformar la `metadata`, con el objetivo de optimizar
* las sugerencias de productos según las intenciones de busqueda del usuario.
*/
export const addSearchToMetadata = (search='', productid ='') => {

  if (!search) return;
  let body = {
    productid: Encode(productid),
    search,
    format: 'decode'
  };

  postData('cart/add-history-search', body, {}, (res, err) => {})
}
