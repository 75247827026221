export const LOADING = 'loading';
export const D1_ITEMS = 'd1Items';
export const SHIPPING_RATE = 'shippingRate';
export const DEFAULT_ADDRESS = 'defaultAddress';
export const FORM_PAYMENT = 'formPayment';
export const D2_ITEMS = 'd2items';
export const D2_SHIPPING_RATE = 'd2ShippingRate';
export const D4_ITEMS = 'd4Items';
export const D4_SHIPPING_RATE = 'd4ShippingRate';
export const BALANCE = 'balance';
export const OPEN = 'open';
export const SUBMIT_ORDER = 'submitOrder';
export const SIREMAS_POINTS = 'siremasPoints';