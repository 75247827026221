import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import AccountContext from "context/Account";
import { getData, postData } from "helpers/service";
import {
  DecimalFormat,
  Encode,
  publishEvent,
  unsubscribeEvent,
} from "helpers/functions";
import Loading from "components/share/loading/cartbar";
import UnAvailable from "components/cart/cart/unavailable";

export default function CartBar({ isOpen, closeModal }) {
  const { isLogin, loadShoppingCart, setReloadItems } =
    useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState({});
  const baseImgRef = useRef();

  useEffect(() => {
    if (isOpen === "modal-cart" && isLogin) {
      setLoading(true);
      getData(`cart`, null, (res, err) => {
        if (!err) {
          setCart(res);
          baseImgRef.current = res.base_img;
          loadShoppingCart(res.balance);
        }
        setLoading(false);
      });
    }
   
  }, [isOpen, isLogin]);

  // ******* CRM ******* //

  // View cart bar //
  useEffect(() => {
    const { items } = cart;
    if (!items) return;
    let ids = items?.map((item) => item?.productid);
    let price = items?.map((item) => parseFloat(item?.price));
    let quantity = items?.map((item) => parseFloat(item?.in_cart));

    const dataViewCart = {
      _id: ids,
      price: price,
      quantity: quantity,
      currency: "DOP",
    };

    publishEvent("view-cart", dataViewCart, "modal-cart");

    return () => {   
      unsubscribeEvent("view-cart", null, "modal-cart");
      unsubscribeEvent("remove-item-cartbar", null, "modal-cart");
    };
  }, [cart]);

  // Remove from cartbar   //
  const handleRemoveCartEvent = ({ productid, in_cart, price }) => {
    const dataRemoved = {
      _id: productid,
      price: parseFloat(price),
      quantity: parseFloat(in_cart),
    };
    publishEvent("remove-item-cartbar", dataRemoved, "modal-cart");
  };

  // *******  Fin CRM ******* //

  const RemoveItem = (e, item) => {
    setLoading(true);

    postData(
      `cart/${Encode(item?.cartid)}/remove`,
      { key: Encode(item?.cartid) },
      {},
      (res, err) => {
        if (!err) {
          loadShoppingCart(res.balance);
          setReloadItems(true);
          setCart(res);
        }
        handleRemoveCartEvent(item); //Event Sitemap
        setReloadItems(false);
        setLoading(false);
      }
    );
    e.preventDefault();
  };

  const ListItems = () => {
    return (
      <>
        {cart.items?.map((item) => (
          <div
            id="cart-list-item"
            key={item.cartid}
            className={`uk-flex cart-list-item ${
              item.visible === 0 || item.available_order === 0
                ? "gi_item_nodisponible"
                : ""
              }`}
              data-coupon={item?.coupon}
              data-discount={item?.discount}
              data-item-brand={item?.item_brand}
              data-item-category={item?.item_category}
              data-item-category2={item?.item_category2}
              data-item-category3={item?.item_category3}
              data-variant={item?.item_variant}  
            
          >
            <a
              id="remove-item-cartbar"
              onClick={(e) => RemoveItem(e, item)}
              href="#0"
              className="cart-list-close uk-position-top-right btn-update-cart-minus"
              
              data-coupon={item?.coupon}
              data-discount={item?.discount}
              data-item-brand={item?.item_brand}
              data-item-category={item?.item_category}
              data-item-category2={item?.item_category2}
              data-item-category3={item?.item_category3}
              data-variant={item?.item_variant}  
            >
              <i className="fas fa-times"></i>
            </a>
            <Link
              to={`/products/index/${item?.friendlyurl}`}
              className="cart-list-image item-list-image uk-width-1-4"
              style={{
                backgroundImage: `url(${baseImgRef.current}${item?.thumbs})`,
              }}
            ></Link>
            <Link
              to={`/products/index/${item?.friendlyurl}`}
              className="cart-list-info uk-width-3-4"
            >
              <p className="uk-text-uppercase">{item?.name}</p>
              <p className="uk-text-small">{`${item?.in_cart} ${
                item?.producttype
              } x RD$  ${DecimalFormat(item?.price)}`}</p>
              <p>
                <UnAvailable item={item} />
              </p>
            </Link>
          </div>
        ))}
      </>
    );
  };

  const EmptyCart = () => (
    <div className="uk-text-center uk-position-bottom-center uk-padding empty-cart">
      <img
        src="/assets/background/shopping-cart-empty-sidebar.svg"
        style={{ width: "50%" }}
        alt="Carrito Vacio"
      />
      <h4>El carrito está vacío.</h4>
    </div>
  );

  return (
    <div
      id="modal-cart"
      data-test="modal-cart"
      className="modal-cart"
      // uk-offcanvas="true"
      data-uk-offcanvas="overlay: true;mode: push ; flip: true;"
    >
      <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-background-default">
        <div className="cart-list-header uk-flex uk-flex-middle uk-background-default">
          <a
            onClick={closeModal}
            href="#0"
            className="uk-offcanvas-close"
            uk-close="true"
          >
            {""}
          </a>
          <div className="uk-padding-small">
            <p className="uk-h5 uk-text-uppercase uk-text-secondary">
              Mi carrito
            </p>
          </div>
        </div>

        <div
          className="cart-list-items"
          data-uk-height-viewport="offset-bottom: true"
          data-uk-overflow-auto
        >
          {loading ? (
            <Loading />
          ) : cart.items?.length === 0 ? (
            <EmptyCart />
          ) : (
            <ListItems />
          )}
        </div>

        <div className="cart-list-footer uk-background-default">
          {parseFloat(cart?.balance?.order_amount) <
            parseFloat(cart?.balance?.minimum_order) && (
            <div className="uk-width-1-1 uk-padding-small">
              <div className="uk-text-small uk-alert uk-alert-primary">
                <i className="fas fa-exclamation-triangle"></i>
                <p>
                  El monto de esta orden está por debajo del mínimo requerido,
                  RD$ {DecimalFormat(cart.balance?.minimum_order)}
                </p>
              </div>
            </div>
          )}

          <div className="uk-flex uk-text-right">
            <div className="uk-width-2-5">Descuento:</div>
            <div className="uk-width-3-5">
              RD$ {DecimalFormat(cart.balance?.order_discount || 0)}
            </div>
          </div>
          <div className="uk-flex uk-text-right">
            <div className="uk-width-2-5">Costo de envío:</div>
            <div className="uk-width-3-5">
              RD$ {DecimalFormat(cart.balance?.shipping_cost || 0)}
            </div>
          </div>

          <div className="uk-flex uk-text-right uk-text-bolder">
            <div className="uk-width-2-5">Total:</div>
            <div className="uk-width-3-5">
              RD$ {DecimalFormat(cart.balance?.order_amount || 0)}
            </div>
          </div>

          <div className="uk-flex uk-text-right uk-text-bolder uk-text-secondary">
            <div className="uk-width-2-5">A Pagar:</div>
            <div className="uk-width-3-5">
              RD$ {DecimalFormat(cart.balance?.amount || 0)}
            </div>
          </div>

          <div className="uk-width-1-1">
            <Link
              id="go-to-cart"
              data-test="go-to-cart"
              to="/cart"
              replace
              onClick={closeModal}
              uk-toggle="target: #modal-cart"
              className="uk-button uk-button-primary uk-width-1-1"
            >
              Ir al carrito <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
