import React from "react";

export default function PageNotFound({
  message = "Lo sentimos, pero esta página ya no está disponible",
}) {
  const errorList = [
    "error-404.svg",
    "error-empty.svg",
    "error-notify.svg",
    "error-warning.svg",
  ];
  const random = Math.floor(Math.random() * errorList.length);

  return (
    <div className="uk-container uk-container-expand">
      <div className="uk-grid uk-grid-medium" uk-grid="true">
        <div className="uk-width-1-1"></div>
        <div className="uk-width-1-1">
          <br />
          <div className="title-border">
            <h2 className="uk-h3" style={{ textAlign: "center" }}>
              {message}
            </h2>
          </div>

          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              style={{ height: "400px" }}
              src={`/assets/background/${errorList[random]}`}
              alt="error-404"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
