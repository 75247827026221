/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useReducer, useRef, useEffect } from "react";
import UIkit from "uikit";
import { getData } from "helpers/service";
import { Encode, addSearchToMetadata } from "helpers/functions";
import Loading from "../loading/list";
import ProductItem from "components/product/item";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSearchHistory } from "hooks/useSearchHistory";
import { TOTAL, TIMEOUT_ID, BASE_IMG, SEARCH_VALUE,
        SHOW, PRODUCTS, CATEGORIES, PAGES, 
        PREDICTIONS, LOADING, HISTORY_VALUE } from "store/searchbar/actionType";
import { initialState } from "store/searchbar/state";
import { updateStateReducer } from "store/common/commonReducer";

export default function SearchBar() {

  const LIMIT = 12;
  const PAGE = 1;
  const SORT = 0;
  const ALLOWED_LENGTH = 3;
  
  const { searchHistory, handleSaveSearchHistory, removeSearchHistory } = useSearchHistory();
 
  const [
    {
      loading, 
      searchValue, 
      products, 
      categories, 
      pages, 
      total, 
      show, 
      timeoutId, 
      baseImg,
      historyValue,
      predictions
  }, dispatch ] = useReducer(updateStateReducer, initialState);

  let closeButtonRef = useRef();
  let resultsRef = useRef();
  let searchHistoryRef = useRef();
  let mainSearchRef = useRef();
  let dropRef = useRef();
  let wraperDropRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  
  const searchData = () => {

    if (searchValue.trim().length < ALLOWED_LENGTH) return;
    
    closeButtonRef.current.classList.remove("uk-hidden");

    //Muestro el drop de resultados 
    if (!dropRef.current.classList.contains("uk-open")) {
        UIkit.drop(dropRef.current).show();
    }
    //y oculto el drop de historial
    UIkit.drop(searchHistoryRef.current)?.hide();

    if (wraperDropRef.current.classList.contains("uk-hidden")) {
      wraperDropRef.current.classList.remove("uk-hidden");     
    }

    resultsRef.current.classList.remove("uk-hidden");
 
      dispatch({type: LOADING, payload:true});

      getData(
        `product/search`,
        {
          "x-p": Encode(PAGE),
          "x-s": Encode(SORT),
          "x-l": Encode(LIMIT),
          "x-f": Encode(1),
          format:'text-plane',
          search:searchValue,
        },
        (res, err) => {
          if (!err) {           
            dispatch({type:TOTAL, payload:res.total});
            dispatch({type:BASE_IMG, payload:res.base_img});
            dispatch({type:SHOW, payload:res.data.length});
            dispatch({type:PRODUCTS, payload:res.data});
            dispatch({type:CATEGORIES,payload:res.category});
            dispatch({type:PAGES, payload:res.pages});
            dispatch({type:PREDICTIONS, payload: res.predictions})
          }
          dispatch({type:LOADING, payload:false});
        }
      );
  };
  const handleHideDrops = () => {
    //Ocultar drops y close button segun longitud de las palabras
    if (searchValue.trim().length < ALLOWED_LENGTH) {
      
      closeButtonRef.current.classList.add("uk-hidden");
      wraperDropRef.current.classList.add('uk-hidden');

    } else {
      UIkit.drop(searchHistoryRef.current)?.hide();
    }
  }
  
  const handleSearchMobile = () => {
    mainSearchRef.current.classList.toggle("search-mobile-open")
  }

  const inputClear = () => {
     dispatch({type:SEARCH_VALUE, payload:''});
  };

  const toSearch = () => {
    clearTimeout(timeoutId);

    //Historial de busqueda
    handleSaveSearchHistory(searchValue);
    //Add to metadata
    addSearchToMetadata(searchValue);
      navigate(`/products/search/${searchValue}`, { replace: true });
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();

    //Historial de busqueda
     handleSaveSearchHistory(searchValue);
    //Add to metadata
    addSearchToMetadata(searchValue);
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }
      return navigate(`/products/search/${searchValue}`, { replace: true });     
    }

    if (timeoutId > 0) {
      clearTimeout(timeoutId);
    }

    let current = setTimeout(function () {
      searchData();
    }, 600);
 
    dispatch({type:TIMEOUT_ID, payload: current})
  };

  const onChange = (e) => {  
    dispatch({type:SEARCH_VALUE, payload: e.target.value})
  };

  useEffect(() => {
    if (!historyValue) return;
    navigate(`/products/search/${historyValue}`, { replace: true });
  },[historyValue])
  
  useEffect(() => {
    closeButtonRef.current.classList.add("uk-hidden");
    wraperDropRef.current.classList.add('uk-hidden');
    UIkit.drop(searchHistoryRef.current)?.hide();
 }, [location]);

  useEffect(()=>{
     handleHideDrops();
  },[searchValue])

  const SearchResult = () => (
    <>
      {/*< para móvil -->*/}
      <div className="uk-hidden@m">
        <ul className="uk-tab" uk-tab="true">
          <li>
            <a href="#0">Productos ({total})</a>
          </li>
          <li className="uk-hidden">
            <a href="#0">Recetas ({total})</a>
          </li>
          <li className="uk-hidden">
            <a href="#0">Otros </a>
          </li>
        </ul>
         {/* predicciones */}        
         {predictions?.length > 0 && 
          <>            
            <p className="uk-text-uppercase uk-text-small uk-text-bolder uk-flex uk-flex-between">
             <span>Estas buscando...</span> 
            </p>
            <ul className="uk-list uk-list-collapse uk-margin-remove-top">
              {predictions?.map(({product}, idx)=>(
                <li key={idx}>
                   <Link 
                    to={`/products/search/${product}`}
                    onClick={() => {
                      handleSaveSearchHistory(product);
                      addSearchToMetadata(searchValue, product);
                    }}
                    replace
                  >
                    <strong>{product}</strong>                  
                  </Link>
                </li>
              ))}
            </ul>
          </> }   
        <ul className="uk-switcher uk-margin">
          <li className="uk-active">
            <div
              className="uk-drop-grid uk-flex uk-flex-wrap uk-flex-between uk-grid-small"
              uk-grid="true"
            >
              <p className="uk-text-uppercase uk-text-small uk-text-bolder">
                <small>
                  (mostrando {show} resultados de {total})
                </small>
              </p>
              <p className="uk-text-small">
                <Link
                  to={`/products/search/${searchValue}`}
                  className="uk-button uk-button-primary uk-button-small"
                >
                  Ver todos
                </Link>
              </p>
            </div>
            <ul
              className="uk-drop-grid uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s"
              uk-grid="true"
            >
              {products.map((item) => (
                <li key={item.productid}>
                  {" "}
                  <div>
                    <ProductItem
                      key={item.productid}
                      baseImg={baseImg}
                      item={item}   
                      metadata={searchValue} 
                      isSearching={true}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </li>

          <li>
            {categories.length > 0 && (
              <>
                <p className="uk-text-uppercase uk-text-small uk-text-bolder uk-flex uk-flex-between">
                  <span>Categorías:</span>
                  {/*< <a href='productos-cat.php' className='uk-button uk-button-text'><span>ver más</span> +</a> -->*/}
                </p>
                <ul className="uk-list uk-list-collapse uk-margin-remove-top">
                  {categories.map((c, idx) => (
                    <li key={idx}>
                      <Link to={`/products/category/${c.friendlyurl}`}>
                        {c.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}
            {pages.length > 0 && (
              <>
                <p className="uk-text-uppercase uk-text-small uk-text-bolder uk-flex uk-flex-between">
                  <span>Páginas:</span>
                  {/*< <a href='productos-cat.php' className='uk-button uk-button-text'><span>ver más</span> +</a> -->*/}
                </p>
                <ul className="uk-list uk-list-collapse uk-margin-remove-top">
                  {pages.map((x, idx) => (
                    <li key={idx}>
                      <Link to={`/site/page/${x.friendlyURL}`}>
                        {x.title}
                        <br />
                        <small>{x.meta_description}</small>
                      </Link>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}

          </li>
        </ul>
      </div>
      {/*< para desktop -->*/}
      <div className="uk-visible@m">
        <div
          className="uk-drop-grid uk-flex uk-flex-wrap uk-grid uk-grid-divider"
          uk-grid="true"
        >
          <div className="uk-width-1-3 uk-width-2-5@l">

           {/* predicciones */}        
          {predictions?.length > 0 && 
          <>            
            <p className="uk-text-uppercase uk-text-small uk-text-bolder uk-flex uk-flex-between">
              <span>Estas buscando...</span> 
            </p>
            <ul className="uk-list uk-list-collapse uk-margin-remove-top">
              {predictions?.map(({product}, idx)=>(
                <li key={idx}>
                  <Link 
                    to={`/products/search/${product}`}
                    onClick={() => {
                      handleSaveSearchHistory(product);
                      addSearchToMetadata(searchValue, product);
                    }}
                    replace
                  >
                    {product}                
                  </Link>
                </li>
              ))}
            </ul>
          </> }             

            {categories.length > 0 && (
              <>
                <p className="uk-text-uppercase uk-text-small uk-text-bolder uk-flex uk-flex-between">
                  <span>Categorías:</span>
                  {/*< <a href='productos-cat.php' className='uk-button uk-button-text'><span>ver más</span> +</a> -->*/}
                </p>
                <ul className="uk-list uk-list-collapse uk-margin-remove-top">
                  {categories.map((c, idx) => (
                    <li key={idx}>
                      <Link to={`/products/category/${c.friendlyurl}`}>
                        {c.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <hr />
              </>
            )}
            {pages.length > 0 && (
              <>
                <p className="uk-text-uppercase uk-text-small uk-text-bolder">
                  Páginas:
                </p>
                <ul className="uk-list uk-list-collapse uk-margin-remove-top">
                  {pages.map((x, idx) => (
                    <li key={idx}>
                      <Link to={`/site/page/${x.friendlyURL}`}>
                        {x.title}
                        <br />
                        <small>{x.meta_description}</small>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
            
          </div>

          <div className="uk-width-2-3 uk-width-3-5@l">
            <div
              className="uk-drop-grid uk-flex uk-flex-wrap uk-flex-between uk-grid-small"
              uk-grid="true"
            >
              <p className="uk-text-uppercase uk-text-small uk-text-bolder">
                Productos: <br />
                <small>
                  (mostrando {show} resultados de {total})
                </small>
              </p>
              <p className="uk-text-small">
                <Link
                  to={`/products/search/${searchValue}`}
                  className="uk-button uk-button-primary uk-button-small"
                >
                  Ver todos
                </Link>
              </p>
            </div>
            <ul
              className="uk-drop-grid uk-grid uk-grid-small  uk-child-width-1-2 uk-child-width-1-3@m"
              uk-grid="true"
            >
              {products.map((p) => (
                <div key={p.productid}>
                  <ProductItem
                    key={p.productid}
                    baseImg={baseImg}
                    item={p}
                    metadata={searchValue} 
                    isSearching={true}
                  />
                </div>
              ))}
            </ul>
            <hr />         
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="uk-navbar-item uk-width-expand">
      <div className="uk-width-1-1">
        <div
          ref={mainSearchRef}
          id="search-main" className="search-mobile">
          <div className="uk-hidden@m">
            <a href="#0"
              onClick={handleSearchMobile}
              className="btn-search-minimal">
              <i className="fas fa-search"></i>
            </a>
          </div>
                    
          <div id="main-search-bar" className="uk-search uk-search-navbar">
            <a
              ref={closeButtonRef}
              id="search-main-close"
              onClick={inputClear}
              href="#"
              className="uk-hidden"
            >
              <i className="fas fa-times"></i>
            </a>
            <button
              onClick={toSearch}
              className="uk-search-icon uk-search-icon-flip"
            >
              <i className="fas fa-search"></i>
            </button>
            <input
              id="search-main-input"
              data-test="search-main-input"
              className="uk-input uk-search-input"
              onKeyUp={handleKeyUp}
              autoComplete="off"
              onChange={onChange}
              onClick={handleHideDrops}
              value={searchValue}
              placeholder="¿Qué buscas?"
            />
            {   
             searchHistory.length > 0 &&
              <div ref={searchHistoryRef}
                className="uk-drop uk-background-default uk-border-rounded uk-padding-small search-history-container"
                uk-drop="mode: click">
              <ul className="search-history-ul">
                {searchHistory.map((item, idx) => (                            
                  <li key={idx}
                      className="search-history-list">
                    <div className="uk-flex">                     
                      <i className="fa-solid fa-clock-rotate-left"></i>                 
                      <span className='text-history'
                            onClick={() => dispatch({ type: HISTORY_VALUE, payload: item })}>
                        {item.toLowerCase()}
                      </span>
                      <i className="fa-solid fa-close"
                         onClick={() => removeSearchHistory(item)}>
                      </i> 
                    </div>                
                  </li>                  
                ))}
              </ul>
            </div>
            }          
          </div>
              
          <div
            ref={dropRef}
            id="search-main-drop"
            className="uk-drop"
            uk-drop="mode: click">
            <div
              ref={wraperDropRef}
              id="search-main-wrap"
              className="uk-background-default uk-border-rounded uk-box-shadow-small uk-hidden"
            >
              <div ref={resultsRef} id="search-main-results" className="uk-hidden">
                {loading ? <Loading /> : <SearchResult />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
