import React, { useContext, useEffect, useState } from "react";
import AccountContext from "context/Account";
import AuthAnonymous from "components/account/auth/anonymous";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";
import { useParams } from "react-router-dom";
import {
  DecimalFormat,
  setTitle,
  publishEvent,
  unsubscribeEvent,
} from "helpers/functions";

export default function ConfirmationPage() {
  let { confirmation } = useParams();

  const { isLogin, purchaseItems, setPurchaseItems, loadShoppingCart } =
    useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  setTitle("Confirmación de pedido");

  useEffect(() => {
    if (isLogin) {
      getData(
        `checkout/${confirmation}/confirmation`,
        { x: confirmation },
        (res, err) => {
          if (!err) {
            setOrders(res.data);
            loadShoppingCart(null);
          }
          setLoading(false);
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, confirmation]);

  // ******* CRM ******* //

  //***** Purchase *****//

  useEffect(() => {
    let isEmpty = Object.entries(purchaseItems).length === 0;
    if (isEmpty) return;
    publishEvent("purchase", purchaseItems, "confirm-box");

    return () => {
      setPurchaseItems({});
      unsubscribeEvent("purchase", null, "confirm-box");
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseItems]);

  // ***************** //

  if (!isLogin) return <AuthAnonymous source={"full"} />;

  return (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <div
          id="confirm-box"
          data-test="confirm-box"
          className="uk-section uk-margin-medium-bottom"
          data-order-id={purchaseItems?.orderId}
          data-total-value={purchaseItems?.totalValue}
          data-line-items={JSON.stringify(purchaseItems?.lineItems)}
        >
          {loading ? (
            <Loading />
          ) : (
            <div className="uk-container uk-flex uk-flex-center">
              <div className="uk-width-1-1 uk-width-2-3@m uk-width-3-5@l">
                <h2 className="uk-h2 uk-text-center">Confirmación de pedido</h2>

                <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img
                      style={{ height: "200px" }}
                      src={`/assets/background/order_completing.svg`}
                      alt="Confirmación de pedido"
                    />
                  </div>
                  <br />
                  {orders.map((item, idx) => (
                    <span key={idx}>
                      <div className="title-border">
                        <p className="uk-h4">Pedido {item.codigo}</p>
                      </div>
                      <p>
                        <strong>{item.shippingrate}</strong>
                      </p>
                      {item.shippingratetype === "DE" && (
                        <>
                          <div className="uk-flex uk-grid-small">
                            <p>
                              <div className="gi_icon_bdelivery">
                                <img
                                  src="/assets/global/images/img_checkoutexpress.jpg"
                                  alt="Icono delivery"
                                />
                              </div>
                              <span>{`${item.shipping_address} ${item.shipping_address2} ${item.shipping_sector} ${item.shipping_state}`}</span>
                            </p>
                          </div>

                          <p className="uk-flex uk-grid-small">
                            <i className="far fa-calendar-alt"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                        </>
                      )}

                      {item.shippingratetype === "P" && (
                        <>
                          {item.pickup_first_name !== null && (
                            <p className="uk-flex uk-grid-small">
                              <i className="fas fa-user"></i>
                              <span>
                                <strong>
                                  {item.pickup_first_name}{" "}
                                  {item.pickup_last_name}
                                </strong>
                                <br />
                                {item.pickup_documentType}:{" "}
                                {item.pickup_document}
                              </span>
                            </p>
                          )}
                          <p className="uk-flex uk-grid-small">
                            <i className="fas fa-map-pin"></i>
                            <span>
                              <strong>{item.location}</strong>
                              <br /> {item.location_address}
                            </span>
                          </p>
                          <p className="uk-flex uk-grid-small">
                            <i className="far fa-calendar-alt"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                        </>
                      )}

                      {item.shippingratetype === "D" && (
                        <>
                          <p className="uk-flex uk-grid-small">
                            <i className="fas fa-map-pin"></i>
                            <span>{`${item.shipping_address} ${item.shipping_address2} ${item.shipping_sector} ${item.shipping_state}`}</span>
                          </p>
                          <p className="uk-flex uk-grid-small">
                            <i className="far fa-calendar-alt"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                        </>
                      )}

                      {item.shippingratetype === "DS" && (
                        <>
                          <p className="uk-flex uk-grid-small">
                            <i className="fas fa-map-pin"></i>
                            <span>{`${item.shipping_address} ${item.shipping_address2} ${item.shipping_sector} ${item.shipping_state}`}</span>
                          </p>
                          <p className="uk-flex uk-grid-small">
                            <i className="far fa-calendar-alt"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                        </>
                      )}

                      {item.shippingratetype === "HD" && (
                        <>
                          <p className="uk-flex uk-grid-small">
                            <i className="fas fa-map-pin"></i>
                            <span>{`${item.shipping_address} ${item.shipping_address2} ${item.shipping_sector} ${item.shipping_state}`}</span>
                          </p>
                          <p className="uk-flex uk-grid-small">
                            <i className="far fa-calendar-alt"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                        </>
                      )}

                      {item.shippingratetype === "S" && (
                        <>
                          <p>
                            <i className="far fa-money"></i>
                            <span>
                              <strong>{item.timeslot}</strong>
                            </span>
                          </p>
                          <p className="uk-flex uk-grid-small uk-text-justify">
                            Recibimos tu pedido de {item.currency_code}{" "}
                            {DecimalFormat(item.order_amount)}, en Sirena Cash
                            para La(s) persona(s) indicada(s) y le avisaremos al
                            correo que nos indicaste cuando este disponible para
                            consumo (dos horas laborables). La(s) persona(s)
                            indicada(s) podrá utilizarlo mostrando su cédula al
                            momento de pagar en cualquiera de nuestras tiendas
                            Sirena y Sirena Market.
                            <br />
                            <br />
                            Es importante que te asegures que digitaste
                            correctamente el número de cédula del beneficiario
                            pues de lo contrario el no podrá consumirlo.
                            <br />
                            <br />
                            Esta compra de Sirena Cash no generan Puntos Siremas
                            para usted pero si para la persona que realizará el
                            consumo.
                          </p>
                        </>
                      )}

                      {item.aprobado === 0 && (
                        <>
                          <div className="uk-text-small uk-alert uk-alert-warning uk-margin-medium-top">
                            <i className="fas fa-exclamation-triangle"></i>
                            <p>
                              El cargo a su tarjeta no fue procesado, favor
                              intentar de nuevo entrando en el enlace que le
                              enviamos a su correo o puede volver a pagar en la
                              opción que está en debajo.
                            </p>
                          </div>
                          <p className="uk-text-center">
                            <a
                              href={`/order-payment/${item.orderkey}`}
                              rel="noreferrer"
                            >
                              <strong>Volver a Pagar</strong>
                            </a>
                          </p>
                        </>
                      )}
                      <hr className="uk-margin-medium-top uk-margin-medium-bottom" />
                    </span>
                  ))}
                </div>
                {/*
            <div className='uk-padding uk-padding-remove-bottom'>
                <p>Tu pedido ha sido recibido y un experto en recolección estará encargado de prepararlo para que
                    esté listo en el tramo de hora seleccionado por ti.</p>
                <p>Si deseas realizar un cambio, puedes hacerlo hasta una hora antes del inicio del tramo
                    seleccionado. Solo tienes que hacer click sobre tu usuario en la parte superior derecha de la
                    pantalla y en "Mis Órdenes" seleccionar la opción <strong>modificar</strong>.</p>
                <p><a href='/account/order-history'
                      className='uk-button uk-button-primary'><span>Ir a mis órdenes</span> <i
                    className='fas fa-receipt'></i></a></p>
            </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
