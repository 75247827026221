import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { DEFAULT_LAT, DEFAULT_LON, KEY_MAPS } from "helpers/service";

const GoogleMaps = ({ stores = [], route = [], order = [] }) => {
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: KEY_MAPS,
  });

  const mapStyle = {
    width: "100%",
    height: "100%",
  };

  //implementar el default position de esta manera no funciona
  const defaultPosition = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LON,
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(defaultPosition);
    map?.fitBounds(bounds);
    setMap(map);

  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle}
      center={
        route?.driver_lat && route?.driver_lon
          ? {
              lat: parseFloat(route?.driver_lat),
              lng: parseFloat(route?.driver_lon),
            }
          : stores[0]?.lat && stores[0]?.lon
          ? { lat: parseFloat(stores[0]?.lat), lng: parseFloat(stores[0].lon) }
          : { lat: parseFloat(DEFAULT_LAT), lng: parseFloat(DEFAULT_LON) }
      }
      zoom={15}
      onLoad={() => onLoad(map)}
      onUnmount={onUnmount}
    >
      {
        order?.map((order, index) => (
          <Marker
            icon="/assets/global/pin_home.png"
            key={index}
            animation={1}
            position={{
              lat: parseFloat(order.shipping_lat),
              lng: parseFloat(order.shipping_lon),
            }}
          />
        ))}

      {
        route?.map((route, idx) => (
          <Marker
            icon="/assets/global/pin_driver.png"
            key={idx}
            animation={1}
            position={
              route && {
                lat: parseFloat(route?.driver_lat),
                lng: parseFloat(route?.driver_lon),
              }
            }
          />
        ))}

      {route?.map((route, idx) => (
        <Marker
          icon="/assets/global/gmarker.png"
          key={idx}
          animation={1}
          position={
            route && {
              lat: parseFloat(route?.location_lat),
              lng: parseFloat(route?.location_lon),
            }
          }
        />
      ))}

      {stores?.map((item, idx) => (
        <Marker
          icon="/assets/global/gmarker.png"
          key={idx}
          animation={1}
          position={{
            lat: parseFloat(item?.lat),
            lng: parseFloat(item?.lon),
          }}
        />
      ))}
    </GoogleMap>
  ) : (
    <>Cargando...</>
  );
};
export default React.memo(GoogleMaps);
