import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getData, postData } from "helpers/service";
import { Encode } from "helpers/functions";
import Select from "react-select";
import Loading from "components/share/loading";
import ReactDOM from "react-dom";

export default function AddressEdit({ isOpen, item, setClose }) {
  const [address, setAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [stateid, setStateid] = useState(null);
  const [state, setState] = useState([]);
  const [sector, setSector] = useState([]);
  const [sectorLoad, setSectorLoad] = useState(false);
  const [checked, setChecked] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (isOpen === "edit") {
      setLoading(true);
      getData(
        `account/address/${Encode(item.id)}/details`,
        {
          id: Encode(item.id),
        },
        (res, err) => {
          if (!err) {
            reset(res.address);

            setState(res.state);
            setSector(res.sector);
            setAddress(res.address);
            setStateid(res.address.state_id);
            setChecked(res.address.by_default === 1);
            
          } else {
            setClose();
          }
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, item]);

  useEffect(() => {
    if (!loading && isOpen === "edit") {
      setSectorLoad(true);
      getData(
        `setting/address/sector`,
        {
          stateid: Encode(stateid),
          search: Encode(""),
        },
        (res, err) => {
          if (!err) {
            setSector(res.data);
          }
          setSectorLoad(false);
        }
      );
    }
  }, [stateid, isOpen, loading]);

  const handleSelectChangeState = (option) => {
    setStateid(option?.value);
    setValue("state_id", option?.value);
    setSector([]);
  };

  const handleSelectChangeSector = (option) => {
    setValue("sector_id", option?.value);
  };

  const onSubmit = (data) => {
    setLoading(true);

    data["id"] = address.id;

    postData("account/address", data, {}, (res, err) => {
      if (!err) {
        setClose(true, res.message);
      }
      setLoading(false);
    });
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  if (isOpen !== "edit") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        onClick={setClose}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Editar dirección
        </p>
      </div>
      <div className="uk-modal-body">
        <div className="uk-width-1-1">
          {loading ? (
            <Loading />
          ) : (
            <form
              className="uk-form-horizontal uk-margin-remove"
              onSubmit={mySubmitHandler}
            >
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="tag"
                  >
                    Alias <span className="required">*</span>
                  </label>
                  <input
                    className="uk-input"
                    id="tag"
                    name="tag"
                    {...register("tag", {
                      required: "Alias(s) son requeridos",
                    })}
                    type="text"
                    placeholder="Cómo desea guardar la dirección"
                  />
                  <p className="error">{errors?.tag?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="state_id">
                    Provincia <span className="required">*</span>
                  </label>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <Select
                    placeholder="Seleccione..."
                    defaultValue={{
                      label: address?.state,
                      value: address?.state_id,
                    }}
                    options={state}
                    onChange={handleSelectChangeState}
                  />
                  <input
                    type="hidden"
                    id="state_id"
                    name="state_id"
                    {...register("state_id", {
                      required: "La provincia es requerida",
                    })}
                  />
                  <p className="error">{errors?.state_id?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="sector_id">
                    Sector <span className="required">*</span>
                  </label>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <Select
                    placeholder="Seleccione..."
                    isSearchable={true}
                    isLoading={sectorLoad}
                    defaultValue={{
                      label: address?.sector,
                      value: address?.sector_id,
                    }}
                    options={sector}
                    onChange={handleSelectChangeSector}
                  />
                  <input
                    type="hidden"
                    id="sector_id"
                    name="sector_id"
                    {...register("sector_id", {
                      required: "El sector es Requerido",
                    })}
                  />
                  <p className="error">{errors?.sector_id?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address"
                  >
                    Dirección (Calle/Número)<span className="required">*</span>
                  </label>

                  <textarea
                    id="address"
                    name="address"
                    cols="30"
                    rows="2"
                    className="uk-textarea"
                    {...register("address", {
                      required: "la dirección (Calle/Número) es requerida",
                    })}
                  ></textarea>
                  <p className="error">{errors?.address?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address2"
                  >
                    Dirección (Residencial / Casa o Apto. No.){" "}
                    <span className="required">*</span>
                  </label>

                  <textarea
                    id="address2"
                    name="address2"
                    cols="30"
                    rows="2"
                    className="uk-textarea"
                    {...register("address2")}
                  ></textarea>
                  <p className="error">{errors?.address2?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label>
                    <input
                      name="by_default"
                      checked={checked}
                      onChange={(e) => {
                        setChecked(!checked);
                      }}
                      className="uk-checkbox"
                      type="checkbox"
                    />
                    <span style={{ marginTop: "4px" }}>
                      Hacer de esta mi dirección predeterminada
                    </span>
                  </label>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button  uk-button-custom"
          type="button"
          disabled={loading}
          onClick={setClose}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
