/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { getBlob, getData, postData } from "helpers/service";
import Loading from "components/share/loading/table";
import {
  DecimalFormat,
  Encode,
  insertUrlParam,
  setTitle,
  ConsoleLog,
} from "helpers/functions";

import Pagination from "components/product/pagination";
import UIkit from "uikit";

import OrderCancel from "components/account/profile/order/cancel";
import LoadingModal from "components/share/loading/modal";
import DocumentPDFModal from "components/share/PDF";
import { updateStateReducer } from "store/common/commonReducer";
import { LOADING, TOTAL, CURRENT_ORDER, CURRENT_TOTAL, ORDERS,
OPEN, ORDER_GROUP, PAGE, LIMIT, MESSAGE, PDF_URL } from "store/account/orderhistory-actionTypes";

export default function OrderHistoryPage() {
 
  const navigate = useNavigate();  
  const { search } = useLocation();
  const qs = queryString.parse(search);

const initialState={
  total:{},
  currentOrder:[],
  currentTotal:0,
  orders:[],
  loading:true,
  open:'none',
  orderGroup:qs.group || 1,
  page:qs.page || 1,
  limit:15,
  message: "",
  pdfUrl:""
}

const [
  {
    total,
    currentOrder,
    currentTotal,
    orders,
    loading,
    open,
    orderGroup,
    page,
    limit,
    message,
    pdfUrl
  }, dispatch]= useReducer(updateStateReducer, initialState);
  setTitle("Mis Órdenes");

  const Reload = (isFirstRun = false) => {
    dispatch({type:LOADING, payload:true});
    dispatch({type:ORDERS, payload:[]});

    if (isFirstRun) {
      getData("account/order", null, (res, err) => {
        if (!err) {
          dispatch({type:TOTAL, payload:res?.total  || []})
          dispatch({type:CURRENT_TOTAL, payload:res?.total?.group_invoice_1 || 0})
          dispatch({type:ORDERS, payload:res?.orders})      
        }
        dispatch({type:LOADING, payload:false});
      });
    } else {
      insertUrlParam(`page=${page}&group=${orderGroup}&limit=${limit}`);
      window.scrollTo({ behavior: "smooth", top: 0 });

      getData(
        `account/order/${Encode(orderGroup)}/list`,
        {
          "x-p": Encode(page),
          "x-l": Encode(limit),
        },
        (res, err) => {
          if (!err) {
            dispatch({type:ORDERS, payload:res.orders || []})
            dispatch({type:CURRENT_TOTAL, payload:res.total || 0})
          }
          dispatch({type:LOADING, payload:false});
        }
      );
    }
  };

  const onSelect = (item, actions) => {
    dispatch({type:CURRENT_ORDER, payload:item});

    switch (actions) {
      case "buy-again": {
        dispatch({type:MESSAGE, payload:`Agregando pedido ${item?.codigo} al carrito, por favor espere...`})
        dispatch({type:OPEN, payload:'loading'});
        UIkit.modal(`#webModal`).show();

        postData(
          `cart/${item.orderkey}/buy-order-again`,
          { orderid: Encode(item.orderkey) },
          {},
          (res, err) => {
            if (!err) {
              navigate(`/cart`, {replace:true});
            }
            dispatch({type:OPEN, payload:'none'});
            UIkit.modal(`#webModal`).hide();
          }
        );

        break;
      }
      case "order-cancel": {
        dispatch({type:OPEN, payload:'OrderCancel'});
        UIkit.modal(`#webModal`).show();

        break;
      }
      case "print-order": {
        if (item.file_path_order) {
          dispatch({ type: OPEN, payload: 'documentPdf' });
          dispatch({ type: PDF_URL, payload: item?.file_path_order});
          UIkit.modal(`#webModal-container`)?.show();
        }
        else {    
        dispatch({type:MESSAGE, payload:`Descargando pedido ${item.codigo}, por favor espere...`})     
        dispatch({type:OPEN, payload:'loading'});
        UIkit.modal(`#webModal`).show();

        getBlob(
          `account/order/${item.orderkey}/print`,
          { template: Encode("pedido") },
          (res, err) => {
            if (!err) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${item.codigo}-pedido.pdf`);
              document.body.appendChild(link);
              link.click();
            }
            dispatch({type:OPEN, payload:'none'});
            UIkit.modal(`#webModal`).hide();
          }
        );
       }
        break;
      }
      case "print-invoice": {
        if (item.file_path_invoice) {
          dispatch({ type: OPEN, payload: 'documentPdf' });
          dispatch({ type: PDF_URL, payload: item?.file_path_invoice });
          UIkit.modal(`#webModal-container`)?.show();
          
         }else {
          dispatch({type:MESSAGE, payload:`Descargando factura ${item.codigo}, por favor espere...`})          
          dispatch({type:OPEN, payload:'loading'});
          UIkit.modal(`#webModal`).show();
        
          getBlob(
          `account/order/${item.orderkey}/print`,
          { template: Encode("factura") },
          (res, err) => {
            if (!err) {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${item.codigo}-factura.pdf`);
              document.body.appendChild(link);
              link.click();
            }
            dispatch({type:OPEN, payload:'none'});
            UIkit.modal(`#webModal`).hide();
          }
          );
          
        }
        break;
      }
      case "order-variation":
      case "order-modify":
      case "order-payment":
      case "order-tracking": {
        navigate(`/${actions}/${item.orderkey}`, {replace: true});
        break;
      }
      default: {
        ConsoleLog("CASE NOT FOUND");
      }
    }
  };

  const onSetClose = (force = false) => {
    UIkit.modal("#webModal").hide();
    dispatch({type:OPEN, payload:'none'});
    Reload(force);
  };

  const onOrderCancel = () => {
    dispatch({type:MESSAGE, payload:`Cancelando pedido ${currentOrder?.codigo}, por favor espere...`}) 
    dispatch({type:OPEN, payload:'loading'});
    UIkit.modal(`#webModal`).show();

    dispatch({type:LOADING, payload:true});
    postData(
      `account/order/${Encode(currentOrder.orderkey)}/cancel`,
      { key: Encode(currentOrder.orderkey) },
      {},
      (res, err) => {
        if (!err) {
          onSetClose(true);
        }
        dispatch({ type: LOADING, payload: false });
        dispatch({ type: OPEN, payload: 'none' });
        UIkit.modal(`#webModal`)?.hide();
      }
    );
  };
  const handleSetLimit = (value)=>{
    dispatch({type:LIMIT, payload:value})
  }
  const handleSetPage = (value)=>{
    dispatch({type:PAGE, payload:value})
  }
  useEffect(() => {
    Reload(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Reload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderGroup]);

  const OrderItems = ({ row }) => (
    <tr>
      <td>
        <select
          className="uk-select"
          onChange={(event) => onSelect(row, event.target.value)}
        >
          <option>---Opciones---</option>
          {row.buy_again === 1 && (
            <option value="buy-again">Repetir compra</option>
          )}
          {row.allow_cancel === 1 && (
            <option value="order-cancel">Cancelar</option>
          )}
          {row.payment === 0 && row.finished === 0 && (
            <option value="order-payment">Pagar</option>
          )}
          <option value="print-order">Imprimir Pedido</option>
          {row.billed === 1 && (
            <option value="print-invoice">Imprimir Factura</option>
          )}
          {row.allow_modify === 1 && row.payment === 1 && (
            <option value="order-modify">Modificar Pedido</option>
          )}
          {row.buy_again === 1 && row.billed === 1 && (
            <option value="order-variation">Ver variaciones</option>
          )}
          {row.delivered === "2" && (
            <option value="order-tracking">Rastreo de pedido</option>
          )}
        </select>
      </td>
      <td>{row.timeslot}</td>
      <td>{row.codigo}</td>
      <td>{row.ncf}</td>
      <td className="uk-text-right">{row.order_date}</td>
      <td>{row.shippingrate}</td>
      <td>{row.status}</td>
      <td className="uk-text-right">
        {row.currency_code} {DecimalFormat(row.total_amount)}
      </td>
    </tr>
  );

  const OrdersList = () => (
    <div className="uk-overflow-auto">
      <table className="uk-table uk-table-striped uk-table-small">
        <thead>
          <tr>
            <th></th>
            <th>Horario</th>
            <th>Pedido</th>
            <th>Factura</th>
            <th>Compra</th>
            <th>Entrega</th>
            <th>Estatus</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((o) => (
            <OrderItems key={o.orderkey} row={o} />
          ))}
        </tbody>
      </table>

      <Pagination
        setLimit={handleSetLimit}
        setPage={handleSetPage}
        CurrentPage={page}
        total={currentTotal}
        limit={limit}
      />
    </div>
  );

  return (
    <>
    
    <div>
      <div className="title-border uk-flex uk-flex-between uk-flex-bottom">
        <h2 className="uk-h3">Mis Órdenes</h2>
        <div className="uk-hidden@m">
          <a
            href="#0"
            className="uk-button uk-button-default uk-button-custom"
            uk-toggle="target: modal-filters"
          >
            <i className="fas fa-filter"></i>
          </a>
        </div>
      </div>

      <div
        className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters"
        uk-grid="true"
      >
        <div></div>
        <div>
          <span className="uk-inline">
            <i className="fas fa-shopping-bag uk-margin-small-right"></i>{" "}
            {total?.orders} órdenes
          </span>
        </div>
      </div>

      <div className="uk-card uk-card-default uk-padding uk-border-rounded uk-box-shadow-small uk-margin-medium-top uk-margin-large-bottom">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="uk-flex uk-flex-wrap uk-flex-right uk-grid-small">
              <a
                href="#"
                onClick={(e) => {
                  dispatch({type:PAGE, payload:1});
                  dispatch({type:ORDER_GROUP, payload:1})
                  e.preventDefault();
                }}
                className="uk-text-warning"
              >
                Abiertas ({total?.group_invoice_1 || 0})
              </a>
              <a
                href="#"
                onClick={(e) => {
                  dispatch({type:PAGE, payload:1});
                  dispatch({type:ORDER_GROUP, payload:2})
                  e.preventDefault();
                }}
                className="uk-text-secondary"
              >
                Facturadas ({total?.group_invoice_2 || 0} )
              </a>
              <a
                href="#"
                onClick={(e) => {
                  dispatch({type:PAGE, payload:1});
                  dispatch({type:ORDER_GROUP, payload:3})
                  e.preventDefault();
                }}
                className="uk-text-danger"
              >
                Canceladas ({total?.group_invoice_3 || 0})
              </a>
            </div>

            <hr />
            <OrdersList />
          </>
        )}
        <OrderCancel
          isOpen={open}
          onSetClose={onSetClose}
          onOrderCancel={onOrderCancel}
          item={currentOrder}
        />
        <LoadingModal isOpen={open} message={message} />   
      </div>
    </div>
      <DocumentPDFModal
        isOpen={open}
        orderCode={currentOrder.codigo}
        url={pdfUrl}
      />
    </>
  );
}
