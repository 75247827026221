import React from "react";
import ReactDOM from "react-dom";
import ProductDetail from "./index";

export default function ProductDetailModal({ isOpen, current, onSetClose }) {
  if (isOpen !== "detail") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        onClick={onSetClose}
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          {current?.name}
        </p>
      </div>

      <div className="uk-modal-body">
        <ProductDetail friendlyurl={current?.friendlyurl} />
      </div>
    </div>,
    document.getElementById("webModal-full")
  );
}
