import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getData } from "helpers/service";
import {
  Encode,
  GetStorage,
  insertUrlParam,
  setTitle,
} from "helpers/functions";
import Pagination from "components/product/pagination";
import GridProducts from "components/product/grid";
import Filter from "components/product/filter";
import AccountContext from "context/Account";
import { GetTypeDevice } from "helpers/functions";
import { BannerEspeciallyModel } from "models/adsense";
import Advertisements from "components/share/adsense";

export default function EspeciallyPage() {
  const { categories, account, reloadItems } = useContext(AccountContext);
  const { search } = useLocation();
  const qs = queryString.parse(search);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState(qs.sort || GetStorage("sort") || 1);
  const [page, setPage] = useState(qs.page || 1);
  const [category, setCategory] = useState(qs.category || "");
  const [limit, setLimit] = useState(qs.limit || GetStorage("limit") || 15);
  const [baseImg, setBaseImg] = useState("");
  const currentType = GetTypeDevice();
  setTitle("Ofertas en Línea");

  useEffect(() => {
    setLoading(true);
    insertUrlParam(
      `page=${page}&limit=${limit}&sort=${sort}&category=${category}`
    );
    getData(
      `product/especially`,
      {
        "x-p": Encode(page),
        "x-s": Encode(sort),
        "x-l": Encode(limit),
        category: category,
      },
      (res, err) => {
        if (!err) {
          setBaseImg(res.base_img);
          setTotal(res.total);
          setProducts(res.data || []);
        }
        setLoading(false);
      }
    );
  }, [page, sort, limit, category, account, reloadItems]);

  const ShowAll = () => {
    setPage(1);
    setLimit(0);
  };

  return (
    <>
      <div className="uk-padding">
        <div className="uk-section uk-section-small uk-flex uk-flex-center">
          {BannerEspeciallyModel.filter(
            (item) => item.type === currentType
          ).map((item) => (
            <Advertisements
              key={item.slotId}
              className={item.className}
              type={item.type}
              slotId={item.slotId}
              sizes={item.sizes}
              adUnit={item.adUnit}
            />
          ))}
        </div>
        <div className="title-border uk-padding-remove-bottom">
          <h2 className="uk-h4">Ofertas en línea</h2>
          <div
            className="slider-categories"
            uk-slider="sets: true; finite: true"
          >
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-grid-match">
                  {categories.map((c) => (
                    <li
                      key={c.id}
                      className={`${
                        c.friendlyurl === category ? "current-category" : ""
                      }`}
                    >
                      <a
                        href="#0"
                        onClick={() => setCategory(c.friendlyurl)}
                        className="item-category"
                      >
                        <div
                          className="item-category-image"
                          style={{ backgroundImage: `url(${c?.imagen})` }}
                        ></div>
                        <p className="item-category-title">{c?.name}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <a
                className="uk-position-center-left-out"
                href="#0"
                uk-slider-item="previous"
              >
                <i className="fas fa-chevron-left"></i>
              </a>
              <a
                className="uk-position-center-right-out"
                href="#0"
                uk-slider-item="next"
              >
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        <Filter total={total} setSort={setSort} ShowAll={ShowAll} />

        <GridProducts loading={loading} baseImg={baseImg} products={products} />
        <Pagination
          setLimit={setLimit}
          setPage={setPage}
          CurrentPage={page}
          total={total}
          limit={limit}
        />
      </div>
    </>
  );
}
