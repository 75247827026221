import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as XLSX from "xlsx";
import AccountContext from "context/Account";
import {
  CheckDocument11,
  DocumentIdentityFormat,
  IsValidEmail,
  MoneyFormat,
  publishEvent,
  unsubscribeEvent,
  addIdToArray 
} from "helpers/functions";
import LoadingModal from "../share/loading/modal";
import alert from "../../helpers/alerts";


export default function DigitalPointLoadXlsx({ isOpen, setClose }) {
  const { setCash } = useContext(AccountContext);
  const SIRENA_CASH_ID = process.env.REACT_APP_SIRENA_CASH_ID;

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [tmp, setTmp] = useState([]);
  const [filename, setFilename] = useState("");
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (completed) {
      setLoadingOpen(false);
      setItems([]);
      setTotal(0);
      setTmp([]);
      setClose("load");
    }
    return () => {
      unsubscribeEvent('add-to-cart', null, 'modal-sirena-cash-excel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);
  
  //************* CRM *************//
 //********? Add to cart *********//
  const handleViewCartFromExcel = (items) => {
    const amounts = items.map((item) => item.amount);
    const initialValue = 0;
    const quantity = amounts.reduce(
      (prev, current) => parseFloat(prev) + parseFloat(current),
      initialValue)
    
    let lineItem = {
      _id: SIRENA_CASH_ID,
      price:  1,
      quantity,
      currency:'DOP'
    }
    publishEvent('add-to-cart', lineItem, 'modal-sirena-cash-excel');
  
 }
  //************ FIN CRM *********//

  const onSubmit = () => {
    let sirenaCashData = [];
    let totalAmount = 0;

    items.forEach((item) => {
      let cedula = item?.CEDULA?.toString() || "";
      let xlength = cedula?.length || 0;
      if (xlength > 0 && xlength < 11) {
        cedula = cedula?.padStart(11, "0");
      }

      let HasError = 0;
      let Msj = "";

      if (!CheckDocument11(cedula)) {
        HasError = 1;
        Msj = "* El número de cedula es incorrecto";
      }

      if (parseInt(item?.MONTO) < 100) {
        HasError = 1;
        Msj = "* El Monto minimo de venta es RD$100";
      }

      if (parseInt(item?.MONTO) > 99000) {
        HasError = 1;
        Msj =
          "* El limite de compra en una misma transacción por beneficiario es de RD$99,000";
      }

      if (!IsValidEmail(item.CORREO)) {
        HasError = 1;
        Msj = "* La dirección de correo electrónico no es válida.";
      }

      sirenaCashData.push({
        document: DocumentIdentityFormat(cedula),
        first_name: item.NOMBRE,
        last_name: item.APELLIDO,
        email: item.CORREO,
        phone:
          item.TELEFONO?.length > 20
            ? item.TELEFONO.substr(0, 20)
            : item.TELEFONO,
        amount: parseInt(item.MONTO),
        message:
          item.MENSAJE?.length > 500
            ? item.MENSAJE.substr(0, 500)
            : item.MENSAJE,
        error: HasError,
        errorMsj: Msj,
      });

      if (HasError === 0) {
        totalAmount = totalAmount + parseInt(item.MONTO);
      }
    });

    let newSirenaCashData = addIdToArray(sirenaCashData);
    let Order = {
      details: newSirenaCashData,
      total: totalAmount,
    };

    setCash(Order);
    //? Aqui es donde deberia estar la funcion 'handleAddToCartFromExcel'
    //? al recibir un arreglo de beneficiarios.
    handleViewCartFromExcel(newSirenaCashData);
    setCompleted(true);
  };

  const Close = () => {
    setItems([]);
    setTotal(0);
    setTmp([]);
    setClose();
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { range: 10 });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        setLoadingOpen(false);
        reject(error);
      };
    });

    promise.then((d) => {
      setLoadingOpen(false);

      if (d.length > 0) {
        if (CheckDocument11(d[0]?.CEDULA)) {
          setTmp([
            {
              document: DocumentIdentityFormat(d[0]?.CEDULA),
              first_name: d[0]?.NOMBRE,
              last_name: d[0]?.APELLIDO,
              email: d[0]?.CORREO,
              phone: d[0]?.TELEFONO,
              amount: parseInt(d[0]?.MONTO),
              message: d[0]?.MENSAJE,
            },
          ]);
          setTotal(d.length);
          setItems(d);
          
        }
      } else {
        alert.confirm(
          `El archivo "${filename}" no contiene beneficiarios`,
          "error"
        );
      }
    });
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div id="modal-sirena-cash-excel" className="modal-sirena-cash uk-modal-dialog uk-margin-auto-vertical uk-light">
        <button
          onClick={() => Close()}
          className="uk-modal-close-default"
          type="button"
          uk-close="true"
        ></button>
        <div
          className="uk-modal-body uk-text-center"
          style={{ color: "black" }}
        >
          <p className="uk-text-secondary">
            <i className="far fas fa-file-upload fa-3x"></i>
          </p>
          <p className="uk-h5" style={{ color: "black" }}>
            Subir Plantilla desde de Excel
          </p>

          <button
            id="btn-upload-file"
            disabled={loadingOpen}
            onClick={() => {
              document.getElementById(`upload-sirenacash`).click();
            }}
            type="button"
            className="uk-button uk-button-small"
            style={{ color: "black" }}
          >
            <span>Subir Archivo</span> <i className="fas fa-file-upload"></i>
          </button>

          <input
            id="upload-sirenacash"
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => {
              setLoadingOpen(true);
              setMessage("Estamos subiendo el archivo, por favor espere...");
              const file = e.target.files[0];
              setFilename(e.target.files[0].name);
              setTimeout(function () {
                readExcel(file);
              }, 600);
            }}
          />
          {total > 0 ? (
            <>
              <br />
              <p className="uk-h6">
                Archivo cargado: <strong>{filename}</strong>
              </p>
              <p className="uk-h6">
                Registos encontados: <strong>{total}</strong>
              </p>
              <small>
                Se muestra solo el primer registro para confirmacion
              </small>
              <p>
                <small>
                  *Este proceso ignorará los registros con cédulas que no son
                  válidas.
                </small>
              </p>
              <table className="uk-table uk-table-divider uk-table-small">
                <thead>
                  <tr>
                    <th className="uk-width-auto">Persona</th>
                    <th className="uk-width-auto uk-text-right">Monto</th>
                  </tr>
                </thead>
                <tbody>
                  {tmp.map((item, i) => (
                    <tr key={i}>
                      <td className="uk-text-left">
                        {item.document} <br />
                        {`${item.first_name} ${item.last_name}`} <br />
                        {item.email} <br />
                        {item.phone}
                      </td>
                      <td className="uk-width-auto uk-text-right">
                        {MoneyFormat(item.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <hr />
              <p>
                Plantilla para la subida
                  <a
                  id="btn-cash-template"
                  href="/assets/template/cash-template.xlsx"
                  className="uk-button uk-button-small"
                  style={{ color: "black" }}
                >
                  <span>Descargar</span>
                  <i className="fas fa-file-download"></i>
                </a>
              </p>
            </>
          )}
        </div>

        <div className="uk-modal-footer uk-text-center">
          <button
            disabled={loadingOpen}
            onClick={() => setClose()}
            className="uk-button uk-modal-close uk-button-custom"
            type="button"
          >
            <span>Cancelar</span>
            <i className="fas fa-times"></i>
          </button>
          <button
            id="btn-submit-template"
            disabled={loadingOpen}
            onClick={() => {
              setMessage("Estamos procesando el archivo, por favor espere...");
              setLoadingOpen(true);
              setTimeout(function () {
                onSubmit();
              }, 600);
            }}
            className="uk-button uk-button-default uk-button-custom"
            type="button"
          >
            <span>Aplicar</span> <i className="fas fa-check"></i>
          </button>
        </div>
      </div>
      <LoadingModal
        isOpen={loadingOpen ? "loading" : "none"}
        message={message}
      />
    </>,
    document.getElementById("webModal")
  );
}
