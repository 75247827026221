import React, { useState, useEffect } from "react";

const Items = ({ items, page, limit }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let dataLoyalty = [];
    if (items?.length > 0) {
      let index = (page - 1) * limit;

      for (let i = 0; i < limit; i++) {
        let pos = i + index;

        if (pos < items.length) {
          dataLoyalty.push(items[pos]);
        }
      }
    }
    setData(dataLoyalty);
  }, [items, page, limit]);

  return data.map((item, index) => (
    <tr key={index}>
      <td>{item?.fecha}</td>
      <td>{item?.concepto}</td>
      <td className="uk-text-right">{item?.puntos_ganados}</td>
      <td className="uk-text-right">{item?.puntos_redimidos}</td>
      <td className="uk-text-right">{item?.balance}</td>
    </tr>
  ));
};

export default Items;
