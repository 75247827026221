import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loading from "components/share/loading/bullet";
import { getData } from "helpers/service";

export default function SelectCreditcard({ isOpen, onSetClose, onSetCreditCard, current }) {

  const [loading, setLoading] = useState(true);
  const [creditCard, setCreditCard] = useState([]);
  const [currentCreditCard, setCurrentCreditCard] = useState({});

  useEffect(() => {
    if (isOpen === "credit-cards") {
      setCreditCard([]);
      setLoading(true);
      getData(`account/creditcard`, {}, (res, err) => {
        if (!err) {
          setCreditCard(res.data);

          if (res.data.length > 0) {
            setCurrentCreditCard(res.data[0]);
          }
        }
        setLoading(false);
      });
    }
  }, [isOpen]);

  if (isOpen !== "credit-cards") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        onClick={() => onSetClose()}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Mis Tarjetas
        </p>
      </div>

      <div id="modal-hours" className="uk-modal-body">
        {loading && <Loading />}
        <ul className="uk-list">
          {creditCard.map((item, idx) => (
            <li key={idx}>
              <label
                onClick={() => {
                  setCurrentCreditCard(item);
                }}
              >
                <div className="uk-flex uk-grid-small">
                  <input
                    defaultChecked={item.by_default === 1}
                    className="uk-radio"
                    type="radio"
                    name="credit-card"
                  />
                  <div className="uk-width-auto">
                    <img
                      src={`/assets/creditcart/${item.cc_type}.jpg`}
                      alt={item.cc_type}
                      style={{ maxHeight: 35 }}
                    />
                  </div>
                  <div className="uk-width-expand">
                    <strong>{item.cc_mask}</strong>
                    <br />
                    <small>Vence: {item.cc_exp}</small>
                  </div>
                  {item.confirmed === 0 && (
                    <span className="uk-badge uk-badge-danger">
                      Sin Confirmar
                    </span>
                  )}
                </div>
              </label>
            </li>
          ))}
        </ul>
        <p>
          <button
            onClick={() => onSetClose("CreditcardAdd")}
            className="uk-button uk-button-primary uk-button-small"
          >
            <span>Agregar Tarjeta</span>
            <i className="far fa-credit-card"></i>
          </button>
        </p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => onSetCreditCard(false, "", currentCreditCard)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Aplicar</span>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
