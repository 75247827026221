/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import alert from "helpers/alerts";
import { getData, postData } from "helpers/service";
import { Encode, isBlank, setTitle } from "helpers/functions";

import Loading from "components/share/loading/crud";

import ContactsList from "components/account/profile/contacts";
import ContactAdd from "components/account/profile/contacts/add";
import ContactEdit from "components/account/profile/contacts/edit";
import ContactDelete from "components/account/profile/contacts/delete";
import UIkit from "uikit";

export default function ContactPage() {
  const [contactItems, setContactItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState("none");
  const [current, setCurrent] = useState({});

  setTitle("Mis contactos");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    setOpen("none");
    setContactItems([]);
    getData("account/contact", null, (res, err) => {
      if (!err) {
        setContactItems(res.data);
      }
      setLoading(false);
    });
  };

  const onOpen = (action = "add", data = []) => {
    setOpen(action);
    setCurrent(data);
  };

  const onSetByDefault = (id) => {
    setLoading(true);
    postData(
      `account/contact/${Encode(id)}/default`,
      { key: Encode(id) },
      {},
      (res, err) => {
        loadData();
      }
    );
  };

  const onSetClose = (reload = false, message = "") => {
    UIkit.modal("#webModal").hide();
    setOpen('null');

    if (!isBlank(message)) alert.toast(message);
    if (reload) {
      loadData();
    }
  };

  return (
    <div>
      <div className="title-border uk-flex uk-flex-between uk-flex-bottom toggle-contact-add toggle-contact-edit">
        <h3 className="uk-h3">Mis contactos</h3>
        <div className="uk-hidden@m">
          <a
            href="#"
            className="uk-button uk-button-default uk-button-custom"
            uk-toggle="target: #modal-filters"
          >
            <i className="fas fa-filter"></i>
          </a>
        </div>
      </div>
      <div
        className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters"
        uk-grid="true"
      >
        <div></div>
        <div>
          <button
            type="button"
            onClick={()=>onOpen("add")}
            className="uk-button uk-button-default uk-button-custom"
            uk-toggle="target: #webModal"
          >
            <span>Agregar</span> <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div
        className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid uk-grid-match uk-margin-small-top uk-margin-large-bottom"
        data-uk-grid="true"
      >
        {loading ? (
          <Loading />
        ) : (
          <ContactsList
            contactItems={contactItems}
            onOpen={onOpen}
            setByDefault={onSetByDefault}
          />
        )}
      </div>
      <ContactAdd isOpen={open} setClose={onSetClose} />
      <ContactEdit isOpen={open} setClose={onSetClose} item={current} />
      <ContactDelete isOpen={open} setClose={onSetClose} item={current} />
    </div>
  );
}
