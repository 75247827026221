import React from "react";

const DotsNavBar = (props) => {

  const navStyleStep = {
    marginBottom: 15,
    textAlign: "center",
  };

  const dotStyleStep = {
    cursor: "pointer",
    fontSize: 36,
    lineHeight: 1,
    margin: "0 15px",
    opacity: 0.4,
    textShadow: "none",
    transition: "opacity 1s ease, text-shadow 1s ease",
    willChange: "opacity, text-shadow",
    };
    
  const activeStyleStep = {
    color: "#00b2e3",
    opacity: 1,
    textShadow: "0 0px 8px",
  };

  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        style={
          isActive
            ? { ...dotStyleStep, ...activeStyleStep }
            : { ...dotStyleStep }
        }
        onClick={() => props.goToStep(i)}
      >
        &bull;
      </span>
    );
  }

  return <div style={navStyleStep}>{dots.length === 1 ? "" : dots }</div>;
};

export default DotsNavBar;
