import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import Cards from "react-credit-cards-2";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";

export default function CreditCardConfirm({ isOpen, item, setClose }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    postData(
      `account/creditcard/${Encode(item.id)}/confirm`,
      { key: Encode(item.id), amount: Encode(data.amount) },
      {},
      (res, err) => {
        if (!err) {
          setClose(true, res.message);
        }
        setLoading(false);
      }
    );
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  if (isOpen !== "confirm") return null;
  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Confirmar tarjeta de crédito
        </p>
      </div>
      <div className="uk-modal-body">
        <form
          className="uk-form-horizontal uk-margin-remove"
          onSubmit={mySubmitHandler}
        >
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-1-1  uk-margin">
              <Cards
                number={item.cc_mask}
                name={item.cc_name}
                expiry={item.cc_exp}
                cvc="000"
                locale={{ valid: "vencimiento" }}
                placeholders={{ name: "Nombre" }}
              />
            </div>
            <div className="uk-width-1-1 uk-margin-remove">
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="add-tag">
                  Monto transacción <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-text-right"
                    id="add-amount"
                    name="amount"
                    {...register("amount", {
                      required: "Monto transacción es requeridos",
                    })}
                    type="text"
                    placeholder="0.00"
                  />
                  <p className="error">
                    {errors?.amount?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-remove">
            <label>
              <input
                id="add-by_default"
                name="by_default"
                defaultChecked={true}
                {...register("by_default")}
                className="uk-checkbox"
                type="checkbox"
              />
              <span style={{ marginTop: "4px" }}>
                Establecer como método de pago preferido
              </span>
            </label>
          </div>
        </form>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button  uk-button-custom"
          type="button"
          disabled={loading}
          onClick={setClose}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Confirmar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
