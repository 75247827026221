export const initialState = {
    loading :false,
    searchValue:"",
    products:[],
    categories:[],
    pages:[],
    total:0,
    show:0,
    timeoutId:0,
    baseImg: "",
    historyValue:"",
    predictions:[]
  }
  