
import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { postData } from "helpers/service";
import Loading from "components/share/loading/content";
import { Encode, setTitle } from "helpers/functions";
import AccountContext from "context/Account";

export default function ConfirmPage() {
  const { onLogIn } = useContext(AccountContext);

  let { hash, keyid } = useParams();
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [name, setName] = useState("");

  setTitle("Confirmación de Cuenta");

  useEffect(() => {
    postData(
      `auth/confirm`,
      null,
      {
        confirmkey: Encode(hash),
        prekey: Encode(keyid),
      },
      (res, err) => {
        if (!err) {
          onLogIn(res.data);
          setIsValid(true);
          setName(res.data.first_name);
        }
        setLoading(false);
      }
    );

  }, []);

  const Confirmation = () => (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container uk-flex uk-flex-center">
        <div className="uk-width-1-1 uk-width-3-4@m uk-width-4-5@l">
          <br />
          <br />
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
            <div className="title-border uk-text-justify">
              <h2 className="uk-h2 uk-text-center">
                ¡{name}, ya estás listo para tu primera compra en Sirena.do!
              </h2>
            </div>
            <p className="uk-text-justify">
              Sirena Go, es el servicio de compra online de Sirena, nuestro
              objetivo es hacerle la vida más simple a nuestros clientes, al
              ofrecerles soluciones que les permitan disfrutar más, ahorrándoles
              tiempo y adaptándonos a sus estilos de vida.
            </p>

            <p className="uk-text-justify">
              Una nueva experiencia de compra, a través de este canal, podrás
              realizar tus compras, y además nos puedes compartir tus opiniones
              o inquietudes, para garantizarte siempre el mejor servicio.
            </p>
            <p className="uk-text-justify">
              Puedes realizar tus compras online, desde la comodidad de tu
              hogar, oficina o donde quiera que te encuentres y terminarla en el
              súper, con el servicio Pick Up o recibirla donde indiques, con el
              servicio Delivery.
            </p>

            <br />
            <p className="uk-text-center">
              <Link
                to="/"
                className="uk-button uk-button-default uk-button-small"
              >
                COMENZAR A COMPRAR <i className="fas fa-shopping-cart"></i>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const ConfirmationFail = () => (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container uk-flex uk-flex-center">
        <div className="uk-width-1-1 uk-width-3-4@m uk-width-4-5@l">
          <img
            alt="cargando"
            style={{ width: "100%" }}
            src="https://assets-sirenago.s3-us-west-1.amazonaws.com/banner/62f5e5ea67e14bc61df29e876053eddf.jpg"
          />
          <br />
          <br />
          <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
            <div className="title-border uk-text-justify">
              <h2 className="uk-h2 uk-text-center red">
                ¡Error Confirmacion Correo!
              </h2>
            </div>

            <p className="uk-text-justify">
              Estimado usuario a ocurrido un error al confirmar su identidad por
              favor comuniquese con servicio al cliente
              servicioalcliente@Sirena.do
            </p>

            <p className="uk-text-center">
              <Link
                to="/"
                className="uk-button uk-button-default uk-button-small"
              >
                Ir al Inicio <i className="fas fa-home"></i>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <section className="uk-section uk-section-small">
      {loading ? (
        <Loading />
      ) : isValid ? (
        <Confirmation />
      ) : (
        <ConfirmationFail />
      )}
    </section>
  );
}
