import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getData } from "helpers/service";
import { Encode, GetStorage, setTitle, GetTypeDevice } from "helpers/functions";
import Pagination from "components/product/pagination";
import GridProducts from "components/product/grid";
import Filter from "components/product/filter";
import queryString from "query-string";
import { BannerSearchPageModel } from "models/adsense";
import Advertisements from "components/share/adsense";

export default function SearchPage() {
  let { searchvalue } = useParams();
  const { search } = useLocation();
  const qs = queryString.parse(search);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState(qs.sort || GetStorage("sort") || 1);
  const [page, setPage] = useState(qs.page || 1);
  const [limit, setLimit] = useState(qs.limit || GetStorage("limit") || 15);

  const [baseImg, setBaseImg] = useState("");
  const currentType = GetTypeDevice();

  setTitle("Resultados de búsqueda");

  const ShowAll = (e) => {
    setPage(1);
    setLimit(0);

    e.preventDefault();
  };

  useEffect(() => {
    setLoading(true);
    getData(
      `product/search`,
      {
        "x-p": Encode(page),
        "x-s": Encode(sort),
        "x-l": Encode(limit),
        "x-f": Encode(2),
        format:'text-plane',
        search: searchvalue,
      },
      (res, err) => {
        if (!err) {
          setBaseImg(res.base_img);
          setTotal(res.total);
          setProducts(res.data || []);
        }
        setLoading(false);
      }
    );
  }, [page, sort, limit, searchvalue]);

  useEffect(() => {
    setPage(1);
  }, [searchvalue]);

  return (
    <>
      <div className="uk-padding">
        <div className="title-border uk-padding-remove-bottom">
          <div className="uk-section uk-section-small uk-flex uk-flex-center">
            {BannerSearchPageModel.filter(
              (item) => item.type === currentType
            ).map((item) => (
              <Advertisements
                key={item.slotId}
                className={item.className}
                type={item.type}
                slotId={item.slotId}
                sizes={item.sizes}
                adUnit={item.adUnit}
                targetingArguments={{ Seccion: "busqueda" }}
              />
            ))}
          </div>
          <h2 className="uk-h4">Resultados de búsqueda</h2>
          <p className="uk-h5 uk-margin-small-top">
            Para <span className="uk-text-secondary">"{searchvalue}"</span>
          </p>
        </div>
        <Filter total={total} setSort={setSort} ShowAll={ShowAll} />
        <GridProducts loading={loading} baseImg={baseImg} products={products} />
        <Pagination
          setLimit={setLimit}
          setPage={setPage}
          CurrentPage={page}
          total={total}
          limit={limit}
        />
      </div>
    </>
  );
}
