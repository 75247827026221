import React, { useState } from "react";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import alert from "helpers/alerts";

export default function AddCoupons({setBalance}) {
  
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = () => {
    setError(false);
    if (coupon.length === 0) {
      setError(true);
      return;
    }

    setLoading(true);
    postData(
      `cart/add-coupon`,
      {
        coupon: Encode(coupon),
      },
      {},
      (res, err) => {
        if (!err) {
          setCoupon("");
          alert.confirm(res.message);
          setBalance(res.balance);
        }
        setLoading(false);
      }
    );
  };

  return (
    <div className="item-list-cupon">
      <p className="uk-h6">Cupón de descuento</p>
      <div className="uk-flex uk-text-small">
        <input
          className="uk-input"
          type="text"
          onChange={(e) => {
            setCoupon(e.target.value);
            setError(e.target.value.length > 0 ? false : true);
          }}
          placeholder="Ingresa tu código"
        />
        <button
          id="btn-submit-coupon"
          disabled={loading}
          type="button"
          onClick={() => onSubmit()}
          className="uk-button uk-button-default"
        >
          Aplicar
        </button>
      </div>
      <p className="error">{error && "Cupón de descuento es requerido."}</p>
    </div>
  );
}
