/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import UIkit from "uikit";
import AccountContext from "context/Account";
import NoContent from "./NoContent";

export default function Sidebar() {
  const { categories, sidebar } = useContext(AccountContext);
  let location = useLocation();

  useEffect(() => {
    UIkit.offcanvas("#menu-main").hide();
  }, [location]);

  return (
    <nav id="menu-main" uk-offcanvas="mode: push ; overlay: true">
      <div className="uk-offcanvas-bar">
        <div className="menu-level menu-level-1 uk-background-primary">
          <div className="uk-flex uk-flex-middle">
            <a href="#0" className="uk-offcanvas-close" uk-close="ratio: 1.5">
              {""}
            </a>
            <Link className="logo logo-main" to="/">
              <img src="/assets/logo/logo.svg?v=20221013" alt="Sirena" />
            </Link>
          </div>

          {/* Lista */}
          <ul className="uk-nav uk-margin-top nav-main">
            <li>
              <a href="#0">
                Productos <i className="fas fa-angle-right"></i>
              </a>

              <div
                className="menu-level menu-level-2 nav-products uk-box-shadow-small"
                uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
              >
                <ul className="uk-nav uk-width-1-1">
                  <li
                    className="uk-nav-header"
                    onClick={() => UIkit.dropdown(".nav-products").hide(false)}
                  >
                    <i className="fas fa-angle-left"></i> Volver
                  </li>
                  {categories.map((c) => (
                    <li key={c.id}>
                      <Link to={`/products/category/${c.friendlyurl}`}>
                        {c?.name} <i className="fas fa-angle-right"></i>
                      </Link>
                      <div
                        className={`menu-level menu-level-3 subnav-products-${c.id} uk-box-shadow-small`}
                        uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
                      >
                        <ul className="uk-nav uk-width-1-1">
                          <li
                            className="uk-nav-header"
                            onClick={() =>
                              UIkit.dropdown(`.subnav-products-${c.id}`).hide(
                                false
                              )
                            }
                          >
                            <i className="fas fa-angle-left"></i> Volver
                          </li>
                          <li className="view-all">
                            <Link
                              to={`/products/category/${c.friendlyurl}`}
                              className="uk-text-bolder"
                            >
                              Ver todos
                            </Link>
                          </li>
                          {c.children.map((c2) => (
                            <li key={c2.id}>
                              <Link to={`/products/category/${c2.friendlyurl}`}>
                                {c2?.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <a href="#0">
                Ofertas y Promociones <i className="fas fa-angle-right"></i>
              </a>

              <div
                className="menu-level menu-level-2 nav-offers uk-box-shadow-small"
                uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
              >
                <ul className="uk-nav uk-width-1-1">
                  <li
                    className="uk-nav-header"
                    onClick={() => UIkit.dropdown(".nav-offers").hide(false)}
                  >
                    <i className="fas fa-angle-left"></i> Volver
                  </li>
                  <li className="nav-item-highlight">
                    <Link to="/products/specials">Ofertas en línea</Link>
                  </li>
                  <li className="nav-item-highlight">
                    <Link to="/promotions">Promociones</Link>
                  </li>

                  <li className="nav-item-highlight">
                    <a href="#">
                      Publicaciones
                      <i className="fas fa-angle-right"></i>
                    </a>
                    <div
                      className="menu-level menu-level-3 subnav-offers-1 uk-box-shadow-small"
                      uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
                    >
                      <ul className="uk-nav uk-width-1-1">
                        {sidebar?.posts?.length > 0 ? (
                          sidebar.posts
                            ?.filter((x) => x.post_type === "post")
                            .map((n, idx) => (
                              <li key={idx} className="nav-item-highlight">
                                <Link to={`/${n.friendlyURL}`}>
                                  {n.meta_title}
                                </Link>
                              </li>
                            ))
                        ) : (
                          <NoContent />
                        )}
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item-highlight">
                    <a href="#">
                      Catálogos <i className="fas fa-angle-right"></i>
                    </a>
                    <div
                      className="menu-level menu-level-3 subnav-offers-2 uk-box-shadow-small"
                      uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
                    >
                      <ul className="uk-nav uk-width-1-1">
                        <li
                          className="uk-nav-header"
                          onClick={() =>
                            UIkit.dropdown(".subnav-offers-2").hide(false)
                          }
                        >
                          <i className="fas fa-angle-left"></i> Volver
                        </li>
                        {sidebar?.issuu?.length > 0 ? (
                          sidebar?.issuu?.map((i, idx) => (
                            <li
                              key={idx}
                              className="nav-item-catalog uk-text-center"
                            >
                              <Link
                                to={`/catalog/${i.friendlyURL}`}
                                state={{
                                  metaTitle: i?.meta_title,
                                  metaDescription: i?.meta_description,
                                  metaKeywords: i?.meta_keywords,
                                }}
                              >
                                <img
                                  src={`${sidebar?.img_issuu}${i.thumbnail}`}
                                  alt={i.title}
                                />
                                <p>
                                  <span>{i.title}</span>
                                  <small>Hasta el {i.end_date} </small>
                                </p>
                              </Link>
                            </li>
                          ))
                        ) : (
                          <NoContent />
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="#0">
                Novedades{" "}
                {sidebar?.post?.length > 0 && (
                  <i className="fas fa-angle-right"></i>
                )}
              </a>
              <div
                className="menu-level menu-level-2 nav-novedades uk-box-shadow-small"
                uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
              >
                <ul className="uk-nav uk-width-1-1">
                  <li
                    className="uk-nav-header"
                    onClick={() => UIkit.dropdown(".nav-novedades").hide(false)}
                  >
                    <i className="fas fa-angle-left"></i> Volver
                  </li>
                  {sidebar?.posts?.some((x) => x.post_type === "news") ? (
                    sidebar?.posts
                      ?.filter((x) => x.post_type === "news")
                      .map((n, idx) => (
                        <li key={idx} className="nav-item-highlight">
                          <Link to={`/${n.friendlyURL}`}>{n.meta_title}</Link>
                        </li>
                      ))
                  ) : (
                    <NoContent />
                  )}
                </ul>
              </div>
            </li>
            <li>
              <Link to="#">
                Recetas{" "}
                {sidebar?.recipe?.length > 0 && (
                  <i className="fas fa-angle-right"></i>
                )}
              </Link>
              <div
                className="menu-level menu-level-2 nav-recipes uk-box-shadow-small"
                uk-dropdown="pos: top-right; mode: click; boundary: .uk-offcanvas-bar; animation: uk-animation-slide-left-small;"
              >
                <ul className="uk-nav uk-width-1-1">
                  <li
                    className="uk-nav-header"
                    onClick={() => UIkit.dropdown(".nav-recipes").hide(false)}
                  >
                    <i className="fas fa-angle-left"></i> Volver
                  </li>
                  {sidebar?.recipe?.length > 0 ? (
                    sidebar?.recipe?.map((item) => (
                      <li key={item.id} className="nav-item-highlight">
                        <Link to={`/recipe/${item.friendlyURL}`}>
                          {item.title}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <img
                      src="/assets/background/error-notify.svg"
                      alt="Loading..."
                    />
                  )}
                </ul>
              </div>
            </li>
            <li>
              <Link to="/products/favorites">Mis Favoritos</Link>
            </li>
            <li>
              <Link to="/account/shopping-lists">Mis Listas</Link>
            </li>
            <li>
              <Link to="/club-siremas">Siremás</Link>
            </li>
            <li>
              <Link to="/sirena-cash">Sirena Cash</Link>
            </li>
            <li>
              <Link to="/apap">Sirena APAP</Link>
            </li>
          </ul>
          <ul className="uk-nav uk-margin-medium-top nav-sub">
            <li>
              <Link to="/stores">Nuestras Tiendas</Link>
            </li>
            <li>
              <Link to="/site/page/zona-cobertura">Zonas de Cobertura</Link>
            </li>
            <li>
              <Link to="/site/page/ventajas">Ventajas</Link>
            </li>
            <li>
              <Link to="/site/page/como-ordenar">Cómo Ordenar</Link>
            </li>
            <li>
              <Link to="/site/page/preguntas-frecuentes">
                Preguntas Frecuentes
              </Link>
            </li>
            <li>
              <Link to="/site/page/sobre-nosotros">Sobre Nosotros</Link>
            </li>
            <li>
              <Link to="/site/page/cambios-y-devoluciones">
                Cambios y Devoluciones
              </Link>
            </li>
            <li>
              <a
                className="link-gruporamos"
                href="https://gruporamos.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/logo/logo_gr_sidebar.svg" alt="Grupo Ramos" />
                <span>Sobre Grupo Ramos</span>
              </a>
            </li>
            <li className="footer-version">
              <small>v{process.env.REACT_APP_VERSION}</small>
            </li>
          </ul>
        </div>
        <div
          className="uk-position-cover"
          onClick={() => UIkit.offcanvas("#menu-main").hide()}
        ></div>
      </div>
    </nav>
  );
}
