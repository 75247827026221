/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import Loading from "components/share/loading";
import { DecimalFormat } from "helpers/functions";
import { Encode } from "helpers/functions";
import { postData } from "helpers/service";
import { getData } from "helpers/service";
import { useRef, useState } from "react";
import UIkit from "uikit";

const InputSearch = ({
  order,
  orderkey,
  tmpkey,
  handleAddItem,
  onSetClose,
}) => {
  const [timeoutId, setTimeoutId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [baseImg, setBaseImg] = useState("");
  const [loading, setLoading] = useState(false);
  const mainSearchDropRef = useRef();

  const handleKeyUp = () => {
    if (timeoutId > 0) {
      clearTimeout(timeoutId);
    }

    let current = setTimeout(function () {
      searchData();
    }, 600);

    setTimeoutId(current);
  };

  const searchData = () => {
    if (searchTerm !== "") {
      setLoading(true);
      getData(
        `product/searchModify`,
        {
          "x-p": Encode(1),
          "x-s": Encode(0),
          "x-l": Encode(12),
          "x-f": Encode(2),
          order: order?.codigo,
          format:'text-plane',
          search: searchTerm,
        },
        (res, err) => {
          if (!err) {
            setBaseImg(res.base_img);
            UIkit.drop(mainSearchDropRef.current).show();
            setProducts(res?.data);
          }
          setLoading(false);
        }
      );
    }
  };

  const addToCart = (item) => {
    let data = {
      action: "add",
      productid: item.productid,
    };

    UIkit.drop(mainSearchDropRef.current)?.hide();

    postData(
      `account/order/${orderkey}/modify/item`,
      data,
      { tmpkey: tmpkey },
      (res, err) => {
        if (!err) {
          handleAddItem(res);
          onSetClose();
        }
      }
    );
    setProducts([]);
  };

  const SearchResult = () => (
    <ul className="uk-list uk-list-divider uk-list-large">
      {products.map((item, idx) => (
        <li key={idx} className="uk-margin-remove uk-padding-remove">
          <div
            className={`item-list ${
              item.visible === 0 || item.available_order === 0
                ? "gi_item_nodisponible"
                : ""
            }`}
          >
            <a
              href="#"
              className="item-list-image"
              style={{ backgroundImage: `url(${baseImg}${item?.thumbs})` }}
            ></a>
            <div className="item-list-info uk-width-expand">
              <p className="uk-h5 uk-text-uppercase">{item.name}</p>
              <h4 className="uk-h4 uk-text-bold uk-margin-remove">
                ${DecimalFormat(item?.price)}
              </h4>
            </div>
            <div className="item-list-btns uk-width-auto uk-flex-last@s">
              <div style={{ padding: "5px" }}>
                <button
                  onClick={() => addToCart(item)}
                  disabled={
                    loading ||
                    item.visible === 0 ||
                    item.available_order === 0
                  }
                  className="uk-button uk-button-primary btn-add-cart"
                  type="button"
                >
                  <span>agregar</span> <i className="fas fa-shopping-cart"></i>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <div className="uk-form-horizontal">
        <input
          maxLength="128"
          className="uk-input"
          type="text"
          onKeyUp={handleKeyUp}
          autoComplete="off"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="¿Cuál artículo deseas agregar?"
        />
        <div
          ref={mainSearchDropRef}
          id="search-modify-drop"
          className="uk-drop"
          uk-drop="mode: click"
          style={{ width: "90%" }}
        >
          <div
            id="search-modify-wrap"
            className="uk-background-default uk-border-rounded uk-box-shadow-small"
          >
            <div id="search-modify-results">
              {loading ? <Loading /> : <SearchResult />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InputSearch;
