import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useParams
} from "react-router-dom";
import 'assets/styles/main.css';
import 'assets/styles/style.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {ExistsStorage, SetValue} from "./helpers/functions";
import reportWebVitals from './reportWebVitals';
import LayoutSite from 'layouts/Site'
import LayoutAccount from 'layouts/Account'
import LayoutProduct from 'layouts/Product'
import LayoutMobile from 'layouts/Mobile'
//import { Offline, Online } from "react-detect-offline";
//import PageOffline from "components/share/error/offline";
import AccountProvider from "context/Account/Provider"
import NoFountPage from "./pages/error/NotFoundPage";
import FavoritePage from "pages/account/FavoritePage";
import CategoryPage from "pages/products/CategoryPage";
import EspeciallyPage from "pages/products/EspeciallyPage";
import SearchPage from "pages/products/SearchPage";
import ProductPage from "pages/products/ProductPage";
import ProfilePage from "pages/account/ProfilePage";
import ProfileEditPage from "pages/account/ProfileEditPage";
import OrderHistoryPage from "pages/account/OrderHistoryPage";
import ShoppingListPage from "pages/account/ShoppingListPage";
import AddressesPage from "pages/account/AddressesPage";
import ContactPage from "pages/account/ContactPage";
import CreditCardPage from "pages/account/CreditCardPage";
import PasswordChangePage from "pages/account/PasswordChangePage";
import OrderTrackingPage from "pages/account/OrderTrackingPage";
import OrderVariationPage from "pages/account/OrderVariationPage";
import ContentPage from "pages/site/ContentPage";
import LoyaltyPage from "pages/site/LoyaltyPage";
import DigitalPointsPage from "pages/cart/DigitalPointsPage";
import StoresPage from "pages/site/StoresPage";
import AccountLoginPage from "pages/account/LoginPage";
import OrderModifyPage from "pages/account/OrderModifyPage";
import OrderPaymentPage from "pages/account/OrderPaymentPage";
import CartPage from "pages/cart/CartPage";
import CheckoutPage from "pages/cart/CheckoutPage";
import ConfirmationPage from "pages/cart/ConfirmationPage";
import QuotationPage from "pages/cart/QuotationPage";
import RecipePage from "pages/site/RecipePage";
import CatalogPage from "pages/site/CatalogPage";
import PromotionPage from "pages/site/PromotionPage";
import HomePage from "pages/site/HomePage";
import AccountRegisterPage from "pages/account/RegisterPage";
import AccountConfirmPage from "pages/account/ConfirmPage";
import PasswordRecoverPage from "pages/account/PasswordRecoverPage";
import PostPage from "pages/site/PostPage";
import { CheckoutProvider } from "context/Checkout/Provider";


if (!ExistsStorage('visitorId')) {
    setTimeout(async () => {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        SetValue('visitorId', result.visitorId)

    }, 0);
}

const RedirectAccountProfile = () => {
    let params = useParams();
    window.location.replace(`/account-profile/verify/${params.hash}/${params.keyid}`);

    return <></>
}

const RedirectOrderTracking = () => {
    let params = useParams();
    window.location.replace(`/order-tracking/${params.routeid}/${params.routeitemid}`);
    return <></>
}

const RedirectFriendlyurl = () => {
    let params = useParams();
    window.location.replace(`/mobile/${params.friendlyurl}`);
    return <></>
}


const RedirectKeyId = ({lpath}) => {
    let params = useParams();
    window.location.replace(`/${lpath}/${params.keyid}`);
    return <></>
}

ReactDOM.render(
    <AccountProvider>
        <BrowserRouter>
            {/* <SentryRoutes> */}
            <Routes>
                {/* Product */}
                <Route path="products" element={<LayoutProduct/>}>
                    <Route path="favorites" element={<FavoritePage/>}/>
                    <Route path="category/:friendlyurl" element={<CategoryPage/>}/>
                    <Route path="specials" element={<EspeciallyPage/>}/>
                    <Route path="search/:searchvalue" element={<SearchPage/>}/>
                    <Route path="index/:friendlyurl" element={<ProductPage/>}/>
                </Route>

                {/* Account */}
                <Route path="account" element={<LayoutAccount/>}>
                    <Route path='profile/verify/:hash/:keyid' element={<RedirectAccountProfile/>}/>
                    <Route path='favorites' element={<Navigate to='/products/favorites' replace/>}/>
                    <Route path='orders/payment/:keyid' element={<RedirectKeyId lpath='order-payment'/>}/>
                    <Route path='orders/modify/:keyid' element={<RedirectKeyId lpath='order-modify'/>}/>

                    <Route path='orders/variation/:keyid' element={<RedirectKeyId lpath='order-variation'/>}/>

                    <Route path='orders/mobile_variation/:keyid/:imei/:hash'
                           element={<RedirectKeyId lpath='mobile/variation'/>}/>
                    <Route path='orders/mobile_tracking/:keyid/:imei/:hash'
                           element={<RedirectKeyId lpath='mobile/tracking'/>}/>

                    <Route path='orders/tracking/:routeid/:routeitemid' element={<RedirectOrderTracking/>}/>
                    <Route path='orders/view/factura/:keyid'
                           element={<RedirectKeyId lpath='order-view/aW52b2ljZQ=='/>}/>

                    <Route path='orders/view/pedido/:keyid' element={<RedirectKeyId lpath='order-view/b3JkZXI='/>}/>

                    <Route index element={<ProfilePage/>}/>
                    <Route path='profile' element={<ProfilePage/>}/>
                    <Route path='edit' element={<ProfileEditPage/>}/>
                    <Route path='profile/edit' element={<ProfileEditPage/>}/>
                    <Route path='order-history' element={<OrderHistoryPage/>}/>
                    <Route path='order' element={<OrderHistoryPage/>}/>
                    <Route path='shopping-lists' element={<ShoppingListPage/>}/>
                    <Route path='mylists' element={<ShoppingListPage/>}/>
                    <Route path='addresses' element={<AddressesPage/>}/>
                    <Route path='address' element={<AddressesPage/>}/>
                    <Route path='password' element={<PasswordChangePage/>}/>
                    <Route path='password/change' element={<PasswordChangePage/>}/>
                    <Route path='buy-history' element={<OrderHistoryPage/>}/>
                    <Route path='browse-history' element={<OrderHistoryPage/>}/>
                    <Route path='contacts' element={<ContactPage/>}/>
                    <Route path='creditcards' element={<CreditCardPage/>}/>
                </Route>

                {/* Mobile */}
                <Route path="mobile" element={<LayoutMobile/>}>
                    <Route path='tracking/:orderkey' element={<OrderTrackingPage/>}/>
                    <Route path='variation/:orderkey' element={<OrderVariationPage/>}/>
                    <Route path="promotions" element={<PromotionPage/>}/>
                    <Route path=':friendlyurl' element={<ContentPage/>}/>
                </Route>
g
                {/* Home */}
                <Route path="/" element={<LayoutSite/>}>
                    <Route path="club-siremas" element={<LoyaltyPage/>}/>
                    <Route path="sirena-cash" element={<DigitalPointsPage/>}/>
                    <Route path="store" element={<StoresPage/>}/>
                    <Route path="stores" element={<StoresPage/>}/>
                    <Route path="tienda" element={<StoresPage/>}/>
                    <Route path="tiendas" element={<StoresPage/>}/>
                    <Route path="account-login" element={<AccountLoginPage/>}/>
                    <Route path="account-register" element={<AccountRegisterPage/>}/>
                    <Route path="account-profile/verify/:hash/:keyid" element={<AccountConfirmPage/>}/>
                    <Route path="account-password/:hash/:keyid" element={<PasswordRecoverPage/>}/>
                    <Route path="order-modify/:orderkey" element={<OrderModifyPage/>}/>
                    <Route path="order-payment/:orderkey" element={<OrderPaymentPage/>}/>
                    <Route path="order-tracking/:routeid/:routeitemid" element={<OrderTrackingPage/>}/>
                    <Route path="order-tracking/:orderkey" element={<OrderTrackingPage/>}/>
                    <Route path="order-variation/:orderkey" element={<OrderVariationPage/>}/>
                    <Route path="site/home" element={<Navigate to="/" replace/>}/>
                    <Route path="site/page/:friendlyurl" element={<ContentPage/>}/>
                    <Route path="site/mobile/:friendlyurl" element={<RedirectFriendlyurl/>}/>
                    <Route path="catalog/:friendlyurl" element={<CatalogPage/>}/>
                    <Route path="catalog" element={<CatalogPage/>}/>
                    <Route path="recipe/:friendlyurl" element={<RecipePage/>}/>
                    <Route path="promotions" element={<PromotionPage/>}/>
                    <Route path="ce/cart/:orderkey" element={<QuotationPage/>}/>
                    <Route path="cart" element={<CartPage/>}/>
                    <Route path="checkout" element={<CheckoutProvider> <CheckoutPage/> </CheckoutProvider>} />
                    <Route path="order-confirmation/:confirmation" element={<ConfirmationPage/>}/>
                    <Route path="order-confirmation-cash/:confirmation" element={<ConfirmationPage/>}/>
                    <Route path=":friendlyurl" element={<PostPage/>}/>
                    <Route index element={<HomePage/>}/>
                    <Route path='*' element={<NoFountPage/>}/>
                </Route>
                </Routes>
            {/* </SentryRoutes> */}
        </BrowserRouter>
    </AccountProvider>,
    document.getElementById('webapp')
);

reportWebVitals();
