/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { SetStorage } from "helpers/functions";

export default function ProductFilter({ total=0, setSort, sort, ShowAll }) {
    
  const onChangeSort = (e) => {
    let value = parseInt(e.target.value);
    SetStorage("sort", value);
    setSort(value);
  };
  
  return (
    <div
      className="uk-flex uk-flex-right uk-flex-middle uk-grid-small uk-grid-divider uk-text-small uk-margin-medium-bottom nav-filters"
      uk-grid="true"
    >
      <div>
        <span className="uk-inline">
          <i className="fas fa-tags uk-margin-small-right"></i> {total}{" "}
          artículos
        </span>
      </div>
      <div>
        <span className="uk-inline">
          <a href="#" onClick={ShowAll}>
            Mostrar Todos
          </a>
        </span>
      </div>
      <div>
        <div className="uk-inline">
          <select
            onChange={onChangeSort}
            defaultValue={sort}
            className="uk-select"
          >
            <option disabled>Ordenar por</option>
            <option value="1">Relevancia</option>
            <option value="2">Especiales</option>
            <option value="3">Precio (desde menor)</option>
            <option value="4">Precio (desde mayor)</option>
            <option value="5">Nombre</option>
          </select>
        </div>
      </div>
      <div className="uk-hidden@m">
        <a
          href="#"
          className="uk-button uk-button-default uk-button-custom"
          uk-toggle="target: #modal-filters"
        >
          <i className="fas fa-filter"></i>
        </a>
      </div>
    </div>
  );
}
