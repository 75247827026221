import React from "react";
import PageNotFound from "components/share/error/404";

export default function NotFoundPage() {
    return (
        <section className='uk-section uk-section-small'>
            <PageNotFound />
        </section>
    )
}
        


