import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import { formatCreditCardNumber } from "helpers/creditcart";
import { monthWithNameOptions, yearOptions } from "models/options";

import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

export default function CreditCardAdd({ isOpen, setClose }) {
  const [checked, setChecked] = useState(true);
  const [focus, setFocus] = useState("number");
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  let cardNumber = watch("cardnumber", "");
  let creditCardNumber = formatCreditCardNumber(cardNumber) || "";
  let cardName = watch("name", "") || "";
  let cardCvv = watch("CVV", "") || "";
  let expMonth = watch("exp_month", "01") || "01";
  let expYear = watch("exp_year", "") || "";
  let expiry = `${expMonth}${expYear}`;

  useEffect(() => {
    if (isOpen !== "add") {
      reset();
    }
  }, [isOpen]);

  const onSubmit = (data) => {
    setLoading(true);
    postData(
      "account/creditcard",
      {
        cardnumber: Encode(data["cardnumber"]),
        name: Encode(data["name"]),
        exp_month: Encode(data["exp_month"]),
        exp_year: Encode(data["exp_year"]),
        CVV: Encode(data["CVV"]),
        by_default: checked ? 1 : 0,
      },
      {},
      (res, err) => {
        if (!err) {
          setClose(true, res.message, res.data);
        }
        setLoading(false);
      }
    );
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const handleClose =()=>{
    reset();
    setClose();
  }

  if (isOpen !== "add") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
        onClick={handleClose}
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Agregar tarjeta de crédito
        </p>
      </div>
      <div className="uk-modal-body">
        <form
          className="uk-form-horizontal uk-margin-remove"
          onSubmit={mySubmitHandler}
        >
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-1-1  uk-margin">
              <Cards
                number={creditCardNumber}
                name={cardName}
                expiry={expiry}
                cvc={cardCvv}
                focused={focus}
                locale={{ valid: "vencimiento" }}
                placeholders={{ name: "Nombre" }}
              />
            </div>
            <div className="uk-width-1-1 uk-margin-remove">
              <input
                className="uk-input"
                name="cardnumber"
                maxLength="16"
                type="text"
                placeholder="Número tarjeta de crédito/débito"
                {...register("cardnumber", {
                  required: "Número tarjeta de crédito/débito es requerido",
                })}
                onFocus={() => setFocus("number")}
                autoComplete={false}
              />
              <p className="error">
                {errors?.cardnumber?.message}
              </p>
            </div>
            <div className="uk-width-3-4 uk-margin-remove">
              <input
                className="uk-input"
                name="name"
                placeholder="Nombre en la tarjeta"
                {...register("name", { required: "Alias(s) son requeridos" })}
                type="text"
                maxLength="50"
                onFocus={() => setFocus("name")}
                autoComplete={false}
              />
              <p className="error">{errors?.name?.message}</p>
            </div>
            <div className="uk-width-1-4 uk-margin-remove">
              <input
                maxLength="4"
                name="cvc"
                className="uk-input"
                type="password"
                placeholder="cvc"
                {...register("CVV", { required: "CVV es requerido" })}
                onFocus={() => setFocus("cvc")}
                autoComplete={false}
              />
              <p className="error">{errors?.CVV?.message}</p>
            </div>
            <div className="uk-width-1-4 uk-margin-remove">
              <select
                name="exp_month"
                className="uk-select"
                defaultValue={"01"}
                {...register("exp_month", { required: "Mes es requerido" })}
                onFocus={() => setFocus("expiry")}
              >
                {monthWithNameOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="uk-width-1-4 uk-margin-remove">
              <select
                name="exp_year"
                className="uk-select"
                {...register("exp_year", { required: "Año es requerido" })}
                onFocus={() => setFocus("expiry")}
              >
                {yearOptions.map(({ value, label }, idx) => (
                  <option key={idx} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="uk-width-2-5 uk-margin-remove">
              <img
                src="/assets/creditcart/pic-pagos.gif"
                style={{ marginTop: "10px" }}
                alt="pagos"
              />
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-remove">
            <label>
              <input
                name="by_default"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                className="uk-checkbox"
                type="checkbox"
              />
              <span style={{ marginTop: "4px" }}>
                Establecer como método de pago preferido
              </span>
            </label>
          </div>
        </form>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button  uk-button-custom"
          type="button"
          disabled={loading}
          onClick={setClose}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
