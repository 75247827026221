import React, { useState, useEffect } from "react";
import Pagination from "components/product/pagination";
import Items from "./Items";

export default function Loyalty({ vdetails }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(vdetails);
  }, [vdetails]);
  return (
    <div>
      <table className="uk-table uk-table-striped uk-table uk-table-condensed uk-table-hover">
        <thead>
          <tr>
            <th className="uk-width-small">Fecha</th>
            <th className="uk-table-expand">Concepto</th>
            <th className="uk-width-small">Ganados</th>
            <th className="uk-width-small">Redimidos</th>
            <th className="uk-width-small">Balance</th>
          </tr>
        </thead>
        <tbody>
          <Items items={items} page={page} limit={limit} />
        </tbody>
      </table>
      <Pagination
        setLimit={setLimit}
        setPage={setPage}
        CurrentPage={page}
        total={vdetails?.length}
        limit={limit}
      />
    </div>
  );
}
