/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getData } from "helpers/service";
import Loading from "components/share/loading/table";
import { useForm } from "react-hook-form";
import { DateFormat, MoneyFormat } from "helpers/functions";
import FavoriteDelete from "components/digital-points/delete-favorite";
import UIkit from "uikit";
import AccountContext from "context/Account";
import {
  unsubscribeEvent,
  publishEvent,
  addIdToArray,
} from "helpers/functions";

export default function DigitalPointLoadFavorite({ isOpen, setClose }) {

  const { getCash, setCash } = useContext(AccountContext);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [open, setOpen] = useState("none");
  const [current, setCurrent] = useState({});

  const [completed, setCompleted] = useState(false);
  const SIRENA_CASH_ID = process.env.REACT_APP_SIRENA_CASH_ID;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getData(`account/contact/cash`, null, (res, err) => {
        if (!err) {
          setItems(res.data || []);
        }
        setLoading(false);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!completed) return;
    return () => {
      setCash({details:[],total:0})
      unsubscribeEvent("add-to-cart", null, "modal-sirena-cash-favorite");
    };
  }, [completed]);

  //************* CRM *************//
  //********? Add to cart *********//
  const handleAddToCartFromFavorite = (items) => {
    const amounts = items.map((item)=> item.amount)
    const initialValue = 0;
    const quantity = amounts.reduce(
      (prev, current) => parseFloat(prev) + parseFloat(current),
      initialValue
    );

    let lineItem = {
      _id: SIRENA_CASH_ID,
      price: 1,
      quantity,
      currency: "DOP",
    };
    publishEvent("add-to-cart", lineItem, "modal-sirena-cash-favorite");
  };
  //************ FIN CRM *********//

  const onOpen = (action = "add", data = []) => {
    setOpen(action);
    setCurrent(data);
    UIkit.modal("#webModal").show();
  };

  const onSetClose = () => {
    UIkit.modal("#webModal").hide();
    setClose();
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    let tmp = getCash();

    let sirenaCashData = tmp["details"] || [];
    let totalAmount = tmp["total"] || 0;

    data.details.forEach((item) => {
      if (item.tocart) {
        sirenaCashData.push({
          document: item.document,
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          phone: item.phone,
          amount: parseInt(item.amount),
          message: item.message,
          add_fav: 1,
          error: 0,
          errorMsj: "",
        });

        totalAmount = totalAmount + parseInt(item.amount);
      }
    });

    const newSirenaCashData = addIdToArray(sirenaCashData);

    let Order = {
      details: newSirenaCashData,
      total: totalAmount,
    };

    setCash(Order);
    //? Aqui es donde deberia estar la funcion 'handleAddToCartFromFavorite'
    //? al recibir un arreglo de beneficiarios.
    handleAddToCartFromFavorite(newSirenaCashData);
    setCompleted(true);
  };

  useEffect(() => {
    if (completed) {
      setItems([]);
      setClose("load", "webModal-container");
      setCompleted(false);
    }
  }, [completed]);

  if (!isOpen) return null;

  const ListEmpty = () => {
    return (
      <div className="uk-width-1-1">
        <img
          src="/assets/background/contact-empty.svg"
          style={{ height: "200px", textAlign: "center" }}
          alt="Lista de contactos Vacio"
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">No has registrado contactos. </h4>
        </div>
      </div>
    );
  };

  const ListCash = () => {
    return (
      <>
        <table className="uk-table uk-table-divider uk-table-small">
          <thead>
            <tr>
              <th className="uk-table-shrink"></th>
              <th className="uk-table-expand">Documeto</th>
              <th className="uk-table-expand">Nombre</th>
              <th className="uk-table-expand">Ultima Compra</th>
              <th>Monto</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <tr key={i}>
                <td>
                  <label>
                    <input
                      className="uk-checkbox"
                      type="checkbox"
                      name={`details[${i}]tocart`}
                      {...register(`details.${i}.tocart`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={item.document}
                      name={`details[${i}]document`}
                      {...register(`details.${i}.document`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={item.first_name}
                      name={`details[${i}]first_name`}
                      {...register(`details.${i}.first_name`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={item.last_name}
                      name={`details[${i}]last_name`}
                      {...register(`details.${i}.last_name`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={item.email}
                      name={`details[${i}]email`}
                      {...register(`details.${i}.email`)}
                    />
                    <input
                      type="hidden"
                      defaultValue={item.phone}
                      name={`details[${i}]phone`}
                      {...register(`details.${i}.phone`)}
                    />
                  </label>
                </td>
                <td className="uk-text-left" style={{ paddingTop: "12px" }}>
                  {item.document}
                </td>
                <td className="uk-text-left" style={{ paddingTop: "12px" }}>
                  {`${item.first_name} ${item.last_name}`}
                </td>
                <td className="uk-text-left" style={{ paddingTop: "12px" }}>
                  {`${MoneyFormat(item.last_amount)} / ${DateFormat(
                    item.last_date
                  )}`}
                </td>
                <td className="uk-text-right">
                  <div className="uk-form-controls">
                    <input
                      name={`details[${i}]amount`}
                      disabled={!watch(`details.${i}.tocart`)}
                      {...register(`details.${i}.amount`, {
                        required: watch(`details.${i}.tocart`),
                        min: { value: 100, message: "Monto minimo de venta" },
                      })}
                      min={100}
                      className="uk-input"
                      id="amount"
                      style={{ maxWidth: "120px", width: "100%" }}
                      type="number"
                      placeholder="RD$ 0"
                    />
                    {errors?.details?.[i]?.amount && (
                      <span className="error">
                        <br />
                        {errors?.details?.[i]?.amount.message}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Opcional"
                      disabled={!watch(`details.${i}.tocart`)}
                      name={`details[${i}]message`}
                      {...register(`details.${i}.message`)}
                    />
                  </div>
                </td>
                <td>
                  <a
                    onClick={() => onOpen("delete", item)}
                    title={`Borrar favorito ${item.first_name} ${item.last_name}`}
                    href="#"
                    className="uk-button  uk-button-custom"
                  >
                    <i className="far fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FavoriteDelete isOpen={open} setClose={onSetClose} item={current} />
      </>
    );
  };

  const ShowList = () => (items.length > 0 ? <ListCash /> : <ListEmpty />);

  return ReactDOM.createPortal(
    <div
      id="modal-sirena-cash-favorite"
      className="modal-sirena-cash uk-modal-dialog uk-margin-auto-vertical uk-light"
    >
      <button
        onClick={() => setClose("", "webModal-container")}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-body uk-text-center">
        <p className="uk-text-secondary">
          <i className="far fas fa-heart fa-3x"></i>
        </p>
        <p className="uk-h5">Mis Favoritos</p>
        {loading ? <Loading /> : <ShowList />}
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          onClick={() => setClose("", "webModal-container")}
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Agregar al carrito</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
}
