import {SetStorage, GetStorage} from "helpers/functions";
import { useEffect, useState } from "react";
/**
 * Historial de busqueda
 * 
 * Guarda las ultimas 10 busquedas realizadas por dispositivo, incluyendo
 * los productos visitados o productos agregados al carrito justo luego 
 * de consultar en la barra de busqueda.
 * @returns `searchHistory` Historial actual.
 * @returns `handleSaveSearchHistory` Funcion para guardar en el historial.
 * @returns `removeSearchHistory` Funcion para eliminar del historial   .
 */
export const useSearchHistory = () => {
    const [searchHistory, setSearchHistory] = useState([]);

    useEffect(() => {
        //Obtengo el historial actual desde el Local Storage
        const currentSearchHistory = GetStorage('searchHistory');
        //Lo transformo a array a partir del string
        if (currentSearchHistory) {
            let arrayFromStorage = currentSearchHistory.split(',');
            setSearchHistory([...arrayFromStorage]);
        }
    }, []);
   
    //Envio el valor a la funcion para guardar el nuevo valor 
    /**
     * Funcion para guardar el historial.
     * @param {string} value valor que va a ser agregado al historial.
     */
    const handleSaveSearchHistory = (value) => {
        // Remuevo las  "," de "value", para poder hacer "split" sin problemas al array
        let newValue = value.replace(/(,)/g, " ");
        // Verifico que el valor tenga contenido
        if(!newValue.trim()) return;
        // array contenedora
        let newSearchHistory = [];
        //Verifico si existe el valor que ingresa
        const isExists = searchHistory.includes(newValue);
        //Si existe, retorno el array sin ese elemento
        //inserto el elemento en el primer indice del array con "splice"
        if (isExists) {
            const filteredHistory = searchHistory.filter(item => item !== newValue)
            filteredHistory.splice(0, 0, newValue);
            newSearchHistory = [...filteredHistory];
        } else {
            //En caso contrario, guardo el valor al inicio del array 
            //y la copia del antiguo historial 
            newSearchHistory = [
                newValue,
                ...searchHistory
            ]
        }
        //El arreglo solo debe permitir 10 elementos, asi que
        //siempre se hace Slice hasta la posicion 10
        let newSlicedHistory = newSearchHistory.slice(0, 10);

        //Se guarda el history en string en el Local Storage
        SetStorage('searchHistory', newSlicedHistory.toString());
        //Set State
        setSearchHistory(newSlicedHistory);
    }
   /**
    * Funcion para eliminar del historial
    * @param {string} value valor que va a ser eliminado del historial.
    */
    const removeSearchHistory = (value) => {
         // Remuevo las  "," de "value", para manejar el array sin problemas
        let newValue = value.replace(/(,)/g, " ");
        let newCurrentSearchHistory = searchHistory.filter(item => item !== newValue);

        //Se guarda el history en string en el Local Storage
        SetStorage('searchHistory', newCurrentSearchHistory.toString());
        //Set State
        setSearchHistory(newCurrentSearchHistory);
    }

    return {searchHistory, handleSaveSearchHistory, removeSearchHistory}
}
