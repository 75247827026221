import React, { useContext } from "react";
import AuthAnonymous from "../../components/account/auth/anonymous";
import AccountContext from "../../context/Account";
import ConectedAccount from "components/account/profile/favorite/ConnectedAccount";

export default function FavoritePage() {
  const { isLogin } = useContext(AccountContext);

  return isLogin ? <ConectedAccount /> : <AuthAnonymous source="full" />;
}
