/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AccountContext from "context/Account";
import { setTitle } from "helpers/functions";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";

export default function CatalogPage() {
  const { sidebar } = useContext(AccountContext);

  let { friendlyurl } = useParams();

  const [loading, setLoading] = useState(true);

  const [baseImg, setBaseImg] = useState("");
  const [issuu, setIssuu] = useState({});
  const [issuuList, setIssuuList] = useState([]);

  useEffect(() => {
    setTitle("Catálogos de Ofertas");
  }, []);

  useEffect(() => {
    let findOnline = true;

    if (sidebar?.img_issuu !== undefined) {
      setBaseImg(sidebar?.img_issuu || "");
      setIssuuList(sidebar?.issuu || []);
      let xissuu = sidebar?.issuu?.find((e) => e.friendlyURL === friendlyurl);

      if (xissuu !== undefined) {
        setIssuu(xissuu);
        setTitle(`Catálogo ${xissuu.title}`);
        findOnline = false;
        setLoading(false);
      }
    }

    if (findOnline) {
      getData(`setting/site/issue`, {}, (res, err) => {
        if (!err) {
          setBaseImg(res.base_img_issuu);
          setIssuuList(res.issuu);

          let xissuu = res.issuu?.find((e) => e.friendlyURL === friendlyurl);
          if (xissuu) {
            setIssuu(xissuu);
            setTitle(`Catálogo ${xissuu.title}`);
          }
        }
        setLoading(false);
      });
    }
  }, [friendlyurl]);

  const Content = () => {
    return (
      <div className="uk-section uk-section-small uk-margin-medium-bottom">
        <div className="uk-container uk-container-expand">
          <div
            className="uk-position-relative"
            uk-slider="finite: true; center: true;"
          >
            <ul className="uk-slider-items uk-grid-match">
              {issuuList?.map((i, idx) => (
                <li key={idx} style={{ padding: "0 15px" }}>
                  <Link to={`/catalog/${i.friendlyURL}`}>
                    <img
                      style={{ maxHeight: "128px" }}
                      src={`${baseImg}${i.thumbnail}`}
                      alt={i.title}
                    />
                  </Link>
                </li>
              ))}
            </ul>

            <a
              className="uk-position-center-left slider-arrow"
              href="#"
              uk-slider-item="previous"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <a
              className="uk-position-center-right slider-arrow"
              href="#"
              uk-slider-item="next"
            >
              <i className="fas fa-chevron-right"></i>
            </a>
          </div>
          <br />

          {issuu && (
            <iframe
              style={{ width: "100%", height: "800px" }}
              allowFullScreen
              allow="fullscreen"
              frameBorder="0"
              className="uk-responsive"
              src={issuu?.content}
            ></iframe>
          )}
        </div>
      </div>
    );
  };

  return loading ? <Loading /> : <Content />;
}
