import React from "react";
import ReactDOM from "react-dom";


export default function ShoppingListDelete(props) {
    const {
        isOpen,
        item,
        onSetDelete
    } = props


    const onDeleteClick = () => {
        onSetDelete(item.listid);

    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(<div className='uk-modal-dialog uk-margin-auto-vertical'>
            <button className='uk-modal-close-default' type='button' uk-close="true"></button>
            <div className='uk-modal-body uk-text-center'>
                <p className='uk-text-secondary'><i className='far fa-question-circle fa-3x'></i></p>
                <p className='uk-h4'>Borrar Lista de compra </p>
                <p>¿Estas seguro que deseas borrar la lista <strong>{item.list}</strong>?</p>
            </div>
            <div className='uk-modal-footer uk-text-center'>
                <button  className="uk-button uk-modal-close uk-button-custom"><span>Cancelar</span> <i className='fas fa-times'></i>
                </button>
                <button  onClick={onDeleteClick} className="uk-button uk-modal-close uk-button-default uk-button-custom" type='button'>
                    <span>Borrar</span> <i
                    className='fas fa-check'></i></button>
            </div>
        </div>,
        document.getElementById('webModal')
    )


}
