import { useState, useEffect } from 'react';
import { getData } from 'helpers/service';
/**
 * Obtiene el Nombre / Razón social registrado en la entidad *DGII*.
 *
 * @param {string} document número de documento de identidad 
 * @param {('01'|'02')} invoiceType tipo de factura(`crédito fiscal`, `factura de consumo`)
 * @param {('Cedula' | 'RNC')} currentDocTypeName nombre tipo de documento(`Cedula`, `RNC`)
 */
export const useDgiiDocumentChecker = (document = "", invoiceType, currentDocTypeName)=> {

const RNC = 'RNC';
const DNI = 'Cedula'
const CREDITO_FISCAL = '01';

const [ isValid, setIsValid ] = useState(false);
const [socialName, setSocialName ] = useState('');
const [documentNoSpace, setDocumentNoSpace] = useState('');

useEffect(()=>{
//Quito los caracteres que no sean número y sustituyo por espacios en blanco.
//Luego retorno el documento
let document_no_spaces = document?.replace(/[^0-9]/g, "");
setDocumentNoSpace(document_no_spaces);

//Si la factura es de "Credito fiscal", entonces procedemos a las demas validaciones.
    if (invoiceType !== CREDITO_FISCAL) return setSocialName('');
    if (!document_no_spaces) return setSocialName('');
    if (currentDocTypeName === RNC && document_no_spaces?.length < 9 ) return setSocialName('');
    if (currentDocTypeName === DNI && document_no_spaces?.length < 11) return setSocialName('');
    
        getData(`account/profile/${document}/check-document`, {}, (res, err) => {
            if (!err) {
                setSocialName(res?.nombre);
                setIsValid(true);
            } 
        })
        
        return ()=>setIsValid(false);
},[document, invoiceType, currentDocTypeName])

    return { socialName, isValid, documentNoSpace }
}