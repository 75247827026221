/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "components/share/error/404";
import Iframe from "components/share/Iframe";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";
import { Encode, toArray } from "helpers/functions";
import { Helmet } from "react-helmet";
import AccountContext from "context/Account";

const initialState = {
  loading: true,
  contentView: "",
  contentStyle: [],
  contentScript: [],
  contentHtml: [],
};

export default function PostPage() {
  let { friendlyurl } = useParams();
  const { setSeoData } = useContext(AccountContext);

  const [
    { contentHtml, contentView, contentStyle, contentScript, loading },
    setState,
  ] = useState(initialState);

  useEffect(() => {
    getData(`setting/site/post/${Encode(friendlyurl)}`, null, (res, err) => {
      if (!err) {
        if (Boolean(res.found)) {
          setState((prevState) => ({
            ...prevState,
            contentView: res.data?.content_view,
            contentStyle: toArray(res.data?.content_style),
            contentScript: toArray(res.data?.content_script),
            contentHtml: res.data?.content_html,
          }));
          setSeoData({
            metaTitle: res.data?.meta_title,
            metaDescription: res.data?.meta_description,
            metaKeywords: res.data?.meta_keywords,
          });
        }
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    });
  }, [friendlyurl]);

  const Content = () => {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </>
    );
  };

  const Page = () => {
    return contentHtml?.length === 0 ? (
      <PageNotFound
        message={"Esta publicación de momento no está disponible"}
      />
    ) : contentView === "iframe" ? (
      <Iframe content={contentHtml} />
    ) : (
      <>
        <Helmet>
          {contentStyle?.map((x, idx) => (
            <link key={idx} rel="stylesheet" href={x.script} />
          ))}
          {contentScript?.map((x, idx) => (
            <script key={idx} type="text/javascript" src={x.script} />
          ))}
        </Helmet>
        <Content />
      </>
    );
  };

  return loading ? <Loading /> : <Page />;
}
