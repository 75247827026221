import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import Cards from "react-credit-cards-2";
import { yearOptions, monthWithNameOptions } from "models/options";

export default function CreditCardEdit({ isOpen, item, setClose }) {
  const initialCardState = {
    number: "",
    focus: "",
    loading: false,
    checked: false
  };

  const [{ number, focus, loading, checked }, setCardState] =
    useState(initialCardState);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm();

   let nameCard = watch("name","");
   let expMonth = watch("exp_month", "01");
   let expYear = watch("exp_year", "");
   let expiry = `${expMonth}${expYear}`;

  useEffect(() => {
    if (isOpen === "edit") {
      setCardState((prev) => ({
        ...prev,
        number: item?.cc_mask,
        checked:item?.by_default === 1
      }));

      const exp = item?.cc_exp.split("/");
      setValue("exp_month", exp[0]);
      setValue("exp_year", exp[1]);
      setValue("name", item.cc_name);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSubmit = (data) => {
    setCardState((prev) => ({ ...prev, loading: true }));
    postData(
      `account/creditcard/${Encode(item.id)}/update`,
      {
        key: Encode(item.id),
        name: Encode(data["name"]),
        exp_month: Encode(data["exp_month"]),
        exp_year: Encode(data["exp_year"]),
        by_default: checked ? 1 : 0,
      },
      {},
      (res, err) => {
        if (!err) {
          setClose(true, res.message);
        }
        setCardState((prev) => ({ ...prev, loading: false }));
      }
    );
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };
const handleClose =()=>{
  reset();
  setClose();
}
  if (isOpen !== "edit") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
        onClick={handleClose}
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Editar tarjeta de crédito
        </p>
      </div>
      <div className="uk-modal-body">
        <form
          className="uk-form-horizontal uk-margin-remove"
          onSubmit={mySubmitHandler}
        >
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-1-1  uk-margin">
              <Cards
                number={number}
                name={nameCard}
                expiry={expiry}
                cvc="000"
                focused={focus}
                locale={{ valid: "vencimiento" }}
                placeholders={{ name: "Nombre" }}
              />
            </div>
            <div className="uk-width-1-1 uk-margin-remove">
              <input
                className="uk-input"
                name="name"
                placeholder="Nombre en la tarjeta"
                {...register("name", { required: "Alias(s) son requeridos" })}
                type="text"
                maxLength="50"
                onFocus={() =>
                  setCardState((prev) => ({ ...prev, focus: "name" }))
                }
              />
              <p className="error">{errors?.name?.message}</p>
            </div>
            <div className="uk-width-1-4 uk-margin-remove">
              <select
                name="exp_month"
                className="uk-select"
                {...register("exp_month", { required: "Mes es requerido" })}
                onFocus={() =>
                  setCardState((prev) => ({ ...prev, focus: "expiry" }))
                }
              >
                {monthWithNameOptions.map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
            <div className="uk-width-1-4 uk-margin-remove">
              <select
                name="exp_year"
                className="uk-select"
                {...register("exp_year", { required: "Año es requerido" })}
                onFocus={() =>
                  setCardState((prev) => ({ ...prev, focus: "expiry" }))
                }
              >
                {yearOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="uk-width-2-5 uk-margin-remove">
              <img
                src="/assets/creditcart/pic-pagos.gif"
                style={{ marginTop: "10px" }}
                alt="pagos"
              />
            </div>
          </div>
          <div className="uk-width-1-1 uk-margin-remove">
            <label>
              <input
                name="by_default"
                checked={checked}
                onChange={() => {
                  setCardState((prev)=>({...prev, checked:!checked}))
                }}
                className="uk-checkbox"
                type="checkbox"
              />
              <span style={{ marginTop: "4px" }}>
                Hacer de este mi método de pago predeterminado
              </span>
            </label>
          </div>
        </form>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-button-danger uk-button-custom"
          type="button"
          disabled={loading}
          onClick={() => setClose()}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
