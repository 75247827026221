import React from "react";
import ContentLoader from "react-content-loader"

export default function LoadingContent() {

    return <div className="uk-margin uk-padding  uk-width-1-1 uk-height-1-1" style={{height: '700px'}}>
        <div className="uk-position-center uk-padding-small uk-text-center">
                <ContentLoader
                    speed={2}
                    height={600}
                    width={1000}
                    viewBox="0 0 400 300"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                    <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                    <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                    <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                    <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                    <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                </ContentLoader>
        </div>
    </div>





}
