import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { getData } from "helpers/service";
import {
  Encode,
  GetStorage,
  GetTypeDevice,
  insertUrlParam,
  setTitle,
} from "helpers/functions";
import Pagination from "components/product/pagination";
import Filter from "components/product/filter";
import GridProducts from "components/product/grid";
import CategoryChildren from "components/product/category-children";
import Sponsored from "components/product/sponsored";
import Advertisements from "components/share/adsense";
import { BannerCategoryModel } from "models/adsense";
import AccountContext from "context/Account";

export default function CategoryPage() {
  let { friendlyurl } = useParams();
  const { search, state } = useLocation();
  const qs = queryString.parse(search);
  const { account, reloadItems, setSeoData } = useContext(AccountContext);
  const currentType = GetTypeDevice();

  const initialState = {
    loadingCat: true,
    loading: true,
    categories: [],
    sBanners: [],
    pBanners: [],
    baseImgBanner: "",
    categoryName: "",
    products: [],
    featured: [],
    category: "",
    total: 0,
    sort: qs.sort || GetStorage("sort") || 1,
    page: qs.page || 1,
    limit: qs.limit || GetStorage("limit") || 15,
    adsArgument: {},
    readyToShowAds: false,
    baseImg: "",
  };

  const [categoryState, setCategoryState] = useState(initialState);
  const {
    loadingCat,
    loading,
    page,
    limit,
    sort,
    adsArgument,
    category,
    categoryName,
    categories,
    total,
    readyToShowAds,
    baseImg,
    baseImgBanner,
    sBanners,
    pBanners,
    products,
    featured,
  } = categoryState;

  useEffect(() => {
    setSeoData({
      metaTitle: state?.metaTitle,
      metaDescription: state?.metaDescription,
      metaKeywords: state?.metaKeywords,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendlyurl]);

  useEffect(() => {
    setCategoryState((prev) => ({ ...prev, loadingCat: true }));
    getData(
      `product/category-children/${Encode(friendlyurl)}`,
      null,
      (res, err) => {
        if (!err) {
          setCategoryState((prev) => ({
            ...prev,
            categories: res.categories,
            sBanners: res.banners,
            pBanners: res.banners_product,
            categoryName: res.breadcrumbs,
            loadingCat: false,
            baseImgBanner: res.base_img_banner,
            page: 1,
          }));
          setTitle(res.category);
        }
        setCategoryState((prev) => ({ ...prev, loadingCat: false }));
      }
    );
  }, [friendlyurl]);

  useEffect(() => {
    setCategoryState((prev) => ({ ...prev, loading: true }));
    insertUrlParam(`page=${page}&limit=${limit}&sort=${sort}`);
    window.scrollTo({ behavior: "smooth", top: 0 });
    getData(
      `product/category/${Encode(friendlyurl)}`,
      {
        "x-p": Encode(page),
        "x-s": Encode(sort),
        "x-l": Encode(limit),
      },
      (res, err) => {
        if (!err) {
          setCategoryState((prev) => ({
            ...prev,
            baseImg: res.base_img,
            total: res.total,
            products: res.data,
            category: res.category,
          }));

          if (res.current_page === 1) {
            setCategoryState((prev) => ({ ...prev, featured: res.featured }));
          }
        }
        setCategoryState((prev) => ({
          ...prev,
          adsArgument: { category: friendlyurl },
          loading: false,
        }));
      }
    );
  }, [page, sort, limit, friendlyurl, account, reloadItems]);

  const ShowAll = (e) => {
    setCategoryState((prev) => ({ ...prev, page: 1, limit: 0 }));
    e.preventDefault();
  };

  useEffect(() => {
    if (Object.entries(adsArgument).length > 0)
      setCategoryState((prev) => ({ ...prev, readyToShowAds: true }));
  }, [adsArgument]);

  const handleSetLimit = (value) => {
    setCategoryState((prev) => ({ ...prev, limit: value }));
  };
  const handleSetSort = (value) => {
    setCategoryState((prev) => ({ ...prev, sort: value }));
  };
  const handleSetPage = (value) => {
    setCategoryState((prev) => ({ ...prev, page: value }));
  };
  return (
    <>
      <div
        id="view-category"
        data-id={category?.trim()}
        data-parent-category-id={category?.trim()}
        data-departament={category?.trim()}
        className="uk-padding"
      >
        <div className="title-border uk-padding-remove-bottom">
          <h2
            className="uk-h4"
            dangerouslySetInnerHTML={{ __html: categoryName }}
          ></h2>
          {!loadingCat && <CategoryChildren categories={categories} />}
        </div>
        <Filter
          total={total}
          setSort={handleSetSort}
          sort={sort}
          ShowAll={ShowAll}
        />
        <div
          className={`uk-section uk-section-small uk-flex uk-flex-center banner_movil ${
            currentType !== "mobile" && "uk-hidden"
          }`}
        >
          {readyToShowAds && (
            <Advertisements
              style={{ minWidth: "350px", minHeight: "90px" }}
              type="mobile"
              sizes={[350, 90]}
              adUnit="Movil_Banner_Par_Skyscraper_350X90"
              slotId="div-gpt-ad-1640180117696-0"
              targetingArguments={adsArgument}
            />
          )}
        </div>

        <div className="uk-container uk-container-expand">
          <div className="slider-sponsored-banners" uk-slider="sets: true">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <div className="uk-slider-items">
                  {BannerCategoryModel.filter(
                    (item) => item.type === currentType
                  ).map((item) => (
                    <div key={item.slotId} className={item.className}>
                      <Advertisements
                        style={item.style}
                        type={item.type}
                        slotId={item.slotId}
                        sizes={item.sizes}
                        adUnit={item.adUnit}
                        targetingArguments={adsArgument}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Sponsored
          loading={loading}
          baseImg={baseImg}
          baseImgBanner={baseImgBanner}
          banners={sBanners}
          products={featured}
        />

        <GridProducts
          loading={loading}
          baseImg={baseImg}
          baseImgBanner={baseImgBanner}
          products={products}
          banners={pBanners}
        />
        <Pagination
          setLimit={handleSetLimit}
          setPage={handleSetPage}
          CurrentPage={page}
          total={total}
          limit={limit}
        />

        <div
          className={`uk-section uk-section-small uk-flex uk-flex-center banner_desktop ${
            currentType !== "desktop" && "uk-hidden"
          }`}
        >
          {readyToShowAds && (
            <Advertisements
              style={{ minWidth: "728px", minHeight: "90px" }}
              type="desktop"
              sizes={[728, 90]}
              adUnit="desktop_banner_leaderboard_footer_728x90"
              slotId="div-gpt-ad-1634740809783-0"
              targetingArguments={adsArgument}
            />
          )}
        </div>
        <div
          className={`uk-section uk-section-small uk-flex uk-flex-center banner_movil ${
            currentType !== "mobile" && "uk-hidden"
          }`}
        >
          {readyToShowAds && (
            <Advertisements
              style={{ minWidth: "350px", minHeight: "90px" }}
              type="mobile"
              sizes={[350, 90]}
              adUnit="Movil_Banner_Par_Leaderboard_Footer_350x90"
              slotId="div-gpt-ad-1640180314482-0"
              targetingArguments={adsArgument}
            />
          )}
        </div>
      </div>
    </>
  );
}
