
import React, { useState, useContext, useEffect } from "react";
import CartRemoveAll from "components/cart/cart/delete-all";
import CreateShoppingList from "components/cart/cart/shoppinglist";
import ShoppingCartList from "components/cart/cart/shoppingCartList";
import ProductDetail from "components/product/detail/modal";
import ProductAddMore from "components/product/item/modal";
import UIkit from "uikit";
import AccountContext from "context/Account";
import { postData, getData } from "helpers/service";
import { DecimalFormat, Encode, unsubscribeEvent, publishEvent } from "helpers/functions";
import { useNavigate, Link } from "react-router-dom";
import alert from "helpers/alerts";
import CommentItem from "components/share/commentItem";

const ShoppingCart = ({ baseImg, setLoading }) => {
  const [open, setOpen] = useState("none");
  const [currentProduct, setCurrentProduct] = useState({});
  const [hasPublishEvent, setHasPublishEvent] = useState(false);
  let idx = 0;
  const navigate = useNavigate();

  const {
    shoppingCart,
    loadShoppingCart,
    shoppingCartItems,
    loadShoppingCartItems,
  } = useContext(AccountContext);

  const ChangeQty = (index, qty) => {
  
    let xitems = shoppingCartItems;
    xitems[index].in_cart = qty;
    setCurrentProduct(xitems[index]); //current producto para events CRM
    loadShoppingCartItems(xitems);
    setHasPublishEvent(true);
  };

  // ******* CRM ******* //
 
  //    Update Cart   //
  useEffect(() => {
    if (!hasPublishEvent) return;
    let dataUpdate = {
      _id: currentProduct.productid,
      price: parseFloat(currentProduct.price),
      quantity: parseFloat(currentProduct.in_cart),    
    }
    publishEvent('update-cart', dataUpdate, 'view-cart');
    setHasPublishEvent(false);

    return () => {
      unsubscribeEvent("update-cart", null, 'view-cart');
    };
  }, [hasPublishEvent, currentProduct]);

  const [viewCart, setViewCart] = useState({});
 
  useEffect(() => { 
    let Items = shoppingCartItems;
    let ids = Items.map((item) => item.productid);
    let price = Items.map((item) => parseFloat(item?.price));
    let quantity = Items.map((item) => parseFloat(item?.in_cart));
  
    let lineItems = {
      _id: ids,
      price: price,
      quantity: quantity,
      currency: "DOP",
    };

    setViewCart(lineItems);
  }, [shoppingCartItems]);

// ******* Fin CRM ******* //

  const RemoveAll = () => {
    postData(`cart/remove-all`, null, {}, (res, err) => {
      if (!err) {
        alert.toast(res.message);
        loadShoppingCartItems([]);
        loadShoppingCart({});
      }
    });
  };

  const RemoveItem = (id) => {
    postData(
      `cart/${Encode(id)}/remove`,
      { key: Encode(id) },
      {},
      (res, err) => {
        if (!err) {
          //alert.toast(res.message);
          loadShoppingCart(res.balance);
          loadShoppingCartItems(res.items || []);
          loadShoppingCart(res.balance);
        }
      }
    );
  };

  const OpenModal = (item, action) => {
    setCurrentProduct(item);
    setOpen(action);
    UIkit.modal(`#webModal`)?.show();
  };

  const onSetClose = () => {
    UIkit.modal(`#webModal`)?.hide();
    setOpen("none");
    setCurrentProduct({});
  };

  const onSetComment = (comment) => {
    let xitems = shoppingCartItems;
    let current = currentProduct;
    current.comment = comment;
    const copyItems = xitems.filter(
      (item) => item.productid !== currentProduct.productid
    );

    loadShoppingCartItems([...copyItems, current]);
    onSetClose();
  };

  const onSetNotReplace = (idx, not_replace) => {
    let xitems = shoppingCartItems;
    xitems[idx].not_replace = not_replace ? 1 : 0;
    loadShoppingCartItems(xitems);
  };

  const handleGoToCheckout = () => {
    setLoading(true);
    if (
      parseFloat(shoppingCart?.order_amount) <
      parseFloat(shoppingCart?.minimum_order)
    ){
      setLoading(false);
      return alert.confirm(
        `El monto de esta orden está por debajo del mínimo requerido.`,
        "warning"
      );;
    }

    getData("account/address", null, (res, err) => {
      if (err) {
        setLoading(false);
        return alert.confirm(
          `Ha ocurrido un error, contacte un administrador.`,
          "warning"
        );
      }
        
      if (res?.data.length === 0) {
        setLoading(false);
        return alert.confirm(
          `No tienes direcciones para continuar, agrega alguna y vuelve a intentar.`,
          "warning"
        );
      }      
      navigate("/checkout", { replace: true });
    });
  };

  const handleSetComment = (comment="", item) => {  
    postData(
      `cart/${Encode(item.cartid)}/preference`,
      {
        key: Encode(item.cartid),
        notReplace: item.not_replace,
        comment: comment,
      },
      {},
      (res, err) => {
        if (!err) {
          onSetComment(comment);
        }
      }
      );
  };


  const onSetQuantity = (in_cart = 0) => {
    let xitems = shoppingCartItems;
    xitems[idx].in_cart = in_cart;
    loadShoppingCartItems(xitems);
    onSetClose();
    setHasPublishEvent(true);
  };

  const BtnRemoveAll = (
    <div
      className="uk-flex uk-flex-right uk-grid-small uk-text-small"
      uk-grid="true"
    >
      <div>
        <button
          onClick={() => OpenModal("", "remove-all")}
          className="uk-button uk-button-danger gi_round_button"
        >
          <span>Limpiar carrito</span>
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  );

  const BtnMakeList = (
    <div className="gi_caja_crear_lista">
      <div className="gi_icon_caja">
        <img
          src="/assets/global/images/icon_lista.png"
          alt="Lista de compras"
        />
      </div>
      <div className="gi_icon_cajatext">
        <p>Puedes guardar esta lista de artículos para un próximo pedido</p>
      </div>
      <div className="gi_crealist_button">
        <button
          id="btn-make-list"
          onClick={() => OpenModal("", "shoppinglist")}
          className="uk-button"
        >
          <span>Crear lista</span> <i className="fas fa-list"></i>
        </button>
      </div>
    </div>
  );

  return (
    <div
      className="uk-grid uk-grid-large item-list-wrap"
      uk-grid="true"
      data-uk-grid
    >
      <div
        id="view-cart"
        data-id={viewCart._id}
        data-price={viewCart.price}
        data-quantity={viewCart.quantity}
        data-currency={viewCart.currency}
        className="uk-width-1-1 uk-width-2-3@m"
      >
        <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small gi_card_carrito">
          <ul id="cart-list" data-test="cart-list" className="uk-list uk-list-divider uk-list-large">
            <ShoppingCartList
              ChangeQty={ChangeQty}
              RemoveItem={RemoveItem}
              baseImg={baseImg}
              items={shoppingCartItems}
              onSetNotReplace={onSetNotReplace}
              OpenModal={OpenModal}
            />
          </ul>
        </div>

        <div
          className="uk-flex uk-flex-right uk-grid-small uk-text-small"
          uk-grid="true"
        ></div>

        {BtnRemoveAll}
      </div>
      <div className="uk-width-1-1 uk-width-1-3@m">
        <div
          className="item-list-footer"
          uk-sticky="offset: 130; bottom: true; media: @m"
        >
          {parseFloat(shoppingCart?.order_amount) <
            parseFloat(shoppingCart?.minimum_order) && (
            <div className="uk-width-1-1 uk-padding-small">
              <div className="uk-text-small uk-alert uk-alert-warning">
                <i className="fas fa-exclamation-triangle"></i>
                <p>
                  El monto de esta orden está por debajo del mínimo requerido,
                  RD$ {DecimalFormat(shoppingCart?.minimum_order)}
                </p>
              </div>
            </div>
          )}

          <div className="item-list-total">
            <div>
              <div className="uk-grid gi_cart_total">
                <div className="uk-width-1-1 uk-text-center gi_cart_artic">
                  <span className="uk-inline">
                    <i className="fas fa-cart-plus"></i> {shoppingCart?.volumen}{" "}
                    {shoppingCart?.volumen > 1 ? "artículos" : "artículo"}
                  </span>
                </div>
                <div className="uk-width-1-1 gi_cart_titletot">
                  Total a pagar
                </div>
                <div className="uk-width-1-1 gi_cart_tot">
                  RD$ {DecimalFormat(shoppingCart?.amount)}
                </div>
                <div className="uk-width-1-1 gi_pay_button">
                  <button
                    data-test="go-to-checkout"
                    onClick={handleGoToCheckout}
                    className="uk-button uk-button-primary"
                  >
                    <span>Proceder a pagar</span>
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
                <div className="uk-width-1-1 gi_seguir_button">
                  <Link to="/" className="uk-button">
                    <i className="fas fa-undo-alt"></i> Seguir comprando
                  </Link>
                </div>
              </div>
            </div>

            {BtnMakeList}
          </div>
        </div>
      </div>
      <CartRemoveAll
        isOpen={open}
        onRemoveAll={RemoveAll}
        onSetClose={onSetClose}
      />
      <CommentItem
        isOpen={open}
        item={currentProduct}
        handleSetComment={handleSetComment}
        onSetClose={onSetClose}
      />
      <CreateShoppingList isOpen={open} onSetClose={onSetClose} />
      {/* Refactored */}
      <ProductDetail
        isOpen={open}
        current={currentProduct}
        onSetClose={onSetClose}
      />
      <ProductAddMore
        isOpen={open}
        item={currentProduct}
        onSetClose={onSetQuantity}
      />
    </div>
  );
};
export default ShoppingCart;
