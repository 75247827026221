export const initialState = {
    loading: true,
    stores: [],
    contacts: [],
    addresses: [],
    locationId: "",
    pickupId: "",
    addressId: "",
    shippingType: "",
    storeMarker: {},
    shippingRates:[]
  }