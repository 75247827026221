import React, { useEffect, useState } from "react";
import { getData } from "helpers/service";
import Loading from "components/share/loading/maps";
import { setTitle } from "helpers/functions";
import { BannerStoresModel } from "models/adsense";
import { GetTypeDevice } from "helpers/functions";
import Advertisements from "components/share/adsense";
import GoogleMaps from "components/share/maps";
export default function StoresPage() {
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState([]);
  const currentType = GetTypeDevice();
  setTitle("Tiendas");

  useEffect(() => {
    getData(`setting/site/stores`, null, (res, err) => {
      if (!err) {
        setStores(res?.data);
        if (res?.data?.length > 0) {
          setStore(res.data[0]);
        }
      }
      setLoading(false);
    });
  }, []);

  const Togglebutton = () => {
    setShowAll(!showAll);
  };

  const handleSelectStore = (e) => {
    const option = stores.find((element) => element.value === Number(e.target.value));
    setStore(option);
  };

  const MapStoreSingle = () => {
    if (store === null) return "";
    return <GoogleMaps stores={[store]} />;
  };

  const MapsAllStores = () => {
    return <GoogleMaps stores={stores} />;
  };

  const ShowAll = () => {
    return (
      <div
        className="uk-width-1-1 uk-position-relative"   
      >
        <div id="gmap" className="gmap">
          {stores.length > 0 && <MapsAllStores />}
        </div>
      </div>
    );
  };

  const ShowSingle = () => {
    return (
      <div id="store_map" className="uk-flex uk-flex-wrap">
        <div
          className="uk-width-1-1 uk-width-2-3@m uk-width-3-4@xl uk-position-relative"
        >
          <div id="gmap" className="gmap">
            <MapStoreSingle /> 
          </div>
        </div>

        <div className="store-info uk-width-1-1 uk-width-1-3@m uk-width-1-4@xl uk-flex-first@m uk-background-default">
          <div className="uk-padding">
            <div className="title-border">
              <h2 className="uk-h4">{store?.label}</h2>
            </div>
            <p className="uk-flex">
              <i className="fas fa-map-marker-alt"></i>
              {store?.address}
            </p>
            <p className="uk-flex">
              <i className="fas fa-route"></i>{" "}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${store?.lat},${store?.lon}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Obtener direcciones
              </a>
            </p>
            <p className="uk-flex">
              <i className="fas fa-phone-alt"></i>{" "}
              <a href={`tel:${store?.phone}`}>{store?.phone}</a>
            </p>
            <p className="uk-flex">
              <i className="fab fa-whatsapp"></i>
              <a href={`tel:${store?.whatapp}`}>{store?.whatapp}</a>
            </p>
            <p className="uk-flex">
              <i className="fas fa-clock"></i>
              {store?.worktime}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const PageContent = () => (
    <>
      <div className="uk-section uk-section-small uk-margin-medium-bottom">
        <div className="uk-container uk-container-expand">
          <div className="uk-section uk-section-small uk-flex uk-flex-center">
            {BannerStoresModel.filter((item) => item.type === currentType).map(
              (item) => (
                <Advertisements
                  key={item.slotId}
                  className={item.className}
                  type={item.type}
                  slotId={item.slotId}
                  sizes={item.sizes}
                  adUnit={item.adUnit}
                  targetingArguments={{ Seccion: "nuestras-tiendas" }}
                />
              )
            )}
          </div>
          <div className="uk-flex uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom">
            <div>
              {showAll ? (
                ""
              ) : (
                <div>
                  <strong className="uk-inline uk-margin-right uk-visible@s">
                    Ver tienda:
                  </strong>
                  <div className="uk-inline" style={{ width: "300px" }}>
                    <select
                      className="uk-select"
                      onChange={(e) => handleSelectStore(e)}
                      value={store?.value}
                    >
                      {stores?.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div>
              <button
                onClick={Togglebutton}
                type="button"
                className="uk-button uk-button-primary"
              >
                {showAll ? "Ver tiendas" : "Ver Todas"}{" "}
                <i className="fas fa-map-marked-alt uk-margin-small-left"></i>
              </button>
            </div>
          </div>
          {showAll ? <ShowAll /> : <ShowSingle />}

          <div className="uk-padding uk-border-rounded uk-background-secondary uk-light uk-margin-medium-top">
            <div className="title-border">
              <h2 className="uk-h4">Servicios y Horarios</h2>
            </div>

            <ul
              className="uk-grid uk-grid-small uk-child-width-1-2@m uk-child-width-1-3@l"
              uk-grid="true"
            >
              {stores.map((item) => (
                <li key={item?.value}>
                  <p className="uk-margin-remove-bottom">
                    <strong>{item?.label}</strong>
                  </p>
                  <p className="uk-flex uk-text-small uk-margin-small-top">
                    <i className="fas fa-clock uk-text-primary uk-margin-small-right"></i>
                    {item?.worktime}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  return loading ? <Loading /> : <PageContent />;
}
