/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import AccounConnected from "components/account/auth/connected";
import AccountLogin from "components/account/auth/login";
import AccountLostPwd from "components/account/auth/lost-password";
import AccountContext from "context/Account";

export default function MainBar({ OpenModal, HideSearch }) {
  const { isLogin, shoppingCart } = useContext(AccountContext);

  const Anonymous = () => {
    return (
      <div>
        <AccountLogin source="header" /> <AccountLostPwd />
      </div>
    );
  };

  const ButtonSiremas = (
    <li className="uk-visible@m">
      <Link to="/club-siremas">
        <i className="fas fa-credit-card uk-text-tertiary"></i>
        <span>Siremás</span>
      </Link>
    </li>
  );

  const ButtonMyAccount = (
    <li>
      <a href="#" className="btn-user-menu">
        <i className="fas fa-user"></i>
        <span className="uk-visible@m">Mi Cuenta</span>
        <i className="fas fa-caret-down"></i>
      </a>

      <div id="dropdown-my-account" className="uk-navbar-dropdown uk-border-rounded uk-box-shadow-small">
        {isLogin ? <AccounConnected /> : <Anonymous />}
      </div>
    </li>
  );

  const ButtonMyCart = (
    <>
      {isLogin && !HideSearch && (
        <li>
          <a
            id="btn-cart"
            data-test="btn-cart"
            href="#"
            onClick={() => OpenModal("modal-cart")}
            className="btn-cart-main btn-cart-valid"
            uk-toggle="target: #modal-cart"
          >
            <i className="fas fa-shopping-cart"></i>
            {shoppingCart?.volumen > 0 ? (
              <span id="qty-items-in-cart" data-test="qty-items-in-cart">
                <small>{shoppingCart?.volumen}</small>
              </span>
            ) : (
              ""
            )}
          </a>
        </li>
      )}
    </>
  );
  return (
    <div className="uk-navbar-right uk-width-auto uk-flex-right">
      <ul className="uk-navbar-nav uk-flex-middle">
        {ButtonSiremas}
        {ButtonMyAccount}
        {ButtonMyCart}
      </ul>
    </div>
  );
}
