import React from "react";
import { Link } from "react-router-dom";


export default function SubNavCategory({ vdetails }) {
 

  return (
    <div className="uk-navbar-dropdown" id="sub-categorybar">
      <div uk-slider="sets: true; finite: true">
        <div className="uk-position-relative">
          <div className="uk-slider-container">
            <ul className="uk-slider-items uk-grid">
              {vdetails.map((c) => (
                <li key={c.id}>
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    <li className="uk-nav-header">
                      <Link to={`/products/category/${c.friendlyurl}`}>
                        {c.name}
                      </Link>
                    </li>
                    {c.children.map((c2) => (
                      <li key={c2.id}>
                        <Link to={`/products/category/${c2.friendlyurl}`}>
                          {c2.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>

          <a
            className="uk-position-center-left-out"
            href="#0"
            uk-slider-item="previous"
          >
            <i className="fas fa-chevron-left"></i>
          </a>
          <a
            className="uk-position-center-right-out"
            href="#0"
            uk-slider-item="next"
          >
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
