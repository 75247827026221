export const BannerHomeModel = [
  {
    classname: "desktop_home_slider banner_desktop banner_giro",
    style: { minWidth: 800, minHeight: 380 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741106124-0",
    sizes: [800, 380],
    adUnit: "desktop_home_slider_1_800x380",
  },
  {
    classname: "desktop_home_slider banner_desktop banner_giro",
    style: { minWidth: 800, minHeight: 380 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741120265-0",
    sizes: [800, 380],
    adUnit: "desktop_home_slider_2_800x380",
  },
  {
    classname: "desktop_home_slider banner_desktop banner_giro",
    style: { minWidth: 800, minHeight: 380 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741124797-0",
    sizes: [800, 380],
    adUnit: "desktop_home_slider_3_800x380",
  },
  {
    classname: "desktop_home_slider banner_desktop banner_giro",
    style: { minWidth: 800, minHeight: 380 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741129857-0",
    sizes: [800, 380],
    adUnit: "desktop_home_slider_4_800x380",
  },
  {
    classname: "movil_home_slider banner_movil banner_giro",
    style: { minWidth: 350, minHeight: 250 },
    type: "mobile",
    slotId: "div-gpt-ad-1640175294373-0",
    sizes: [350, 250],
    adUnit: "Movil_Home_Slider_1",
  },
  {
    classname: "movil_home_slider banner_movil banner_giro",
    style: { minWidth: 350, minHeight: 250 },
    type: "mobile",
    slotId: "div-gpt-ad-1640175390624-0",
    sizes: [350, 250],
    adUnit: "Movil_Home_Slider_2",
  },
  {
    classname: "movil_home_slider banner_movil banner_giro",
    style: { minWidth: 350, minHeight: 250 },
    type: "mobile",
    slotId: "div-gpt-ad-1640176092420-0",
    sizes: [350, 250],
    adUnit: "Movil_Home_Slider_3",
  },
  {
    classname: "movil_home_slider banner_movil banner_giro",
    style: { minWidth: 350, minHeight: 250 },
    type: "mobile",
    slotId: "div-gpt-ad-1640176374757-0",
    sizes: [350, 250],
    adUnit: "Movil_Home_Slider_4",
  },
];

export const BannerHomeStandardModel = [
  {
    classname: "desktop_home_standard",
    style: { minWidth: 350, minHeight: 250 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741248658-0",
    sizes: [400, 180],
    adUnit: "desktop_home_standard_1_400x180",
  },
  {
    classname: "desktop_home_standard",
    style: { minWidth: 350, minHeight: 250 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741269841-0",
    sizes: [400, 180],
    adUnit: "desktop_home_standard_2_400x180",
  },
];

export const BannerCategoryModel = [
  {
    classname: "banner_movil",

    type: "mobile",
    slotId: "div-gpt-ad-1640180822331-0",
    sizes: [350, 90],
    adUnit: "Movil_Patrocinados_350x90",
  },
  {
    classname: "banner_desktop",

    type: "desktop",
    slotId: "div-gpt-ad-1634740471568-0",
    sizes: [440, 127],
    adUnit: "desktop_banner_horizontal_1_440x127",
  },
  {
    classname: "banner_desktop",

    type: "desktop",
    slotId: "div-gpt-ad-1634740625296-0",
    sizes: [440, 127],
    adUnit: "desktop_banner_horizontal_2_440x127",
  },
];

export const BannerSiremasModel = [
  {
    classname: "banner_desktop",
    style: { minWidth: "728px", minHeight: "90px" },
    type: "desktop",
    slotId: "div-gpt-ad-1640176822285-0",
    sizes: [728, 90],
    adUnit: "desktop_siremas_top_728x90",
    arguments: { Seccion: "siremas" },
  },
  {
    classname: "banner_movil",
    style: { minWidth: "350px", minHeight: "90px" },
    type: "mobile",
    slotId: "div-gpt-ad-1640177824049-0",
    sizes: [350, 90],
    adUnit: "Movil_Siremas_Top_350x90",
    arguments: { Seccion: "siremas" },
  },
];
export const BannerCheckoutModel = [
  {
    classname: "banner_desktop",
    style: { minWidth: "728px", minHeight: "90px" },
    type: "desktop",
    slotId: "div-gpt-ad-1665508135241-0",
    sizes: [728, 90],
    adUnit: "Desktop_Checkout_728x90",
  },
  {
    classname: "banner_movil",
    style: { minWidth: "350px", minHeight: "90px" },
    type: "mobile",
    slotId: "div-gpt-ad-1665508354370-0",
    sizes: [350, 90],
    adUnit: "Movil_Checkout_350x90",
  },
];

export const BannerMyListModel = [
  {
    classname: "banner_movil",
    style: { minWidth: "350px", minHeight: "90px" },
    type: "mobile",
    slotId: "div-gpt-ad-1640179101841-0",
    sizes: [350, 90],
    adUnit: "Movil_Mis_Listas_350x90",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: "728px", minHeight: "90px" },
    type: "desktop",
    slotId: "div-gpt-ad-1640179027959-0",
    sizes: [728, 90],
    adUnit: "Desktop_Mis_Listas_728x90",
  },
];
export const BannerStoresModel = [
  {
    classname: "banner_desktop",
    style: { minWidth: 728, minHeight: 90 },
    type: "desktop",
    slotId: "div-gpt-ad-1640179848176-0",
    sizes: [728, 90],
    adUnit: "Desktop_Nuestras_Tiendas_728x90",
  },
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1640179936996-0",
    sizes: [350, 90],
    adUnit: "Movil_Nuestras_Tiendas_350x90",
  },
];

export const BannerEspeciallyModel = [
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1644874376533-0",
    sizes: [350, 90],
    adUnit: "Especiales-Movil",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: 970, minHeight: 250 },
    type: "desktop",
    slotId: "div-gpt-ad-1644874235296-0",
    sizes: [970, 250],
    adUnit: "Especiales-Desktop",
  },
];

export const BannerMyCreditCardsModel = [
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1640179492422-0",
    sizes: [350, 90],
    adUnit: "Movil_Mis_Tarjetas_350x90",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: 728, minHeight: 90 },
    type: "desktop",
    slotId: "div-gpt-ad-1640179425191-0",
    sizes: [
      [728, 90],
      [970, 250],
    ],
    adUnit: "Desktop_Mis_Tarjetas_728x90",
  },
];

export const BannerStepUser5Model = [
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1640178277635-0",
    sizes: [350, 90],
    adUnit: "Movil_Formulario_Registro_Header_Banner_Paso_5_350x90",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: 970, minHeight: 250 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741426674-0",
    sizes: [970, 250],
    adUnit: "desktop_formulario_registro_header_banner_paso_5_970x250",
  },
];
export const BannerStepUser1Model = [
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1640178131334-0",
    sizes: [350, 90],
    adUnit: "Movil_Formulario_Registro_Banner_Paso_1_350x90",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: 728, minHeight: 90 },
    type: "desktop",
    slotId: "div-gpt-ad-1634741406056-0",
    sizes: [728, 90],
    adUnit: "desktop_formulario_registro_footer_banner_paso_1_728x90",
  },
];
export const BannerSearchPageModel = [
  {
    classname: "banner_movil",
    style: { minWidth: 350, minHeight: 90 },
    type: "mobile",
    slotId: "div-gpt-ad-1640178813889-0",
    sizes: [350, 90],
    adUnit: "Movil_Resultado_Busquedas_350x90",
  },
  {
    classname: "banner_desktop",
    style: { minWidth: 728, minHeight: 90 },
    type: "desktop",
    slotId: "div-gpt-ad-1640178718561-0",
    sizes: [728, 90],
    adUnit: "Desktop_Resultado_Busquedas_728x90",
  },
];
