import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { GetSession, SetSession } from "helpers/functions";
import { OPEN } from "store/product/detail/actionTypes";

const DistributionAlertModal = ({ isOpen, onSetClose, distribution = {} }) => {
  const { message, id } = distribution;

  
  const previousDistribution = GetSession("dismissedDistribution");
  const [alert, setAlert] = useState([]);
  

  useEffect(() => {
    if (alert.length > 0) SetSession("dismissedDistribution", alert);
  }, [alert]);
 
  const handleNoShowAgain = (e, id) => {
    e.preventDefault();
    if (alert.length > 0) {
      const existsDistributionId = alert.includes(id);
      if (!existsDistributionId) setAlert((prevState) => [...prevState, id]);
    } else {
      setAlert([id]);
    }
  };

  const handleCloseModal = () => {
    if (previousDistribution !== null) {
      setAlert((prevState) => [...prevState, ...previousDistribution]);
    }
    onSetClose(OPEN,'#webModal');
  };

  if (isOpen !== "delivery") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical gi_modal_aviso"
    >
      <button
        id="btn-close"
        className="uk-modal-close-default"
        type="button"
        data-uk-close
        onClick={handleCloseModal}
      ></button>

      <div className="uk-modal-header"></div>
      <div className="uk-modal-body" uk-overflow-auto="true">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>
            <button
              id="btn-confirm"
              onClick={handleCloseModal}
              className="uk-button uk-button-primary gi_round_button"
            >
              De acuerdo
            </button>
          </p>
        </div>
      </div>

      <div className="uk-modal-footer uk-text-center gi_modal_footer">
        <div
          className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center uk-grid"
          data-uk-grid
        >
          <div className="uk-width-1-1 uk-width-expand@m uk-text-right@m">
            <a
              className="gi_ulink"
              href="#0"
              onClick={(e) => handleNoShowAgain(e, id)}
            >
              No mostrar más este aviso.
            </a>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("webModal")
  );
};
export default DistributionAlertModal;
