import React from "react";
import ShoppingCartItem from "./item";

const ShoppingCartList = ({
  items,
  RemoveItem,
  OpenModal,
  baseImg,
  ChangeQty,
  onSetNotReplace,
}) => {
  const COMMON = 1;
  const OTHERS = 2;

  const MyCart = items
    .filter((item) => item.distributionId === COMMON)
    .map((item, index) => {
      const xChangeQty = (qty) => {
        ChangeQty(index, qty);
      };

      const xChangeNotReplace = (qty) => {
        onSetNotReplace(index, qty);
      };

      const xOpenModal = (item, action) => {
        OpenModal(item, action);
      };

      return (
        <ShoppingCartItem
          key={item.cartid}
          xChangeNotReplace={xChangeNotReplace}
          xChangeQty={xChangeQty}
          item={item}
          RemoveItem={RemoveItem}
          baseImg={baseImg}
          xOpenModal={xOpenModal}
        />
      );
    });

  const HomeDelivery = items
    .filter((item) => item.distributionId === OTHERS)
    .map((item, index) => {
      const xChangeQty = (qty) => {
        ChangeQty(index, qty);
      };

      const xChangeNotReplace = (qty) => {
        onSetNotReplace(index, qty);
      };

      const xOpenModal = (item, action) => {
        OpenModal(item, action);
      };

      return (
        <ShoppingCartItem
          key={item.cartid}
          xChangeNotReplace={xChangeNotReplace}
          xChangeQty={xChangeQty}
          item={item}
          RemoveItem={RemoveItem}
          baseImg={baseImg}
          xOpenModal={xOpenModal}
        />
      );
    });
  const CategorySeparator = HomeDelivery.length > 0 && (
    <li className="gi_list_sepcategory">
      <div className="gi_item_list_category">Home Delivery</div>
    </li>
  );

  return (
    <>
      {MyCart}
      {CategorySeparator}
      {HomeDelivery}
    </>
  );
};

export default ShoppingCartList;
