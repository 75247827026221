import React, { useContext } from "react";
import AuthAnonymous from "components/account/auth/anonymous";
import AccountContext from "context/Account";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const { isLogin } = useContext(AccountContext);
  let navigate = useNavigate();
  if (isLogin) return navigate("/", { replace: true });

  return <AuthAnonymous source="full" />;
}
