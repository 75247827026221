import AddressesList from "components/cart/checkout/addresses";
import AddressAdd from "components/account/profile/addresses/add";
import { useState } from "react";
import UIkit from "uikit";
import { useContext } from "react";
import { CheckoutContext } from "context/Checkout";

const ChangeAddress = ({
  typeDelivery,
  typeAddress = "",
}) => {
  const [openModal, setOpenModal] = useState("none");
  const { setAddresses } = useContext(CheckoutContext);

  const onSetAddress = (data = null) => {
    if (data !== null) {
      setAddresses((prevState) => ({ ...prevState, [typeDelivery]: data }));
    }
    onSetClose("none", "webModal");
  };

  const onSetClose = (action = `none`, modal = "webModal") => {
    setOpenModal(action);
    if (action === "none") {
      UIkit.modal(`#${modal}`)?.hide();
    }
  };

  return (
    <>
      <div>
        <button
          className="uk-button uk-button-danger gi_round_button gi_direcc_button"
          uk-toggle="target: #webModal"
          onClick={() =>setOpenModal('addresses')}
        >
          <i className="fas fa-map-marker-alt"></i>
          <span>Cambiar dirección</span>
        </button>
      </div>
      <AddressesList
        isOpen={openModal}
        onSetClose={onSetClose}
        onSetAddress={onSetAddress}
        typeAddress={typeAddress}
      />
      <AddressAdd
        isOpen={openModal === "AddressAdd" ? "add" : "none"}
        setClose={onSetAddress}
      />
    </>
  );
};
export default ChangeAddress;
