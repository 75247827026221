import React, { useEffect, useState } from "react";
import { getData, } from "helpers/service";
import Loading from "components/share/loading/table";
import { DecimalFormat, setTitle } from "helpers/functions";
import { useParams } from "react-router-dom";
import GoogleMaps from "components/share/maps";

import PageNotFound from "components/share/error/404";


export default function OrderTrackingPage() {
  let { routeid, routeitemid, orderkey } = useParams();

  const [vRouteId, setvRouteId] = useState(routeid);
  const [vRouteItemId, setvRouteItemId] = useState(routeitemid);
  const [order, setOrder] = useState({});
  const [balance, setBalance] = useState({});
  const [route, setRoute] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  setTitle("Seguimiento de mi Pedido");

  useEffect(() => {
    if (routeid === undefined) {
      getData(
        `account/order/${orderkey}/route-tracking`,
        {},
        (res, err) => {
          if (!err) {
            setOrder(res.order);
            setBalance(res.balance);
            setRoute(res.route);

            setvRouteId(res.route?.routeId);
            setvRouteItemId(res.route?.routeitemId);

            setTimeout(function () {
              GetLocationDriver();
            }, 60000);
          }
          setMessage(res?.message);
          setError(err);
          setLoading(false);
        },
        false
      );
    } else {
      getData(
        `account/order/${routeid}/route/${routeitemid}/tracking`,
        {},
        (res, err) => {
          if (!err) {
            setOrder(res.order);
            setBalance(res.balance);
            setRoute(res.route);

            setTimeout(function () {
              GetLocationDriver();
            }, 60000);
          }
          setMessage(res?.message);
          setError(err);
          setLoading(false);
        },
        false
      );
    }


  }, []);

  const GetLocationDriver = () => {
    if (routeid !== undefined) {
      getData(
        `account/order/${vRouteId}/route/${vRouteItemId}/driver`,
        {},
        (res, err) => {
          if (!err) {
            setRoute(res.route);

            setTimeout(function () {
              GetLocationDriver();
            }, 60000);
          }
        }
      );
    }
  };

  const OrderDetails = () => {


    return (
      <div
        className="uk-grid uk-grid-large item-list-wrap"
        uk-grid="true"
        style={{ minHeight: "700px" }}
      >
        <div className="uk-width-1-1 uk-width-2-3@m">
          {/* <div
            className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small"
            style={{ minHeight: "600px" }}
          > */}
          {route.delivered === 0 ? (
            <GoogleMaps route={[route]} order={[order]} />
          ) : (
            <p className="uk-h4 uk-text-center">
              ENTREGADO {route.delivered_date}
            </p>
          )}
          {/* </div> */}
          <small>
            Última actualización de ubicación: {route.last_location}
          </small>
        </div>
        <div className="uk-width-1-1 uk-width-1-3@m">
          <div
            className="item-list-footer"
            uk-sticky="offset: 130; bottom: true; media: @m"
          >
            <div className="item-list-total">
              <div className="title-border">
                <p className="uk-h4">Pedido {order.codigo}</p>
              </div>
              <div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase"></div>
                  <div className="uk-width-2-3 uk-text-right">
                    {order.order_date}
                  </div>
                </div>
              </div>
              <p>
                <strong>{order.shippingrate}</strong>
              </p>

              {order.shippingratetype === "P" && (
                <>
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-user"></i>
                    <span>
                      <strong>
                        {order.pickup_first_name} {order.pickup_last_name}
                      </strong>
                      <br />
                      {order.pickup_documentType}: {order.pickup_document}
                    </span>
                  </p>
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-map-pin"></i>
                    <span>
                      <strong>{order.location}</strong>
                      <br /> {order.location_address}
                    </span>
                  </p>
                  <p className="uk-flex uk-grid-small">
                    <i className="far fa-calendar-alt"></i>
                    <span>
                      <strong>{order.timeslot}</strong>
                    </span>
                  </p>
                </>
              )}

              {order.shippingratetype === "D" && (
                <>
                  <p className="uk-flex uk-grid-small">
                    <i className="fas fa-map-pin"></i>
                    <span>{`${order.shipping_address} ${order.shipping_address2} ${order.shipping_sector} ${order.shipping_state}`}</span>
                  </p>
                  <p className="uk-flex uk-grid-small">
                    <i className="far fa-calendar-alt"></i>
                    <span>
                      <strong>{order.timeslot}</strong>
                    </span>
                  </p>
                </>
              )}
            </div>

            <hr className="uk-margin-medium-top" />

            <div className="item-list-total">
              <div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase">
                    Descuento:
                  </div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(balance?.order_discount || 0)}
                  </div>
                </div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase">Envío:</div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(balance?.shipping_cost || 0)}
                  </div>
                </div>
                <div className="uk-flex uk-grid-small">
                  <div className="uk-width-1-3 uk-text-uppercase">Itbis:</div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(balance?.tax_amount || 0)}
                  </div>
                </div>
                <div className="uk-flex uk-grid-small uk-text-bolder">
                  <div className="uk-width-1-3 uk-text-uppercase">Total:</div>
                  <div className="uk-width-2-3 uk-text-right">
                    RD$ {DecimalFormat(balance?.order_amount || 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return error ? (
    <PageNotFound message={message} />
  ) : (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">SEGUIMIENTO DE MI PEDIDO</h2>
        </div>
        {loading ? <Loading /> : <OrderDetails />}
      </div>
    </div>
  );
}
