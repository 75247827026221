import React, { useEffect, useState } from "react";
import { getData } from "helpers/service";
import Loading from "components/share/loading/table";
import { DecimalFormat, MoneyFormat, setTitle } from "helpers/functions";

import { useParams } from "react-router-dom";

export default function OrderVariationPage() {
  let { orderkey } = useParams();

  const [order, setOrder] = useState({});
  const [balance, setBalance] = useState({});
  const [variations, setVariations] = useState([]);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(true);

  setTitle("Variaciones de mi Pedido");

  useEffect(() => {
    getData(`account/order/${orderkey}/variation`, {}, (res, err) => {
      if (!err) {
        setOrder(res.order);
        setBalance(res.balance);
        setVariations(res.variation);
        setItems(res.items);
      }
      setLoading(false);
    });
  }, [orderkey]);

  const OrderDetails = () => (
    <div className="uk-grid uk-grid-large item-list-wrap" uk-grid="true">
      <div className="uk-width-1-1 uk-width-2-3@m">
        <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
          <div
            className="uk-flex uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom nav-filters"
            uk-grid="true"
          >
            <div>
              <span className="uk-inline">
                <i className="fas fa-cart-plus"></i> {variations?.length}{" "}
                Variaciones
              </span>
            </div>
            <div></div>
          </div>

          <hr />

          <table className="uk-table uk-table-striped uk-table-small">
            <thead>
              <tr>
                <th>Producto</th>
                <th></th>
                <th>Solicitado</th>
                <th>Despachado</th>
                <th>Diferencia</th>
              </tr>
            </thead>
            <tbody>
              {variations.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.product}</td>
                  <td>{item.producttypeId === 1 ? "Und." : "Lbs."}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.quantity_original}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.quantity_dispacht}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {MoneyFormat(item.diff)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
          <div
            className="uk-flex uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom nav-filters"
            uk-grid="true"
          >
            <div>
              <span className="uk-inline">
                <i className="fas fa-cart-plus"></i> {items?.length} Despachado
              </span>
            </div>
            <div></div>
          </div>

          <hr />

          <table className="uk-table uk-table-striped uk-table-small">
            <thead>
              <tr>
                <th>Producto</th>
                <th colSpan={2}>Cantidad</th>
                <th>Precio</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.product}</td>
                  <td style={{ textAlign: "right" }}>{item.quantity}</td>
                  <td>{item.producttypeId === 1 ? "Und." : "Lbs."}</td>
                  <td style={{ textAlign: "right" }}>
                    {MoneyFormat(item.price)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {MoneyFormat(item.total_order)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="uk-width-1-1 uk-width-1-3@m">
        <div
          className="item-list-footer"
          uk-sticky="offset: 130; bottom: true; media: @m"
        >
          <div className="item-list-total">
            <div className="title-border">
              <p className="uk-h4">Pedido {order.codigo}</p>
            </div>

            <div>
              <div className="uk-flex uk-grid-small">
                <div className="uk-width-1-3 uk-text-uppercase"></div>
                <div className="uk-width-2-3 uk-text-right">
                  {order.order_date}
                </div>
              </div>
            </div>

            <p>
              <strong>{order.shippingrate}</strong>
            </p>

            {order.shippingratetype === "P" && (
              <>
                <p className="uk-flex uk-grid-small">
                  <i className="fas fa-user"></i>{" "}
                  <span>
                    <strong>
                      {order.pickup_first_name} {order.pickup_last_name}
                    </strong>
                    <br />
                    {order.pickup_documentType}: {order.pickup_document}
                  </span>
                </p>
                <p className="uk-flex uk-grid-small">
                  <i className="fas fa-map-pin"></i>{" "}
                  <span>
                    <strong>{order.location}</strong>
                    <br /> {order.location_address}
                  </span>
                </p>
                <p className="uk-flex uk-grid-small">
                  <i className="far fa-calendar-alt"></i>{" "}
                  <span>
                    <strong>{order.timeslot}</strong>
                  </span>
                </p>
              </>
            )}

            {order.shippingratetype === "D" && (
              <>
                <p className="uk-flex uk-grid-small">
                  <i className="fas fa-map-pin"></i>{" "}
                  <span>{`${order.shipping_address} ${order.shipping_address2} ${order.shipping_sector} ${order.shipping_state}`}</span>
                </p>
                <p className="uk-flex uk-grid-small">
                  <i className="far fa-calendar-alt"></i>{" "}
                  <span>
                    <strong>{order.timeslot}</strong>
                  </span>
                </p>
              </>
            )}
          </div>

          <hr className="uk-margin-medium-top" />

          <div className="item-list-total">
            <div>
              <div className="uk-flex uk-grid-small">
                <div className="uk-width-1-3 uk-text-uppercase">Descuento:</div>
                <div className="uk-width-2-3 uk-text-right">
                  RD$ {DecimalFormat(balance?.order_discount || 0)}
                </div>
              </div>
              <div className="uk-flex uk-grid-small">
                <div className="uk-width-1-3 uk-text-uppercase">Envío:</div>
                <div className="uk-width-2-3 uk-text-right">
                  RD$ {DecimalFormat(balance?.shipping_cost || 0)}
                </div>
              </div>
              <div className="uk-flex uk-grid-small">
                <div className="uk-width-1-3 uk-text-uppercase">Itbis:</div>
                <div className="uk-width-2-3 uk-text-right">
                  RD$ {DecimalFormat(balance?.tax_amount || 0)}
                </div>
              </div>
              <div className="uk-flex uk-grid-small uk-text-bolder">
                <div className="uk-width-1-3 uk-text-uppercase">Total:</div>
                <div className="uk-width-2-3 uk-text-right">
                  RD$ {DecimalFormat(balance?.order_amount || 0)}
                </div>
              </div>
              <div className="uk-flex uk-grid-small uk-text-bolder uk-text-secondary">
                <div className="uk-width-1-3 uk-text-uppercase">A Pagar:</div>
                <div className="uk-width-2-3 uk-text-right">
                  RD$ {DecimalFormat(balance?.amount || 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">VARIACIONES DE MI PEDIDO</h2>
        </div>
        {loading ? <Loading /> : <OrderDetails />}
      </div>
    </div>
  );
}
