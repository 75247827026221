

import React, { useEffect, useState } from "react";
import AccountContext from "./index";

import {
  Islogin,
  Logout,
  GetAccount,
  SetAccount,
  SetStorage,
  GetSession,
  SetSession,
  ExistsSession,
} from "helpers/functions";

import { getData } from "helpers/service";

export default function AccountProvider({ children }) {
  const [isLogin, setIslogin] = useState(Islogin());
  const [account, setAccount] = useState(GetAccount());
  const [shoppingCart, setShoppingCart] = useState({});
  const [shoppingCartItems, setShoppingCartItems] = useState([]);
  const [categories, setCategories] = useState(GetSession("category") || []);
  const [sidebar, setSidebar] = useState(GetSession("sidebar") || []);
  const [shoppingCartCash, setShoppingCartCash] = useState({
    details: [],
    total: 0,
  });
  const [purchaseItems, setPurchaseItems] = useState({});
  const [reloadItems, setReloadItems] = useState(false);
  const [seoData, setSeoData] = useState({metaTitle:"", metaDescription:"", metaKeywords:""})
  const isLoading = false;

  useEffect(() => {
    if (!ExistsSession("category") || window.location.pathname === "/") {
      getData(`product/categories`, null, (res, err) => {
        if (!err) {
          SetSession("category", res.data);
          setCategories(res.data);
        }
      });
    }

    if (!ExistsSession("sidebar") || window.location.pathname === "/") {
      getData(`setting/site/sidebar`, null, (res, err) => {
        if (!err) {
          let data = {
            img_issuu: res.base_img_issuu,
            issuu: res.issuu,
            posts: res.posts,
            recipe: res.recipe,
          };

          SetSession("sidebar", data);
          setSidebar(data);
        }
      });
    }

    if (isLogin) {
      if (account?.default_shipping_type === undefined) {
        onLogOut();
      }
    }

  }, []);

  useEffect(() => {
    if (isLogin) {
      getData(`cart`, null, (res, err) => {
        if (!err) {
          loadShoppingCart(res.balance);
        }
      });
    }
  }, [isLogin]);

  const onLogIn = (data) => {
    SetAccount(data);
    SetStorage("login", "1");
    setIslogin(true);
    setAccount(data);
  };

  /*
 const getShoppingCart = () => {
    getData(`cart/balance`, null, (res, err) => {
      if (!err) {
        SetSession("sc", res.data);
        setShoppingCart(res.data);
      }
    });
  };
*/
  const loadShoppingCart = (data) => {
    setShoppingCart(data);
  };

  const loadShoppingCartItems = (data) => {
    setShoppingCartItems(data);
  };

  const getCash = () => {
    return shoppingCartCash;
  };

  const setCash = (data) => {
    setShoppingCartCash(data);
  };

  const onLogOut = () => {
    Logout();
    setIslogin(false);
    window.location = "/";
  };


  return (
    <AccountContext.Provider
      value={{
        isLogin,
        isLoading,
        account,
        shoppingCart,
        loadShoppingCart,
        shoppingCartItems,
        loadShoppingCartItems,
        onLogIn,
        onLogOut,
        categories,
        sidebar,
        setSidebar,
        shoppingCartCash,
        getCash,
        setCash,
        purchaseItems,
        setPurchaseItems,
        reloadItems,
        setReloadItems,
        seoData,
        setSeoData
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
