import React, { useState } from "react";
import ReactDOM from "react-dom";
import { postData } from "helpers/service";
import alert from "helpers/alerts";

export default function ProductRequestModal({ isOpen, onSetClose }) {
  const [comment, setComment] = useState("");

  const submit = () => {
    if (comment?.length === 0) return;

    postData(`product/request`, { request: comment }, null, (res, err) => {
      if (!err) {
        alert.confirm(res.message).then(() => {
          onSetClose();
        });
      }
    });
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        onClick={() => onSetClose()}
        className="uk-modal-close-default uk-modal-close"
        type="button"
        data-uk-close
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Solicitar producto
        </p>
      </div>

      <div className="uk-modal-body">
        <p>
          Agradecemos que nos comuniques sobre los productos que deseas ver.
          Estaremos atendiendo a esta solicitud con brevedad.
        </p>
        <p>
          <textarea
            name=""
            cols="30"
            rows="3"
            className="uk-textarea"
            onChange={(event) => setComment(event.target.value)}
            placeholder="Agrega tus productos aquí..."
          ></textarea>
        </p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={submit}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Solicitar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
