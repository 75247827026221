import React from "react";
import ReactDOM from "react-dom";

const DocumentPDFModal = ({ isOpen, url, orderCode }) => {
  if (isOpen !== "documentPdf") return null;

  return ReactDOM.createPortal(
    <div
      className="uk-modal-dialog uk-margin-auto-vertical"
      style={{ width: "80%", height: "100%" }}
    >
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <div className="uk-modal-header" style={{ height: "5%" }}>
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Orden No: {orderCode}
        </p>
      </div>
      <hr />
      <div
        className="uk-modal-body uk-flex"
        style={{ width: "100%", height: "90%" }}
      >
        <iframe
          src={url}
          style={{ width: "100%", height: "100%" }}
          title="Documento pdf"
        />
      </div>
    </div>,
    document.getElementById("webModal-container")
  );
};
export default DocumentPDFModal;
