
import React from "react";
import { SetStorage } from "helpers/functions";
import Items from "./Items";

export default function Pagination({
  setLimit,
  setPage,
  CurrentPage,
  total = 0,
  limit,
}) {
  const onChangeLimit = (e) => {
    let value = parseInt(e.target.value);
    SetStorage("limit", value);
    setLimit(value);
    setPage(1);

    window.scrollTo(0, 0);
  };

  const handleClickBack = (e) => {
    setPage(CurrentPage - 1);
    window.scrollTo(0, 0);
    e.preventDefault();
  };

  const handleClickNext = (e) => {
    setPage(CurrentPage + 1);
    e.preventDefault();
  };

  let lastPage = Math.floor(total / limit) + 1;

  return (
    <>
      <hr className="uk-margin-medium-top" />
      <div
        className="uk-grid uk-grid-small uk-flex-between uk-margin-medium-bottom"
        uk-grid="true"
      >
        <div>
          <div className="uk-text-small">
            <span className="uk-inline uk-margin-right uk-visible@s">
              Mostrar
            </span>
            <div className="uk-inline">
              <select
                onChange={onChangeLimit}
                value={limit}
                className="uk-select"
              >
                <option disabled>Mostrar</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="0">Todos</option>
              </select>
            </div>
            <span className="uk-inline uk-margin-left uk-visible@s">
              por página
            </span>
          </div>
        </div>
        <div>
          {limit !== 0 && (
            <ul className="uk-pagination">
              {CurrentPage !== 1 && (
                <li>
                  <a href="#0" onClick={handleClickBack}>
                    <span uk-pagination-previous="true"></span>
                  </a>
                </li>
              )}
              <Items
                currentPage={CurrentPage}
                setPage={setPage}
                total={total}
                limit={limit}
              />

              {CurrentPage !== lastPage && lastPage > 1 && (
                <li>
                  <a href="#0" onClick={handleClickNext}>
                    <span uk-pagination-next="true"></span>
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
