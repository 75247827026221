import React, { useContext, useEffect, useState } from "react";
import AuthAnonymous from "components/account/auth/anonymous";
import AccountContext from "context/Account";
import { getData, postData } from "helpers/service";
import alert from "helpers/alerts";
import { Encode, setTitle } from "helpers/functions";

import CheckoutList from "components/cart/checkout";
import BalanceCart from "components/cart/cart/balance";

import AddressesList from "components/cart/checkout/addresses";
import CreditcardsList from "components/cart/checkout/creditcarts";
import CreditcardAdd from "components/account/profile/creditcards/add";

import Loading from "components/share/loading/table";
import LoadingModal from "components/share/loading/modal";

import UIkit from "uikit";
import { useForm } from "react-hook-form";
import AddressAdd from "components/account/profile/addresses/add";
import { useParams } from "react-router-dom";

export default function QuotationPage() {
  let { orderkey } = useParams();

  const { isLogin } = useContext(AccountContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [loading, setLoading] = useState(true);
  const [baseImg, setBaseImg] = useState("");

  const [formPayment, setFormPayment] = useState({});

  const [d2items, setD2items] = useState([]);
  const [d2ShippingRate, setD2ShippingRate] = useState({});
  const [address, setAddress] = useState({});

  const [balance, setBalance] = useState({});

  const [open, setOpen] = useState("none");

  // const [locationslotId, setLocationslotId] = useState(null)
  // const [locationslot, setLocationslot] = useState("")

  setTitle("Pagar tu compra");

  useEffect(() => {
    if (isLogin) {
      getData(
        `checkout/${Encode(orderkey)}/extended-catalog`,
        null,
        (res, err) => {
          if (!err) {
            setBalance(res.balance);
            setBaseImg(res.base_img);
            setFormPayment(res.form_payment);

            /* DISTRIBUTION HD */
            setD2items(res.d2_items || []);
            setD2ShippingRate(res.d2_shipping_rate);
            setAddress(res.address);

            setLoading(false);
          } else {
            window.location.replace("/");
          }
        }
      );
    }
  }, []);

  const onSetClose = (action = `none`, modal = "webModal") => {
    setOpen(action);

    if (action === "none") {
      UIkit.modal(`#${modal}`).hide();
    }
  };

  // const onSetTimeSlot = (xlocationslotId, xlocationslot) => {
  //     setOpen('none')
  //     UIkit.modal(`#webModal`).hide();

  //     setLocationslotId(xlocationslotId);
  //     setLocationslot(xlocationslot)
  // }

  const onSetCreditCard = (reload = false, message = "", data = null) => {
    setOpen("none");

    if (data !== null) {
      let xyz = {
        ...formPayment,
        ccId: data.id,
        cc_exp: data.cc_exp,
        cc_mask: data.cc_mask,
        cc_type: data.cc_type,
        confirmed: data.confirmed,
      };

      setFormPayment(xyz);
    }
    UIkit.modal(`#webModal`).hide();
  };

  const onSetAddress = (reload = false, message = "", data = null) => {
    setOpen("none");

    if (data !== null) {
      setAddress(data);
    }

    UIkit.modal(`#webModal`).hide();
  };

  const onSubmit = (data) => {
    if (formPayment?.ccId === null) {
      alert.confirm(
        ` <p>Se requiere una tarjeta de crédito o débito registrada.</p>`,
        "warning"
      );
      return;
    }

    if (d2items.length > 0 && address === null) {
      alert.confirm(
        ` <p>Se requiere una una dirección de entrega</p>`,
        "warning"
      );
      return;
    }

    let submitOrder = {
      cc: {
        cvv: Encode(data["CVV"]),
        confirm: Encode(data["confirm_amount"]),
        id: formPayment?.ccId,
      },
      d2: {
        item: d2items.length > 0,
        shippingrateid: d2ShippingRate.shippingrateid,
        addressid: address.id,
        comment: data["d2_comment"],
      },
      quotationid: orderkey,
    };

    setOpen("loading");
    UIkit.modal(`#webModal`).show();

    postData(`checkout/extended-catalog`, submitOrder, {}, (res, err) => {
      if (!err) {
        window.location.replace("/order-confirmation/" + Encode(res.id));
      } else {
        setOpen("none");
        UIkit.modal(`#webModal`).hide();
        setLoading(false);
      }
    });
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const ConectedAccount = () => (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">Pagar tu compra</h2>
        </div>
        {loading ? <Loading /> : <Checkout />}
      </div>
    </div>
  );

  const Checkout = () => (
    <>
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <div className="uk-grid uk-grid-large item-list-wrap" uk-grid="true">
          <div className="uk-width-1-1 uk-width-2-3@m">
            <div
              className="uk-flex uk-flex-between uk-flex-middle uk-text-small uk-margin-medium-bottom nav-filters uk-grid-small"
              uk-grid="true"
            >
              <div>
                <span className="uk-inline">
                  <i className="fas fa-cart-plus"></i> {balance?.items}{" "}
                  artículos
                </span>
              </div>
            </div>

            {d2items.length > 0 && (
              <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
                <CheckoutList items={d2items} baseImg={baseImg} />
                <hr />

                <div
                  className="uk-child-width-1-1 uk-grid uk-grid-small"
                  uk-grid="true"
                >
                  <div className="item-list-block">
                    <div
                      className="uk-child-width-1-2@m uk-grid uk-grid-small"
                      uk-grid="true"
                    >
                      <div>
                        <p>
                          Entrega: <strong>Home Delivery</strong>
                        </p>
                        <p className="uk-text-bold">
                          Envío de 5 a 10 días laborables.
                        </p>

                        {address ? (
                          <>
                            <p className="uk-text-bold">
                              {address?.tag}
                              <br />
                              {address?.state} {address?.sector} <br />
                              {address?.address} {address?.address2}
                            </p>

                            <p>
                              <a
                                href="#"
                                onClick={() => setOpen("addresses")}
                                className="uk-button uk-button-default uk-button-small"
                                uk-toggle="target: #webModal"
                              >
                                <span>Cambiar Dirección de Entrega</span>
                                <i className="fas fa-map-marker-alt"></i>
                              </a>
                            </p>
                          </>
                        ) : (
                          <p>
                            <a
                              href="#"
                              onClick={() => setOpen("addresses")}
                              className="uk-button uk-button-default uk-button-small"
                              uk-toggle="target: #webModal"
                            >
                              <span>Asignar una Dirección de Entrega</span>
                              <i className="fas fa-map-marker-alt"></i>
                            </a>
                          </p>
                        )}
                      </div>
                      <div>
                        <textarea
                          name="d2_comment"
                          cols="30"
                          rows="3"
                          className="uk-textarea"
                          {...register("d2_comment")}
                          placeholder="Comentario (opcional)"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="uk-width-1-1 uk-width-1-3@m">
            <div
              className="item-list-footer"
              uk-sticky="offset: 130; bottom: true; media: @m"
            >
              <div className="item-list-block">
                <p>
                  Pagar: <strong>Tarjeta de Crédito</strong>{" "}
                  {formPayment?.ccId !== null && (
                    <>
                      (
                      <a
                        href="#"
                        className="uk-button uk-button-link"
                        onClick={() => setOpen("credit-cards")}
                        uk-toggle="target: #webModal"
                      >
                        cambiar
                      </a>
                      )
                    </>
                  )}
                </p>

                {formPayment?.ccId !== null ? (
                  <div>
                    <div className="uk-flex uk-grid-small">
                      <div className="uk-width-auto">
                        <img
                          src={`/assets/creditcart/${formPayment?.cc_type}.jpg`}
                          alt={formPayment?.cc_type}
                          style={{ maxHeight: 35 }}
                        />
                      </div>
                      <div className="uk-width-expand">
                        <strong>{formPayment?.cc_mask}</strong>
                        <br />
                        <small>Vence: {formPayment?.cc_exp}</small>
                      </div>
                      <div className="uk-width-auto">
                        <input
                          type="password"
                          name="CVV"
                          autoComplete="cc-csc"
                          maxLength="4"
                          className="uk-input uk-form-width-small"
                          placeholder="CVV"
                          {...register("CVV", { required: "CVV es requerido" })}
                        />
                        <p className="uk-width-1-1 error">
                          {errors?.CVV && errors?.CVV.message}
                        </p>
                      </div>
                    </div>
                    {formPayment?.confirmed === 0 ? (
                      <div className="uk-grid-small">
                        <div className="uk-width-1-1">
                          <br />
                          <p className="uk-h5">Confirmar tarjeta de crédito</p>
                        </div>
                        <div className="uk-width-1-1 uk-margin-remove">
                          <div className="uk-margin-small">
                            <label
                              className="uk-form-label"
                              htmlFor="confirm_amount"
                            >
                              Monto transacción{" "}
                              <span className="required">*</span>
                            </label>
                            <div className="uk-form-controls">
                              <input
                                className="uk-input uk-text-right"
                                name="confirm_amount"
                                {...register("confirm_amount", {
                                  required: "Monto transacción es requeridos",
                                })}
                                type="text"
                                placeholder="0.00"
                              />
                            </div>
                            <p className="error">
                              {errors?.confirm_amount &&
                                errors?.confirm_amount.message}
                            </p>
                          </div>
                        </div>
                        <div className="uk-width-1-1 uk-margin-remove">
                          Se realizó un cargo a su tarjeta de 1 a 20 pesos que
                          será su clave de validación. Consulte dicho monto en
                          su tarjeta. Este proceso solo es necesario al
                          registrar por primera vez la tarjeta y el monto será
                          reversado.
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div>
                    <p className="uk-text-bold">
                      No tienes una tarjeta de crédito o débito registrada.
                    </p>
                    <p>
                      <a
                        onClick={() => setOpen("CreditcardAdd")}
                        className="uk-button uk-button-default uk-button-small"
                        uk-toggle="target: #webModal"
                      >
                        <span>Registrar</span>
                        <i className="far fa-credit-card"></i>
                      </a>
                    </p>
                  </div>
                )}
              </div>

              <hr className="uk-margin-medium-top" />

              <div className="item-list-total">
                <BalanceCart balance={balance} />

                <div
                  className="uk-flex uk-flex-wrap uk-flex-center uk-grid-small item-list-btns"
                  uk-grid="true"
                >
                  <div className="uk-width-1-1 uk-text-center">
                    Al realizar tu pedido, aceptas los
                    <a href="/site/page/terminos-y-condiciones" target="_blank">
                      términos y condiciones
                    </a>{" "}
                    de Sirena.do
                  </div>
                  <div>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="uk-button uk-button-primary"
                    >
                      <span>Pagar compra</span>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <AddressesList
        isOpen={open}
        onSetClose={onSetClose}
        onSetAddress={onSetAddress}
      />
      <AddressAdd
        isOpen={open === "AddressAdd" ? "add" : "none"}
        setClose={onSetAddress}
      />

      <CreditcardsList
        isOpen={open}
        onSetClose={onSetClose}
        onSetCreditCard={onSetCreditCard}
      />
      <CreditcardAdd
        isOpen={open === "CreditcardAdd" ? "add" : "none"}
        setClose={onSetCreditCard}
      />

      <LoadingModal
        isOpen={open}
        message="Estamos procesando el pago de su pedido, por favor espere..."
      />
    </>
  );

  return isLogin ? <ConectedAccount /> : <AuthAnonymous source={"full"} />;
}
