/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DecimalFormat } from "helpers/functions";
import { useState } from "react";

const OrderItem = ({
  idx,
  item,
  SubmitModify,
  baseImg,
  onChangeNotReplace,
  opencomment,
  RemoveItem,
  restoreItem
}) => {
  const [qty, setQty] = useState(item?.quantity);
  const [notReplace, setNotReplace] = useState(
    parseInt(item?.not_replace) === 1
  );
  const [isRemoved, setIsRemoved] = useState(false);
  const handleSelectItems = (e, idx) => {
    let data = {
      action: "update",
      itemid: item.key_id,
      productid: item.product_key_id,
      not_replace: item.not_replace,
      quantity: e.target.value,
      comment: item.comment,
    };

    setQty(e.target.value);
    SubmitModify(idx, data);
  };

  const handleRemove = (idx, item) => {
    RemoveItem(idx, item);
    setIsRemoved(true);
  };

  const QuantityList = ({ item }) => {
    const list = [];

    for (let i = 0; i < parseFloat(item?.quantity) + 10; i++) {
      let total = parseFloat(item?.producttype_step) * parseFloat(i);
      let values = parseFloat(item?.minimum) + total;
      list.push(values);
    }

    return list.map((quantity, i) => (
      <option
        key={i}
        value={DecimalFormat(quantity, item?.producttype_decimal)}
      >
        {DecimalFormat(quantity, item?.producttype_decimal)}
      </option>
    ));
  };

  return (
    <li>
      <div
        className={`item-list gi_item_carrito ${
          isRemoved ? "gi_item_nodisponible" : ""
        }`}
      >
        <a
          href="#"
          className="item-list-image"
          style={{ backgroundImage: `url(${baseImg}${item?.thumbs})` }}
        ></a>

        <div className="item-list-info uk-width-expand">
          <p className="uk-h5 uk-text-uppercase">
            <a href="#">{item?.product}</a>
          </p>
          {isRemoved ? null : (
            <div className="uk-flex uk-flex-wrap uk-grid-small uk-child-width-1-1 uk-child-width-auto@s uk-text-small">
              <label>
                <input
                  onChange={(e) => {
                    setNotReplace(!notReplace);
                    onChangeNotReplace(idx, item, e);
                  }}
                  defaultChecked={notReplace}
                  className="uk-checkbox"
                  type="checkbox"
                />{" "}
                No sustituir
              </label>
              <label>
                <input
                  onChange={() => opencomment(idx)}
                  checked={item?.comment?.length}
                  className="uk-checkbox"
                  type="checkbox"
                />{" "}
                Comentario
              </label>
            </div>
          )}
        </div>

        {isRemoved ? (
          <div className="item-list-btns uk-width-auto uk-flex-last@s">
            <div style={{ padding: "5px" }}>
              <button
                onClick={() => restoreItem(idx, item, setIsRemoved)}
                className="uk-button uk-button-primary btn-add-cart"
                type="button"
              >
                <span>Volver a agregar</span>{" "}
                <i className="fas fa-shopping-cart"></i>
              </button>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div
              className="item-list-btns uk-width-auto uk-flex-last@s"
              style={{ padding: "5px" }}
            >
              <button
                onClick={() => handleRemove(idx, item)}
                className="uk-button gi_trash_icon"
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </div>
            <div
              style={{ minWidth: "110px" }}
              className="item-list-detail uk-width-1-1 uk-width-auto@s uk-text-right"
            >
              <p className="uk-text-bold">${DecimalFormat(item?.price)}</p>
              <select
                value={DecimalFormat(qty, item?.producttype_decimal)}
                onChange={(e) => handleSelectItems(e, idx)}
                className="uk-select uk-text-small"
              >
                <QuantityList item={item} />
              </select>
            </div>
          </>
        )}
      </div>
    </li>
  );
};

export default OrderItem;
