import React from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const CreditCardsList = ({creditCardItems, setByDefault, onOpen}) => {
    
  if (creditCardItems.length === 0)
    return (
      <div className="uk-width-1-1">
        <img
          src="/assets/background/credit-card-empty.svg"
          style={{ height: "350px", textAlign: "center", marginBottom: "15px" }}
          alt="Lista de tarjetas de crédito vacio"
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">
            No has tenido un registro de tarjeta de crédito.
          </h4>
          <p className="uk-text-center">
            Esta herramienta te permitirá visualizar todas tus tarjetas
            registradas.
          </p>
        </div>
      </div>
    );

  return creditCardItems.map((item, idx) => (
    <div key={idx}>
      <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small card-block">
        <p className="uk-h5" style={{ minHeight: "22px" }}>
          {item.confirmed === 0 && (
            <span className="uk-badge uk-badge-danger">Sin Confirmar</span>
          )}
          {item.by_default === 1 && (
            <span className="uk-badge">Predeterminado</span>
          )}
        </p>
        <div className="uk-flex uk-grid-small">
          <Cards
            cvc="000"
            expiry={item.cc_exp}
            name={item.cc_name}
            number={item.cc_mask}
            locale={{ valid: "vencimiento" }}
          />
        </div>
        <hr />
        <div className="uk-flex uk-flex-wrap uk-flex-between uk-grid-small">
          <div>
            <button
              type="button"
              onClick={() => onOpen("delete", item)}
              className="uk-button uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Borrar</span> <i className="far fa-trash-alt"></i>
            </button>
          </div>
          <div>
            {item.confirmed === 0 && (
              <button
                type="button"
                onClick={() => onOpen("confirm", item)}
                className="uk-button uk-button-danger uk-button-small"
                uk-toggle="target: #webModal"
              >
                <span>Confirmar</span> <i className="fas fa-check"></i>
              </button>
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={() => onOpen("edit", item)}
              className="uk-button uk-button-default uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Editar</span> <i className="fas fa-edit"></i>
            </button>
          </div>
        </div>
        <hr />
        {item.by_default === 0 && item.confirmed === 1 && (
          <button
            type="button"
            onClick={() => setByDefault(item.id)}
            className="uk-button uk-button-text uk-button-small"
          >
            <span>Establecer por defecto</span>
            <i className="far fa-star"></i>
          </button>
        )}
      </div>
    </div>
  ));
}
export default CreditCardsList;