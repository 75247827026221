/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function AddressesList({ addressesItems, setByDefault, onOpen }) {

  if (addressesItems?.length === 0)
    return (
      <div className="uk-width-1-1">
        <img
          src="/assets/background/address-empty.svg"
          style={{ height: "350px", textAlign: "center" }}
          alt="sin direcciones de entrega"
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">
            No has tenido un registro de direcciones de entrega.
          </h4>
          <p className="uk-text-center">
            Esta herramienta te permitirá visualizar todas tus direcciones
            registradas.
          </p>
        </div>
      </div>
    );

  return addressesItems.map((item) => (
    <div key={item.id}>
      <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small card-block">
        <p className="uk-h5">
          {item.tag}{" "}
          {item.by_default === 1 && (
            <span className="uk-badge">Predeterminado</span>
          )}
        </p>
        <p>
          {item.address}, {item.address2}
          <br />
          {item.localidad}
        </p>
        <p className="uk-hidden">
          <a href="#" className="uk-button uk-button-text uk-button-small">
            <span>Ver tu ubicación</span> <i className="fas fa-map-pin"></i>
          </a>
        </p>
        <hr />
        <div className="uk-flex uk-flex-wrap uk-flex-between uk-grid-small">
          <div>
            <button
              type="button"
              onClick={() => onOpen("delete", item)}
              className="uk-button uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Borrar</span> <i className="far fa-trash-alt"></i>
            </button>
          </div>

          <div>
            <button
              type="button"
              onClick={() => onOpen("edit", item)}
              className="uk-button uk-button-default uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Editar</span> <i className="fas fa-edit"></i>
            </button>
          </div>
        </div>
        <hr />
        <div>
          {item.by_default === 0 && (
            <button
              type="button"
              onClick={() => setByDefault(item.id)}
              className="uk-button uk-button-text uk-button-small"
            >
              <span>Establecer por defecto</span>
              <i className="far fa-star"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  ));
}
