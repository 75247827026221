import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "components/share/error/404";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";
import { Encode } from "helpers/functions";
import AccountContext from "context/Account";

export default function RecipePage() {
  let { friendlyurl } = useParams();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const { setSeoData } = useContext(AccountContext);

  useEffect(() => {
    getData(`setting/site/recipe/${Encode(friendlyurl)}`, null, (res, err) => {
      if (!err) {
        if (Boolean(res.found)) {
          setContent(res.data?.content);
          setSeoData({
            metaTitle: res.data?.meta_title,
            metaKeywords: res.data?.meta_keywords,
            metaDescription: res.data?.meta_description,
          });
        }
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendlyurl]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="uk-section uk-section-small">
          {content?.length === 0 ? (
            <PageNotFound />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="uk-container"
            />
          )}
        </section>
      )}
    </>
  );
}
