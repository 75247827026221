import React, { useContext, useEffect, useReducer, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { DecimalFormat, Encode, MoneyFormat, addSearchToMetadata } from "helpers/functions";
import { postData } from "helpers/service";
import AccountContext from "context/Account";
import UIkit from "uikit";
import DistributionAlertModal from "../distribution";
import ProductAddMore from "components/product/item/modal";
import { useSearchHistory } from "hooks/useSearchHistory";
import { CURRENT, FAVORITE, QUANTITY, LOADING_ITEM, TIMEOUT_ID, DISTRIBUTION, OPEN} from 'store/product/item/actionTypes';
import { updateStateReducer } from "store/common/commonReducer";

export default function ProductItem({ item, baseImg, metadata, isSearching=false }) {
  let location = useLocation();

  const isFavorite = item.favorite === 1 ? true : false;
  const quantityInCart = parseFloat(item.in_cart);

  const initialState = {
    current: item,
    favorite: isFavorite,
    quantity: quantityInCart,
    loadingItem: false,
    timeoutId: 0,
    distribution: {},
    open:'none'
  }

  const [
    {
      current, 
      favorite, 
      quantity, 
      loadingItem, 
      timeoutId, 
      distribution, 
      open 
    }, dispatch ] = useReducer(updateStateReducer, initialState);

  const { loadShoppingCart, loadShoppingCartItems } =
    useContext(AccountContext);

  const { handleSaveSearchHistory } = useSearchHistory();

  const OpenModal = (action) => {
    dispatch({ type: OPEN, payload: action });
  };

  const onSetClose = useCallback(() => {
    dispatch({ type: OPEN, payload: 'none' });
    UIkit.modal("#webModal")?.hide();
  }, []);

  const handleSelectItems = (e) => {
    if (e.target.value === "+") {
      OpenModal("more");
      return;
    }
    dispatch({ type: QUANTITY, payload: e.target.value });
  };

  const addToCart = () => {
    let data = {
      id: Encode(current?.productid),
      qty: quantity,
    };
    dispatch({type: LOADING_ITEM, payload:true});
    postData(
      "cart/add",
      data,
      { in_cart: location.pathname === "/cart" ? "D" : "R" },
      (res, err) => {
        if (!err) {
          let values = current;

          values["cartid"] = res.item?.cartid;
          values["in_cart"] = res.item?.in_cart;

          dispatch({type: QUANTITY, payload: res.item.in_cart });
          dispatch({type: CURRENT, payload: values});
          dispatch({ type: DISTRIBUTION, payload: res.distribution });
          
          loadShoppingCart(res.balance);

          if (location.pathname === "/cart") {
            loadShoppingCartItems(res.cart || []);
          }
          if(isSearching){
            //Historial
            handleSaveSearchHistory(current?.name);        
            //Texto predictivo
            addSearchToMetadata(metadata, current?.productid);
          }     
        }
        dispatch({type: LOADING_ITEM, payload:false})
      }
    );
  };

  const updateToCart = (qty) => {
    let data = {
      key: Encode(current?.cartid),
      qty: qty,
    };

    dispatch({type:LOADING_ITEM, payload:true})
    postData(
      `cart/${Encode(current?.cartid)}/update`,
      data,
      { in_cart: location.pathname === "/cart" ? "D" : "R" },
      (res, err) => {
        if (!err) {
          let values = current;
          values["in_cart"] = res.item.in_cart;
          dispatch({type: QUANTITY, payload: res.item.in_cart });
          dispatch({type: CURRENT, payload: values});
          loadShoppingCart(res.balance);

          if (location.pathname === "/cart") {
            loadShoppingCartItems(res.cart || []);
          }
        }
        dispatch({type:LOADING_ITEM, payload:false})
      }
    );
  };

  const ChangeFav = () => {
    let fav = !favorite;
    let data = {
      id: Encode(current?.productid),
      favorite: fav,
    };

    postData(`account/favorite`, data, {}, (res, err) => {
      if (!err) {
        dispatch({type: FAVORITE, payload:fav})
      }
    });
  };

  const upQuantity = () => {
    let qty_new = parseFloat(quantity) + parseFloat(current?.producttype_step);
    dispatch({type: QUANTITY, payload:qty_new})
    ReloadTimeslot(qty_new);
  };

  const DownQuantity = () => {
    let qty_new = parseFloat(quantity) - parseFloat(current?.producttype_step);
    dispatch({type: QUANTITY, payload:qty_new})
    ReloadTimeslot(qty_new);
  };

  const ReloadTimeslot = (qty) => {
    if (timeoutId > 0) {
      clearTimeout(timeoutId);
    }

    let current = setTimeout(function () {
      updateToCart(qty);
    }, 600);

    dispatch({type:TIMEOUT_ID, payload:current})
  };

  const QuantityList = () => {
    const x = [];
    for (let i = 0; i < 6; i++) {
      let total = parseFloat(current?.producttype_step) * parseFloat(i);
      let values = DecimalFormat(
        parseFloat(current?.minimum) + total,
        current?.producttype_decimal
      );

      x.push(values);
    }

    return x.map((quantity, i) => <option key={i}>{quantity}</option>);
  };

  useEffect(() => {
    if (Object.entries(distribution).length > 0) {
      const { notify } = distribution;
      if (notify === 1) OpenModal("delivery");
    }
  }, [distribution]);

  useEffect(() => {
    if (open === "more") UIkit.modal("#webModal")?.show();
    if (open === "delivery") UIkit.modal("#webModal")?.show();
  }, [open]);

  const ButtonFavorite = (
    <button
      id="favorite"
      data-id={!favorite ? item.productid : ""}
      type="button"
      className="btn-favorite favorite"
      onClick={ChangeFav}
      uk-tooltip={favorite ? "Borrar de Favoritos" : "Agregar a Favoritos"}
    >
      <i
        data-id={!favorite ? item.productid : ""}
        className={favorite ? "favorite fas fa-heart" : "favorite far fa-heart"}
      ></i>
    </button>
  );

  if (current === null) return "";

  return (
    <>
      <div
        className={`item-product uk-animation-fade
        ${current?.available_order === 0 ? "item-unavailable" : ""} 
        ${current?.visible === 0 ? "item-not-in-location" : ""} 
        ${current?.cartid !== null &&
          current?.available_order === 1 &&
          current?.visible === 1
            ? "item-in-cart"
            : ""
        } ${current?.especially === 1 ? "item-offer" : ""}`}
        id={current?.productid}
      >
        {ButtonFavorite}

        <Link
          to={`/products/index/${current?.friendlyurl}`}
          onClick={() => {
            if(isSearching) {
              //Historial
              handleSaveSearchHistory(current?.name); 
              //Texto predictivo
              addSearchToMetadata(metadata, current?.productid);
            }
          }}
          className="item-product-image"
          style={{ backgroundImage: `url(${baseImg}${current?.thumbs})` }}
        >
          {}
        </Link>

        <div className="item-product-info">
          <p className="item-product-title">
            <Link
              to={`/products/index/${current?.friendlyurl}`}
              onClick={() => {
                if(isSearching) {
                  //Historial
                  handleSaveSearchHistory(current?.name); 
                  //Texto predictivo
                  addSearchToMetadata(metadata, current?.productid);
                }
              }}>
              {current?.name}
            </Link>
          </p>
          <p className="item-product-price">
            {current?.especially === 1 && (
              <small>{MoneyFormat(current?.regular_price)}</small>
            )}
            <strong>{MoneyFormat(current?.price)}</strong>
          </p>
          <p className="item-product-discount">
            <strong>{current?.Discount}%</strong>
            <small>de descuento</small>
          </p>
          <p className="item-product-special">
            <strong>{MoneyFormat(current?.regular_price)}</strong>
          </p>
          <p className="item-product-cat">
            <a href="#0">
              <i className="fas fa-tag uk-margin-small-right"></i>
              {current?.category}
              <span></span>
            </a>
          </p>
          <p className="item-product-date">
            Válido hasta el ${current?.validDate}
          </p>
        </div>

        <div className="item-product-add">
          <div className="item-product-quantity">
            <div className="item-product-quantity-select">
              <select
                value={quantity}
                disabled={loadingItem || current?.available_order === 0}
                onChange={handleSelectItems}
                className="uk-select"
              >
                <QuantityList />
                <option value="+">Más</option>
              </select>
            </div>

            <div className="item-product-quantity-buttons">
              <button
                id="btn-update-cart-minus"
                data-id={current?.productid}
                data-price={current?.price}
                data-quantity={current?.producttype_step}
                data-currency="DOP"
                data-coupon={current?.coupon}
                data-discount={current?.discount}
                data-item-brand={current?.item_brand}
                data-item-category={current?.item_category}
                data-item-category2={current?.item_category2}
                data-item-category3={current?.item_category3}
                data-variant={current?.item_variant}
                disabled={
                  quantity <= parseFloat(current?.minimum) ? true : false
                }
                onClick={DownQuantity}
                className="uk-button uk-button-primary btn-minus btn-update-cart-minus"
                type="button"
              >
                <i className="fas fa-minus"></i>
              </button>
              <span id="current-quantity">
                {DecimalFormat(quantity, current?.producttype_decimal)}
              </span>
              <button
                id="btn-update-cart-plus"
                data-id={current?.productid}
                data-price={current?.price}
                data-quantity={current?.producttype_step}
                data-currency="DOP"
                data-coupon={current?.coupon}
                data-discount={current?.discount}
                data-item-brand={current?.item_brand}
                data-item-category={current?.item_category}
                data-item-category2={current?.item_category2}
                data-item-category3={current?.item_category3}
                data-variant={current?.item_variant}
                onClick={upQuantity}
                className="uk-button uk-button-primary btn-plus btn-update-cart-plus"
                type="button"
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
          </div>

          <div className="item-product-btn">
            <button
              id="btn-add-to-cart"
              data-test="btn-add-to-cart"
              data-id={current?.productid}
              data-price={current?.price}
              data-quantity={current?.minimum}
              data-currency="DOP"
              data-coupon={current?.coupon}
              data-discount={current?.discount}
              data-item-brand={current?.item_brand}
              data-item-category={current?.item_category}
              data-item-category2={current?.item_category2}
              data-item-category3={current?.item_category3}
              data-variant={current?.item_variant}
              onClick={addToCart}
              disabled={loadingItem || current?.available_order === 0}
              className="uk-button uk-button-primary btn-add-cart btn-add-to-cart"
              type="button"
            >
              <i
                id="btn-add-to-cart"
                data-id={current?.productid}
                data-price={current?.price}
                data-quantity={current?.minimum}
                data-currency="DOP"
                className="fas fa-shopping-cart btn-add-to-cart"
              ></i>
            </button>
          </div>
        </div>

        <div className="item-product-store">
          <p>
            <i className="fas fa-info-circle"></i>{" "}
            <small>Solo disponible en tiendas.</small>
          </p>
        </div>
        {current?.visible === 0 && (
          <div className="item-product-location">
            <p>
              <i className="fas fa-info-circle"></i>{" "}
              <small>No disponible en esta localidad.</small>
            </p>
          </div>
        )}
      </div>
      <DistributionAlertModal
        isOpen={open}
        onSetClose={onSetClose}
        distribution={distribution}
      />
      <ProductAddMore isOpen={open} item={item} onSetClose={onSetClose} />
    </>
  );
}
