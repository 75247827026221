import React from "react";
import CheckoutItem from "./item";

export default function CheckoutList({ items, baseImg }) {
  return (
    <>
      {items.map((item) => (
        <CheckoutItem key={item.cartid} item={item} baseImg={baseImg} />
      ))}
    </>
  );
}
