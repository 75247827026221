import React from "react";
import { useForm } from "react-hook-form";
import { BannerStepUser1Model } from "models/adsense";
import Advertisements from "components/share/adsense";
import { GetTypeDevice } from "helpers/functions";

export default function RegisterAccountStep1(props) {
  const { handleDataChange } = props;
  const currentType = GetTypeDevice();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    handleDataChange(data);
    props.nextStep();
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
        <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
          <progress
            className="uk-progress uk-position-top"
            value="25"
            max="100"
          ></progress>
          <p className="uk-text-right uk-text-small uk-margin-remove-top">
            Paso 1 de 4
          </p>

          <div className="title-border">
            <p className="uk-h4">Datos de acceso:</p>
          </div>
          <div className="uk-margin-small">
            <label className="uk-form-label" htmlFor="account">
              Correo electrónico <span className="required">*</span>
            </label>
            <div className="uk-form-controls">
              <input
                id="account"
                name="account"
                className="uk-input"
                type="email"
                placeholder="ej. mi@correo.com"
                {...register("account", {
                  required: "El correo electrónico es requerido",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "La dirección de correo electrónico no es válida.",
                  },
                
                })}
                maxLength={128}
              />
              <p className="error">
                {errors?.account && errors?.account.message}
              </p>
            </div>
          </div>
          <div className="uk-margin-small">
            <label className="uk-form-label" htmlFor="password">
              Contraseña <span className="required">*</span>
            </label>
            <div className="uk-form-controls">
              <input
                id="password"
                name="password"
                className="uk-input"
                type="password"
                placeholder="Contraseña"
                {...register("password", {
                  required: "La contraseña es requerida",
                  minLength: {
                    value: 8,
                    message: "La contraseña debe tener al menos 8 caracteres",
                  },
                })}
                maxLength={255}
              />
              <p className="error">
                {errors?.password && errors?.password.message}
              </p>
            </div>
          </div>
          <div className="uk-margin-small">
            <label className="uk-form-label" htmlFor="repassword">
              Confirmar contraseña <span className="required">*</span>
            </label>
            <div className="uk-form-controls">
              <input
                id="repassword"
                name="repassword"
                className="uk-input"
                type="password"
                placeholder="Confirmar contraseña"
                {...register("repassword", {
                  required: "Confirmar la contraseña es requerido",
                  validate: {
                    comparepwd: (value) =>
                      value === getValues("password") ||
                      "Las contraseñas son diferentes",
                  },
                })}
                maxLength={255}
              />
              <p className="error">
                {errors?.repassword && errors?.repassword.message}
              </p>
            </div>
          </div>
          <hr />
          <div className="uk-margin uk-text-right">
            <button
              id="btn-next-step-1"
              type="button"
              className="uk-button uk-button-primary"
              onClick={handleSubmit(onSubmit)}
            >
              Siguiente
            </button>
          </div>
        </form>
      </div>
      <div className="uk-section uk-section-small uk-flex uk-flex-center banner_desktop uk-hidden">
        {BannerStepUser1Model.filter((item) => item.type === currentType).map(
          (item) => (
            <Advertisements
              key={item.slotId}
              className={item.className}
              type={item.type}
              slotId={item.slotId}
              sizes={item.sizes}
              adUnit={item.adUnit}
              targetingArguments={{
                Seccion: "Formulario de registro paso 1",
              }}
            />
          )
        )}
      </div>
    </>
  );
}
