import React, { useState } from "react";
import UIkit from "uikit";
import { Link } from "react-router-dom";
import BuyAgain from "./BuyAgain";
import ProductRequestModal from "components/product/request";
import { setTitle } from "helpers/functions";
import Favorites from "./Favorites";

const ConnectedAccount = () => {
  setTitle("Mis Favoritos");
  const [openR, setOpenR] = useState(false);

  const openRequest = () => {
    setOpenR(true);
    UIkit.modal(`#webModal`).show();
  };

  const closeRequest = () => {
    setOpenR(false);
    UIkit.modal(`#webModal`).hide();
  };

  return (
    <>
      <div className="uk-padding">
        <div className="title-border uk-flex uk-flex-between uk-flex-bottom">
          <h2 className="uk-h3">Mis Favoritos</h2>
          <div className="uk-hidden@m">
            <a
              href="#0"
              className="uk-button uk-button-default uk-button-custom"
              uk-toggle="target: modal-filters"
            >
              <i className="fas fa-filter"></i>
            </a>
          </div>
        </div>

        <div
          className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters"
          uk-grid="true"
        >
          <div>
            <a
              href="#0"
              onClick={(e) => {
                openRequest();
                e.preventDefault();
              }}
              className="uk-button uk-button-primary"
            >
              <span>Solicitar producto</span>
              <i className="fas fa-plus"></i>
            </a>
          </div>
          <div>
            <Link
              to="/account/order-history"
              className="uk-button uk-button-default uk-button-custom"
            >
              <span>Repetir compra</span>
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
        <BuyAgain />
        <Favorites />
      </div>
      <ProductRequestModal isOpen={openR} onSetClose={closeRequest} />
    </>
  );
};
export default ConnectedAccount;
