
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "helpers/service";
import { Encode } from "helpers/functions";
import ReactDOM from "react-dom";
import Loading from "components/share/loading";
import InputMask from "react-input-mask";
import { isBlank } from "helpers/functions";

export default function ContactsAdd({ isOpen, setClose }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      tag: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      document: "",
    },
  
  });

  const [documentType, setDocumentType] = useState([]);
  const [mask, setMask] = useState("");
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (isOpen === "add") {
      getData(
        `setting/account/type-document`,
        {
          search: Encode(""),
          cf: Encode("02"),
        },
        (res, err) => {
          if (!err) {
            setDocumentType(res.data);
          }
        }
      );
      reset();
    }
  }, [isOpen]);

  const onSubmit = (data) => { 
    setLoading(true);
    data["by_default"] = checked ? 1 : 0;
    postData("account/contact", data, {}, (res, err) => {
      if (!err) {
        setClose(true, res.message);
      } else {
        if (!isBlank(res.message)) alert.toast(res.message);
      }
    });
    setLoading(false);
  };


  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const onChangeDocumentType = (e) => {
    const xDocumentType = documentType.find(
      (element) => element.value === e.target.value
    );
    setMask(xDocumentType.mask);
  };

  if (isOpen !== "add") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        onClick={()=>reset()}
        uk-close="true"
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Agregar Contacto
        </p>
      </div>
      <div className="uk-modal-body">
        <div className="uk-width-1-1">
          {loading ? (
            <Loading />
          ) : (
             
            <form
              className="uk-form-horizontal uk-margin-remove"
              onSubmit={mySubmitHandler}
            >
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-tag">
                    Alias <span className="required">*</span>
                  </label>
                  <input
                    className="uk-input"
                    name="tag"
                    {...register("tag", {
                      required: "Alias(s) son requeridos",
                    })}
                    type="text"
                    maxLength={128}
                    placeholder="Cómo desea guardar el contacto?"
                  />
                  <p className="error">{errors?.tag && errors?.tag.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-first_name">
                    Nombre(s) <span className="required">*</span>
                  </label>

                  <input
                    className="uk-input"
                    name="first_name"
                    {...register("first_name", {
                      required: "Nombre(s) son requeridos",
                    })}
                    maxLength={128}
                    type="text"
                    placeholder="Nombre(s)"
                  />
                  <p className="error">
                    {errors?.first_name && errors?.first_name.message}
                  </p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-form-apellido">
                    Apellido(s) <span className="required">*</span>
                  </label>

                  <input
                    className="uk-input"
                    name="last_name"
                    {...register("last_name", {
                      required: "Apellido(s) son requeridos",
                    })}
                    maxLength={128}
                    type="text"
                    placeholder="Apellido(s)"
                  />
                  <p className="error">
                    {errors?.last_name && errors.last_name.message}
                  </p>
                </div>

                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-phone">
                    Teléfono <span className="required">*</span>
                  </label>

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        name="phone"
                        alwaysShowMask
                        mask="(999) 999-9999"
                        {...field}
                        className='uk-input'
                      />
                    )}
                      rules={{
                        required: "Número de telefono es requerido",
                        maxLength: 20,
                        pattern: { value: /(809|829|849)/, message:'El número de telefono debe ser local.'},
                    }}
                  />
                  <p className="error">
                    {errors?.phone && errors?.phone.message}
                  </p>
                </div>

                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-email">
                    Correo electrónico{" "}
                  </label>

                  <input
                    className="uk-input"
                    name="email"
                    {...register("email")}
                    maxLength={128}
                    type="text"
                    placeholder="Correo electronico"
                  />
                  <p className="error">
                    {errors.email && errors.email.message}
                  </p>
                </div>

                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="add-document">
                    Documento de identidad <span className="required">*</span>
                  </label>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <div className="uk-child-width-1-2 uk-grid">
                    <label>
                      <select
                        className="uk-select"
                        name="document_type_id"
                        {...register("document_type_id")}
                        onChange={onChangeDocumentType}
                      >
                        {documentType.map((item, idx) => (
                          <option
                            key={idx}
                            value={item.value}
                          >{`${item.label}`}</option>
                        ))}
                      </select>
                      <p className="error">
                        {errors?.document_type_id &&
                          errors?.document_type_id?.message}
                      </p>

                    </label>
                    <label>
                      <Controller
                        name="document"
                        control={control}
                        rules={{ 
                          required: "El No. documento es requerido",
                          
                       }}
                        render={({ field }) => (
                          <InputMask
                          maxLength={15}
                            name="document"
                            alwaysShowMask
                            mask={mask}
                            {...field}
                            className="uk-input"
                          />
                        )}
                      />
                    </label>
                    <p className="error">
                      {errors.document_type_id &&
                        errors.document_type_id.message}
                    </p>
                    <p className="error">
                      {errors.document && errors.document.message}
                    </p>
                  </div>
                </div>

                <div className="uk-width-1-1 uk-margin-remove">
                  <label>
                    <input
                      name="by_default"
                      checked={checked}
                      onChange={(e) => {
                        setChecked(!checked);
                      }}
                      className="uk-checkbox"
                      type="checkbox"
                    />
                    Hacer de este mi contacto predeterminada
                  </label>
                </div>
              </div>
              </form>
          )}
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button  uk-button-custom"
          type="button"
          disabled={loading}
          onClick={setClose}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
     
    </div>,
    document.getElementById("webModal")
  );
}
