import React from "react";

const UnAvailable = ({ item }) => {
  const MESSAGE = Object.freeze({
    available_order: {
      0: "No disponible para esta selección.",
      1: "",
    },
    visible: {
      0: "Este producto esta agotado.",
      1: "",
    },
  });

  return (
    <span className="gi_aviso_txt">
      {`${MESSAGE["available_order"][item?.available_order]} ${
        MESSAGE["visible"][item?.visible]
      }`}
    </span>
  );
};

export default UnAvailable;
