const ProfileMenu = [
  {
    name: "Mi Cuenta",
    url: "/account",
    icon: "fas fa-info-circle",
  },
  {
    name: "Mis Órdenes",
    url: "/account/order-history",
    icon: "fas fa-receipt",
  },
  {
    name: "Mis Listas",
    url: "/account/shopping-lists",
    icon: "far fa-file-alt",
  },
  {
    name: "Mis Favoritos",
    url: "/account/favorites",
    icon: "fas fa-heart",
  },
  {
    name: "Mis Direcciones",
    url: "/account/addresses",
    icon: "fas fa-map-marker-alt",
  },
  {
    name: "Mis Contactos",
    url: "/account/contacts",
    icon: "fas fa-users",
  },
  {
    name: "Mis Tarjetas",
    url: "/account/creditcards",
    icon: "far fa-credit-card",
  },
  {
    name: "Cambiar contraseña",
    url: "/account/password",
    icon: "fas fa-key",
  },
];
const monthOptions = [
  {
    value: "00",
    label: "Mes",
  },
  {
    value: "01",
    label: "01",
  },
  {
    value: "02",
    label: "02",
  },
  {
    value: "03",
    label: "03",
  },
  {
    value: "04",
    label: "04",
  },
  {
    value: "05",
    label: "05",
  },
  {
    value: "06",
    label: "06",
  },
  {
    value: "07",
    label: "07",
  },
  {
    value: "08",
    label: "08",
  },
  {
    value: "09",
    label: "09",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
];
 const monthWithNameOptions = [
  {
      value: "00",
      label: "Mes",
    },
    {
      value: "01",
      label: "Ene",
    },
    {
      value: "02",
      label: "Feb",
    },
    {
      value: "03",
      label: "Mar",
    },
    {
      value: "04",
      label: "Abr",
    },
    {
      value: "05",
      label: "May",
    },
    {
      value: "06",
      label: "Jun",
    },
    {
      value: "07",
      label: "Jul",
    },
    {
      value: "08",
      label: "Ago",
    },
    {
      value: "09",
      label: "Sep",
    },
    {
      value: "10",
      label: "Oct",
    },
    {
      value: "11",
      label: "Nov",
    },
    {
      value: "12",
      label: "Dic",
    },
] 

const daysOptions = [
  {
    value: "00",
    label: "Día",
  },
  {
    value: "01",
    label: "01",
  },
  {
    value: "02",
    label: "02",
  },
  {
    value: "03",
    label: "03",
  },
  {
    value: "04",
    label: "04",
  },
  {
    value: "05",
    label: "05",
  },
  {
    value: "06",
    label: "06",
  },
  {
    value: "07",
    label: "07",
  },
  {
    value: "08",
    label: "08",
  },
  {
    value: "09",
    label: "09",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16",
  },
  {
    value: "17",
    label: "17",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "21",
    label: "21",
  },
  {
    value: "22",
    label: "22",
  },
  {
    value: "23",
    label: "23",
  },
  {
    value: "24",
    label: "24",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "26",
    label: "26",
  },
  {
    value: "27",
    label: "27",
  },
  {
    value: "28",
    label: "28",
  },
  {
    value: "29",
    label: "29",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "31",
    label: "31",
  },
];
// años opciones
let date = new Date();
let year = date.getFullYear();
let yearOptions = [];

for (let i = year; i < year + 10; i++) {
  yearOptions.push({ value: i, label: i });
}

export { ProfileMenu, monthOptions,monthWithNameOptions, daysOptions, yearOptions };
