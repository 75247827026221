export const TOTAL = 'total';
export const CURRENT_ORDER ='currentOrder';
export const CURRENT_TOTAL = 'currentTotal';
export const ORDERS ='orders';
export const LOADING = 'loading';
export const OPEN ='open';
export const ORDER_GROUP ='orderGroup';
export const PAGE ='page';
export const LIMIT = 'limit';
export const MESSAGE = 'message';
export const PDF_URL = 'pdfUrl'

