import React from "react";

export default function EmptyCart() {
  return (
    <div className="uk-width-1-1 uk-text-center">
      <img
        src="/assets/background/shopping-cart-empty.svg"
        style={{ height: "350px", textAlign: "center" }}
        alt="carrito vacío"
      />
      <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
        <h4 className="uk-text-center">El carrito está vacío.</h4>
        <p className="uk-text-center">
          Para escoger con más facilidad puedes visitar la lista de tus
          productos favoritos.
        </p>
      </div>
    </div>
  );
}
