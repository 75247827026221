import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import SearchBar from "../searchbar";
import MainBar from "../mainbar";
import MenuBar from "../menubar";
import SideBar from "../sidebar";
import CartBar from "../cartbar";


export default function Header() {
  const location = useLocation();
  const [open, setOpen] = useState("none");

  const [hideSearch, setHideSearch] = useState(false);

  const OpenModal = (option) => {
    setOpen(option);
  };

  const CloseModal = () => {
    setOpen("none");
  };

  useEffect(() => {
   
    if (
      location.pathname.startsWith("/ce/cart/") ||
      location.pathname.startsWith("/checkout") ||
      location.pathname.startsWith("/cart")
    ) {
      setHideSearch(true);
    }
    return () => setHideSearch(false);
  }, [location]);

  return (
    <header id="main-header">
      <div className="uk-container uk-container-expand top-main">
        <nav
          className="uk-navbar-container uk-navbar-transparent uk-navbar"
          uk-navbar="mode: click; boundary-align: true; align: right;"
        >
          <div className="uk-navbar-left uk-width-expand">
            <a
              href="#0"
              className="uk-navbar-item"
              uk-icon="icon: menu; ratio: 1.5"
              uk-toggle="target: #menu-main"
            ></a>

            <Link to="/" className="logo logo-main">
              <img src="/assets/logo/logo.svg?v=20221013" alt="Sirena" />
              <img src="/assets/logo/isotipo.svg?v=20221013" alt="Sirena" />
            </Link>
            {!hideSearch && <SearchBar OpenModal={OpenModal} />}
          </div>
          <MainBar HideSearch={hideSearch} OpenModal={OpenModal} />
        </nav>
      </div>
      <MenuBar /> {/* Blue Bar */}
      {/* Modals */}
      <SideBar />
      <CartBar isOpen={open} closeModal={CloseModal} />
    </header>
  );
}
