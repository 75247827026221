import React, {useContext} from "react";
import StepWizard from "react-step-wizard";


import DigitalPointsStepCart from "components/digital-points/cart";
import DigitalPointsStepCheckout from "components/digital-points/checkout";
import AccountContext from "context/Account";
import { Logout, setTitle} from "helpers/functions";
import UIkit from "uikit";

import YoutubeEmbed from "components/share/youtube"

export default function DigitalPointsPage() {

    const {isLogin} = useContext(AccountContext)


    setTitle('Sirena Cash')


    const ContentPage = (propx) => {
        return (<>

            <div id="SirenaCash" className="uk-section">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-top uk-grid uk-grid-large" uk-grid="true">

                        <div className="uk-width-1-4@m uk-flex uk-flex-wrap uk-flex-center">
                            <img src="/assets/sirena-cash/logo_sirena-cash.svg" alt="Sirena Cash"
                                 className="logo-sirena-cash"/>
                        </div>

                        <div className="uk-width-3-4@m uk-flex-first@m">
                            <div className="title-border">
                                <h1 className="uk-h3">Sirena Cash</h1>
                            </div>
                            <p className="uk-text-large uk-text-bold">Comparte mayor practicidad con <strong
                                className="uk-text-primary">Sirena Cash</strong>, el nuevo servicio de sirena
                                con el que puedes acreditar a otros beneficiarios de forma inmediata para hacer sus
                                compras en Sirena y Super Pola.</p>
                            <p>
                                <button id="btn-comprar-sirena-cash" type="button" onClick={() => {
                                    if (!isLogin) {
                                        Logout();
                                        UIkit.modal("#webLoginModal")?.show();
                                        return;
                                    }

                                    propx.nextStep();
                                }} className="uk-button uk-button-primary">
                                    Comprar Sirena Cash
                                </button>
                            </p>
                            <YoutubeEmbed embedId="Xf8PmGNyz3Q" title="Sirena Cash" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="int-header" className="uk-background-primary"
                 uk-sticky="show-on-up: true; animation: uk-animation-slide-top">
                <div className="uk-container uk-container-expand">
                    <nav className="uk-navbar-container uk-navbar-transparent" uk-navbar="mode: click">
                        <div className="uk-navbar-right uk-width-expand uk-flex-center">
                            <div className="uk-hidden@m">
                                <a href="#0" id="int-mobile-btn" uk-icon="icon: menu; ratio: 1.5"
                                    uk-toggle="target: #int-mobile-nav" title="Abrir Menú">{}</a>
                            </div>
                            <ul className="uk-navbar-nav uk-visible@m"
                                uk-scrollspy-nav="closest: li; scroll: true; offset: 20">
                                <li><a href="#SirenaCash">Sirena Cash</a></li>
                                <li><a href="#Beneficios">Beneficios</a></li>
                                <li><a href="#MododeUso">Modo de Uso</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <nav id="int-mobile-nav" uk-offcanvas="overlay: true">
                    <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-background-primary">
                        <button className="uk-offcanvas-close" type="button" uk-close="true"></button>
                        <ul className="uk-nav" uk-scrollspy-nav="closest: li; scroll: true; offset: 40">
                            <li><a href="#SirenaCash" className="btn-mobile">Sirena Cash</a></li>
                            <li><a href="#Beneficios" className="btn-mobile">Beneficios</a></li>
                            <li><a href="#MododeUso" className="btn-mobile">Modo de Uso</a></li>
                        </ul>
                    </div>
                </nav>

            </div>

            <div id="Beneficios" className="uk-section uk-section-large">

                <div className="uk-container uk-container-small uk-position-relative uk-position-z-index">
                    <div className="uk-child-width-1-2@m uk-grid uk-grid-match" uk-grid="true">
                        <div>
                            <div className="uk-card uk-background-tertiary uk-border-rounded">
                                <div className="uk-card-title">
                                    Remitente
                                </div>
                                <div className="uk-card-body">

                                <span className="icon-sirena-cash">
                                    <img src="/assets/sirena-cash/icon-remitente.svg"
                                         alt="sirena cash"/></span>

                                    <p className="uk-h5"><span>Beneficios</span></p>
                                    <ul>
                                        <li>Compra tus Sirena Cash desde donde estés.</li>
                                        <li>Realiza compras múltiples para beneficiar a todos los que quieras.</li>
                                        <li>Envía el monto exacto que desees regalar.</li>
                                        <li>El beneficiario recibirá el monto enviado de manera digital.</li>
                                        <li>El monto enviado solo podrá ser utilizado por el titular al mostrar su
                                            cédula.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="uk-card uk-background-tertiary uk-border-rounded">
                                <div className="uk-card-title">
                                    Destinatario
                                </div>
                                <div className="uk-card-body">

                                <span className="icon-sirena-cash">
                                    <img src="/assets/sirena-cash/icon-destinatario.svg"
                                         alt=""/></span>

                                    <p className="uk-h5"><span>Beneficios</span></p>
                                    <ul>
                                        <li>Utiliza el monto que desees al hacer tus compras en todas las tiendas Sirena
                                            y
                                            Sirena Market.
                                        </li>
                                        <li>Recibe el beneficio de los precios siempre bajos.</li>
                                        <li>Los puntos que acumulas con la Siremás se suman a tus Sirena Cash.</li>
                                        <li>Canjea tus Sirena cash al presentar solo tu cédula en caja.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="uk-position-cover">
                    <div
                        className="uk-width-1-4 uk-position-bottom-left uk-background-bottom-left uk-background-norepeat uk-background-image@m"
                        uk-scrollspy="cls: uk-animation-slide-left-small; delay:100" uk-img=""
                        style={{backgroundImage: `url(/assets/sirena-cash/sirena-cash-1.png)`}}></div>


                    <div
                        className="uk-width-1-4 uk-position-bottom-right uk-background-bottom-right uk-background-norepeat uk-background-image@m"
                        uk-scrollspy="cls: uk-animation-slide-right-small; delay:100" uk-img=""
                        style={{backgroundImage: `url(/assets/sirena-cash/sirena-cash-2.png)`}}></div>
                </div>

            </div>

            <div id="MododeUso" className="uk-section uk-section-large">

                <div className="uk-container uk-container-small uk-position-relative uk-position-z-index">

                    <div className="uk-card uk-background-tertiary uk-border-rounded">
                        <h3 className="uk-h4">¿Cómo puedes enviar?</h3>
                        <div className="uk-card-body">
                            <ul className="uk-list uk-list-disc">
                                <li>Entra en <a href="#0">Sirena.do</a> y regístrate como comprador.</li>
                                <li>En la pestaña superior de <a href="#0"><strong>Sirena Cash</strong></a> busca el
                                    botón <strong className="uk-text-primary">“Comprar Sirena Cash”</strong>.
                                </li>
                                <li>Indica la cédula y datos de la(s) persona(s) a la que enviarás tus <strong
                                    className="uk-text-primary">Sirena Cash</strong>.
                                </li>
                                <li>Marca el monto exacto que deseas compartir.</li>
                                <li>Envía algún mensaje si así lo deseas y completa el pago.</li>
                            </ul>
                        </div>
                        <h3 className="uk-h4">¿Cómo puedes utilizarlos?</h3>
                        <div className="uk-card-body">
                            <ul className="uk-list uk-list-disc">
                                <li>Recibirás un correo de confirmación indicando el monto recibido y nombre del
                                    remitente.
                                </li>
                                <li>Visita cualquiera de nuestras tiendas y presenta solo tu cédula para redimir
                                    tus <strong
                                        className="uk-text-primary">Sirena Cash</strong>.
                                </li>
                                <li>Utiliza tu monto de manera parcial o total en compras en cualquiera de nuestras
                                    tiendas.
                                </li>
                                <li>Consulta tu balance en <a href="#0">Sirena.do</a>, en la pestaña de consulta de
                                    puntos <a href="#0"><strong>Siremás</strong></a>.
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="uk-position-cover">
                    <div
                        className="uk-width-1-4 uk-position-bottom-left uk-background-bottom-left uk-background-norepeat uk-background-image@m"
                        uk-scrollspy="cls: uk-animation-slide-left-small; delay:100" uk-img=""
                        style={{backgroundImage: `url(/assets/sirena-cash/bg-sirena-cash-3.png)`}}></div>
                </div>

            </div>

            <div className="nubes">
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -100"
                     className="nube size1 opacity2"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -150"
                     className="nube opacity1 uk-visible@m"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -50"
                     className="nube size2 opacity1 uk-visible@m"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -50"
                     className="nube size2 opacity2"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -100"
                     className="nube size1 opacity1"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -150"
                     className="nube opacity2"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -150"
                     className="nube"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -100"
                     className="nube size1 opacity1"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -150"
                     className="nube"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -50"
                     className="nube size2"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -50"
                     className="nube size2 opacity2"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -100"
                     className="nube size1 opacity1"></div>
                <div style={{backgroundImage: `url(/assets/sirena-cash/img-nube.png)`}} uk-parallax="y: -100"
                     className="nube size1"></div>
            </div>
        </>)
    }

    return (
        <div className="uk-background-secondary uk-light cont-sirena-cash">
            <StepWizard isLazyMount={true} isHashEnabled={true}>
                <ContentPage hashKey={"home"}/>
                <DigitalPointsStepCart hashKey={"cart"}/>
                <DigitalPointsStepCheckout hashKey={"checkout"}/>
            </StepWizard>


        </div>
    )

}


;
