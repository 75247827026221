import React from "react";
import { Outlet } from "react-router-dom";
//site

export default function Site() {
  return (
    <>
      <main role="main" id="container" className="mobile-container">
        <Outlet />
      </main>
    </>
  );
}
