import React, { useEffect, useState, useContext } from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import Header from "components/share/header";
import Footer from "components/share/footer";
import AccountContext from "context/Account";
import Advertisements from "components/share/adsense";

//import {setKey} from "@react-google-maps/api/src/docs/docs-api-key";

UIkit.use(Icons);

export default function ProductLayout() {
  const { categories } = useContext(AccountContext);
  const [keyvalue, SetKeyValue] = useState("");
  const [adsArgument, setAdsArgument] = useState({});
  const [readyToshowAds, setReadyToShowAds] = useState(false);

  const location = useLocation();
  const currentUrl = location.pathname;

  useEffect(() => {
    let prm = currentUrl.split("/");
    let val = prm[2];
    if (val === "category") {
      val = prm[3];
    }
    SetKeyValue(val);
  }, [currentUrl]);

  useEffect(() => {
    setAdsArgument({ category: keyvalue });
  }, [keyvalue]);

  useEffect(() => {
    if (Object.entries(adsArgument).length > 0) setReadyToShowAds(true);
  }, [adsArgument]);

  const CategoryItem = ({ format = "M", categories }) => {
    return (
      <>
        {format === "M" ? (
          <ul className="uk-nav uk-nav-parent-icon" uk-nav="multiple: true">
            {categories.map((c) => (
              <li className="uk-parent" key={c.id}>
                <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
                <CategoryItemN2
                  friendlyurl={c.friendlyurl}
                  children={c.children}
                  format={format}
                />
              </li>
            ))}
          </ul>
        ) : (
          <ul className="uk-nav">
            {categories.map((c) => (
              <li className="uk-parent" key={c.id}>
                <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
                <CategoryItemN2
                  friendlyurl={c.friendlyurl}
                  children={c.children}
                  format={format}
                />
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  const CategoryItemN2 = ({ friendlyurl, children, format = "M" }) => {
    return (
      <>
        {format === "M" ? (
          <ul className="uk-nav-sub">
            {children?.map((c) => (
              <li key={c.id}>
                <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
                <CategoryItemN3
                  friendlyurl={c.friendlyurl}
                  children={c.children}
                  format={format}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="uk-dropdown uk-box-shadow-medium" uk-dropdown="true">
            <ul className="uk-nav uk-dropdown-nav">
              {children?.map((c) => (
                <li
                  className={c.children.length === 0 ? "view-all" : ""}
                  key={c.id}
                >
                  <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
                  <CategoryItemN3
                    friendlyurl={c.friendlyurl}
                    children={c.children}
                    format={format}
                  />
                </li>
              ))}
              <li className="view-all">
                <Link to={`category/${friendlyurl}`} className="uk-text-bolder">
                  Ver todos
                </Link>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };

  const CategoryItemN3 = ({ format = "M", friendlyurl, children }) => {
    return (
      <>
        {format === "M" ? (
          <ul>
            {children?.map((c) => (
              <li key={c.id}>
                <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <div className="uk-dropdown uk-box-shadow-medium" uk-dropdown="true">
            <ul className="uk-nav uk-dropdown-nav">
              {children?.map((c) => (
                <li key={c.id}>
                  <Link to={`category/${c.friendlyurl}`}>{c.name}</Link>
                </li>
              ))}
              <li className="view-all">
                <Link to={`category/${friendlyurl}`} className="uk-text-bolder">
                  Ver todos
                </Link>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Header />
      <div
        id="modal-filters"
        className="uk-hidden@m"
        uk-offcanvas="overlay: true;"
      >
        <div className="uk-offcanvas-bar uk-flex uk-flex-column sidebar">
          <div className="uk-flex uk-flex-middle">
            <a href="#0" className="uk-offcanvas-close" uk-close="true">
              {}
            </a>
            <p className="uk-h5 uk-text-uppercase">Categorías</p>
          </div>
          <hr />
          <CategoryItem format="M" categories={categories} />
        </div>
      </div>
      <main role="main" id="container">
        <section className="uk-section uk-padding-remove-vertical">
          <div
            className="uk-grid uk-grid-collapse uk-grid-match"
            uk-grid="true"
          >
            <div className="uk-background-muted uk-width-1-1 uk-width-1-5@m uk-width-1-6@l uk-visible@m sidebar">
              {/*Category bar */}
              <div className="uk-padding-small uk-padding-remove-right">
                <div className="title-border">
                  <h3 className="uk-h5">Categorías</h3>
                </div>
                <CategoryItem format="C" categories={categories} />

                <div className="uk-section uk-section-small uk-flex uk-flex-center banner_desktop">
                  {readyToshowAds && (
                    <Advertisements
                      type="desktop"
                      sizes={[160, 600]}
                      slotId="div-gpt-ad-1634741449921-0"
                      adUnit="desktop_banner_skyscraper_izquierdo_navegacion_160x600"
                      targetingArguments={adsArgument}
                    />
                  )}
                </div>
              </div>
              {/*Category Bar */}
            </div>
            {/* Switch */}
            <div className="uk-width-1-1 uk-width-4-5@m uk-width-5-6@l">
              <Outlet />
            </div>
            {/* Switch */}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
