import React, { useContext, useEffect, useState } from "react";
import AuthAnonymous from "components/account/auth/anonymous";
import Loading from "components/share/loading/table";
import AccountContext from "context/Account";
import { getData } from "helpers/service";
import { setTitle } from "helpers/functions";
import EmptyCart from "components/cart/cart/empty";
import AlertsCart from "components/cart/cart/Alerts";
import ShoppingCart from "components/cart/cart";

export default function CartPage() {
  const {
    isLogin,
    loadShoppingCart,
    shoppingCartItems,
    loadShoppingCartItems,
    account,
    reloadItems,
  } = useContext(AccountContext);

  const [loading, setLoading] = useState(true);
  const [baseImg, setBaseImg] = useState("");

  setTitle("Carrito");

  useEffect(() => {
    setLoading(true);
    if (isLogin) {
      getData(`cart`, null, (res, err) => {
        if (!err) {
          loadShoppingCartItems(res.items || []);
          loadShoppingCart(res.balance);
          setBaseImg(res.base_img);
        }
        setLoading(false);
      });
    }
  }, [account, reloadItems]);

  return isLogin ? (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">


        {loading ? (
          <>
            <div className="title-border uk-margin-medium-bottom">
                <h2 className="uk-h3">Mi carrito</h2>
            </div>
            <Loading />
          </>
        ) : shoppingCartItems.length === 0 ? (
                       
          <>
            <div className="title-border uk-margin-medium-bottom">
              <h2 className="uk-h3">Mi carrito</h2>
            </div>
            <EmptyCart />
          </>
            
        ) : (
          <>
           <AlertsCart />
            <div className="title-border uk-margin-medium-bottom">
              <h2 className="uk-h3">Mi carrito</h2>
            </div>
            <ShoppingCart baseImg={baseImg} setLoading={setLoading} />
          </>
        )}
      </div>
    </div>
  ) : (
    <AuthAnonymous source="full" />
  );
}
