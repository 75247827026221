import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { NotificationContent } from "./NotificationContent";
import StepWizard from "react-step-wizard";
import DotsNavBar from "components/share/dotsNavBar";
import { SetStorage } from "helpers/functions";

export default function NotificationModal({
  isOpen,
  onSetClose,
  dismissedNotifications,
  items = [],
}) {
  const webModalRef = useRef(document.getElementById("webModal"));
  const [homeNotifications, setHomeNotifications] = useState([]);
  const [notificationProps, setNotificationProps] = useState({});
  const [isLastStep, setIsLastStep] = useState(false);
  const { currentId, currentStep, totalSteps, nextStep } = notificationProps;

  useEffect(() => {
    if (homeNotifications.length > 0)
      SetStorage("dismissedNotifications", homeNotifications);

    if (isLastStep) onSetClose();

  }, [isLastStep, homeNotifications]);

  const handleCloseNotification = () => {
    if (dismissedNotifications !== null) {
      setHomeNotifications((prevState) => [
        ...dismissedNotifications,
        ...prevState,
      ]);
    }
    setIsLastStep(true);
  };

  const handleNextStep = (currentStep, totalSteps, nextStep) => {
    if (currentStep === totalSteps) return handleCloseNotification();
    nextStep();
  };

  const handleNoShowAgain = (
    e,
    currentId,
    currentStep,
    totalSteps,
    nextStep
  ) => {
    e.preventDefault();
    if (homeNotifications.length > 0) {
      const existsNotificationId = homeNotifications.includes(currentId);
      if (!existsNotificationId)
        setHomeNotifications((prevState) => [...prevState, currentId]);
    } else {
      setHomeNotifications([currentId]);
    }
    handleNextStep(currentStep, totalSteps, nextStep);
  };

  if (isOpen !== "notification") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical gi_modal_aviso">
      <button
        onClick={() => onSetClose()}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-body" style={{ minHeight: "400px" }}>
        <StepWizard nav={<DotsNavBar />}>
          {items
            ?.filter(
              (filterItem) =>
                !dismissedNotifications?.includes(filterItem.notificationId)
            )
            .map((item, idx) => (
              <NotificationContent
                key={idx}
                data={item}
                setNotificationProps={setNotificationProps}
                handleCloseNotification={handleCloseNotification}
              />
            ))}
        </StepWizard>
      </div>
      <div className="uk-modal-footer uk-text-center gi_modal_footer">
        <div
          className="uk-flex uk-flex-wrap uk-flex-middle uk-text-center uk-grid"
          data-uk-grid
        >
          <div className="uk-width-1-1 uk-width-expand@m uk-text-right@m">
            <a
              className="gi_ulink"
              href="#0"
              onClick={(e) =>
                handleNoShowAgain(
                  e,
                  currentId,
                  currentStep,
                  totalSteps,
                  nextStep
                )
              }
            >
              No mostrar más este aviso.
            </a>
          </div>
        </div>
      </div>
    </div>,
    webModalRef.current
  );
}
