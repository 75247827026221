import React, { useContext, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import AccountContext from "context/Account";
import { CheckoutContext } from "context/Checkout";
import AuthAnonymous from "components/account/auth/anonymous";
import AddCoupons from "components/cart/cart/coupons";
import CreditcardsList from "components/cart/checkout/creditcarts";
import CreditcardAdd from "components/account/profile/creditcards/add";
import Loading from "components/share/loading/table";
import LoadingModal from "components/share/loading/modal";
import CartBalance from "components/cart/cart/balance";
import Advertisements from "components/share/adsense";
import SupermarketCard from "components/cart/checkout/supermarketCard";
import HomeDeliveryCard from "components/cart/checkout/homeDeliveryCard";
import DeliveryExpressCard from "components/cart/checkout/deliveryExpressCard";
import { BannerCheckoutModel } from "models/adsense";
import {
  DecimalFormat,
  Encode,
  setTitle,
  SetSession,
  GetTypeDevice,
  GetSession,
} from "helpers/functions";
import { getData, postData } from "helpers/service";
import alert from "helpers/alerts";
import { updateStateReducer } from "store/common/commonReducer";
import { initialState } from "store/checkout/checkoutPage/state";
import {
  LOADING,
  D1_ITEMS,
  SHIPPING_RATE,
  DEFAULT_ADDRESS,
  FORM_PAYMENT,
  D2_ITEMS,
  D2_SHIPPING_RATE,
  D4_ITEMS,
  D4_SHIPPING_RATE,
  BALANCE,
  OPEN,
  SUBMIT_ORDER,
  SIREMAS_POINTS,
} from "store/checkout/checkoutPage/actionTypes";

export default function CheckoutPage() {
  const navigate = useNavigate();
  const SUPERMARKET = "supermarket";
  const HOME_DELIVERY = "homeDelivery";
  const HOME_DELIVERY_STORE = "homeDeliveryStore";
  const currentType = GetTypeDevice();
  let addressStorage = GetSession("addresses");

  const { isLogin, onLogIn, setPurchaseItems, purchaseItems } =
    useContext(AccountContext);
  const {
    addresses,
    setAddresses,
    setBaseImg,
    setLocationslot,
    setLocationslotId,
    locationslot,
    locationslotId,
    totalSirepointsApplied,
    pointsApplied,
    isLoading,
  } = useContext(CheckoutContext);

  const [
    {
      loading,
      d1Items,
      shippingRate,
      defaultAddress,
      formPayment,
      d2items,
      d2ShippingRate,
      d4Items,
      d4ShippingRate,
      balance,
      open,
      submitOrder,
      siremasPoints,
    },
    dispatch,
  ] = useReducer(updateStateReducer, initialState);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  setTitle("Pagar tu compra");

  useEffect(() => {
    if (isLogin) {
      getData(`checkout`, null, (res, err) => {
        if (!err) {
          if (
            parseFloat(res.balance?.order_amount) <
            parseFloat(res.balance?.minimum_order)
          ) {
            alert
              .confirm(
                ` <p>El monto de esta orden está por debajo del mínimo requerido,
                                    RD$ ${DecimalFormat(
                                      res.balance?.minimum_order
                                    )}</p>`,
                "warning"
              )
              .then(() => {
                return navigate("/cart", { replace: true });
              });
          }

          dispatch({ type: BALANCE, payload: res.balance });
          setBaseImg(res.base_img);

          /* Default address */
          dispatch({ type: DEFAULT_ADDRESS, payload: res.address });

          /* Distribucion Pickup / Delivery / Delivery Express */
          dispatch({ type: D1_ITEMS, payload: res.d1_items });
          dispatch({ type: SHIPPING_RATE, payload: res.d1_shipping_rate });
          dispatch({ type: FORM_PAYMENT, payload: res.form_payment });

          if (res.d1_timeslot !== null) {
            setLocationslotId(res.d1_timeslot?.locationslotid);
            setLocationslot(res.d1_timeslot?.timeslot_range);
          }
          /* Distribucion HD */
          dispatch({ type: D2_ITEMS, payload: res.d2_items });
          dispatch({ type: D2_SHIPPING_RATE, payload: res.d2_shipping_rate });

          /*Distribucion HD Electro*/
          dispatch({ type: D4_ITEMS, payload: res.d4_items });
          dispatch({ type: D4_SHIPPING_RATE, payload: res.d4_shipping_rate });
        }
        dispatch({ type: LOADING, payload: false });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  useEffect(() => {
    // Direccion inicial tipos de delivery
    if (
      Object.entries(shippingRate).length > 0 &&
      Object.entries(defaultAddress).length > 0
    ) {
      const initialAddresses = {
        [SUPERMARKET]: shippingRate,
        [HOME_DELIVERY]: defaultAddress,
        [HOME_DELIVERY_STORE]: defaultAddress,
      };

      if (addressStorage === null) {
        SetSession("addresses", initialAddresses);
        setAddresses(initialAddresses);
      } else {
        setAddresses(() => ({
          [SUPERMARKET]: shippingRate, // Siempre la localidad actual
          [HOME_DELIVERY]: addressStorage[HOME_DELIVERY],
          [HOME_DELIVERY_STORE]: addressStorage[HOME_DELIVERY_STORE],
        }));
      }
    }
  }, [shippingRate, defaultAddress]);

  useEffect(() => {
    getData("account/profile/balance", null, (res, err) => {
      if (!err) {
        dispatch({ type: SIREMAS_POINTS, payload: res?.balance });
      } else {
        throw new Error();
      }
    });
  }, []);

  useEffect(() => {
    let { orderId } = purchaseItems;
    if (!orderId) return;
    navigate(`/order-confirmation/${Encode(orderId)}`, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseItems]);

  const onSetClose = (action = `none`, modal = "webModal") => {
    dispatch({ type: OPEN, payload: action });
    if (action === "none") {
      UIkit.modal(`#${modal}`)?.hide();
    }
  };

  const onSetShippingRate = (data) => {
    setLocationslotId(null);
    setLocationslot(null);
    onLogIn(data?.session);

    if (data.shipping_rate.shipping_type === "DE") {
      setLocationslotId(data?.timeslot?.locationslotid);
      setLocationslot(data?.timeslot?.timeslot_range);
    }

    dispatch({ type: SHIPPING_RATE, payload: data.shipping_rate });
    onSetClose("none", "webModal-container");
  };

  const onSetCreditCard = (reload = false, message = "", data = null) => {
    if (data !== null) {
      let payment = {
        ccId: data.ccId,
        cc_exp: data.cc_exp,
        cc_mask: data.cc_mask,
        cc_type: data.cc_type,
        confirmed: data.confirmed,
      };
      dispatch({ type: FORM_PAYMENT, payload: { ...formPayment, ...payment } });
    }
    onSetClose("none", "webModal");
  };

  const onSubmit = (data) => {
    if (formPayment?.ccId === null) {
      alert.confirm(
        ` <p>Se requiere una tarjeta de crédito o débito registrada.</p>`,
        "warning"
      );
      return;
    }

    if (d1Items.length > 0 && locationslotId === null) {
      alert.confirm(
        `<p>Se requiere un horario para el <strong>${shippingRate?.shipping_rate}</strong> </p>`,
        "warning"
      );
      return;
    }

    if (d2items.length > 0 && addresses === null) {
      alert.confirm(`<p>Se requiere una dirección de entrega</p>`, "warning");
      return;
    }

    if (d4Items.length > 0 && addresses === null) {
      alert.confirm(` <p>Se requiere una dirección de entrega</p>`, "warning");
      return;
    }

    dispatch({
      type: SUBMIT_ORDER,
      payload: {
        payment: {
          point: totalSirepointsApplied,
          cc: {
            cvv: Encode(data["CVV"]),
            confirm: Encode(data["confirm_amount"]),
            id: formPayment?.ccId,
          },
        },
        d1: {
          item: d1Items.length > 0,
          comment: data["d1_comment"],
          "payment-point": pointsApplied[SUPERMARKET],
          locationslotid: locationslotId,
        },
        d2: {
          item: d2items.length > 0,
          comment: data["d2_comment"],
          "payment-point": pointsApplied[HOME_DELIVERY],
          shippingrateid: d2ShippingRate.shippingrateid,
          addressid: shippingRate.addressId,
        },
        d4: {
          item: d4Items.length > 0,
          comment: data["d4_comment"],
          "payment-point": pointsApplied[HOME_DELIVERY_STORE],
          shippingrateid: d4ShippingRate.shippingrateid,
          addressid: shippingRate.addressId,
        },
      },
    });
  };

  const handlePurchaseItems = (id, items, balance) => {
    let _id = items.map((item) => item.productid);
    let price = items.map((item) => parseFloat(item?.price));
    let quantity = items.map((item) => parseFloat(item?.in_cart));

    const lineItems = {
      _id,
      price,
      quantity,
    };

    setPurchaseItems({
      orderId: id,
      totalValue: parseFloat(balance?.amount),
      lineItems,
    });
  };
  const handleSetBalance = (balance) => {
    dispatch({ type: BALANCE, payload: balance });
  };

  useEffect(() => {
    if (Object.entries(submitOrder)?.length > 0) {
      dispatch({ type: OPEN, payload: "loading" });
      UIkit.modal(`#webModal`).show();

      postData(
        `checkout`,
        submitOrder,
        { version: Encode("V2") },
        (res, err) => {
          if (!err) {
            let items = [...d1Items, ...d2items, ...d4Items];
            handlePurchaseItems(res.id, items, balance);
            onSetClose("none", "webModal");
            // Go to order confirmation
          } else {
            onSetClose("none", "webModal");
            dispatch({ type: LOADING, payload: false });
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [submitOrder]);

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const handleOpenModal = (e, modal) => {
    dispatch({ type: OPEN, payload: modal });
    e.preventDefault();
  };

  const ButtonEditCart = () => {
    return (
      <div className="gi_checkout_bottom">
        <Link
          id="btn-edit-cart"
          to="/cart"
          className="uk-button uk-button-danger gi_round_button gi_direcc_button"
        >
          <span>Modificar carrito</span>
          <i className="fas fa-edit"></i>
        </Link>
      </div>
    );
  };

  const CreditCard = () => {
    return (
      <div className="item-list-block gi_checkout_creditc">
        {formPayment?.ccId !== null ? (
          <>
            <p>
              Pagar: <strong>Tarjeta de crédito </strong>
              {formPayment.ccId !== null && (
                <button
                  className="uk-button uk-button-link"
                  onClick={(e) => handleOpenModal(e, "credit-cards")}
                  uk-toggle="target: #webModal"
                >
                  (Cambiar)
                </button>
              )}
            </p>
            <div>
              <div className="uk-flex uk-grid-small">
                <div className="uk-width-auto">
                  <img
                    src={`/assets/creditcart/${formPayment?.cc_type}.jpg`}
                    alt={formPayment?.cc_type}
                    style={{ maxHeight: 35 }}
                  />
                </div>
                <div className="uk-width-expand">
                  <strong>{formPayment?.cc_mask}</strong>
                  <br />
                  <small>{formPayment?.cc_exp}</small>
                </div>
                <div className="uk-width-auto">
                  <input
                    id="cvv"
                    data-test="cvv"
                    className="uk-input uk-form-width-small"
                    type="password"
                    name="CVV"
                    autoComplete="cc-csc"
                    maxLength="4"
                    autoFocus
                    placeholder="CVV"
                    {...register("CVV", { required: "CVV es requerido" })}
                  />
                  <p className="uk-width-1-1 error">{errors?.CVV?.message}</p>
                </div>
              </div>
              {formPayment?.confirmed === 0 ? (
                <div className="uk-grid-small">
                  <div className="uk-width-1-1">
                    <br />
                    <p className="uk-h5">Confirmar tarjeta de crédito</p>
                  </div>
                  <div className="uk-width-1-1 uk-margin-remove">
                    <div className="uk-margin-small">
                      <label className="uk-form-label" htmlFor="confirm_amount">
                        Monto transacción <span className="required">*</span>
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input uk-text-right"
                          name="confirm_amount"
                          {...register("confirm_amount", {
                            required: "Monto transacción es requerido",
                          })}
                          type="text"
                          placeholder="0.00"
                        />
                      </div>
                      <p className="error">{errors?.confirm_amount?.message}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-margin-remove">
                    Se realizó un cargo a su tarjeta de 1 a 20 pesos que será su
                    clave de validación. Consulte dicho monto en su tarjeta.
                    Este proceso solo es necesario al registrar por primera vez
                    la tarjeta y el monto será reversado.
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div>
            <p className="uk-text-bold">
              No posees tarjeta de crédito o débito registrada.
            </p>
            <p>
              <button
                id="btn-register-card"
                onClick={(e) => handleOpenModal(e, "CreditcardAdd")}
                className="uk-button uk-button-default uk-button-small"
                uk-toggle="target: #webModal"
              >
                <span>
                  Registrar <i className="far fa-credit-card"></i>
                </span>
              </button>
            </p>
          </div>
        )}
      </div>
    );
  };

  const SiremasPoints = ({ points = 0 }) => {
    return (
      <div className="item-list-cupon">
        <p className="uk-h6 title-ptos-disp">Puntos Siremás</p>
        <p className="ptos-disp">{points.toLocaleString()} disponibles</p>
      </div>
    );
  };

  const GoToPay = () => {
    return (
      <>
        <div className="uk-width-1-1 gi_pay_button">
          <button
            id="btn-pay"
            data-test="btn-pay"
            className="uk-button uk-button-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={
              locationslot === null &&
              d1Items.length > 0 &&
              shippingRate.shipping_type !== "P"
            }
          >
            <span>Proceder a pagar</span>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
        <div className="uk-width-1-1 gi_seguir_button"></div>
      </>
    );
  };

  const Checkout = () => (
    <>
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <div className="uk-grid uk-grid-large item-list-wrap" uk-grid="true">
          <div className="uk-width-1-1 uk-width-2-3@m gi_checkout_leftcol">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {/* Distribution 1 items */}
                {shippingRate?.shipping_type === "DE" ? (
                  <DeliveryExpressCard
                    items={d1Items}
                    balance={balance}
                    shippingRate={shippingRate}
                    deliveryExpressAddress={addresses[SUPERMARKET]}
                    locationslot={locationslot}
                    points={siremasPoints}
                  />
                ) : (
                  <SupermarketCard
                    items={d1Items}
                    balance={balance}
                    shippingRate={shippingRate}
                    superMarketAddresses={addresses[SUPERMARKET]}
                    points={siremasPoints}
                    onSetShippingRate={onSetShippingRate}
                  />
                )}

                {/* Distribution 2 items */}
                <HomeDeliveryCard
                  items={d2items}
                  shippingRate={d2ShippingRate}
                  homeDeliveryAddresses={addresses[HOME_DELIVERY]}
                  points={siremasPoints}
                  typeDelivery={HOME_DELIVERY}
                />

                {/* Distribution 4 items */}
                <HomeDeliveryCard
                  items={d4Items}
                  shippingRate={d4ShippingRate}
                  homeDeliveryAddresses={addresses[HOME_DELIVERY_STORE]}
                  points={siremasPoints}
                  typeDelivery={HOME_DELIVERY_STORE}
                  typeAddress={"DS"}
                />
              </>
            )}

            <ButtonEditCart />
          </div>

          <div className="uk-width-1-1 uk-width-1-3@m gi_checkout_rightcol">
            <div
              className="item-list-footer"
              uk-sticky="offset: 130; bottom: true; media: @m"
            >
              <CreditCard />
              <hr className="uk-margin-medium-top uk-text-center" />
              <SiremasPoints points={siremasPoints} onSetClose={onSetClose} />
              <hr className="uk-margin-medium-top uk-text-center" />
              <AddCoupons setBalance={handleSetBalance} />
              <hr className="uk-margin-medium-top uk-text-center" />
              <CartBalance balance={balance} buttonPay={<GoToPay />} />
            </div>
          </div>
        </div>
      </form>

      <CreditcardsList
        isOpen={open}
        onSetClose={onSetClose}
        onSetCreditCard={onSetCreditCard}
        current={formPayment.ccId}
      />

      <CreditcardAdd
        isOpen={open === "CreditcardAdd" ? "add" : "none"}
        setClose={onSetCreditCard}
      />

      <LoadingModal
        isOpen={open}
        message="Estamos procesando el pago de su pedido, por favor espere..."
      />
    </>
  );

  const AdsCheckout = () =>
    BannerCheckoutModel.filter((item) => item.type === currentType).map(
      (item) => (
        <Advertisements
          key={item.slotId}
          className={item.className}
          type={item.type}
          slotId={item.slotId}
          sizes={item.sizes}
          adUnit={item.adUnit}
        />
      )
    );

  const ConectedAccount = () => (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container">
        <AdsCheckout />
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">Pagar tu compra</h2>
        </div>
        {loading ? <Loading /> : <Checkout />}
      </div>
    </div>
  );

  return isLogin ? <ConectedAccount /> : <AuthAnonymous source={"full"} />;
}
