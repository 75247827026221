import React from "react";

export default function ContactsList({ contactItems, setByDefault, onOpen }) {
  if (contactItems.length === 0)
    return (
      <div className="uk-width-1-1">
        <img
          src="/assets/background/contact-empty.svg"
          style={{ height: "350px", textAlign: "center" }}
          alt="Lista de contactos Vacio"
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">
            No has tenido un registro de contactos.{" "}
          </h4>
          <p className="uk-text-center">
            Esta herramienta te permitirá visualizar todos tus contactos
            registrados.
          </p>
        </div>
      </div>
    );

  return contactItems.map((item) => (
    <div key={item.id}>
      <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small card-block">
        <p className="uk-h5">
          {item.tag}{" "}
          {item.by_default === 1 && (
            <span className="uk-badge">Predeterminado</span>
          )}
        </p>
        <p>
          <span className="uk-text-capitalize">
            {" "}
            {`${item.first_name} ${item.last_name}`}
          </span>
          <br />
          {item.document_type_id}: <strong>{item.document}</strong>
          <br />
          <i className="fas fa-mobile-alt"></i> {item.phone}
          <br />
          <i className="far fa-envelope"></i> {item.email}
        </p>
        <hr />
        <div className="uk-flex uk-flex-wrap uk-flex-between uk-grid-small">
          <div>
            <button
              type="button"
              onClick={() => onOpen("delete", item)}
              className="uk-button uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Borrar</span> <i className="far fa-trash-alt"></i>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => onOpen("edit", item)}
              className="uk-button uk-button-default uk-button-small"
              uk-toggle="target: #webModal"
            >
              <span>Editar</span> <i className="fas fa-edit"></i>
            </button>
          </div>
        </div>
        <hr />
        <div>
          {item.by_default === 0 && (
            <button
              type="button"
              onClick={() => setByDefault(item.id)}
              className="uk-button uk-button-text uk-button-small"
            >
              <span>Establecer por defecto</span>
              <i className="far fa-star"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  ));
}
