import { CheckoutContext } from "context/Checkout";
import { DecimalFormat } from "helpers/functions";
import { useContext } from "react";

export default function CartBalance({ balance, buttonPay = null }) {
  const { totalSirepointsApplied } = useContext(CheckoutContext);
  
  const amount = parseFloat(balance.amount) - totalSirepointsApplied;

  return (
    <div className="item-list-total">
      <div>
        <div className="uk-flex uk-grid-small">
          <div className="uk-width-1-3 uk-text-uppercase">Descuento:</div>
          <div className="uk-width-2-3 uk-text-right">
            RD$ {DecimalFormat(balance?.order_discount || 0)}
          </div>
        </div>
        <div className="uk-flex uk-grid-small">
          <div className="uk-width-1-3 uk-text-uppercase">Envío:</div>
          <div className="uk-width-2-3 uk-text-right">
            RD$ {DecimalFormat(balance?.shipping_cost || 0)}
          </div>
        </div>
        <div className="uk-flex uk-grid-small">
          <div className="uk-width-1-3 uk-text-uppercase">Itbis:</div>
          <div className="uk-width-2-3 uk-text-right">
            RD$ {DecimalFormat(balance?.tax_amount || 0)}
          </div>
        </div>
        <div className="uk-flex uk-grid-small">
          <div className="uk-width-2-3 uk-text-uppercase">Puntos Siremás</div>
          <div className="uk-width-1-3 uk-text-right">
            RD${DecimalFormat(totalSirepointsApplied ||  0)}
          </div>
        </div>

        <div className="uk-grid gi_cart_total">
          <div className="uk-width-1-1 gi_cart_titletot">Total a pagar:</div>
          <div className="uk-width-1-1 gi_cart_tot">
            RD$ {DecimalFormat(amount || 0)}
          </div>
          <div className="uk-width-1-1 gi_cart_legal">
            Al realizar tu pedido, aceptas los{" "}
            <a href="/site/page/terminos-y-condiciones" target="_blank">
              términos y condiciones
            </a>{" "}
            de Sirena.do
          </div>
          {buttonPay}
        </div>
      </div>
    </div>
  );
}
