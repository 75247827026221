
import React, { useContext, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { DecimalFormat, Encode } from "helpers/functions";
import { postData } from "helpers/service";
import AccountContext from "context/Account";
import Loading from "components/share/loading";

export default function ProductItemModal({ isOpen, item, onSetClose }) {

  const { loadShoppingCart, loadShoppingCartItems } =
    useContext(AccountContext);
  const webModalRef = useRef(document.getElementById("webModal"));
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [quantity, setQuantity] = useState(0);
  const [itemsQuantity, setItemsQuantity] = useState(0);

  useEffect(() => {
    if (isOpen === "more") {
      setCurrent(item);

      let qty = 0;

      if (parseFloat(item?.in_cart) > 0)
        qty = parseFloat(item?.in_cart) + parseFloat(item.producttype_step) * 5;
      else
        qty = parseFloat(item?.minimum) + parseFloat(item.producttype_step) * 5;

      setQuantity(qty);
      setItemsQuantity(
        DecimalFormat(
          parseFloat(item?.minimum) + parseFloat(item?.producttype_step) * qty,
          item?.producttype_decimal
        )
      );
    }

  }, [isOpen]);

  useEffect(() => {
    if (isOpen === "more") {
      setItemsQuantity(
        DecimalFormat(
          parseFloat(current?.minimum) +
            parseFloat(current?.producttype_step) * quantity,
          current?.producttype_decimal
        )
      );
    }

  }, [quantity]);

  /*
  const onSetClose = () => {
    setOpen("none");
    UIkit.modal("#webModal")?.hide();
  };
  */

  const onsubmit = () => {
    if (parseFloat(current?.in_cart) === 0) {
      addToCart();
    } else {
      updateToCart();
    }
  };

  const addToCart = () => {
    let data = {
      id: Encode(current?.productid),
      qty: itemsQuantity,
    };

    setLoading(true);
    postData(
      "cart/add",
      data,
      { in_cart: window.location.pathname === "/cart" ? "D" : "R" },
      (res, err) => {
        if (!err) {
          let values = current;
          values["cartid"] = res.item.cartid;
          values["in_cart"] = res.item.in_cart;

          setQuantity(res.item.in_cart);
          setCurrent(values);

          loadShoppingCart(res.balance);

          if (window.location.pathname === "/cart") {
            loadShoppingCartItems(res.cart || []);
          }

          //onSetClose(values);
            onSetClose();
        }
        setLoading(false);
      }
    );
  };

  const updateToCart = () => {
    let data = {
      key: Encode(current?.cartid),
      qty: itemsQuantity,
    };

    setLoading(true);
    postData(
      `cart/${Encode(current?.cartid)}/update`,
      data,
      { in_cart: window.location.pathname === "/cart" ? "D" : "R" },
      (res, err) => {
        if (!err) {
          let values = current;

          values["in_cart"] = res.item.in_cart;

          setQuantity(parseFloat(res.item.in_cart));
          setCurrent(values);

          loadShoppingCart(res.balance);

          if (window.location.pathname === "/cart") {
            loadShoppingCartItems(res.cart || []);
          }

          //onSetClose(parseFloat(res.item.in_cart));
            onSetClose();
        }
        setLoading(false);
      }
    );
  };

  const upQuantity = () => {
    setQuantity(quantity + 1);
  };

  const DownQuantity = () => {
    setQuantity(quantity - 1);
  };

  if (isOpen !== "more") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        onClick={() => onSetClose()}
        className="uk-modal-close-default uk-modal-close"
        type="button"
        data-uk-close
      ></button>
      <div className="uk-modal-body uk-text-center">
        <div className="item-product-modal">
          <div
            className="item-product-image"
            style={{
              backgroundImage: `url(https://assets-sirenago.s3-us-west-1.amazonaws.com/product/thumbs/${current?.thumbs})`,
            }}
          ></div>
          <div className="item-product-info">
            <p className="item-product-title">
              <Link to={`/products/index/${current?.friendlyurl}`}>
                {current?.name}
              </Link>
            </p>
          </div>
          <div className="item-product-add">
            {loading ? (
              <Loading />
            ) : (
              <div className="item-product-quantity">
                <div className="item-product-quantity-buttons">
                  <button
                    disabled={
                      itemsQuantity <= parseFloat(current?.minimum)
                        ? true
                        : false
                    }
                    onClick={DownQuantity}
                    className="uk-button uk-button-primary btn-minus"
                    type="button"
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  <span>{itemsQuantity}</span>
                  <button
                    onClick={upQuantity}
                    className="uk-button uk-button-primary btn-plus"
                    type="button"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          onClick={() => onSetClose()}
          disabled={loading}
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>

        <button
          onClick={() => onsubmit()}
          disabled={loading}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Agregar</span> <i className="fas fa-shopping-cart"></i>
        </button>
      </div>
    </div>,
    webModalRef.current
  );
}
