import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import alert from "helpers/alerts";

export default function CreateShoppingList({ isOpen, onSetClose }) {
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    postData(
      `cart/create-list`,
      { name: Encode(data.name) },
      {},
      (res, err) => {
        if (!err) {
          alert.toast(res.message);
          onSetClose();
        }
        setLoading(false);
      }
    );
  };

  if (isOpen !== "shoppinglist") return null;
  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        onClick={onSetClose}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Lista de compra
        </p>
      </div>

      <div className="uk-modal-body uk-text-center">
        <p>
          <strong>¿Qué nombre deseas ponerle a tu nueva lista?</strong>
        </p>
        <div className="uk-margin-small">
          <div className="uk-form-controls">
            <input
              className="uk-input"
              id="add-name"
              name="name"
              maxLength={100}
              {...register("name", { required: "Nombre de la lista es requerido." })}
              type="text"
              placeholder="Escribe el nombre de tu nueva lista"
            />
            <p className="error">{errors?.name?.message}</p>
          </div>
        </div>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Confirmar</span>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
