import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { getData, postData } from "helpers/service";
import { Encode } from "helpers/functions";
import Select from "react-select";
import ReactDOM from "react-dom";
import Loading from "components/share/loading";

export default function AddressAdd({ isOpen, setClose }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset
  } = useForm();

  const COUNTRY_ID = "dbe31cd5d3c64a4b0dfad6a1c344c0b3";
  const stateId = watch("state_id", null);

  const [states, setStates] = useState([]);
  const [sectors, setSectors] = useState([]);

  const [sectorLoad, setSectorLoad] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (isOpen === "add") {
      reset({ state_id: null, sector_id: null });
      getData(
        `setting/address/state`,
        {
          countryid: Encode(COUNTRY_ID),
          search: Encode(""),
        },
        (res, err) => {
          if (!err) {
            setStates(res.data);
          }
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, COUNTRY_ID]);

  useEffect(() => {
    if (isOpen === "add") {
      setSectorLoad(true);
      getData(
        `setting/address/sector`,
        {
          stateid: Encode(stateId?.value),
          search: Encode(""),
        },
        (res, err) => {
          if (!err) {
            setSectors(res.data);
          }
          setSectorLoad(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId, isOpen]);

  const onSubmit = (data) => {
    setLoading(true);
    data.state_id = data.state_id.value;
    data.sector_id = data.sector_id.value;
    data["by_default"] = checked ? 1 : 0;

    postData("account/address", data, {}, (res, err) => {
      if (!err) {
        setClose(true, res.message, res.data);
      }
      setLoading(false);
    });
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  if (isOpen !== "add") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
        onClick={()=>setClose(true)}
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Agregar dirección
        </p>
      </div>
      <div className="uk-modal-body">
        <div className="uk-width-1-1">
          {loading ? (
            <Loading />
          ) : (
            <form
              className="uk-form-horizontal uk-margin-remove"
              onSubmit={mySubmitHandler}
            >
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="tag"
                  >
                    Alias <span className="required">*</span>
                  </label>
                  <input
                    className="uk-input"
                    id="tag"
                    name="tag"
                    {...register("tag", {
                      required: "Alias(s) son requeridos",
                    })}
                    type="text"
                    placeholder="Cómo desea guardar la dirección"
                  />
                  <p className="error">{errors?.tag?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="state_id">
                    Provincia <span className="required">*</span>
                  </label>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <Controller
                    control={control}
                    name="state_id"
                    rules={{
                      required: "La provincia es requerida",
                    }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione..."
                        options={states}
                        onChange={field.onChange}
                        {...field}
                      />
                    )}
                  />
                  <p className="error">{errors?.state_id?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label className="uk-form-label" htmlFor="sector_id">
                    Sector <span className="required">*</span>
                  </label>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <Controller
                    control={control}
                    name="sector_id"
                    rules={{ required: "El sector es requerido" }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione..."
                        isSearchable={true}
                        isLoading={sectorLoad}
                        options={sectors}
                        onChange={field.onChange}
                        {...field}
                      />
                    )}
                  />
                  <p className="error">{errors?.sector_id?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address"
                  >
                    Dirección (Calle/Número)<span className="required">*</span>
                  </label>
                  <textarea
                    id="address"
                    name="address"
                    cols="30"
                    rows="2"
                    className="uk-textarea"
                    {...register("address", {
                      required: "la dirección (Calle/Número) es requerida",
                    })}
                  ></textarea>
                  <p className="error">{errors?.address?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address2"
                  >
                    Dirección (Residencial / Casa o Apto. No.){" "}
                    <span className="required">*</span>
                  </label>
                  <textarea
                    id="address2"
                    name="address2"
                    cols="30"
                    rows="2"
                    className="uk-textarea"
                    {...register("address2")}
                  ></textarea>
                  <p className="error">{errors?.address2?.message}</p>
                </div>
                <div className="uk-width-1-1 uk-margin-remove">
                  <label>
                    <input
                      name="by_default"
                      checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                      }}
                      className="uk-checkbox"
                      type="checkbox"
                    />
                    Hacer de esta mi dirección predeterminada
                  </label>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button  uk-button-custom"
          type="button"
          disabled={loading}
          onClick={setClose}
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={handleSubmit(onSubmit)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
