import React from "react";
import { DecimalFormat } from "helpers/functions";

export default function CheckoutItem({ item, baseImg }) {
  return (
    <div className="item-list gi_item_carrito">
      <a
        href="#0"
        className="item-list-image"
        style={{ backgroundImage: `url(${baseImg}${item?.thumbs})` }}
      >
        {}
      </a>
      <div className="item-list-info uk-width-expand">
        <p className="uk-h5">
          <a href="#0">{item.name}</a>
        </p>

        <div className="uk-flex uk-child-width-1-1 uk-child-width-auto@s uk-text-small gi_nosustit">
          {item.comment?.length > 0 && <> | {item.comment}</>}
        </div>
      </div>
      <div className="item-list-detail uk-width-1-1 uk-width-auto uk-flex-last@s uk-text-right">
        <p className="uk-h3" 
           style={{color:'black', fontSize:'1.5em', fontWeight:'700'}}>
            ${DecimalFormat(item?.total || 0)}
        </p>
      </div>
    </div>
  );
}
