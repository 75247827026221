
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Encode } from "helpers/functions";
import { getData, postData } from "helpers/service";
import Loading from "components/share/loading";

export default function RegisterAccountStep4({ form, onRegistered, previousStep }) {

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  
  const [countryid] = useState(""); //dbe31cd5d3c64a4b0dfad6a1c344c0b3
  const [stateid, setStateid] = useState(null);
  const [state, setState] = useState([]);
  const [sector, setSector] = useState([]);
  const [sectorCurrent, setSectorCurrent] = useState({});
  const [sectorLoad, setSectorLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(
      `setting/address/state`,
      {
        countryid: Encode(countryid),
        search: Encode(""),
      },
      (res, err) => {
        if (!err) {
          setState(res.data);
        }
      }
    );

  }, []);

  useEffect(() => {
    setSectorLoad(true);
    setSectorCurrent({});
    getData(
      `setting/address/sector`,
      {
        stateid: Encode(stateid),
        search: Encode(""),
      },
      (res, err) => {
        if (!err) {
          setSector(res.data);
        }
        setSectorLoad(false);
      }
    );
  }, [stateid]);

  const handleSelectChangeState = (option) => {
    setStateid(option?.value);
    setValue("state_id", option?.value);
    setSector([]);
  };

  const handleSelectChangeSector = (option) => {
    setValue("sector_id", option?.value);
    setSectorCurrent(option);
  };

  const onSubmit = (data) => {
    let record = { ...form, ...data };

    setLoading(true);

    postData("account", record, {}, (res, err) => {
      if (!err) {
        onRegistered();
      }

      setLoading(false);
    });
  };

  const ToPreviousStep = () => {
    previousStep();
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <progress
          className="uk-progress uk-position-top"
          value="100"
          max="100"
        ></progress>
        <p className="uk-text-right uk-text-small uk-margin-remove-top">
          Paso 4 de 4
        </p>
        <div className="title-border">
          <p className="uk-h4">Zona residencial:</p>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="state_id">
            Provincia <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <Select
              placeholder="Seleccione..."
              options={state}
              onChange={handleSelectChangeState}
            />
            <input
              type="hidden"
              id="state_id"
              name="state_id"
              {...register("state_id", {
                required: "La provincia es requerida",
              })}
            />
            <p className="error">
              {errors.state_id && errors.state_id.message}
            </p>
          </div>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="sector_id">
            Sector <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <Select
              placeholder="Seleccione..."
              isSearchable={true}
              value={sectorCurrent}
              isLoading={sectorLoad}
              options={sector}
              onChange={handleSelectChangeSector}
            />
            <input
              type="hidden"
              id="sector_id"
              name="sector_id"
              {...register("sector_id", { required: "El sector es Requerido" })}
            />
            <p className="error">
              {errors.sector_id && errors.sector_id.message}
            </p>
          </div>
        </div>
        <div className="uk-margin">
          <label>
            <input
              id="checkboxTerms"
              name="checkboxTerms"
              {...register("checkboxTerms", {
                required:
                  "Debe aceptar Términos y condiciones para registrarte.",
              })}
              className="uk-checkbox"
              type="checkbox"
            />{" "}
            Cuando te registras, aceptas los{" "}
            <a
              href="/site/page/terminos-y-condiciones"
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>{" "}
            de Sirena.do
          </label>
          <p className="error">
            {errors?.checkboxTerms && errors?.checkboxTerms.message}
          </p>
        </div>
        {loading && <Loading />}
        <hr />
        <div className="uk-margin uk-text-right">
          <button
            type="button"
            className="uk-button  uk-button-custom"
            disabled={loading}
            onClick={ToPreviousStep}
          >
            Anterior
          </button>
          <button
            id="btn-sign-up"
            type="button"
            className="uk-button uk-button-primary"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Registrarme
          </button>
        </div>
      </form>
    </div>
  );
}
