/* eslint-disable */
import React, {useEffect, useState} from "react";
import Barcode from "react-barcode";
import {getData} from "helpers/service";
import {Encode, setTitle, DecimalFormat} from "helpers/functions";
import Loading from "components/share/loading/content";
import PageNotFound from "components/share/error/404";
import {Link} from "react-router-dom";
import UIkit from "uikit";
import LoyaltyModal from "components/share/loyalty/modal";

export default function ProfilePage() {
    const [account, setAccount] = useState(null);
    const [address, setAddress] = useState([]);
    const [sirenaCash, setSirenaCash] = useState([]);
    const [loyalty, setLoyalty] = useState([]);
    const [preference, setPreference] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState("");

    useEffect(() => {
        setTitle("Mi Cuenta");
        getData("account/profile", {type: Encode("F")}, (res, err) => {
            if (!err) {
                setAccount(res.account);
                setAddress(res.address);
                setLoyalty(res.loyalty);
                setPreference(res.preference);
                setSirenaCash(res.sirena_cash);
            }
            setLoading(false);
        });
    }, []);

    const openModal = (modal) => {
        setOpen(modal);
        UIkit.modal("#webModal-container")?.show();
    };
    const onSetClose = () => {
        setOpen("");
        UIkit.modal("#webModal-container")?.hide();
    };

    return loading ? (
        <Loading/>
    ) : (
        <div>
            <div className="title-border uk-flex uk-flex-between uk-flex-bottom">
                <h2 className="uk-h3">Mis datos</h2>
                <div className="uk-hidden@m">
                    <a
                        href="#0"
                        className="uk-button uk-button-default uk-button-custom"
                        uk-toggle="target: #modal-filters"
                    >
                        <i className="fas fa-filter"></i>
                    </a>
                </div>
            </div>

            {account === null ? (
                <PageNotFound/>
            ) : (
                <>
                    <div
                        className="uk-child-width-1-1 uk-child-width-1-2@m uk-grid uk-grid-large uk-margin-large-bottom"
                        uk-grid="true"
                    >
                        <div>
                            <p className="uk-h5">{`${account?.first_name?.toUpperCase()} ${account?.last_name?.toUpperCase()}`}</p>
                            <p className="uk-flex uk-grid-small">
                                <i className="fas fa-envelope"></i>
                                <span>{account?.email}</span>
                            </p>
                            <p className="uk-flex uk-grid-small">
                                <i className="fas fa-phone-alt"></i>
                                <span>{account?.phone || "-"}</span>
                            </p>
                            <p className="uk-flex uk-grid-small">
                                <i className="fas fa-mobile-alt"></i>
                                <span>{account?.mobile}</span>
                            </p>

                            <p className="uk-h5">Facturación</p>

                            <p>{account?.crm_dgii_cf}</p>
                            <p>
                                {account?.document_type}: {account?.document}
                            </p>
                            {account?.business_name !== "" && (
                                <p>Nombre/Razón Social: {account?.business_name}</p>
                            )}
                            <p className="uk-h5">Preferencias</p>
                            <p>
                                Localidad: <strong>{preference?.location}</strong>
                            </p>
                            <p>
                                Método de entrega: <strong>{preference?.shipping}</strong>
                            </p>


                            <p>
                                <Link
                                    to="edit"
                                    className="uk-button uk-button-primary uk-button-small"
                                >
                                    <span>Editar mi cuenta</span>
                                    <i className="fas fa-edit"></i>
                                </Link>
                            </p>
                        </div>

                        <div>
                            <p className="uk-h5">Siremás</p>
                            <p className="uk-h1 uk-margin-remove-top uk-margin-remove-bottom">
                                <strong>{loyalty?.puntos}</strong>
                            </p>
                            <p>
                                Saldo de puntos acumulados al <strong>{loyalty?.fecha}</strong>
                            </p>
                            <p>
                                <button
                                    className="uk-button uk-button-primary uk-button-small"
                                    onClick={() => openModal("loyalty")}
                                >
                                    <span>Ver Detalle</span> <i className="fas fa-eye"></i>
                                </button>
                            </p>

                            <p className="uk-h5">Siremás Digital</p>
                            <p className="uk-text-center">
                                <a href="#0" uk-toggle="target: #modal-code">
                                    <Barcode format="EAN13" value={account?.loyaltyid}/>,
                                    <br/>
                                    <small>Click para agrandar</small>
                                </a>
                            </p>
                            {address != null && (
                                <>
                                    <p className="uk-h5">Dirección principal</p>
                                    <p>
                                        <strong>{address?.sector}</strong>
                                        <br/>
                                        {address?.address},{address?.address2} <br/>
                                        {address?.municipality},{address?.sector}
                                    </p>
                                </>
                            )}
                            {parseInt(account?.order_quantity) > 0 ? (
                                <>
                                    <p className="uk-h5">Histórico de órdenes</p>
                                    <p>
                                        <Link to="order-history">
                                            Tiene <strong>{account?.order_quantity}</strong> órden(es)
                                        </Link>
                                    </p>
                                </>
                            ) : null}
                            {parseFloat(account?.balance) > 0 ? (
                                <>
                                    <p className="uk-h5">Balance tickets de cambio</p>
                                    <p>
                                        RD$ <strong>{DecimalFormat(account?.balance)}</strong>
                                    </p>
                                </>
                            ) : null}
                            {parseInt(sirenaCash?.credito) != 0 ? (
                                <>
                                    <p className="uk-h5">Balance en Credito (SirenaCash)</p>
                                    <p>
                                        RD${" "}
                                        <strong>
                                            {DecimalFormat(sirenaCash?.credito)}
                                        </strong>
                                    </p>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div
                        id="modal-code"
                        className="uk-flex-top uk-modal-container uk-modal"
                        data-uk-modal="bg-close: false"
                    >
                        <div className="uk-modal-dialog uk-margin-auto-vertical">
                            <button
                                className="uk-modal-close-default"
                                type="button"
                                uk-close="true"
                            ></button>
                            <div className="uk-modal-body">
                                <div className="title-border">
                                    <h1 className="uk-h3">En la tienda</h1>
                                </div>
                                <p className="uk-text-large uk-text-center">
                                    <strong>
                                        Escanee al finalizar la compra para aplicar sus ahorros
                                    </strong>
                                </p>
                                <div className="uk-text-center">
                                    <Barcode
                                        format="EAN13"
                                        height={256}
                                        width={4}
                                        value={account?.loyaltyid}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <LoyaltyModal
                open={open}
                vdetails={loyalty?.detalle}
                onSetClose={onSetClose}
            />
        </div>
    );
}
