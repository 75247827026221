import React from "react";
import ReactDOM from "react-dom";

export default function CartDeleteAll({ isOpen, onRemoveAll, onSetClose }) {

  const onDeleteClick = () => {
    onRemoveAll();
  };

  if (isOpen !== "remove-all") return null;
  return ReactDOM.createPortal(
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      <button
        onClick={() => onSetClose("modal-cart-deleteall")}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-body uk-text-center">
        <p className="uk-text-secondary">
          <i className="far fa-question-circle fa-3x"></i>
        </p>
        <p className="uk-h4">Limpiar Carrito</p>
        <p>¿Estás seguro que deseas borrar los artículos del carrito?</p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          onClick={onSetClose}
          className="uk-button uk-modal-close uk-button-custom"
        >
          <span>Cancelar</span> <i className="fas fa-times"></i>
        </button>
        <button
          onClick={onDeleteClick}
          className="uk-button uk-modal-close uk-button-default uk-button-custom"
          type="button"
        >
          <span>Borrar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
