/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { DecimalFormat, Encode, MoneyFormat } from "helpers/functions";
import { getData, postData } from "helpers/service";
import Loading from "components/share/loading/product";
import AccountContext from "context/Account";
import ProductItem from "../item";
import DistributionAlertModal from "components/product/distribution";
import WarrantyModal from "components/product/detail/warrantyModal";
import {
  PRODUCT,
  PICTURES,
  SPECS,
  QUANTITY,
  DISTRIBUTION_DETAIL,
  DISTRIBUTION,
  FAVORITE,
  RELATEDS,
  LOCATIONS,
  BASE_IMG,
  INIT_LOADING,
  OPEN,
  OPEN_FULL,
  OPEN_CONTAINER,
  LOADING,
  ACTION,
  VIDEO_SRC
} from "store/product/detail/actionTypes";
import { initialState } from "store/product/detail/state";
import VideoModal from "./videoModal";
import { updateStateReducer } from "store/common/commonReducer";


export default function ProductDetail({ friendlyurl }) {
  const [
    {
      loading,
      initLoading,
      product,
      favorite,
      quantity,
      action,
      baseImg,
      specs,
      pictures,
      relateds,
      locations,
      distribution,
      distributionDetail,
      open,
      openFull,
      openContainer,
      videoSrc
    },
    dispatch,
  ] = useReducer(updateStateReducer, initialState);

  const navigate = useNavigate();
  const location = useLocation();
  const INCLUDE = "include";
  const NOT_INCLUDE = "noinclude";
  const GENERAL = "general";
  const WARRANTY = "warranty";
  const TYPE_OF_DELIVERY = "72";
  const { loadShoppingCart, account, setSeoData } = useContext(AccountContext);

  useEffect(() => {
    if (friendlyurl !== "") {
      dispatch({ type: INIT_LOADING, payload: true });
      getData(`product/detail/${Encode(friendlyurl)}`, null, (res, err) => {
        if (err) return navigate("/", { replace: true });
        if (!err) {
          dispatch({ type: PRODUCT, payload: res.product });
          dispatch({ type: SPECS, payload: res.specs });
          dispatch({ type: PICTURES, payload: [...res.pictures]});
          dispatch({ type: RELATEDS, payload: res.relateds });
          dispatch({ type: LOCATIONS, payload: res.locations });
          dispatch({ type: BASE_IMG, payload: res.base_img });
          dispatch({ type: FAVORITE, payload: res.product.favorite });
          dispatch({ type: DISTRIBUTION_DETAIL, payload: res.distribution });
          dispatch({ type: QUANTITY, payload: parseFloat(res.product.minimum) });

          setSeoData({
            metaTitle: res.product?.meta_title,
            metaKeywords: res.product?.meta_keywords,
            metaDescription: res.product?.meta_description})

          if (res.product.cartid !== null) {
            dispatch({
              type: QUANTITY,
              payload: parseFloat(res.product.in_cart),
            });
          }
        }
        dispatch({ type: INIT_LOADING, payload: false });
      });
    }
  }, [friendlyurl, account]);

  useEffect(() => {
    if (action === "U") {
      updateToCart();
    }
  }, [quantity, action]);

  useEffect(() => {
    const { notify } = distribution;
    if (notify === 1) openModal("delivery");
  }, [distribution]);

  useEffect(() => {
    if (open !== "none") UIkit.modal("#webModal")?.show();
    if (openFull !== "none") UIkit.modal("#webModal-full")?.show();
    if (openContainer !== "none") UIkit.modal("#webModal-container")?.show();
  }, [open, openFull, openContainer]);

  const openModal = (action) => {
    dispatch({ type: OPEN, payload: action });
  };

  const onSetClose = (type, webModal) => {
    dispatch({ type, payload: "none" });
    UIkit.modal(webModal)?.hide();
  };

  const addToCart = () => {
    let data = {
      id: Encode(product.productid),
      qty: quantity,
    };

    dispatch({ type: LOADING, payload: true });
    postData("cart/add", data, {}, (res, err) => {
      if (!err) {
        let values = product;
        values["cartid"] = res.item.cartid;
        values["in_cart"] = res.item.in_cart;
        loadShoppingCart(res.balance);
        dispatch({ type: PRODUCT, payload: values });
        dispatch({ type: DISTRIBUTION, payload: res.distribution });
        dispatch({ type: ACTION, payload: "A" });
      }
      dispatch({ type: LOADING, payload: false });
    });
  };

  const updateToCart = () => {
    let data = {
      key: Encode(product.cartid),
      qty: quantity,
    };

    dispatch({ type: LOADING, payload: true });
    postData(`cart/${Encode(product.cartid)}/update`, data, {}, (res, err) => {
      if (!err) {
        loadShoppingCart(res.balance);
        dispatch({ type: ACTION, payload: "X" });
      }
      dispatch({ type: LOADING, payload: false });
    });
  };

  const ChangeFav = () => {
    let fav = !favorite;

    let data = {
      id: Encode(product.productid),
      favorite: fav,
    };

    postData(`account/favorite`, data, {}, (res, err) => {
      if (!err) {
        dispatch({ type: FAVORITE, payload: fav });
      }
    });
  };

  const upQuantity = () => {
    dispatch({
      type: QUANTITY,
      payload: parseFloat(quantity) + parseFloat(product?.producttype_step),
    });
    dispatch({ type: ACTION, payload: "U" });
  };

  const DownQuantity = () => {
    dispatch({
      type: QUANTITY,
      payload: parseFloat(quantity) - parseFloat(product?.producttype_step),
    });
    dispatch({ type: ACTION, payload: "U" });
  };

  const QuantityList = () => {
    const x = [];
    for (let i = 0; i < 6; i++) {
      let total = parseFloat(product?.producttype_step) * parseFloat(i);
      let values = DecimalFormat(
        parseFloat(product?.minimum) + total,
        product?.producttype_decimal
      );
      x.push(values);
    }

    return x.map((quantity, i) => <option key={i}>{quantity}</option>);
  };

  const handleViewProduct = ({ props }) => {
    const mediaType = props["media-type"];
    const videoSrc = props["video-src"];

    if (mediaType === "imagen") {
      let element = document.getElementById(props?.id);
      element.requestFullscreen();
    } else if (mediaType === "video") {
      dispatch({ type: VIDEO_SRC, payload: videoSrc });
      dispatch({ type: OPEN_CONTAINER, payload: "videoModal" });
    }
  };

  const KitInclude = () => {
    const include = specs?.filter((item) => item.code === INCLUDE);
    return (
      <>
        {include?.length > 0 && (
          <div className="uk-flex uk-flex-center gi_caja_incluye">
            <div className="gi_icon_caja">
              <img
                src="/assets/global/images/icon_instalacion1.png"
                alt="Loading..."
              />
            </div>
            <div className="gi_icon_cajatext">
              <h4>{include[0].items[0].spec}</h4>
              <p>{include[0].items[0].value}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  const KitNotInclude = () => {
    const noInclude = specs?.filter((item) => item.code === NOT_INCLUDE);
    return (
      <>
        {noInclude?.length > 0 && (
          <div className="uk-flex uk-flex-center gi_caja_incluye">
            <div className="gi_icon_caja">
              <img
                src="/assets/global/images/icon_instalacion2.png"
                alt="Loading..."
              />
            </div>
            <div className="gi_icon_cajatext">
              <h4>{noInclude[0].items[0].spec}</h4>
              <p>{noInclude[0].items[0].value}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  const AvailableStores = () => {
    const AlertUnavailableItem = (
      <div className="uk-text-small uk-alert uk-alert-warning">
        <i className="fas fa-exclamation-triangle"></i>
        <p>{`No disponible en ${account?.default_location}`} </p>
      </div>
    );

    const LocationsList =
      locations?.length > 0 ? (
        <div className="uk-text-small">
          <h3 className="uk-h5">Puedes encontrarlo en:</h3>
          <ul className="uk-list uk-list-disc">
            {locations?.map((item, i) => (
              <li key={i}>{item.location}</li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      );

    return (
      <>
        {product?.visible === 0 && account ? (
          <div className="item-product-location" style={{ display: "block" }}>
            {AlertUnavailableItem}
            {LocationsList}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };


  const ProductDetail = () => (
    <>
      <section className="uk-section">
        <div className="uk-container">
          <div
            id="view-item"
            data-id={product?.productid}
            data-name={product?.name}
            data-url={location?.pathname}
            data-image-url={product?.thumbs}
            data-description={product?.description}
            data-price={product?.price}
            data-list-price={product?.regular_pice}
            data-currency={"DOP"}
            data-sku={product?.sku}
            data-categories={product?.category}

            data-coupon={product?.coupon}
            data-discount={product?.discount}
            data-item-brand={product?.item_brand}
            data-item-category={product?.item_category}
            data-item-category2={product?.item_category2}
            data-item-category3={product?.item_category3}
            data-variant={product?.item_variant}

            data-related-catalog-object={JSON.stringify({
              relatedCatalogObject: {
                brand: product?.brand,
                offer: product?.especially === 1 ? "Si" : "No",
              },
            })}
            className={`uk-grid uk-grid-large item-product-detail ${
              product.available_order === 0 && "item-unavailable"
            } ${product.visible === 0 && "item-not-in-location"} ${
              product.cartid !== null && "item-in-cart"
            } ${product.especially === 1 && "item-offer"}`}
            uk-grid="true"
          >
            <div className="uk-width-1-1 uk-width-1-2@m">
              <div
                className="uk-width-1-1 uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small item-product-image-full-content"
                uk-sticky="offset: 130; bottom: true; media: @m"
              >
                {pictures?.length > 0 && (
                  <Carousel
                    infiniteLoop
                    useKeyboardArrows
                    showIndicators={false}
                    showStatus={false}
                    thumbWidth={60}
                    className="productCarousel"
                    onClickItem={(_, item) => handleViewProduct(item)}
                    stopOnHover={false}
                  >
                    {pictures.map((item, idx) => (
                      <img
                        key={idx}
                        id={`img-${idx}`}
                        className="uk-animation-fade"
                        alt="Cargando..."
                        src={item?.original}
                        media-type={item?.media_type}
                        video-src={item?.video_src}
                      />
                    ))}
                  </Carousel>
                )}
                {distributionDetail?.notify !== 0 ? (
                  <div className="uk-margin-medium-top uk-flex uk-flex-center gi_caja_metodoentrega">
                    <div className="gi_icon_caja">
                      <img
                        src="/assets/global/images/icon_metodoentrega.png"
                        alt="Loading..."
                      />
                    </div>
                    <div className="gi_icon_cajatext">
                      <h4>Disponible por</h4>
                      <h5>{distributionDetail.name}</h5>
                      <h6>{distributionDetail.time}</h6>
                    </div>
                  </div>
                ) : null}

                {specs
                  ?.filter((item) => item.code === GENERAL)[0]
                  ?.items.some((item) => item.code === WARRANTY) ? (
                  <div className="uk-margin-medium-top uk-flex uk-flex-center gi_garantia">
                    <div className="gi_boton_garantia">
                      <button
                        className="uk-button uk-button-primary"
                        onClick={() =>
                          dispatch({ type: OPEN_FULL, payload: "warranty" })
                        }
                      >
                        Garantía
                      </button>
                      <p>
                        Ver términos de servicio y condiciones de la garantía
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="uk-width-1-1 uk-width-1-2@m">
              <button
                data-id={product.productid}
                className="btn-favorite"
                uk-tooltip={
                  favorite ? "Borrar de Favoritos" : "Agregar a Favoritos"
                }
                onClick={ChangeFav}
              >
                <i className={favorite ? "fas fa-heart" : "far fa-heart"}></i>
              </button>

              <div className="title-border">
                <h2 className="uk-h3">{product?.name}</h2>
              </div>

              <div className="item-product-info">
                <p className="item-product-price">
                  {" "}
                  {product.especially === 1 && (
                    <small>{MoneyFormat(product?.regular_price)}</small>
                  )}
                  <strong>{MoneyFormat(product?.price)}</strong>
                </p>
              </div>

              <div className="item-product-add">
                <div className="item-product-quantity">
                  <div className="item-product-quantity-select">
                    <select className="uk-select">
                      <QuantityList />
                      <option value="+">Más</option>
                    </select>
                  </div>
                  <div className="item-product-quantity-buttons">
                    <button
                      id="btn-det-cart-minus"
                      data-id={product?.productid}
                      data-quantity={product?.producttype_step}

                      data-price={product?.price}
                      data-currency="DOP"
                      data-coupon={product?.coupon}
                      data-discount={product?.discount}
                      data-item-brand={product?.item_brand}
                      data-item-category={product?.item_category}
                      data-item-category2={product?.item_category2}
                      data-item-category3={product?.item_category3}
                      data-variant={product?.item_variant}

                      disabled={
                        quantity <= parseFloat(product?.minimum) ? true : false
                      }
                      onClick={DownQuantity}
                      className="uk-button uk-button-primary btn-minus"
                      type="button"
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                    <span id="current-quantity">
                      {DecimalFormat(quantity, product?.producttype_decimal)}
                    </span>
                    <button
                      id="btn-det-cart-plus"
                      data-id={product?.productid}
                      data-quantity={product?.producttype_step}

                      data-price={product?.price}
                      data-currency="DOP"
                      data-coupon={product?.coupon}
                      data-discount={product?.discount}
                      data-item-brand={product?.item_brand}
                      data-item-category={product?.item_category}
                      data-item-category2={product?.item_category2}
                      data-item-category3={product?.item_category3}
                      data-variant={product?.item_variant}

                      onClick={upQuantity}
                      className="uk-button uk-button-primary btn-plus"
                      type="button"
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="item-product-btn">
                  <button
                    id="btn-det-add-to-cart"
                    data-id={product?.productid}
                    data-quantity={product?.minimum}

                    data-price={product?.price}
                    data-currency="DOP"
                    data-coupon={product?.coupon}
                    data-discount={product?.discount}
                    data-item-brand={product?.item_brand}
                    data-item-category={product?.item_category}
                    data-item-category2={product?.item_category2}
                    data-item-category3={product?.item_category3}
                    data-variant={product?.item_variant}

                    onClick={addToCart}
                    disabled={loading}
                    className="uk-button uk-button-primary btn-add-cart"
                    type="button"
                  >
                    <span>agregar</span>{" "}
                    <i className="fas fa-shopping-cart"></i>
                  </button>
                </div>
              </div>

              <div className="item-product-store">
                <p>
                  <i className="fas fa-info-circle"></i>{" "}
                  <small>Solo disponible en tiendas.</small>
                </p>
              </div>

              <AvailableStores />

              <hr className="uk-margin-medium-top uk-margin-medium-bottom" />
              <div dangerouslySetInnerHTML={{ __html: product?.description }} />
              {specs?.length > 0 && (
                <>
                  <p className="uk-h5">Características</p>
                  <table className="uk-table uk-table-small uk-table-striped">
                    <tbody>
                      {specs
                        ?.filter((item) => item.code === GENERAL)
                        ?.map((item, idx) =>
                          item.items
                            ?.filter(
                              (item) =>
                                (item.code !== WARRANTY) &&
                                (item.code !== TYPE_OF_DELIVERY)
                            )
                            .map((detail, idx) => (
                              <tr key={idx}>
                                <td>{detail.spec}</td>
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: detail.value,
                                  }}
                                ></td>
                              </tr>
                            ))
                        )}
                    </tbody>
                  </table>
                  <KitInclude />
                  <KitNotInclude />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container uk-container-expand">
          <div className="title-border">
            <h2 className="uk-h4">También te pueden interesar...</h2>
          </div>

          <div className="slider-products" uk-slider="sets: true">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-grid-match">
                  {relateds.map((p) => (
                    <li key={p.productid}>
                      <ProductItem
                        key={p.productid}
                        baseImg={baseImg}
                        item={p}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <a
                className="uk-position-center-left-out slider-arrow"
                href="#0"
                uk-slider-item="previous"
              >
                <i className="fas fa-chevron-left"></i>
              </a>
              <a
                className="uk-position-center-right-out slider-arrow"
                href="#0"
                uk-slider-item="next"
              >
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <DistributionAlertModal
        isOpen={open}
        onSetClose={onSetClose}
        distribution={distribution}
      />
      <WarrantyModal isOpen={openFull} onSetClose={onSetClose} />
      <VideoModal
        src={videoSrc}
        isOpen={openContainer}
        onSetClose={onSetClose}
      />
    </>
  );

  return initLoading ? <Loading /> : <ProductDetail />;
}
