import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageNotFound from "components/share/error/404";
import { getData } from "helpers/service";
import Loading from "components/share/loading/content";
import { Encode } from "helpers/functions";
import AccountContext from "context/Account";

const initialState = {
  loading: true,
  content: "",
};
export default function ContentPage() {
  let { friendlyurl } = useParams();
  const { setSeoData } = useContext(AccountContext);

  const [{ loading, content }, setState] = useState(initialState);

  useEffect(() => {
    getData(`setting/site/content/${Encode(friendlyurl)}`, null, (res, err) => {
      if (!err) {
        if (Boolean(res.found)) {
          setState((prevState) => ({
            ...prevState,
            content: res.data?.content,
          }));
          setSeoData({
            metaTitle: res.data?.meta_title,
            metaKeywords: res.data?.meta_keywords,
            metaDescription: res.data?.meta_description,
          });
        }
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendlyurl]);

  const Content = () => {
    return (
      <section className="uk-section uk-section-small">
        {content?.length === 0 ? (
          <PageNotFound />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="uk-container"
          />
        )}
      </section>
    );
  };

  return loading ? <Loading /> : <Content />;
}
