import React, { useState } from "react";
import ShoppingListDeleteItem from "./deleteItem";
import { getData, postData } from "helpers/service";
import { Encode, MoneyFormat } from "helpers/functions";
import Loading from "components/share/loading/list";
import UIkit from "uikit";

const ShoppingList = ({ shoppingLists, openRemoveList, postLoading, addToCard }) => {
  const [baseImg, setBaseImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [slItems, setSlItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [listId, setListId] = useState("");
  const [open, setOpen] = useState("");

  



  const openRemoveItemList = (id, item) => {
    setListId(id);
    setCurrentItem(item);
    setOpen("remove-list-item");
    UIkit.modal(`#deleteItemModal`).show();
  };



  const RemoveItemList = (listId, productId) => {
    setLoading(true);

    postData(
      `account/shopping-list/${Encode(listId)}/delete/${Encode(
        productId
      )}/item`,
      null,
      null,
      (res, err) => {
        setSlItems([]);
        if (!err) {
          setSlItems(res.items || []);
        }
        setLoading(false);
      }
    );
  };

  const DetailsList = (listId) => {
    setLoading(true);
    getData(
      `account/shopping-list/${Encode(listId)}/items`,
      null,
      (res, err) => {
        
        if (!err) {
          setBaseImg(res.base_img);
          setSlItems(res.items || []);
        }
        setLoading(false);
      }
    );
  };

  const ShoppingListsItems = ({ listId }) => (
    <>
      <ul className="uk-list uk-list-divider uk-list-large">
        {loading && <Loading />}
        {slItems.map((sli) => (
          <li key={sli.productid}>
            <div className="item-list">
              <a
                href="#0"
                className="item-list-image"
                style={{ backgroundImage: `url(${baseImg}${sli?.thumbs})` }}
              >
                {""}
              </a>
              <div className="item-list-info uk-width-expand">
                <p className="uk-h5">
                  <a href="#0">{sli.name}</a>
                </p>
                <p className="uk-text-meta uk-margin-remove-top">
                  {sli?.quantity} {sli?.producttype} x {MoneyFormat(sli?.price)}
                </p>
                <div className="uk-flex uk-flex-wrap uk-grid-small uk-child-width-1-1 uk-child-width-auto@s uk-text-small"></div>
              </div>
              <div className="item-list-btns uk-width-auto uk-flex-last@s">
                <a
                  onClick={(e) => {
                    openRemoveItemList(listId, sli);
                    e.preventDefault();
                  }}
                  href="#0"
                  className="uk-button  uk-button-custom"
                >
                  <i className="far fa-trash-alt"></i>
                </a>
              </div>
              <div className="item-list-detail uk-width-1-1 uk-width-auto@s uk-text-right">
                <p className="uk-text-bold">{MoneyFormat(sli?.quantity * sli?.price)}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      <div className="uk-card uk-card-default uk-padding uk-border-rounded uk-box-shadow-small uk-margin-medium-top uk-margin-large-bottom">
        <ul className="uk-accordion" data-uk-accordion="multiple: true">
          {shoppingLists.map((sl) => (
            <li key={sl.listid}>
              <a
                onClick={(e) => {
                  DetailsList(sl.listid);
                  e.preventDefault();
                }}
                className="uk-accordion-title uk-text-bold"
                href="#0"
              >
                {sl.list}
              </a>
              <div className="uk-accordion-content">
                <div
                  className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom nav-filters"
                  uk-grid="true"
                >
                  <div>
                    <a
                      href="#0"
                      className="uk-button  uk-button-custom"
                      onClick={(e) => {
                        openRemoveList(sl);
                        e.preventDefault();
                      }}
                    >
                      <span>Borrar lista</span>
                      <i className="far fa-trash-alt"></i>
                    </a>
                  </div>
                  <div>
                    <span className="uk-inline uk-margin-right">
                      <i className="fas fa-cart-plus"></i> {sl.items} artículos
                    </span>
                    <a
                      href="#0"
                      className="uk-button uk-button-primary"
                      disabled={postLoading}
                      onClick={(e) => {
                        addToCard(sl.listid);
                        e.preventDefault();
                      }}
                    >
                      <span>Agregar al carrito</span>
                      <i className="fas fa-cart-plus"></i>
                    </a>
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <ShoppingListsItems listId={sl.listid} />
                )}
              </div>
            </li>
          ))}
        </ul>
        <ShoppingListDeleteItem
          isOpen={open === "remove-list-item"}
          item={currentItem}
          listid={listId}
          onSetDelete={RemoveItemList}
        />
      </div>

      <div
          id="modal-sl-delete-item"
          className="uk-flex-top uk-modal"
          uk-container="false"
          data-uk-modal="bg-close: false"
        ></div>
    </>
  );
};

export default ShoppingList;
