import React from "react";
import AccountLogin from "components/account/auth/login";
import AccountLostPwd from "components/account/auth/lost-password";

const AuthAnonymous = ({ source }) => {
  return (
    <div className="uk-container uk-container-expand">
      <div className="uk-grid uk-grid-medium uk-padding " uk-grid="true">
        <div className="uk-width-1-1 uk-width-1-5@l" />
        <div className="uk-width-1-1 uk-width-3-5@l uk-background-default">
          <div className="uk-card uk-card-default uk-padding uk-border-rounded uk-box-shadow-small uk-margin-medium-top uk-margin-large-bottom">
            <div className="uk-form-horizontal">
              <AccountLogin source={source} />
              <AccountLostPwd />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthAnonymous;
