import React, { useContext, useEffect, useRef, useState } from "react";
import { DecimalFormat } from "helpers/functions";
import UIkit from "uikit";
import SiremasPointsModal from "./siremasPointsModal";
import ShippingRate from "components/cart/checkout/shipping-rate";
import TimeSlot from "components/cart/checkout/timeslot";
import { CheckoutContext } from "context/Checkout";

const SupermarketCard = ({
  items,
  balance,
  shippingRate = {},
  superMarketAddresses = {},
  points,
  onSetShippingRate,
  typeDelivery ='supermarket'
}) => {

  const [openModal, setOpenModal] = useState("none");
  const checkRef = useRef(null);

  const {
    locationslot,
    locationslotId,
    setLocationslot,
    setLocationslotId,
    handleSetPointsApplied,
    pointsApplied,
    handleUnSetPointsApplied
  } = useContext(CheckoutContext);

  const {
    location,
    location_address,
    address_tag,
    sector,
    address,
    shipping_type,
    shipping_rate: addressShippingRate,
  } = superMarketAddresses;
  const { shipping_rate, pickup_name } = shippingRate;

  let initialValue = 0;
  const orderAmount = items.reduce(
    (prev, current) => parseFloat(prev) + parseFloat(current.total),
    initialValue
  );

  const handleOnChange = (e) => {
    if (e.target.checked) {
      setOpenModal("points-modal");
      UIkit.modal("#webModal-container")?.show();
    } else {
       handleUnSetPointsApplied(typeDelivery);
    }
  };

  const onSetCloseModalSiremas = (isPointsApplied, points = 0) => {
    setOpenModal("none");
    if (!isPointsApplied) {
      checkRef.current.checked = false;
    } else {
      let params={
        type:typeDelivery,
        points
      }
         handleSetPointsApplied(params);
      //Set Session Storage
    }
  };

  const onSetClose = (
    action = `none`,
    modal = "webModal",
    pointsApplied = false
  ) => {
    setOpenModal(action);
    if (action === "none") {
      UIkit.modal(`#${modal}`)?.hide();
    }
  };

  const onSetTimeSlot = (xlocationslotId, xlocationslot) => {
    setLocationslotId(xlocationslotId);
    setLocationslot(xlocationslot);
    onSetClose();
  };

  useEffect(() => {
    if (openModal !== "none") return;
    UIkit.modal(`#webModal-container`)?.hide();
  }, [openModal]);

  useEffect(() => {
    if (parseInt(pointsApplied[typeDelivery]) > 0) checkRef.current.checked = true;
  }, [pointsApplied, typeDelivery]);

  if (items?.length === 0) return null;

  return (
    <>
      <div className="uk-card gi_checkout_bloque1">
        <div className="gi_leftblock">
          <p className="uk-h3">Tu compra por {shipping_rate}</p>
          <p className="tiene">
            tiene {balance?.volumen}{" "}
            {balance?.volumen > 1 ? "artículos" : "artículo"}
          </p>
        </div>
        <div className="gi_rightblock">
          <p className="uk-h3">$ {DecimalFormat(orderAmount)}</p>
        </div>
        <div className="item-list-block">
          <div
            className="uk-child-width-1-2@m uk-grid uk-grid-small"
            uk-grid="true"
          >
            <div>
              <p>
                Entrega: <strong>{addressShippingRate}</strong> (
                <button
                  className="uk-button uk-button-link"
                  onClick={() => setOpenModal("shipping-rate")}
                  uk-toggle="target: #webModal-container"
                >
                  Cambiar
                </button>
                )
              </p>
              {shipping_type === "P" ? (
                <p className="uk-text-bold gi_addr">
                  Entregar a <i>{pickup_name}</i>
                  <br />
                  {location} <br />
                  {location_address}
                </p>
              ) : (
                <p className="uk-text-bold gi_addr">
                  {address_tag}
                  <br />
                  {sector}
                  <br />
                  {address}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="item-list-block">
          <div>
            <button
              data-test="change-schedule"
              onClick={() => setOpenModal("timeslot")}
              className="uk-button uk-button-danger gi_round_button gi_hours_button"
              uk-toggle="target: #webModal"
            >
              <i className="far fa-clock"></i>
              <span>Horario</span>
            </button>
          </div>
          <div>
            <p data-test="timeslot-text" className="uk-text-bold">
              {locationslotId !== null
                ? locationslot
                : "No tiene un horario asignado."}
            </p>
          </div>

          {/* Switch */}
          {/* <div className="gi_aplic_puntos">
            <label className="switch">
              <input
                ref={checkRef}
                type="checkbox"
                defaultChecked={false}
                disabled={points === 0}
                onChange={handleOnChange}
              />
              <span className="slider round"></span>
            </label>
            <span className="toggle-legend">
              Aplicar puntos Siremás a este pedido
            </span>
          </div> */}

          <div className="uk-child-width-1-2@m uk-grid uk-grid-small gi_checkout_comment">
            <textarea
              className="uk-textarea"
              rows="2"
              cols="30"
              placeholder="Comentario (opcional)"
            ></textarea>
          </div>
        </div>
      </div>
      <TimeSlot
        isOpen={openModal}
        onSetClose={onSetClose}
        onSetTimeSlot={onSetTimeSlot}
      />

      <SiremasPointsModal
        isOpen={openModal}
        points={points}
        onSetClose={onSetCloseModalSiremas}
        orderAmount={orderAmount}
      />

      <ShippingRate
        isOpen={openModal}
        onSetClose={onSetClose}
        onSetShippingRate={onSetShippingRate}
      />
    </>
  );
};

export default SupermarketCard;
