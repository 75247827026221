import React from "react";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { Outlet } from "react-router-dom";
import Header from "components/share/header";
import Footer from "components/share/footer";
import SEOHelmet from "components/share/SEO";

UIkit.use(Icons);

export default function Site() {
  return (
    <>
      <Header />
      <main role="main" id="container">
        <Outlet />
      </main>
      <Footer />
      <SEOHelmet/>
    </>
  );
}
