import React from "react";

const NoContent = () => {
  return (
    <div className="uk-flex uk-flex-column uk-width-1-1">
      <div>
        <img src="/assets/background/error-notify.svg" alt="Loading..." />{" "}
      </div>
      <div className="uk-margin-top uk-text-center">
        <p>
          <strong>Sin contenido para mostrar.</strong>
        </p>
      </div>
    </div>
  );
};

export default NoContent;
