import ProductItem from "components/product/item";
import AccountContext from "context/Account";
import Loading from "components/share/loading/list";
import { getData } from "helpers/service";
import React, { useState, useEffect } from "react";
import { useContext } from "react";

const BuyAgain = () => {
  const { account, reloadItems } = useContext(AccountContext);
  const [buyAgainItems, setBuyAgainItems] = useState([]);
  const [baseImg, setBaseImg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getData("account/favorite", null, (res, err) => {
      if (!err) {
        setBuyAgainItems(res.buyAgain);
        setBaseImg(res.base_img);
      }
      setLoading(false);
    });
  }, [account, reloadItems]);
  
  if(loading) return <Loading/>
  return buyAgainItems?.length > 0 ? (
    <section className="uk-section uk-section-small">
      <div className="uk-section uk-section-small">
        <div className="title-border">
          <h2 className="uk-h4">Comprar nuevamente...</h2>
        </div>
        <div className="slider-products-fav" uk-slider="sets: true">
          <div className="uk-position-relative">
            <div className="uk-slider-container">
              <ul className="uk-slider-items uk-grid-match">
                {buyAgainItems.map((item) => (
                  <li key={item.productid}>
                    <ProductItem
                      key={item.productid}
                      baseImg={baseImg}
                      item={item}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <a
              className="uk-position-center-left-out slider-arrow"
              href="#0"
              uk-slider-item="previous"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <a
              className="uk-position-center-right-out slider-arrow"
              href="#0"
              uk-slider-item="next"
            >
              <i className="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default BuyAgain;
