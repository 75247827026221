/* eslint-disable */
import React, { useContext, useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode, publishEvent, unsubscribeEvent  } from "helpers/functions";
import Loading from "components/share/loading";
import AccountContext from "context/Account";
import UIkit from "uikit";
import { useNavigate } from "react-router-dom";

const LogIn = ({ source='local' }) => {
  const navigate = useNavigate();
  const submitRef = useRef();

  const { onLogIn } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);

    postData(
      `auth/submit`,
      {
        username: Encode(data["username_login"]),
        password: Encode(data["password_login"]),
      },
      {},
      (res, err) => {
        if (!err) {
          onLogIn(res.data);
          if (source === "modal") {
            UIkit.modal("#webLoginModal")?.hide();
          }
          handleSignInSirenaCash();
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      unsubscribeEvent("login", null, "submit_login-modal");
    };
  }, []);

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const toRegister = () => {
    if (source === 'modal') UIkit.modal('#webLoginModal')?.hide();
    if (source === 'header') UIkit.dropdown('#dropdown-my-account')?.hide();
    
    navigate("/account-register", { replace: true });
  };

  const handlePassword = (event) => {
    if (event.keyCode === 13) {
      submitRef.current.click();
    }
  };

  //************* CRM **************//

  const handleSignInSirenaCash = () => {
    const email = getValues("username_login");
    publishEvent("login", email, "submit_login-modal");
  };

  //*************Fin CRM**************//

  return (
    <div
      id="login-user-box"
      className="box-user box-user-login toggle-login toggle-password"
    >
      <div className="uk-text-center">
        <p className="uk-h4">Ingresar</p>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
            <p>
              <input
                className="uk-input"
                name="username_login"
                id={`username_login-${source}`}
                autoComplete="username"
                type="email"
                placeholder="Correo electrónico"
                {...register("username_login", {
                  required: "El correo electrónico es requerido",
                })}
              />
            </p>
            <p className="error">
              {errors?.username && errors?.username.message}
            </p>
            <p>
              <input
                className="uk-input"
                onKeyDown={handlePassword}
                name="password_login"
                id={`password_login-${source}`}
                autoComplete="current-password"
                type="password"
                placeholder="Contraseña"
                {...register("password_login", {
                  required: "La contraseña es requerida",
                })}
              />
            </p>
            <p className="error">
              {errors?.password && errors?.password.message}
            </p>
            <p>
              <label>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  defaultChecked={true}
                />{" "}
                <small>Mantenerme conectado</small>
              </label>
            </p>
            <p>
              <button
                ref={submitRef}
                id={`submit_login-${source}`}
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="uk-button uk-button-default uk-width-1-1"
                disabled={isLoading}
              >
                Ingresar
              </button>
            </p>
            <p className="uk-text-small uk-text-bold uk-text-center">
              <a href="#" uk-toggle="target: .toggle-password">
                Olvidé mi contraseña
              </a>
            </p>
          </form>
          <hr />
          <div className="uk-text-center">
            <p className="uk-h5">¿Aún no eres miembro?</p>
            <p>
              <button
                id={`btn-sign-in-${source}`}
                onClick={toRegister}
                type="button"
                className="uk-button uk-button-default uk-button-custom uk-button-primary uk-width-1-1"
                disabled={isLoading}
              >
                Regístrate
              </button>
            </p>
          </div>
        </>
      )}
    </div>
  );
}
export default LogIn;
