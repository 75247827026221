/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import alert from "helpers/alerts";
import AccountContext from "context/Account";
import { Encode, setTitle } from "helpers/functions";

export default function PasswordChangePage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { onLogOut } = useContext(AccountContext);

  setTitle("Actualizar Contraseña");

  const onSubmit = (data) => {
    let pwd = {
      current_password: Encode(data["current_password"]),
      new_password: Encode(data["new_password"]),
      new_password_confirm: Encode(data["new_password_confirm"]),
    };

    setLoading(true);
    postData("account/profile/new-password", pwd, {}, (res, err) => {
      if (!err) {
        alert.toast(res.message).then(() => {
          onLogOut();
        });
      }
      setLoading(false);
    });
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="title-border uk-flex uk-flex-between uk-flex-bottom uk-margin-medium-bottom">
        <h2 className="uk-h3">Actualizar contraseña</h2>
        <div className="uk-hidden@m">
          <a
            href="#"
            className="uk-button uk-button-default uk-button-custom"
            uk-toggle="target: #modal-filters"
          >
            <i className="fas fa-filter"></i>
          </a>
        </div>
      </div>
      <p className="uk-text-small">
        (*) Los campos marcados con asteriscos son obligatorios.
      </p>

      <div className="uk-width-1-1 uk-width-3-4@m uk-width-3-5@l">
        <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
          <div className="uk-card uk-card-default uk-padding uk-border-rounded uk-box-shadow-small uk-margin-medium-top uk-margin-large-bottom">
            <div className="uk-form-horizontal">
              <div className="title-border">
                <p className="uk-h5">Datos de acceso:</p>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="current_password">
                  Contraseña actual <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="current_password"
                    name="current_password"
                    {...register("current_password", {
                      required: "Contraseña actual es requerida",
                    })}
                    type="password"
                    placeholder="Contraseña actual"
                  />
                  <p className="error">
                    {errors?.current_password &&
                      errors?.current_password.message}
                  </p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="new_password">
                  Contraseña nueva <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_password"
                    name="new_password"
                    {...register("new_password", {
                      required: "La Contraseña nueva  es requerida",
                      minLength: {
                        value: 8,
                        message:
                          "La contraseña debe tener al menos 8 caracteres",
                      },
                    })}
                    type="password"
                    placeholder="Contraseña nueva"
                  />
                  <p className="error">
                    {errors.new_password && errors.new_password.message}
                  </p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="new_password_confirm">
                  Confirmar contraseña <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_password_confirm"
                    name="new_password_confirm"
                    type="password"
                    placeholder="Confirmar contraseña nueva"
                    {...register("new_password_confirm", {
                      required: "Confirmar la contraseña es requerido",
                      validate: {
                        comparepwd: (value) =>
                          value === getValues("new_password") ||
                          "Las contraseñas son diferentes",
                      },
                    })}
                  />
                  <p className="error">
                    {errors.new_password_confirm &&
                      errors.new_password_confirm.message}
                  </p>
                </div>
              </div>
              <hr className="uk-margin-medium-top uk-margin-medium-bottom" />
              <div className="uk-margin uk-text-center">
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="uk-button uk-button-primary"
                >
                  <span>Actualizar</span> <i className="fas fa-check"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
