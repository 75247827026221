import React from 'react'
import ReactDOM from 'react-dom'
import { OPEN_CONTAINER } from 'store/product/detail/actionTypes';

const VideoModal = ({src='', isOpen, onSetClose }) => {
    
    if (isOpen !== 'videoModal') return null;
  
    return ReactDOM.createPortal(
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <button      
            className="uk-modal-close-default"
            type="button"
            data-uk-close
            onClick={()=>onSetClose(OPEN_CONTAINER,'#webModal-container')}
          ></button>
             
          <div className="uk-modal-header">
            <p className="uk-h4 uk-text-uppercase uk-text-secondary">
              Videos
            </p>
            <hr/>
          </div>
    
        <div className="uk-modal-body uk-flex carousel-container"> 

          <div className='carousel-video-container'>
            <video  autoPlay controls loop>
              <source src={src}/>
            </video>
          </div> 

          <aside className='carousel-list-video-container uk-padding uk-padding-remove-vertical'>
            <p>
              video - 1
              <img src='/assets/example/round-play-white.png' alt='cargando...'/>
            </p>
            <hr/>
            <p>
              video - 2
              <img src='/assets/example/round-play-white.png' alt='cargando...'/>
            </p>
            <hr/>
            <p>
              video - 3
              <img src='/assets/example/round-play-white.png' alt='cargando...'/>
            </p>
            <hr/>          
          </aside>
          
        </div>
        </div>,
        document.getElementById("webModal-container")
      );
}

export default VideoModal;