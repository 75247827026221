import React, { useState } from "react";
import { GetTypeDevice } from "helpers/functions";
import { useForm } from "react-hook-form";
import { getData } from "helpers/service";
import { Encode, setTitle } from "helpers/functions";
import Loading from "components/share/loading/table";
import Loyalty from "components/share/loyalty";
import { BannerSiremasModel } from "models/adsense";
import Advertisements from "components/share/adsense";

export default function LoyaltyPage() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [details, setDetails] = useState([]);
  const [current, setcurrent] = useState(1);
  const currentType = GetTypeDevice();
  setTitle("Club Siremás");

  const onSubmit = (data) => {
    setLoading(true);
    getData(
      `setting/site/loyalty/point`,
      {
        documentid: Encode(data["documentid"]),
        loyaltyid: Encode(data["loyaltyid"]),
      },
      (res, err) => {
        if (!err) {
          setDetails(res.data.detalle);
          setcurrent(1);
        }
        setLoading(false);
      }
    );
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="uk-section uk-margin-medium-bottom">
        {BannerSiremasModel.filter((item) => item.type === currentType).map(
          (item, idx) => (
            <div
              key={idx}
              className={`uk-section uk-section-small uk-flex uk-flex-center ${item.classname}`}
            >
              <Advertisements
                type={item.type}
                slotId={item.slotId}
                sizes={item.sizes}
                adUnit={item.adUnit}
                targetingArguments={item.arguments}
              />
            </div>
          )
        )}

        <div className="uk-container">
          <div className="uk-flex uk-grid uk-grid-large" uk-grid="true">
            <div className="uk-width-2-5@m uk-flex uk-flex-wrap uk-flex-center">
              <div className="uk-width-1-3 uk-width-1-4@s uk-margin-medium-bottom uk-hidden@m">
                <img src="/assets/logo/logo_siremas.png" alt="Club Siremás" />
              </div>

              <div className="uk-width-1-1 uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
                <h2 className="uk-h5 uk-text-center">
                  Consulta tus puntos Siremás
                </h2>
                <p className="uk-flex uk-flex-bottom uk-flex-right uk-text-small hover-siremas cursor-pointer">
                  <strong className="uk-width-expand uk-text-right uk-margin-right">
                    ¿Dónde encontrar tu número Siremás?
                  </strong>
                  <span className="uk-width-auto">
                    <img
                      src="/assets/siremas/img-siremas-mini.png"
                      alt="siremas"
                    />
                  </span>
                </p>
                <div
                  className="uk-drop"
                  uk-drop="mode:click; pos: bottom-right; animation: uk-animation-slide-bottom-small; toggle: .hover-siremas; boundary: .content"
                >
                  <img src="/assets/siremas/img-siremas.png" alt="siremas" />
                </div>

                <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
                  <p>
                    <input
                      id="loyaltyid"
                      name="loyaltyid"
                      className="uk-input"
                      type="text"
                      placeholder="Siremás"
                      {...register("loyaltyid", {
                        required: "El número de Siremás es requerida",
                      })}
                    />
                  </p>
                  <p className="error">
                    {errors?.loyaltyid && errors?.loyaltyid.message}
                  </p>
                  <p>
                    <input
                      id="documentid"
                      name="documentid"
                      className="uk-input"
                      type="text"
                      placeholder="Cédula o Pasaporte"
                      {...register("documentid", {
                        required: "El número Cédula o Pasaporte es requerido",
                      })}
                    />
                  </p>
                  <p className="error">
                    {errors?.documentid && errors.documentid.message}
                  </p>
                  <p>
                    <button
                      id="btn-check-sirepoints"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      className="uk-button uk-button-default uk-width-1-1"
                    >
                      Consultar
                    </button>
                  </p>
                </form>
              </div>
            </div>
            <div className="uk-width-3-5@m uk-flex-first@m">
              <div className="uk-width-1-3 uk-width-1-4@l uk-margin-medium-bottom uk-visible@m">
                <img src="/assets/logo/logo_siremas.png" alt="Club Siremás" />
              </div>
              <div className="title-border">
                <h1 className="uk-h3">Beneficios del Club Siremás</h1>
              </div>
              <p className="uk-text-large">
                <strong>
                  El más exitoso programa de lealtad a nivel comercial en la
                  República Dominicana.
                </strong>{" "}
                Establecido en el año 2003, el programa cuenta actualmente con
                más de 1,400,000 afiliados, quienes disfrutan de los siguientes
                beneficios:
              </p>
            </div>

            {loading ? (
              <Loading />
            ) : (
              details?.length > 0 && (
                <div className="uk-width-1-1">
                  <div className="title-border uk-margin-medium-bottom">
                    <h2 className="uk-h3">Balance de Siremas</h2>
                  </div>
                  <Loyalty vdetails={details} vcurrent={current} />
                </div>
              )
            )}

            <div className="uk-width-1-3@m">
              <h3 className="uk-h5">Dinero</h3>
              <p>
                Al presentar su tarjeta Siremás al momento de pagar en Sirena y
                Súper Pola, acumulan 1 punto por cada RD$100 pesos de compra.
              </p>
            </div>
            <div className="uk-width-1-3@m">
              <h3 className="uk-h5">Promociones</h3>
              <p>
                Los puntos acumulados pueden ser redimidos por vales para compra
                en nuestros establecimientos y pueden ser canjeados por boletos
                electrónicos en promociones.
              </p>
            </div>

            <div className="uk-width-1-3@m">
              <h3 className="uk-h5">Diversión</h3>
              <p>
                Participan en promociones, ofertas y actividades especiales para
                socios.
              </p>
            </div>

            <div className="uk-width-1-1">
              <div className="title-border uk-margin-medium-bottom">
                <h2 className="uk-h3">
                  Ser miembro del Club Siremás es fácil y gratis:
                </h2>
              </div>

              <div className="uk-grid uk-grid-large" uk-grid="true">
                <div className="uk-width-1-2@m">
                  <p>
                    Sólo tiene que dirigirse con su cédula de identidad al
                    kiosco de Promociones de cualquiera de las sucursales de{" "}
                    <strong>Sirena</strong> y <strong>Súper Pola</strong> y
                    solicitar su <strong>Tarjeta Siremás</strong>. Luego de
                    completar un breve formulario, le entregarán una tarjeta
                    codificada que deberá presentar al momento de pagar sus
                    compras.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://assets-sirenago.s3-us-west-1.amazonaws.com/web/documents/reglas-club-siremas.pdf"
                      className="uk-button uk-button-secondary"
                    >
                      Ver Reglas del Club Siremás
                    </a>
                  </p>
                </div>
                <div className="uk-width-1-2@m">
                  <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
                    <p className="uk-margin-remove-bottom">
                      Para más información sobre el{" "}
                      <a href="https://clubsiremas.com">
                        <strong>Club Siremás</strong>,
                      </a>{" "}
                      contacte a la Gerencia de Servicio al Cliente de{" "}
                      <a href="https://gruporamos.com">
                        <strong>Grupo Ramos</strong>
                      </a>{" "}
                      en el teléfono:{" "}
                      <a href="tel:18094724444">
                        <strong>809-472-4444</strong>
                      </a>{" "}
                      Ext. <strong>14448</strong>, o diríjase al área de
                      Servicio al Cliente de la tienda de su preferencia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
