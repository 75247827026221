/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from 'react'
import { CheckDocument11 } from 'helpers/functions';
import { useDgiiDocumentChecker } from 'hooks/useDgiiDocumentChecker';
import {Controller, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import { useEffect } from 'react';

export default function RegisterAccountStep3(
    {documentTypeOptions, 
    invoiceTypeOptions, 
    handleDataChange,
    nextStep, 
    previousStep}) {

    const {
         register,
         formState:{ errors },
         handleSubmit,
         control,
         watch,
         clearErrors,
         setValue } = useForm();

    const [currentInvoiceType, setCurrentInvoiceType] = useState("");
    const [mask, setMask] = useState("");
    const [currentDocTypeName, setCurrentDocTypeName] = useState('');

    const CREDITO_FISCAL = '01'; //factura de credito fiscal
    const DNI = 'Cedula';

    const document = watch('document','');
    
    const {socialName, isValid, documentNoSpace} = useDgiiDocumentChecker(document, currentInvoiceType, currentDocTypeName);
    setValue('business_name', socialName);
    setValue('document_short', documentNoSpace);

    useEffect(() => {
        if (isValid) clearErrors('business_name');
      }, [isValid]);

    const onChangeInvoiceType = (e) => {
        let current_invoice_type = invoiceTypeOptions.find(element => element.value === e.target.value);
        setCurrentInvoiceType(current_invoice_type.cfId);

        //Primer elemento dentro del array y coloca valores de mask y demas
        let current_document_types = documentTypeOptions.find(element => element.cfId === current_invoice_type?.cfId)
        setCurrentDocTypeName(current_document_types?.label);
        setValue('document', '');
        setValue('business_name', ''); 
        setValue("document_type_id", current_document_types?.value);
        setValue('document_type', current_document_types?.cfId);
        setMask(current_document_types?.mask)
    }

    const onChangeDocumentType = (e) => {
        let document_type = documentTypeOptions
                            .find(element => 
                             (element.cfId === currentInvoiceType
                                && element.value === e.target.value))
        setCurrentDocTypeName(document_type.label);
        setValue('document_type_id', document_type?.value);
        setValue("document_type", document_type?.cfId);
        setValue("document", '');
        setMask(document_type?.mask);
    }

    const onSubmit = (data) => {
        /*
          Coloco el valor de business_name de manera "Manual", ya que si el usuario
          no interactua con el campo del formulario la libreria no registra el valor,
          aunque visualmente se pueda apreciar.
        */
        data.business_name = socialName;
        handleDataChange(data);
        nextStep();   
}

    const ToPreviousStep = () => {
          previousStep();
    }
    return <div className='uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small'>
        <form className='uk-form-horizontal'>
            <progress className='uk-progress uk-position-top' value='75' max='100'></progress>
            <p className='uk-text-right uk-text-small uk-margin-remove-top'>Paso 3 de 4</p>
            <div className='title-border'>
                <p className='uk-h4'>Datos de contacto:</p>
            </div>
            <div className='uk-margin-small'>
                <label className='uk-form-label' htmlFor='mobile'>Teléfono móvil <span
                    className='required'>*</span></label>
                <div className='uk-form-controls'>
                    <input
                        id='mobile'
                        name="mobile"
                        className='uk-input'
                        type='text'
                        maxLength={20}
                        placeholder='Teléfono móvil'
                        {...register("mobile",
                            { required: "Teléfono móvil es requerido" })} />
                    <p className="error">
                        {errors?.mobile?.message}
                    </p>
                </div>

            </div>
            <div className='uk-margin-small'>
                <label className='uk-form-label' htmlFor='phone'>Teléfono fijo</label>
                <div className='uk-form-controls'>
                    <input
                        id='phone'
                        name="phone"
                        className='uk-input'
                        type='text'
                        maxLength={20}
                        placeholder='Teléfono fijo'/>
                </div>
            </div>

            <div className='uk-margin-small'>
                <label className='uk-form-label' htmlFor='dgii_cf_id'>¿Cómo deseas tu factura? <span
                    className='required'>*</span></label>
                <div className='uk-form-controls'>
                    <select
                        className='uk-select'
                        id='dgii_cf_id'                        
                        name="dgii_cf_id"
                        {...register("dgii_cf_id",
                          { required: "tipo de factura es requerido" })}
                            onChange={onChangeInvoiceType}>
                        <option value=''>Selecciona una opción</option>
                        {invoiceTypeOptions.map((item, idx) => 
                        <option 
                           key={idx} 
                           value={item.value}>{`${item.cfId} ${item.label}`}
                        </option> )}
                    </select>
                    <p className="error">
                        {errors?.dgii_cf_id?.message}
                    </p>
                </div>
            </div>

            <div className='uk-margin-small'>
                <label className='uk-form-label' htmlFor='document_type_id'>Documento de identidad<span
                    className='required'>*</span>
                </label>
                <div className='uk-form-controls uk-grid-small uk-child-width-1-2 uk-grid'>
                    <label>
                        <select className='uk-select'
                                name="document_type_id" 
                                {...register("document_type_id", 
                                {required: "documento de identidad es requerido"})}
                                onChange={onChangeDocumentType}>
                            {
                                documentTypeOptions
                                .filter(element => element.cfId === currentInvoiceType)
                                .map((item, idx) =>
                                    <option 
                                        key={idx} 
                                        value={item.value}>{` ${item.label}`}
                                     </option>)
                            }
                        </select>
                        <p className="error">
                            {errors?.document_type_id?.message}
                        </p>
                    </label>
                    <label>
                        <Controller
                            name="document"
                            control={control}
                            rules={{
                                required: "El No. documento es requerido",
                                validate: currentDocTypeName === DNI ?
                                    (value) => (CheckDocument11(value)
                                               || "El documento no es valido") : null,                                      
                            }}
                            render={({field}) => (
                                <InputMask
                                    name="document"
                                    alwaysShowMask 
                                    mask={mask} {...field}
                                    className='uk-input'/>
                            )}
                        />
                        <p className="error">
                            {errors?.document?.message}
                        </p>
                    </label>
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="business_name">
                  Nombre / Razón social
                </label>
                <div className="uk-form-controls">
                  <input
                    maxLength="128"
                    className="uk-input"
                    type="text"
                    name="business_name"
                    style={{pointerEvents:'none'}}
                    {...register("business_name",
                    { validate:  currentInvoiceType === CREDITO_FISCAL 
                        ? ()=>( isValid || "Nombre / Razón social no fue encontrado.")
                        :null
                      })}
                  />
                  <p className="error">
                    {errors?.business_name?.message}
                  </p>
                </div>
              </div>
            <hr/>
            <div className='uk-margin uk-text-right'>
                <button
                    type="button"
                    className="uk-button  uk-button-custom"
                    onClick={ToPreviousStep}>Anterior</button>
                <button
                    id='btn-next-step-3'
                    type="button"
                    className='uk-button uk-button-primary'
                    onClick={handleSubmit(onSubmit)}>Siguiente
                  
                </button>
            </div>
        </form>
    </div>
}
