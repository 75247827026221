export const initialState = {
    open: 'none',
    order: {},
    balance: {},
    pickupId: "",
    locationslotId: "",
    locationslot: "",
    currentBalance: 0,
    currentTotal: 0,
    baseImg: {},
    items: [],
    loading: true,
    currentItem: {},
    currentIdx: -1,
    tmpkey:0
}