
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getData } from "helpers/service";
import { Encode } from "helpers/functions";
import Loading from "components/share/loading";
import alert from "helpers/alerts";

export default function SelectTimeslot({ isOpen, onSetClose, onSetTimeSlot }) {
  
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dateslot, setDateslot] = useState([]);
  const [timeslot, setTimeslot] = useState([]);
  const [dateId, setDateId] = useState("");
  const [currentTimeslot, setCurrentTimeslot] = useState("");
  const [currentDateslot, setCurrentDateslot] = useState("");
  const [locationSlotId, setLocationSlotId] = useState("");

  const setTimeSlot = () => {
    if (locationSlotId === "") {
      alert.confirm(` <p>Se requiere un horario para continuar</p>`, "warning");
      return;
    }
    
    onSetTimeSlot(locationSlotId, `${currentDateslot} ${currentTimeslot}`);
    
  };

  useEffect(() => {
    if (isOpen === "timeslot") {
      getData(`checkout/dateslot`, {}, (res, err) => {
        if (!err) {
          setDateslot(res.dateslot || []);
          setTimeslot(res.timeslot || []);
          if (res.dateslot?.length > 0) {
            setDateId(res.dateslot[0].dateid);
            setCurrentDateslot(res.dateslot[0].text);
          }
        }
        setLoading(false);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!init) {
      setTimeslot([]);
      setLoading(true);
      getData(`checkout/${Encode(dateId)}/timeslot`, {}, (res, err) => {
        if (!err) {
          setTimeslot(res.timeslot || []);
        }
        setLoading(false);
      });
    }

  }, [dateId]);

  if (isOpen !== "timeslot") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog" data-test="available-hours">
      <button
        onClick={onSetClose}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Horarios disponibles
        </p>
      </div>

      <div id="modal-hours" className="uk-modal-body">
        <p>
          <select
            className="uk-select"
            data-test="day-select"
            onChange={(event) => {
              setInit(false);

              setDateId(event.target.value);

              var index = event.nativeEvent.target.selectedIndex;
              setCurrentDateslot(event.nativeEvent.target[index].text);
            }}
          >
            {dateslot.map((item) => (
              <option key={item.dateid} value={item.dateid}>
                {item.text}{" "}
              </option>
            ))}
          </select>
        </p>

        <ul className="uk-list" data-test="time-slots">
          {timeslot.map((item) => (
            <li
              key={item.locationslotid}
              className={`${item.available !== 1 && "unavailable"}`}
            >
              <label>
                {item.available === 1 && (
                  <input
                    data-test="timeslot"
                    onClick={() => {
                      setLocationSlotId(item.locationslotid);
                      setCurrentTimeslot(item.timeslot_range);
                    }}
                    className="uk-radio"
                    type="radio"
                    name="radio-hours"
                  />
                )}
                <span>{item.timeslot_range}</span>
                {item.available !== 1 && (
                  <span className="unavailable">
                    {item.available === 2 ? " No disponible" : " Agotado"}
                  </span>
                )}
              </label>
            </li>
          ))}
        </ul>
        {loading && <Loading />}
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          onClick={onSetClose}
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>
          <i className="fas fa-times"></i>
        </button>
        <button
          data-test="btn-change"
          onClick={setTimeSlot}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Aplicar</span>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
