import AccountContext from "context/Account";
import React, { useContext } from "react";

const AlertsCart = () => {
  const { shoppingCartItems } = useContext(AccountContext);

  let distributions = shoppingCartItems?.map((item) => item?.distributionId);
  let distributionIds = Array.from(new Set(distributions));
  const isDifferentDelivery = distributionIds.length > 1;

  let noVisible = shoppingCartItems?.map((item) => item.visible);
  let unAvailable = shoppingCartItems?.map((item) => item.available_order);
  let merge = [...noVisible, ...unAvailable];
  const isUnavailable = merge.includes(0);

  const DateDelivery = (
    <div className="gi_caja_aviso_carrito">
      <div className="gi_icon_caja">
        <img src="/assets/global/images/icon_bigbell.png" alt="Alerta" />
      </div>
      <div className="gi_icon_cajatext">
        <p>Este pedido tiene varias fechas distintas de entrega</p>
      </div>
    </div>
  );

  const TypeDelivery = (
    <div className="gi_caja_aviso_carrito">
      <div className="gi_icon_caja">
        <img src="/assets/global/images/icon_bigalert.png" alt="Alerta" />
      </div>
      <div className="gi_icon_cajatext">
        <p>
          Hay productos que ya no están disponibles en tu lista al haber
          cambiado de tienda o tipo de entrega
        </p>
      </div>
    </div>
  );

  return (
    <div className="uk-grid uk-grid-large gi_hay_avisos_carrito" data-uk-grid>
      <div className="uk-width-1-1 uk-width-2-3@m">
        {isDifferentDelivery && DateDelivery}
        {isUnavailable && TypeDelivery}
      </div>
      <div className="uk-width-1-1 uk-width-1-3@m"></div>
    </div>
  );
};

export default AlertsCart;
