import ProductItem from "components/product/item";
import Loading from "components/share/loading/list";
import AccountContext from "context/Account";
import { getData } from "helpers/service";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

const Favorites = () => {
  const { account, reloadItems } = useContext(AccountContext);
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [baseImg, setBaseImg] = useState("");
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    setLoading(true)
    getData("account/favorite", null, (res, err) => {
      if (!err) {
        setBaseImg(res.base_img);
        setFavoriteItems(res?.favorite);
      }
      setLoading(false);
    });
  }, [account, reloadItems]);
 
  const EmptyFavorite = () => (
    <div
      className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid uk-grid-match uk-margin-small-top uk-margin-large-bottom"
      uk-grid="true"
    >
      <div className="uk-width-1-1">
        <img
          src="/assets/background/favorite-empty.svg"
          style={{ height: "350px", textAlign: "center" }}
          alt="Lista de contactos Vacio."
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">
            No has tenido un registro de productos favoritos.
          </h4>
          <p className="uk-text-center">
            Esta herramienta te permitirá visualizar todos los productos que
            alguna vez has comprado.
          </p>
          <p className="uk-text-center">
            Si deseas repetir su compra sólo tendrás que indicarlo aquí y
            cargarlo a tu carrito.
          </p>
        </div>
      </div>
    </div>
  );
  if (loading) return <Loading />;
  return favoriteItems?.length === 0 ? <EmptyFavorite />
    : favoriteItems.map((f, indexp) => (
      <section key={indexp} className="uk-section uk-section-small">
        <div className="uk-section uk-section-small">
          <div className="title-border">
            <h2 className="uk-h4">{f.category}</h2>
            <Link
              hidden
              to="/products/category/"
              className="uk-button uk-button-text"
            >
              <span>ver todos</span> +
            </Link>
          </div>
          <div className="slider-products-fav" uk-slider="sets: true">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-grid-match">
                  {f.item.map((p, index) => (
                    <li key={`${indexp}_${index}`}>
                      <ProductItem key={p.productid} baseImg={baseImg} item={p} />
                    </li>
                  ))}
                </ul>
              </div>
              <a
                className="uk-position-center-left-out slider-arrow"
                href="#0"
                uk-slider-item="previous"
              >
                <i className="fas fa-chevron-left"></i>
              </a>
              <a
                className="uk-position-center-right-out slider-arrow"
                href="#0"
                uk-slider-item="next"
              >
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>))
}
  
export default Favorites;
