import React from "react";
import ContentLoader from "react-content-loader"

export default function LoadingMaps() {

    return <div className="uk-margin uk-padding  uk-width-1-1 uk-height-1-1" style={{height: '700px'}}>
        <div className="uk-position-center uk-padding-small uk-text-center">
                <ContentLoader
                    speed={2}
                    height={650}
                    width={900}
                    viewBox="0 0 100 100"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="0" rx="4" ry="4" width="30" height="10"/>
                    <rect x="50" y="0" rx="4" ry="4" width="50" height="10"/>
                    <rect x="0" y="15" rx="5" ry="5" width="25" height="80"/>

                    <rect x="30" y="15" rx="5" ry="5" width="70" height="80"/>
                </ContentLoader>

        </div>
    </div>





}
