import React from 'react';
import ReactDOM from "react-dom";

export default function LoadingModal({isOpen, message}) {

    if (isOpen !== 'loading') return null;

    return ReactDOM.createPortal(
        <div className='uk-modal-dialog uk-margin-auto-vertical'>
            <div className='uk-modal-body uk-text-center'>
                <p>{message}</p>
                <div className='uk-display-inline-block' uk-spinner="true"/>
            </div>
        </div>,
        document.getElementById('webModal'))

}

