import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "components/share/loading/list";
import { getData, postData } from "helpers/service";
import { Encode, setTitle } from "helpers/functions";
import ShoppingListDelete from "components/account/profile/shoppinglist/delete";
import ProductRequestModal from "components/product/request";
import UIkit from "uikit";
import alert from "helpers/alerts";

import ShoppingList from "components/account/profile/shoppinglist/ShoppingList";

import { BannerMyListModel } from "models/adsense";
import { GetTypeDevice } from "helpers/functions";
import Advertisements from "components/share/adsense";

export default function ShoppingListPage() {
  const [open, setOpen] = useState(false);
  const [shoppingLists, setShoppingLists] = useState([]);
  const [currentList, setCurrentList] = useState({});
  const [loading, setLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(true);
  const currentType = GetTypeDevice();
  setTitle("Mis Listas");

  const LoadData = () => {
    setLoading(true);
    getData("account/shopping-list", null, (res, err) => {
      if (!err) {
        setShoppingLists(res.list || []);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    LoadData();
  }, []);

  const addToCard = (listId) => {
    setPostLoading(true);
    postData(
      `account/shopping-list/${Encode(listId)}/add-cart`,
      null,
      null,
      (res, err) => {
        if (!err) {
          window.location.replace("/cart");
        }
        setPostLoading(false);
      }
    );
  };

  const openRequestProducts = () => {
    setOpen("product-request");
    UIkit.modal(`#webModal`).show();

  };

  const closeRequest = () => {
    UIkit.modal(`#webModal`).hide();
  };

  const openRemoveList = (list) => {
    setCurrentList(list);
    setOpen("remove-list");
    UIkit.modal(`#webModal`).show();
  };

  const RemoveList = (listId) => {
    postData(
      `account/shopping-list/${Encode(listId)}/delete`,
      null,
      null,
      (res, err) => {
        if (!err) {
          UIkit.modal(`#webModal`).hide();
          alert.toast(res.message);
          LoadData();
        }
        setLoading(false);
      }
    );
  };

  const EmptyList = () => (
    <div
      className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid uk-grid-match uk-margin-small-top uk-margin-large-bottom"
      uk-grid="true"
    >
      <div className="uk-width-1-1">
        <img
          src="/assets/background/shopping-list-empty.svg"
          style={{ height: "350px", textAlign: "center" }}
          alt="Lista de contactos Vacio"
        />
        <div className="uk-card uk-card-primary uk-card-body uk-border-rounded">
          <h4 className="uk-text-center">
            No has tenido un registro de listas de compras.
          </h4>
          <p className="uk-text-center">
            Esta herramienta te permitirá visualizar todos tus listas
            registrados.
          </p>
        </div>
      </div>
    </div>
  );


  return (
    <>
      <div>
        <div className="uk-section uk-section-small uk-flex uk-flex-center">
          {BannerMyListModel.filter((item) => item.type === currentType).map(
            (item) => (
              <Advertisements
                key={item.slotId}
                className={item.className}
                type={item.type}
                slotId={item.slotId}
                sizes={item.sizes}
                adUnit={item.adUnit}
                targetingArguments={{ Seccion: "mis listas" }}
              />
            )
          )}
        </div>
        <div className="title-border uk-flex uk-flex-between uk-flex-bottom">
          <h2 className="uk-h3">Mis listas de Compra</h2>
          <div className="uk-hidden@m">
            <a
              href="#0"
              className="uk-button uk-button-default uk-button-custom"
              uk-toggle="target: #modal-filters"
            >
              <i className="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div
          className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters"
          uk-grid="true"
        >
          <div>
            <button
              onClick={openRequestProducts}
              className="uk-button uk-button-primary"
            >
              <span>Solicitar producto</span>
              <i className="fas fa-plus"></i>
            </button>
          </div>
          <div>
            <Link
              to="/account/order-history"
              className="uk-button uk-button-default uk-button-custom"
            >
              <span>Repetir compra</span>
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
        {loading && <Loading />}
        {!loading && shoppingLists?.length > 0 && (
          <>
            {" "}
            <ShoppingList
              shoppingLists={shoppingLists}
              openRemoveList={openRemoveList}
              addToCard={addToCard}
              openRequestProducts={openRequestProducts}
              postLoading={postLoading}
            />
            
          </>
        )}
        {!loading && shoppingLists?.length === 0 && <EmptyList />}

        <ShoppingListDelete
          isOpen={open === "remove-list"}
          item={currentList}
          onSetDelete={RemoveList}
        />

        <ProductRequestModal
          isOpen={open === "product-request"}
          onSetClose={closeRequest}
        />

 

        <div
          id="modal-sl-delete"
          className="uk-flex-top uk-modal"
          uk-container="false"
          data-uk-modal="bg-close: false"
        ></div>


      </div>
    </>
  );
}
