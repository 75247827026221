/* eslint-disable */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import alert from "helpers/alerts";
import Loading from "components/share/loading";

export default function LostPassword() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    postData(
      `auth/lost-password`,
      {
        account: Encode(data["username_lost"]),
      },
      {},
      (res, err) => {
        if (!err) {
          reset({});
          alert.confirm(res.message);
        }
        setLoading(false);
      }
    );
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className="box-user box-user-recover toggle-password" hidden>
      <div className="uk-text-center">
        <p className="uk-h4">Recuperar tu acceso</p>
      </div>
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <p>
          <input
            className="uk-input"
            name="username_lost"
            type="email"
            placeholder="Correo electrónico"
            {...register("username_lost", {
              required: "El correo electrónico es requerido",
            })}
          />
        </p>
        <p className="error">
          {errors?.username_lost?.message}
        </p>
        {loading && <Loading />}
        <p>
          <button
            disabled={loading}
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="uk-button uk-button-default uk-width-1-1"
          >
            Recuperar
          </button>
        </p>
        <p className="uk-text-small uk-text-bold uk-text-center">
          Tu nueva contraseña será enviada al correo registrado. Por favor
          revisa tu buzón.
        </p>
      </form>
      <hr />
      <div className="uk-text-center">
        <p className="uk-text-small uk-text-bold">
          {" "}
          <a href="#" uk-toggle="target: .toggle-password">
            Volver al login
          </a>
        </p>
      </div>
    </div>
  );
}
