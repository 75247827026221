
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postData } from "helpers/service";
import { DecimalFormat, Encode } from "helpers/functions";
import alert from "helpers/alerts";
import AccountContext from "context/Account";
import ReactTooltip from "react-tooltip";
import UnAvailable from "./unavailable";

export default function ShoppingCartItem({
  item,
  RemoveItem,
  xOpenModal,
  baseImg,
  xChangeQty,
  xChangeNotReplace,
}) {
  const { loadShoppingCart } = useContext(AccountContext);

  const [action, setAction] = useState("load");
  const [qty, setQty] = useState();
  const [notReplace, setNotReplace] = useState(
    parseInt(item.not_replace) === 1
  );
  const comment = item.comment;

  const handleSelectItems = (e) => {
    if (e.target.value === "+") {
      xOpenModal(item, "more");
      return;
    }

    setAction("edit");
    setQty(e.target.value);
  };

  const openComment = () => {
    xOpenModal(item, "comment");
  };

  const onChangeNotReplace = () => {
    setAction("edit");
    xChangeNotReplace(!notReplace);
    setNotReplace(!notReplace);
  };

  const SubmitPreference = () => {
    postData(
      `cart/${Encode(item.cartid)}/preference`,
      {
        key: Encode(item.cartid),
        notReplace: notReplace,
        comment: Encode(comment),
      },
      {},
      (res, err) => {
        if (!err) {
          alert.toast(res.message);
        }
      }
    );
  };

  const QuantityList = () => {
    let x = [];

    for (let i = 0; i < parseFloat(item?.in_cart) + 5; i++) {
      let total = parseFloat(item?.producttype_step) * parseFloat(i);
      let values = parseFloat(item?.minimum) + total;

      x.push(values);
    }

    return x.map((quantity, i) => (
      <option
        key={i}
        value={DecimalFormat(quantity, item?.producttype_decimal)}
      >
        {DecimalFormat(quantity, item?.producttype_decimal)}
      </option>
    ));
  };

  useEffect(() => {
    setQty(item?.in_cart);
  }, [item]);

  useEffect(() => {
    if (action === "edit") {
      postData(
        `cart/${Encode(item.cartid)}/update`,
        {
          key: Encode(item.cartid),
          qty: parseFloat(qty),
        },
        {},
        (res, err) => {
          if (!err) {
            loadShoppingCart(res.balance);
            xChangeQty(qty);
          }
        }
      );

      setAction("none");
    }

  }, [qty]);

  useEffect(() => {
    if (action === "edit") {
      SubmitPreference();
      setAction("none");
    }

  }, [notReplace]);

  const AlertIcon = (
    <div className="gi_alert_icon">
      <i>
        <img src="/assets/global/images/icon_alert.png" alt="Alerta" />
      </i>
    </div>
  );

  const TrashIcon = (
    <button
      id="remove-cart"
      data-id={item?.productid}
      data-price={item?.price}
      data-quantity={item?.minimun}
      data-quantity-in-cart={item?.in_cart}
      data-currency="DOP"

      data-coupon={item?.coupon}
      data-discount={item?.discount}
      data-item-brand={item?.item_brand}
      data-item-category={item?.item_category}
      data-item-category2={item?.item_category2}
      data-item-category3={item?.item_category3}
      data-variant={item?.item_variant}  
      
      onClick={() => RemoveItem(item.cartid)}
      className="uk-button gi_trash_icon remove-cart"
    >
      <i className="far fa-trash-alt"></i>
    </button>
  );

  return (
    <li>
      <div
        className={`item-list gi_item_carrito ${
          item.visible === 0 || item.available_order === 0
            ? "gi_item_nodisponible"
            : ""
          }`}
          data-currency="DOP"
          data-coupon={item?.coupon}
          data-discount={item?.discount}
          data-item-brand={item?.item_brand}
          data-item-category={item?.item_category}
          data-item-category2={item?.item_category2}
          data-item-category3={item?.item_category3}
          data-variant={item?.item_variant}  
      >
        <Link
          to={`/products/index/${item?.friendlyurl}`}
          className="item-list-image"
          style={{ backgroundImage: `url(${baseImg}${item?.thumbs})` }}
        ></Link>
        <div className="item-list-info uk-width-expand">
          <p className="uk-h5 uk-text-uppercase">
            <Link to={`/products/index/${item?.friendlyurl}`}>{item.name}</Link>
          </p>

          {item.visible === 1 && item.available_order === 1 ? (
            <div className="uk-flex uk-flex-wrap uk-grid-small uk-child-width-1-1 uk-child-width-auto@s uk-text-small">
              <ReactTooltip
                textColor="#fff"
                backgroundColor="#00b2e3"
                id={`cartid-${item.cartid}`}
                type="light"
                effect="float"
                aria-haspopup="true"
                role="example"
              >
                <strong>Si no esta disponible lo sustituiremos:</strong>
                <ol>
                  <li>Por uno de la misma marca en otro formato.</li>
                  <li>Por el mismo formato en otra marca.</li>
                </ol>
              </ReactTooltip>
              <label data-tip data-for={`cartid-${item.cartid}`}>
                <input
                  onChange={onChangeNotReplace}
                  defaultChecked={notReplace}
                  className="uk-checkbox"
                  type="checkbox"
                />{" "}
                No sustituir
              </label>
              <label>
                <input
                  onChange={openComment}
                  checked={comment?.length}
                  className="uk-checkbox"
                  type="checkbox"
                />{" "}
                Comentario
              </label>
            </div>
          ) : (
              <UnAvailable
                item={item}
              />
          )}
        </div>

        <div className="item-list-btns uk-width-auto uk-flex-last@s">
          {item.visible === 0 || item.available_order === 0
            ? AlertIcon
            : TrashIcon}
        </div>

        {/* Este bloque se olculta mediante la clase css "item-list-detail" */}
        <div className="item-list-detail uk-width-1-1 uk-width-auto@s uk-text-right">
          <p className="uk-text-bold">${DecimalFormat(item?.price)}</p>
          <select
            id="update-cart"
            data-test="update-cart"
            data-currency="DOP"
            value={DecimalFormat(qty, item?.producttype_decimal)}
            onChange={(e) => handleSelectItems(e)}
            className="uk-select uk-text-small update-cart"
          >
            <QuantityList />
            <option value="+">Más</option>
          </select>
        </div>
      </div>
    </li>
  );
}
