export const PRODUCT ='product'
export const SPECS = 'specs'
export const PICTURES = 'pictures'
export const RELATEDS = 'relateds'
export const LOCATIONS ='locations'
export const BASE_IMG ='baseImg'
export const FAVORITE = 'favorite'
export const DISTRIBUTION ='distribution'
export const DISTRIBUTION_DETAIL = 'distributionDetail'
export const QUANTITY = 'quantity'
export const INIT_LOADING = 'initLoading'
export const LOADING ='loading'
export const ACTION ='action'
export const OPEN = 'open'
export const OPEN_FULL = 'openFull'
export const OPEN_CONTAINER = 'openContainer'
export const VIDEO_SRC = 'videoSrc'