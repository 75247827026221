
import { toNumber } from "uikit/src/js/util";

const Items = ({ currentPage, setPage, total, limit }) => {

    if (total === 0 || limit === 0) return "";

  const handleClick = (e) => {
      if (parseInt(e.target.id) > 0) {
        setPage(toNumber(e.target.id));
        window.scrollTo(0, 0);
        e.preventDefault();
      }
    };

    let pageNumbers = [];
    let currentTotal = Math.ceil(total / limit);
    let current = currentPage;
    let lastPoint = false;

    for (let page = 1; page <= currentTotal; page++) {
      const Locallimit = 8;

      const preservedDistanceToEdge = 4;
      let distanceToLastPage = Math.abs(currentTotal - page);
      let distanceToCurrent = Math.abs(page - current);
      let isEdgePage = page === currentTotal || page === 1;
      let isLastPreservedRange =
        currentTotal - current < preservedDistanceToEdge &&
        distanceToLastPage < preservedDistanceToEdge;
      let isFirstPreservedRange =
        page <= preservedDistanceToEdge + 1 &&
        current <= preservedDistanceToEdge + 1;

      if (
        current === preservedDistanceToEdge + 1 &&
        currentTotal > Locallimit
      ) {
        isFirstPreservedRange = false;
      }

      if (
        currentTotal >= Locallimit &&
        current !== page &&
        !isEdgePage &&
        !isFirstPreservedRange &&
        !isLastPreservedRange &&
        distanceToCurrent > 1
      ) {
        if (lastPoint) continue;

        pageNumbers.push("...");
        lastPoint = true;
        continue;
      }

      lastPoint = false;
        pageNumbers.push(page);
    }
    return (
      <>
        {pageNumbers.map((number, index) => (
          <li
            key={index}
            className={
              (number === currentPage ? "uk-active" : "") ||
              (number === "..." ? "uk-disabled" : "")
            }
          >
            <a href="#0" id={number} onClick={handleClick}>
              <span id={number}>{number}</span>
            </a>
          </li>
        ))}
      </>
    );
};

export default Items;
