import React from "react";


export default function Loading({ratio}) {

    let current = ratio ||'5';

    return <div className="uk-margin uk-padding  uk-width-1-1">
        <div className="uk-position-center uk-padding-small uk-text-center">
            <div uk-spinner={`ratio: ${current}`}></div>
        </div>
    </div>





}
