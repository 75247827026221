/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import AccountContext from "context/Account";

export default function ProductCategory() {
  const { categories } = useContext(AccountContext);

  return (
    <section className="uk-section uk-section-small">
      <div className="uk-container uk-container-expand">
        <div className="title-border">
          <h2 className="uk-h4">Comprar por categoría</h2>
        </div>
        <div className="slider-categories" uk-slider="sets: true; finite: true">
          <div className="uk-position-relative">
            <div className="uk-slider-container">
              <ul className="uk-slider-items uk-grid-match">
                {categories.map((c) => (
                  <li key={c.id}>
                    <Link
                      to={`/products/category/${c.friendlyurl}`}
                      className="item-category"
                    >
                      <div
                        className="item-category-image"
                        style={{ backgroundImage: `url(${c?.imagen})` }}
                      ></div>
                      <p className="item-category-title">{c?.name}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <a
              className="uk-position-center-left-out"
              href="#"
              uk-slider-item="previous"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <a
              className="uk-position-center-right-out"
              href="#"
              uk-slider-item="next"
            >
              <i className="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
