import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getData } from "helpers/service";
import Loading from "components/share/loading/bullet";

export default function SelectContacts({ isOpen, onSetClose, onSetContact }) {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [currentContacts, setCurrentContacts] = useState({});

  useEffect(() => {
    if (isOpen === "contacts") {
      setContacts([]);
      setLoading(true);
      getData(`account/contact`, {}, (res, err) => {
        if (!err) {
          setContacts(res.data);

          if (res.data.length > 0) {
            setCurrentContacts(res.data[0]);
          }
        }
        setLoading(false);
      });
    }
  }, [isOpen]);

  if (isOpen !== "contacts") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        onClick={onSetClose}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Mis contactos
        </p>
      </div>

      <div id="modal-hours" className="uk-modal-body">
        {loading && <Loading />}
        <ul className="uk-list">
          {contacts.map((item, idx) => (
            <li key={idx}>
              <label
                onClick={() => {
                  setCurrentContacts(item);
                }}
              >
                <div className="uk-flex uk-grid-small">
                  <input
                    defaultChecked={item.by_default === 1}
                    className="uk-radio"
                    type="radio"
                    name="credit-card"
                  />
                  <div className="uk-width-expand">
                    <strong>{item?.tag}</strong>
                    <br />
                    {`${item?.first_name} ${item?.last_name}`}
                    <br />
                    {`${item?.document_type_id} ${item?.document}`}
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        <p className="uk-hidden">
          <button
            onClick={() => onSetClose("AddressAdd")}
            className="uk-button uk-button-primary uk-button-small"
          >
            <span>Agregar contacto</span>
          </button>
        </p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => onSetContact(false, "", currentContacts)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Aplicar</span>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
