
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getData } from "helpers/service";
import Loading from "components/share/loading/bullet";

export default function SelectAddresses({ isOpen, onSetClose, onSetAddress, typeAddress }) {

  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [currentAddresses, setCurrentAddresses] = useState({});

  useEffect(() => {
    if (isOpen === "addresses") {
      setLoading(true);
      getData(
        `account/address`,
        { shipping_type: typeAddress},
        (res, err) => {
          if (!err) {
            setAddresses(res.data);

            if (res?.data?.length > 0) {
              setCurrentAddresses(res.data[0]);
            }
          }
          setLoading(false);
        }
      );
    }


  }, [isOpen]);

  if (isOpen !== "addresses") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        onClick={() => onSetClose()}
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
      ></button>

      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Mis direcciónes
        </p>
      </div>

      <div id="modal-hours" className="uk-modal-body">
        {loading ? <Loading /> :
          <ul className="uk-list">
            {addresses.map((item, idx) => (
              <li key={idx}>
                <label
                  onClick={() => {
                    setCurrentAddresses(item);
                  }}
                >
                  <div className="uk-flex uk-grid-small">
                    <input
                      defaultChecked={item.by_default === 1}
                      className="uk-radio"
                      type="radio"
                      name="credit-card"
                    />
                    <div className="uk-width-expand">
                      <strong>{item?.tag}</strong>
                      <br />
                      {`${item?.state} ${item?.sector}`}
                      <br />
                      {`${item?.address} ${item?.address2}`}
                    </div>
                  </div>
                </label>
              </li>
            ))}
          </ul>}
        <p>
          <button
            onClick={() => onSetClose("AddressAdd")}
            className="uk-button uk-button-primary uk-button-small"
          >
            <span>Agregar dirección</span>
          </button>
        </p>
      </div>

      <div className="uk-modal-footer uk-text-center">
        <button
          className="uk-button uk-modal-close uk-button-custom"
          type="button"
        >
          <span>Cancelar</span>
          <i className="fas fa-times"></i>
        </button>
        <button
          onClick={() => onSetAddress(currentAddresses)}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
        >
          <span>Aplicar</span>
          <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
