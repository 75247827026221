/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "helpers/service";
import { Encode, setTitle, CheckDocument11 } from "helpers/functions";
import alert from "helpers/alerts";
import Loading from "components/share/loading/content";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { daysOptions, monthOptions } from "models/options";
import { useDgiiDocumentChecker } from "hooks/useDgiiDocumentChecker";
import { initialState } from "store/account/profileEdit/state";
import { updateStateReducer } from "store/common/commonReducer";
import {
  ACCOUNT,
  LOADING,
  GENDER,
  DOCUMENT_TYPE,
  INVOICE_TYPE,
  ACCOUNT_INVOICE_TYPE,
  INPUT_MASK,
  YEAR,
} from "store/account/profileEdit/actionTypes";

const DNI = "Cedula";
const CREDITO_FISCAL = "01"; //factura de credito fiscal

export default function ProfileEditPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm();

  const [
    {
      account,
      loading,
      gender,
      documentType,
      invoiceType,
      accountInvoiceType,
      inputMask,
      year,
    },
    dispatch,
  ] = useReducer(updateStateReducer, initialState);

  let navigate = useNavigate();

  setTitle("Actualizar Cuenta");

  useEffect(() => {
    const x = [];
    const z = new Date().getFullYear() - 18;
    for (let i = z; i > z - 100; i--) {
      x.push(i);
    }
    dispatch({ type: YEAR, payload: x });
  }, []);

  useEffect(() => {
    dispatch({ type: LOADING, payload: true });
    getData("account/profile", { type: Encode("E") }, (res, err) => {
      if (!err) {
        dispatch({ type: GENDER, payload: res.gender });
        dispatch({ type: DOCUMENT_TYPE, payload: res.document_type });
        dispatch({ type: INVOICE_TYPE, payload: res.invoice_type });

        let account_invoice_type = res.invoice_type.find(
          (element) => element.value === res.account.cf_id
        );

        dispatch({
          type: ACCOUNT_INVOICE_TYPE,
          payload: account_invoice_type.cfId,
        });
        dispatch({ type: INPUT_MASK, payload: res.account?.document_mask });
        dispatch({ type: ACCOUNT, payload: res.account });

        reset(res.account);
      }
      dispatch({ type: LOADING, payload: false });
    });
  }, []);

  const onChangeInvoiceType = (e) => {
    let invoice_type = invoiceType.find(
      (element) => element.value === e.target.value
    );

    dispatch({ type: ACCOUNT_INVOICE_TYPE, payload: invoice_type?.cfId });
    setValue("crm_dgii_cf", invoice_type?.label);

    let document_type = documentType.find(
      (element) => element.cfId === invoice_type?.cfId
    );
    //Si cambia el tipo de factura se restablecen los campos dependientes
    setValue("document_type_id", document_type.value);
    setValue("document", "");
    setValue("business_name", "");
    setValue("document_mask", document_type.mask);

    dispatch({ type: INPUT_MASK, payload: document_type.mask });
  };

  const onChangeDocumentType = (e) => {
    let document_type = documentType.find(
      (element) =>
        element.cfId === accountInvoiceType && element.value === e.target.value
    );
    setValue("document_type", document_type.label);

    //Si cambia el tipo de documento, pues la mascara del Input tambien debe cambiar
    setValue("document_mask", document_type.mask);
    setValue("document", "");

    dispatch({ type: INPUT_MASK, payload: document_type.mask });
  };

  const onSubmit = (record) => {
    postData("account/profile", record, {}, (res, err) => {
      if (!err) {
        alert.toast(res.message).then(() => {
          navigate("/account", { replace: true });
        });
      }
    });
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  const currentDocTypeName = watch("document_type", DNI);
  const document = watch("document", "");

  const { socialName, isValid, documentNoSpace } = useDgiiDocumentChecker(
    document,
    accountInvoiceType,
    currentDocTypeName
  );

  setValue("business_name", socialName);
  setValue("document_short", documentNoSpace);

  useEffect(() => {
    if (isValid) clearErrors("business_name");
  }, [isValid]);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="title-border uk-flex uk-flex-between uk-flex-bottom uk-margin-medium-bottom">
        <h2 className="uk-h3">Actualizar cuenta</h2>
        <div className="uk-hidden@m">
          <a
            href="#"
            className="uk-button uk-button-default uk-button-custom"
            uk-toggle="target: #modal-filters"
          >
            <i className="fas fa-filter"></i>
          </a>
        </div>
      </div>
      <p className="uk-text-small">
        (*) Los campos marcados con asteriscos son obligatorios.
      </p>

      <div className="uk-width-1-1 uk-width-3-3@m uk-width-4-5@l">
        <div className="uk-card uk-card-default uk-padding uk-border-rounded uk-box-shadow-small uk-margin-medium-top uk-margin-large-bottom">
          <div className="uk-form-horizontal">
            <div className="title-border">
              <p className="uk-h5">Datos de acceso:</p>
            </div>
            <div className="uk-margin-small">
              <label className="uk-form-label" htmlFor="form-email">
                Correo electrónico <span className="required">*</span>
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  value={account?.email}
                  type="text"
                  readOnly={true}
                  disabled={true}
                  placeholder="Correo electrónico"
                />
              </div>
            </div>

            <hr className="uk-margin-medium-top uk-margin-medium-bottom" />
            <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
              <div className="title-border">
                <p className="uk-h5">Datos personales:</p>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="first_name">
                  Nombre(s) <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="first_name"
                    name="first_name"
                    {...register("first_name", {
                      required: "Nombre(s) son requeridos",
                    })}
                    type="text"
                    placeholder="Nombre(s)"
                  />
                  <p className="error">{errors?.first_name?.message}</p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="last_name">
                  Apellido(s) <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="last_name"
                    name="last_name"
                    {...register("last_name", {
                      required: "Apellido(s) son requeridos",
                    })}
                    type="text"
                    placeholder="Apellido(s)"
                  />
                  <p className="error">{errors?.last_name?.message}</p>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="gender_id">
                  Genero
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="gender_id"
                    name="gender_id"
                    {...register("gender_id", {
                      required: "genero(s) son requeridos",
                    })}
                  >
                    {gender?.map((item, idx) => (
                      <option key={idx} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="error">{errors?.gender_id?.message}</p>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="dob_day">
                  Fecha de nacimiento <span className="required">*</span>
                </label>
                <div className="uk-form-controls uk-grid-small uk-child-width-1-3 uk-grid">
                  <label>
                    <select
                      id="dob_day"
                      name="dob_day"
                      className="uk-select"
                      {...register("dob_day", {
                        required: "Dia es requerido",
                        validate: (value) =>
                          value !== "00" || "Dia es requerido.",
                      })}
                    >
                      {daysOptions?.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label>
                    <select
                      id="dob_month"
                      name="dob_month"
                      className="uk-select"
                      {...register("dob_month", {
                        required: "Mes es requerido",
                        validate: (value) =>
                          value != "00" || "Mes es requerido.",
                      })}
                    >
                      {monthOptions?.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label>
                    <select
                      id="dob_year"
                      name="dob_year"
                      className="uk-select"
                      {...register("dob_year", {
                        required: "Año es requerido",
                        validate: (value) =>
                          value !== "0000" || "Año es requerido.",
                      })}
                    >
                      <option value="0000">Año</option>
                      {year?.map((y, idx) => (
                        <option key={idx} value={y}>
                          {y}
                        </option>
                      ))}
                    </select>
                  </label>
                  <p className="error">{errors?.dob_day?.message}</p>
                  <p className="error">{errors?.dob_month?.message}</p>
                  <p className="error">{errors?.dob_year?.message}</p>
                </div>
              </div>

              <hr className="uk-margin-medium-top uk-margin-medium-bottom" />

              <div className="title-border">
                <p className="uk-h5">Datos de contacto:</p>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="mobile">
                  Teléfono móvil
                </label>
                <div className="uk-form-controls">
                  <Controller
                    name="mobile"
                    control={control}
                    rules={{ required: "Teléfono móvil es requerido" }}
                    render={({ field }) => (
                      <InputMask
                        name="mobile"
                        alwaysShowMask
                        mask="(999) 999-9999"
                        {...field}
                        className="uk-input"
                      />
                    )}
                  />
                  <p className="error">{errors?.mobile?.message}</p>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="phone">
                  Teléfono fijo
                </label>
                <div className="uk-form-controls">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        name="phone"
                        alwaysShowMask
                        mask="(999) 999-9999"
                        {...field}
                        className="uk-input"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="cf_id">
                  ¿Cómo deseas tu factura? <span className="required">*</span>
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="cf_id"
                    name="cf_id"
                    {...register("cf_id", {
                      required: "Formato de factura son requeridos",
                    })}
                    onChange={onChangeInvoiceType}
                  >
                    {invoiceType?.map((item) => (
                      <option key={item.cfId} value={item.value}>
                        {`${item.cfId} ${item.label}`}
                      </option>
                    ))}
                  </select>
                  <p className="error">{errors?.cf_id?.message}</p>
                </div>
              </div>

              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="document_type_id">
                  Documento de identidad<span className="required">*</span>
                </label>
                <div className="uk-form-controls uk-grid-small uk-child-width-1-2 uk-grid">
                  <label>
                    <select
                      className="uk-select"
                      name="document_type_id"
                      {...register("document_type_id")}
                      onChange={onChangeDocumentType}
                    >
                      {documentType
                        ?.filter(
                          (element) => element.cfId === accountInvoiceType
                        )
                        .map((item, idx) => (
                          <option
                            key={idx}
                            value={item.value}
                          >{`${item.label}`}</option>
                        ))}
                    </select>
                    <p className="error">{errors?.document_type_id?.message}</p>
                  </label>
                  <label>
                    <Controller
                      name="document"
                      control={control}
                      rules={{
                        required: "El No. documento es requerido",
                        maxLength: {
                          value: 20,
                          message: "Este campo solo acepta 20 caracteres.",
                        },
                        validate:
                          currentDocTypeName === DNI
                            ? (value) =>
                                CheckDocument11(value) ||
                                "El documento no es valido"
                            : null,
                      }}
                      render={({ field }) => (
                        <InputMask
                          name="document"
                          alwaysShowMask
                          mask={inputMask}
                          {...field}
                          className="uk-input"
                        />
                      )}
                    />
                    <p className="error">{errors?.document?.message}</p>
                  </label>
                </div>
              </div>
              <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="business_name">
                  Nombre / Razón social
                </label>
                <div className="uk-form-controls">
                  <input
                    maxLength="128"
                    className="uk-input"
                    type="text"
                    name="business_name"
                    style={{pointerEvents:'none'}}
                    {...register("business_name", {
                      validate:
                        accountInvoiceType === CREDITO_FISCAL
                          ? () =>
                              isValid ||
                              "Nombre / Razón social no fue encontrado."
                          : null,
                    })}
                  />
                  <p className="error">{errors?.business_name?.message}</p>
                </div>
              </div>

              <hr className="uk-margin-medium-top uk-margin-medium-bottom" />

              <div className="uk-margin uk-text-center">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="uk-button uk-button-primary"
                >
                  <span>Actualizar</span> <i className="fas fa-check"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
