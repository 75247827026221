import React, { useEffect, useState } from "react";
import { Encode, isBlank, setTitle } from "helpers/functions";
import alert from "helpers/alerts";
import { getData, postData } from "helpers/service";
import Loading from "components/share/loading/crud";
import CreditcardsList from "components/account/profile/creditcards";
import CreditcardAdd from "components/account/profile/creditcards/add";
import CreditcardEdit from "components/account/profile/creditcards/edit";
import CreditcardDelete from "components/account/profile/creditcards/delete";
import CreditcardConfirm from "components/account/profile/creditcards/confirm";
import UIkit from "uikit";
import { BannerMyCreditCardsModel } from "models/adsense";
import Advertisements from "components/share/adsense";
import { GetTypeDevice } from "helpers/functions";

export default function CreditCardPage() {
  setTitle("Mis tarjetas");
  const currentType = GetTypeDevice();

  const LoadCreditCardList = () => {
    const [creditCardItems, setCreditCardItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState("none");
    const [current, setCurrent] = useState({});

    useEffect(() => {
      loadData();
    }, []);

    const loadData = async () => {
      setLoading(true);
      await getData("account/creditcard", null, (res, err) => {
        if (!err) {
          setCreditCardItems(res.data);
        }
        setLoading(false);
      });
    };

    const onOpen = (action = "add", data = []) => {
      setOpen(action);
      setCurrent(data);
    };

    const onSetByDefault = (id) => {
      setLoading(true);
      postData(
        `account/creditcard/${Encode(id)}/default`,
        { key: Encode(id) },
        {},
        (res, err) => {
          alert.toast(res.message);
          loadData();
        }
      );
    };

    const onSetClose = (reload = false, message = "", data = null) => {
      UIkit.modal("#webModal").hide();
      setOpen("none");

      if (!isBlank(message)) alert.toast(message);

      if (reload) {
        loadData();
      }
    };

    return (
      <>
        <div className="title-border uk-flex uk-flex-between uk-flex-bottom toggle-creditcard-add toggle-creditcard-edit">
          <h2 className="uk-h3">Mis Tarjetas</h2>
          <div className="uk-hidden@m">
            <a
              href="#0"
              className="uk-button uk-button-default uk-button-custom"
              uk-toggle="target: #modal-filters"
            >
              <i className="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div
          className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle uk-grid-small uk-text-small nav-filters toggle-creditcard-add toggle-creditcard-edit"
          uk-grid="true"
        >
          <div></div>
          <div>
            <button
              type="button"
              onClick={() => onOpen()}
              className="uk-button uk-button-default uk-button-custom"
              uk-toggle="target: #webModal"
            >
              <span>Agregar</span> <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div
          className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid uk-grid-match uk-margin-small-top uk-margin-large-bottom"
          uk-grid="true"
        >
          {loading ? (
            <Loading />
          ) : (
            <CreditcardsList
              creditCardItems={creditCardItems}
              onOpen={onOpen}
              setByDefault={onSetByDefault}
            />
          )}
        </div>

        <CreditcardAdd isOpen={open} setClose={onSetClose} />
        <CreditcardEdit isOpen={open} setClose={onSetClose} item={current} />
        <CreditcardDelete isOpen={open} setClose={onSetClose} item={current} />
        <CreditcardConfirm isOpen={open} setClose={onSetClose} item={current} />
      </>
    );
  };

  return (
    <>
      <div>
        <div className="uk-section uk-section-small uk-flex uk-flex-center">
          {BannerMyCreditCardsModel.filter(
            (item) => item.type === currentType
          ).map((item) => (
            <Advertisements
              key={item.slotId}
              className={item.className}
              type={item.type}
              slotId={item.slotId}
              sizes={item.sizes}
              adUnit={item.adUnit}
              targetingArguments={{ Seccion: "mis-tarjetas" }}
            />
          ))}
        </div>
        <LoadCreditCardList />
      </div>
    </>
  );
}
