/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from "react-dom";
import React, { useState, useEffect, useContext } from "react";
import PageNotFound from "components/share/error/404";
import Loading from "components/share/loading";
import { Encode } from "helpers/functions";
import { getData } from "helpers/service";
import { OPEN_FULL } from "store/product/detail/actionTypes";
import AccountContext from "context/Account";

const WarrantyModal = ({ isOpen, onSetClose }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const { setSeoData } = useContext(AccountContext);

  const URL = "terminos-de-garantia";
  const WARRANTY = "warranty";

  useEffect(() => {
    if (isOpen !== WARRANTY) return;
    setLoading(true);
    getData(`setting/site/content/${Encode(URL)}`, null, (res, err) => {
      if (!err) {
        if (res.found) {
          setContent(res.data?.content);
          setSeoData({
            metaTitle: res.data?.meta_title,
            metaKeywords: res.data?.meta_keywords,
            metaDescription:res.data?.meta_description
          })
        }
      }
      setLoading(false);
    });
  }, [isOpen]);

  if (isOpen !== WARRANTY) return null;

  return ReactDOM.createPortal(
    loading ? (
      <Loading />
    ) : (
      <div className="uk-modal-dialog">
        <button
          className="uk-modal-close-default"
          type="button"
          onClick={() => onSetClose(OPEN_FULL, "#webModal-full")}
          uk-close="true"
        ></button>

        <div className="uk-modal-body">
          <section className="uk-section uk-section-small">
            {content?.length === 0 ? (
              <PageNotFound />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="uk-container"
              />
            )}
            <div
              className="col-12 text-center mt-5"
              style={{ textAlign: "center" }}
            >
              <button
                onClick={() => onSetClose(OPEN_FULL, "#webModal-full")}
                className="uk-button uk-button-primary"
              >
                Volver
              </button>
            </div>
          </section>
        </div>
      </div>
    ),
    document.getElementById("webModal-full")
  );
};
export default WarrantyModal;
