import React, { useContext }  from "react";
import AccountContext from "context/Account";
import { store } from "helpers/functions";
import { Helmet } from "react-helmet";

const SEOHelmet = ({ type = "web" }) => {
  const { seoData } = useContext(AccountContext);
  const { metaTitle, metaDescription, metaKeywords } = seoData;

  if (process.env.REACT_APP_SEO === '0') return null;
  return (
    <>
      {type === "web" && (
        <Helmet>
          <title>{metaTitle ? `${store} - ${metaTitle}` : store}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
        </Helmet>
      )}
    </>
  );
};

export default SEOHelmet;
