export const initialState = {
    loading: true,
    d1Items: [],
    shippingRate: {},
    defaultAddress: {},
    formPayment: {},
    d2items: [],
    d2ShippingRate: {},
    d4Items: [],
    d4ShippingRate: [],
    balance: {},
    open: 'none',
    submitOrder: {},
    siremasPoints: 0
}