import React from "react";

const ListAddress = ({ addresses, shippingType, addressId, setAddressId }) => {
  return (
    <>
      {addresses
        ?.filter((d) => d.shipping_type === shippingType)
        .map((item, idx) => (
          <li key={idx}>
            {item.coverage === 1 ? (
              <label>
                <div>
                  <input
                    onChange={() => setAddressId(item.addressid)}
                    className="uk-radio"
                    type="radio"
                    defaultChecked={item.addressid === addressId}
                    name="radio-address"
                  />
                </div>
                <div>
                  {item.tag}
                  <br />
                  {`${item.address} ${item.address2}, ${item.sector}, ${item.state}`}
                </div>
              </label>
            ) : (
              <>
                <strong>Sin cobertura</strong>
                <div>
                  <p>
                  {item.tag}
                  <br />
                  {`${item.address} ${item.address2} ${item.sector}, ${item.state}`}
                  </p>
                </div>
              </>
            )}
          </li>
        ))}
    </>
  );
};

export default ListAddress;
